import Button from "designToken/button/Button";
import { ButtonFactoryProps } from "domain/task/components/DailyTask/DailyTaskCard";
import { useClaimAnimation } from "domain/task/components/DailyTask/hooks/useClaimAnimation";
import { Reward } from "domain/task/components/DailyTask/Reward";
import { useTaskProvider } from "domain/task/TaskProvider";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { analyticsEvent, logCustomEvent } from "ga";
import { BUTTON_PATTERN, BUTTON_SIZE, BUTTON_VARIANTS } from "designToken/button/types";
import { VideoPlayerStatus } from "../../TaskVideoPlayer/hooks/useVideoPlayer";
import { QUEST_TYPE } from "../../../../../types/quest/QuestType";


const wrapCbWithLogCustomEvent = <Callback extends Function>(cb: Callback) => async () => {
    logCustomEvent(analyticsEvent.webTaskWatchTutorials);
    await cb();
};

export const WatchVideoButtonFactory: React.FC<ButtonFactoryProps> = ({
                                                                          taskInfo,
                                                                          apLevel,
                                                                          taskProgress,
                                                                          taskConfig,
                                                                          taskCode
                                                                      }) => {
    const {
        rewardRef,
        handleAnimationStart
    } = useClaimAnimation();

    const {
        apOverview,
        refreshTaskPrizeClaimed,
        videoPlayer
    } = useTaskProvider();

    useEffect(() => {
        if (videoPlayer.getStatus(QUEST_TYPE.WATCH_VIDEO, taskCode) === VideoPlayerStatus.ENDED) {
            handleAnimationStart();
            refreshTaskPrizeClaimed();
            videoPlayer.reset();
        }
    }, [videoPlayer.getStatus]);

    const handlePlay = wrapCbWithLogCustomEvent(() => {
        videoPlayer.play(taskConfig!.videoUrl!, QUEST_TYPE.WATCH_VIDEO, taskCode);
    });

    if (!taskProgress || !taskInfo) {
        return null;
    }

    const disabled = !apOverview || apOverview.currentLevel !== apLevel || !taskConfig?.videoUrl;

    if (taskProgress.isCompleted) {
        return <Button
            className="position-relative text-nowrap px-0"
            variant={BUTTON_VARIANTS.tertiary}
            pattern={BUTTON_PATTERN.pill}
            size={BUTTON_SIZE.md}
            id="task-button"
            dataTestId={`task-button-${apLevel}`}
            disabled={disabled}
            onClick={handlePlay}
        >
            <FormattedMessage id="daily_task_item_button_play_back" />
        </Button>;
    }

    return <Button
        className="position-relative text-nowrap px-0"
        variant={BUTTON_VARIANTS.success}
        pattern={BUTTON_PATTERN.pill}
        size={BUTTON_SIZE.md}
        onClick={handlePlay}
        id="task-button"
        dataTestId={`task-button-${apLevel}`}
        disabled={disabled}
    >
        <FormattedMessage id="daily_task_item_button_watch_ad" />
        <div ref={rewardRef}
             className="position-absolute top-0 start-0 w-100 d-none align-items-center justify-content-center">
            <Reward depositAmount={taskInfo.coin} rubyAmount={taskInfo.ruby} />
        </div>
    </Button>;
};
