import Button from "designToken/button/Button";
import { auth, signInWithApple, signInWithGoogle } from "domain/auth/AuthConfig";
import { AuthWrapper } from "domain/auth/components/AuthWrapper";
import { ThirdPartyButtons } from "domain/auth/components/ThirdPartyButtons";
import { signInWithEmailAndPassword } from "firebase/auth";
import { analyticsEvent, logCustomEvent } from "ga";
import { FIREBASE_ERROR_CODE, getFirebaseErrorMessage } from "helpers/ErrorHelper";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { NavLink } from "react-router-dom";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";
import useFantasyHistory from "hooks/useFantasyHistory";
import Divider from "domain/auth/components/Divider";
import { throttle } from "lodash";
import { BUTTON_PATTERN, BUTTON_SIZE, BUTTON_VARIANTS } from "designToken/button/types";
import { FantasyTextField } from "components/reactHookFormField/FantasyTextField";
import { FantasyPasswordField } from "components/reactHookFormField/FantasyPasswordField";
import { Icon } from "helpers/IconHelper";
import { useAuth } from "domain/auth/AuthProvider";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { CHECKBOX_VARIANTS } from "components/checkbox/types";
import { FantasyCheckboxField } from "components/reactHookFormField/FantasyCheckboxField";
import { ErrorMessage } from "components/error/ErrorMessage";
import { cn } from "helpers/cn";
import SYSTEM_LINKS from "helpers/LinkHelper";
import { useLocaleProvider } from "LocaleProvider";
import { TEXT_FIELD_VARIANT } from "components/input/FantasyText";
import PagcorLogoSection from "../signUp/components/responsibleGamingPopup/PagcorLogoSection";
import { ContactInfoWithLink } from "domain/auth/components/ContactInfoWithLink";
import { FONT } from "designToken/font";

export interface LoginForm {
    email: string;
    password: string;
    isTncAgree: boolean;
}

const Login: React.FC = () => {
    const intl = useIntl();
    const history = useFantasyHistory();
    const { preLoginSetting } = useAuth();
    const { locale } = useLocaleProvider();

    const [loginFailMessage, setLoginFailMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const form = useForm<LoginForm>({
        resolver: zodResolver(z.object({
                email: z.string()
                    .min(1, { message: intl.formatMessage({ id: "login_page_label_email_is_required" }) })
                    .email({ message: intl.formatMessage({ id: "login_page_label_provide_a_valid_email" }) }),
                password: z.string()
                    .min(1, { message: intl.formatMessage({ id: "login_page_label_password_is_required" }) })
                    .min(6, { message: intl.formatMessage({ id: "login_page_label_password_6_chars" }) }),
                isTncAgree: z.boolean().refine(value => value),
            })
        ),
        defaultValues: {
            email: "",
            password: "",
            isTncAgree: false,
        },
        mode: "onChange"
    });

    const { watch } = form;

    const handleLoginFlow = throttle(async (callback, data) => {
        if (isLoading) return;
        setIsLoading(true);
        setLoginFailMessage(null);

        try {
            await callback(...data);
            logCustomEvent(analyticsEvent.webLogInSuccess);
        } catch (_error: any) {
            const errorMessage = getFirebaseErrorMessage(_error.code);
            switch (_error.code) {
                case FIREBASE_ERROR_CODE.INVALID_LOGIN_CREDENTIALS:
                case FIREBASE_ERROR_CODE.USER_DISABLED:
                case FIREBASE_ERROR_CODE.USER_NOT_FOUND:
                case FIREBASE_ERROR_CODE.WRONG_PASSWORD:
                    form.setError("email", { message: errorMessage });
                    form.setError("password", { message: errorMessage });
                    break;
                default:
                    setLoginFailMessage(errorMessage);
            }
        } finally {
            setIsLoading(false);
        }
    }, 100);

    const handleLogin = form.handleSubmit(async data => {
        await handleLoginFlow(signInWithEmailAndPassword, [auth, data.email, data.password]);
    });

    const handleGoogleLogin = async () => {
        logCustomEvent(analyticsEvent.webSignUpClickGoogle);
        await handleLoginFlow(signInWithGoogle, []);
    };

    const handleAppleLogin = async () => {
        logCustomEvent(analyticsEvent.webSignUpClickApple);
        await handleLoginFlow(signInWithApple, []);
    };

    return <AuthWrapper onNavBack={() => {
        history.replace(ROUTER_PATH_CONFIG.auth.index);
    }}>
        <div>
            <h1 className="tw-text-h3 tw-font-bold tw-text-grey-1000">
                <FormattedMessage id="login_page_title_login" />
            </h1>
            <p className="tw-text-body-2 tw-text-grey-550">
                <FormattedMessage id="login_page_description" />
            </p>
        </div>
        <div className="tw-flex tw-flex-col tw-gap-4 tw-flex-grow">
            {
                preLoginSetting?.isNormal && <>
                    <ThirdPartyButtons disabled={isLoading}
                                       onGoogleClick={handleGoogleLogin}
                                       onAppleClick={handleAppleLogin}
                                       googleLabelId="login_page_button_google_log_in"
                                       appleLabelId="login_page_button_apple_log_in" />

                    <Divider>
                        <FormattedMessage id="login_page_or_login_with" />
                    </Divider>
                </>
            }

            <FormProvider {...form}>
                <form onSubmit={handleLogin}>
                    <div className="tw-flex tw-flex-col tw-gap-4">
                        <FantasyTextField
                            name="email"
                            variant={TEXT_FIELD_VARIANT.OUTLINED}
                            inputProps={{
                                type: "email",
                                placeholder: intl.formatMessage({ id: "label_email" }),
                                className: "tw-placeholder-grey-600",
                                autoComplete: "email",
                                "data-testid": "fantasy-input-email"
                            }}
                            iconLeft={<Icon.Mail className="tw-text-grey-250" />}
                        />
                        <FantasyPasswordField
                            name="password"
                            variant={TEXT_FIELD_VARIANT.OUTLINED}
                            inputProps={{
                                placeholder: intl.formatMessage({ id: "label_password" }),
                                className: "tw-placeholder-grey-600",
                                autoComplete: "current-password",
                                "data-testid": "fantasy-input-password"
                            }}
                        />

                        <NavLink className="tw-text-body-1 tw-text-info-700 tw-block tw-ms-auto tw-w-fit"
                                 to={{
                                     pathname: ROUTER_PATH_CONFIG.auth.resetPassword,
                                     state: { email: watch("email") }
                                 }}
                        >
                            <FormattedMessage id="login_page_label_hint_forgot_password" />
                        </NavLink>
                    </div>
                    <div>
                        <div
                            className={cn("tw-p-1 tw-mt-7 tw-mb-1", { "tw-bg-error-50": form.formState.errors["isTncAgree"] })}
                        >
                            <FantasyCheckboxField
                                name="isTncAgree"
                                variant={CHECKBOX_VARIANTS.primary}
                                textFont={FONT.body2}
                                showErrorMessage={false}
                            >
                                <FormattedMessage id="label_agree_terms_and_conditions"
                                                  values={{
                                                      termsAndConditions:
                                                          <a target="_blank"
                                                             className="tw-text-info-700 tw-no-underline"
                                                             href={SYSTEM_LINKS.termsAndConditions(locale)}
                                                             rel="noreferrer">
                                                              <FormattedMessage
                                                                  id="me_page_menu_label_terms_and_conditions" />
                                                          </a>
                                                      ,
                                                      privacyAndPolicy:
                                                          <a target="_blank"
                                                             className="tw-text-info-700 tw-no-underline"
                                                             href={SYSTEM_LINKS.privacyPolicy(locale)}
                                                             rel="noreferrer">
                                                              <FormattedMessage
                                                                  id="me_page_menu_label_privacy_policy" />
                                                          </a>
                                                  }} />
                            </FantasyCheckboxField>
                        </div>
                        {form.formState.errors["isTncAgree"] &&
                            <ErrorMessage
                                errorMessage={intl.formatMessage({ id: "login_page_label_tnc_not_agree" })} />}
                    </div>
                    <div className="tw-pt-4 tw-pb-3">
                        <PagcorLogoSection />
                    </div>
                    <Button
                        disabled={isLoading}
                        variant={BUTTON_VARIANTS.primary}
                        size={BUTTON_SIZE.lg}
                        pattern={BUTTON_PATTERN.pill}
                        className="tw-w-full tw-mt-5"
                    >
                        <FormattedMessage id="login_page_label_log_in" />
                    </Button>
                    <div className="tw-text-center tw-text-body-1 tw-mt-4 tw-text-error-700">
                        {loginFailMessage}
                    </div>
                </form>
            </FormProvider>
        </div>
        <div className="text-center tw-flex tw-flex-col tw-gap-3">
            <div>
                <div
                    className="tw-text-body-2 tw-text-grey-550 tw-flex tw-justify-center tw-items-center tw-gap-2 tw-mb-1">
                    <FormattedMessage id="login_page_label_do_not_have_account" tagName="div" />
                    <NavLink className="tw-text-info-700" to={ROUTER_PATH_CONFIG.auth.signUp}>
                        <FormattedMessage id="login_page_label_sign_up" />
                    </NavLink>
                </div>
                <ContactInfoWithLink />
            </div>
            <NavLink className="tw-text-info-700 tw-text-body-2"
                     data-testid="delete-account-button"
                     to={ROUTER_PATH_CONFIG.deleteUser.index}
            >
                <FormattedMessage id="label_deactivate_account_message" />
            </NavLink>
        </div>
    </AuthWrapper>;
};

export default Login;
