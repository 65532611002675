import SpeechBubble from "components/speechBubble";
import { SportItem, SportName, SportWrapper } from "domain/home/HomeStyle";
import { cn } from "helpers/cn";
import { isEqual } from "lodash";
import React from "react";
import { SPORT } from "types/sports/Sport";
import { useCurrentSport } from "domain/sport/useCurrentSport";
import { useSportConfigs } from "domain/sport/useSportConfigs";

const AdditionalMessage: React.FC<{ message: string }> = ({ message }) => {

    return <SpeechBubble
        arrow="left"
        arrowSize="small"
        className="tw-text-grey-1000 tw-text-overline tw-leading-[100%] tw-px-1 tw-whitespace-nowrap tw-mt-1.5 tw-mb-2.5"
        bgClassName="tw-bg-grey-150"
    >
        {message}
    </SpeechBubble>;
};

const isCurrentSport = (sport: string, currentSport: SPORT) => isEqual(currentSport, sport);

export const SportMenu: React.FC = () => {
    const sportTabs = useSportConfigs();
    const { currentSport, changeCurrentSport } = useCurrentSport();

    return <>
        {
            sportTabs.map(({ icon, name, key: sportKey, enabled, message }) => {
                const isSelected = isCurrentSport(sportKey, currentSport);
                const isDisable = !enabled;

                return <SportWrapper key={sportKey}>
                    <SportItem
                        $isSelected={isSelected}
                        className={cn(isDisable ? "tw-cursor-default" : "tw-cursor-pointer")}
                        onClick={() => !isDisable && changeCurrentSport(sportKey)}
                    >
                        <div className="tw-mr-[0.2rem]">
                            {icon("1.8rem", isSelected ? "nav-tabs-on-text" : "nav-tabs-off-text")}
                        </div>
                        <SportName $isSelected={isSelected}>
                            {name}
                        </SportName>

                    </SportItem>

                    {message && <AdditionalMessage message={message} />}
                </SportWrapper>;
            })}
    </>;
};
