import React from "react";
import { useIntl } from "react-intl";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { Icon } from "helpers/IconHelper";
import { BOLD_WEIGHT, BoldText } from "components/boldText/BoldText";
import { FantasyTextField } from "components/reactHookFormField/FantasyTextField";
import { TEXT_FIELD_VARIANT } from "components/input/FantasyText";
import { BirthFormField } from "domain/responsibleGaming/components/BirthFormField";
import { FantasySelectField } from "components/reactHookFormField/FantasySelectField";
import { DataCannotEditConfirmCheckbox } from "domain/responsibleGaming/components/DataCannotEditConfirmCheckbox";
import { ResponsibleGamingRegistrationForm } from "domain/responsibleGaming/ResponsibleGamingRegistrationPopup";

interface FormProps {
    form: UseFormReturn<ResponsibleGamingRegistrationForm>;
}

export const Form: React.FC<FormProps> = ({ form }) => {
    const intl = useIntl();
    return <FormProvider {...form}>
        <div className="tw-flex tw-flex-col tw-gap-2">
            <div className="tw-flex tw-flex-col tw-gap-4 tw-bg-grey-50 tw-p-3 tw-rounded-[1.2rem]">
                <div className="tw-flex tw-flex-col tw-gap-1 tw-px-1 tw-items-center">
                    <div
                        className="tw-flex tw-gap-1 tw-px-1 tw-py-0.5 tw-bg-grey-150 tw-text-grey-650 tw-w-fit tw-rounded-full">
                        <Icon.User />
                        <Icon.GameController />
                    </div>
                    <div className="tw-text-caption-1">
                        <BoldText
                            text={intl.formatMessage({ id: "responsible_gaming_dialog_prompt" })}
                            weight={BOLD_WEIGHT.EXTRA_BOLD}
                        />
                    </div>
                </div>

                <FantasyTextField
                    name="firstName"
                    variant={TEXT_FIELD_VARIANT.STANDARD}
                    inputProps={{
                        type: "text",
                        placeholder: intl.formatMessage({ id: "label_first_name" }),
                        className: "tw-placeholder-grey-600",
                        autoComplete: "given-name",
                        tabIndex: 1
                    }}
                />

                <FantasyTextField
                    name="lastName"
                    variant={TEXT_FIELD_VARIANT.STANDARD}
                    inputProps={{
                        type: "text",
                        placeholder: intl.formatMessage({ id: "label_last_name" }),
                        className: "tw-placeholder-grey-600",
                        autoComplete: "family-name",
                        tabIndex: 2
                    }}
                />

                <BirthFormField />

                <div className="tw-flex tw-gap-2">
                    <FantasySelectField
                        name="countryCode"
                        variant={TEXT_FIELD_VARIANT.STANDARD}
                        inputProps={{
                            className: "tw-placeholder-grey-600 tw-w-20 tw-flex-shrink-0 tw-bg-grey-100",
                        }}
                        options={[{
                            label: "+63",
                            value: "+63"
                        }]}
                        showErrorMessage={false}
                        disabled
                    />

                    <div className="tw-flex-1">
                        <FantasyTextField
                            name="mobileNumber"
                            variant={TEXT_FIELD_VARIANT.STANDARD}
                            inputProps={{
                                type: "text",
                                placeholder: intl.formatMessage({ id: "label_mobile_number" }),
                                className: "tw-placeholder-grey-600",
                                tabIndex: 6
                            }}
                        />
                    </div>
                </div>

            </div>
            <DataCannotEditConfirmCheckbox />
        </div>
    </FormProvider>;
};
