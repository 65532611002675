import { Icon } from "helpers/IconHelper";
import { divideByOneHundred } from "helpers/number/NumberHelper";
import useCurrency from "hooks/useCurrency/useCurrency";
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { MdCheckCircle } from "react-icons/md";
import { FormattedMessage } from "react-intl";
import { FullRubyValue, ListTitle, RubyWrapper } from "./ConfirmationDialog/ConfirmationModal/ConfirmationModalStyle";
import { useSetting } from "hooks/useSetting";

interface FullRubySectionProps {
    canUseRuby: boolean;
    onToggleRuby: () => void;
    isUseRuby: boolean;
    costRubyCent: number;
}

export function RubyDiscountExchangeRateTooltip(props: { rubyExchangeRate: number }) {
    return <OverlayTrigger
        placement="top"
        overlay={
            <Tooltip>
                <div className="text-start fz-sf-regular-caption-2">
                    <FormattedMessage id="label_full_ruby_discount"
                                      values={{ rubyExchangeRate: props.rubyExchangeRate }} /><br />
                </div>
            </Tooltip>
        }
    >
                <span className={"tw-flex tw-items-center tw-text-grey-300"}>
                    <Icon.Info />
                </span>
    </OverlayTrigger>;
}

export const FullRubySection: React.FC<FullRubySectionProps> = ({
                                                                    canUseRuby,
                                                                    onToggleRuby,
                                                                    isUseRuby,
                                                                    costRubyCent,
                                                                }) => {
    const { currentCurrencyObject: { toFormattedNumber } } = useCurrency();
    const { settingState: { contest: { rubyExchangeRate } } } = useSetting();

    if (!canUseRuby) {
        return null;
    }

    return <RubyWrapper data-testid="deposit-confirmation-full-ruby">
        <ListTitle>
            <FormattedMessage id="label_full_ruby" />
            <RubyDiscountExchangeRateTooltip rubyExchangeRate={rubyExchangeRate} />
        </ListTitle>
        <FullRubyValue
            data-testid="ruby-switch"
            onClick={onToggleRuby}
            $isUseRuby={isUseRuby}
        >
            {toFormattedNumber(divideByOneHundred(costRubyCent))}
            <MdCheckCircle size={20} />
        </FullRubyValue>
    </RubyWrapper>;
};
