import { useState } from "react";
import { KycVerificationVo } from "domain/kyc/types";
import { merge } from "lodash";

const initialState: KycVerificationVo = {
    firstName: "",
    lastName: "",
    yearOfBirth: "",
    monthOfBirth: "",
    dayOfBirth: "",
    nationality: "",
    placeOfBirth: "",
    currentAddress: "",
    permanentAddress: "",
    countryCode: "",
    phoneNumber: "",
    natureOfWork: "",
    sourceOfIncome: "",
    documentType: "",
    idNumber: "",
    idFrontSideImage: null,
    identityProofPhoto: null,
};


export const useKycData = () => {

    const [kycData, setKycData] = useState<KycVerificationVo>(initialState);

    const updateKycData = (userInputKycData: Partial<KycVerificationVo>) => {
        const newKycData: KycVerificationVo = merge(kycData, userInputKycData);
        setKycData(newKycData);
        return newKycData;
    };

    return {
        kycData,
        updateKycData,
    };
};
