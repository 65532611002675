import { fetchResponsibleGamingRegistrationUrl, } from "apis/ApiUrlConfig";
import { ajax } from "apis/ApiUtils";
import { ResponsibleGamingRegistrationDto } from "data/dto/responsibleGaming/ResponsibleGamingRegistrationDto";
import {
    AddResponsibleGamingRegistrationRequest
} from "data/dto/responsibleGaming/AddResponsibleGamingRegistrationRequest";

export const fetchResponsibleGamingRegistration = async () => {
    const response = await ajax<ResponsibleGamingRegistrationDto>({ url: fetchResponsibleGamingRegistrationUrl });
    return response.data.data;
};

export const addResponsibleGamingRegistration = async (request: AddResponsibleGamingRegistrationRequest) => {
    await ajax({
        url: fetchResponsibleGamingRegistrationUrl,
        method: "POST",
        data: { ...request }
    });
};
