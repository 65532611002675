import { ApiError } from "apis/Api";
import { fetchSetting } from "apis/setting/SettingApi";
import BottomNavbar from "components/container/bottom/BottomNavbar";
import { LiveChat } from "components/liveChat/LiveChat";
import { LoadingProvider } from "components/loading/Loading";
import SelectTeamPanel from "containers/selectTeamPanel";
import TeamPreview from "containers/teamPreview";
import ActivityPointProgram from "domain/ap/ActivityPointProgram";
import ChatRoom from "domain/chat/ChatRoom";
import AddCoinContainer from "domain/coin/AddCoinContainer";
import JoinContestFlow from "domain/contest/join/JoinContestFlow";
import MyCoupon from "domain/coupon/pages/MyCoupon";
import Currency from "domain/currency/Currency";
import FriendContainer from "domain/friend/FriendContainer";
import Home from "domain/home/Home";
import HowToPlayPage from "domain/howToPlay/HowToPlayPage";
import Kyc from "domain/kyc/Kyc";
import Language from "domain/language/Language";
import { MainWrapper, PageWrapper } from "domain/MainStyle";
import MyMatches from "domain/match/pages/my/MyMatches";
import MyStartedMatchDetail from "domain/match/pages/started/MyStartedMatchDetail";
import MatchDetailFlow from "domain/match/pages/upcoming/MatchDetailFlow";
import MeSwitcher from "domain/me/MeSwitcher";
import ResultMatchDetail from "domain/result/detail/ResultMatchDetail";
import ResultMatches from "domain/result/ResultMatches";
import RewardContainer from "domain/reward/RewardContainer";
import MyNineCatSwitch from "domain/slate/views/MySlateDetail/MyNineCatSwitch";
import NineCatPage from "domain/slate/views/NineCatPage";
import WalletHistory from "domain/wallet/WalletHistory";
import PaymongoWinningWithdraw from "domain/withdraw/PaymongoWithdraw";
import { createErrorObject } from "helpers/ErrorHelper";
import useFantasyQuery from "hooks/useFantasyQuery";
import { QUERY_KEY } from "hooks/useFantasyQuery/type";
import { ERROR_HANDLER_FLOW } from "hooks/useHandleApiError";
import useMyProfile from "hooks/useMyProfile";
import useUserIdentity from "hooks/useUserIdentity";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";
import { useSelector } from "store";
import { updateFeatureConfig } from "store/actions/Actions";
import { addErrorPopup } from "store/actions/AlertActions";
import { isVerified } from "types/kyc/KycStatus";
import useFantasyConfig from "hooks/useFantasyConfig";
import Task from "domain/task";
import AppPopupsFlow from "domain/popup/AppPopupsFlow";
import { useCurrentSport } from "domain/sport/useCurrentSport";
import { useSetting } from "hooks/useSetting";


const B2CRoutes = () => {
    const { featureConfig } = useFantasyConfig();
    const { myProfile } = useMyProfile();

    return <Switch>
        <Route path={ROUTER_PATH_CONFIG.me.index}>
            <MeSwitcher />
        </Route>
        <Route path={ROUTER_PATH_CONFIG.reward.index}>
            <RewardContainer />
        </Route>
        <Route exact path={ROUTER_PATH_CONFIG.chatRoom}>
            <ChatRoom />
        </Route>
        <Route path={ROUTER_PATH_CONFIG.userProfile.index}>
            <FriendContainer />
        </Route>
        <Route path={ROUTER_PATH_CONFIG.activityPoint}>
            <ActivityPointProgram />
        </Route>
        {
            featureConfig.addCoin && (
                <Route path={ROUTER_PATH_CONFIG.deposit.index}>
                    <AddCoinContainer />
                </Route>
            )
        }
        <Route path={ROUTER_PATH_CONFIG.language}>
            <Language />
        </Route>
        {
            featureConfig.canUserSetCurrencyFormat &&
            <Route path={ROUTER_PATH_CONFIG.currency}>
                <Currency />
            </Route>
        }
        {
            featureConfig.withdrawWinning && isVerified(myProfile?.kycStatus) && (
                <Route path={ROUTER_PATH_CONFIG.withdraw}>
                    <PaymongoWinningWithdraw />
                </Route>
            )
        }
        {
            featureConfig.kyc && (
                <Route path={ROUTER_PATH_CONFIG.verifyKyc.index}>
                    <Kyc />
                </Route>
            )
        }
        <Redirect to={ROUTER_PATH_CONFIG.home} />
    </Switch>;
};

const MainProvider = ({ children }) => {
    return (
        <LoadingProvider>
            <MainWrapper>
                <BottomNavbar />
                <PageWrapper>{children}</PageWrapper>
            </MainWrapper>
            <TeamPreview />
            <SelectTeamPanel />
        </LoadingProvider>
    );
};

const Main = () => {
    const dispatch = useDispatch();
    const {
        UserState: { isNewUser },
    } = useSelector(store => store);
    const { currentSport } = useCurrentSport();
    const { settingState: settings, updateSettingState } = useSetting();
    const { isB2B } = useUserIdentity();
    const { getMyProfile } = useMyProfile();

    const setting = useFantasyQuery([QUERY_KEY.SETTING], fetchSetting, {
        onFail: (_error: ApiError) => {
            dispatch(addErrorPopup(createErrorObject(_error)));
            return ERROR_HANDLER_FLOW.STOP;
        }
    });

    useEffect(() => {
        if (setting.data) {
            updateSettingState(setting.data);

            //todo remove after feature config not used
            const fantasyGameConfig = window.FANTASY_GAME_CONFIG();
            dispatch(updateFeatureConfig({
                ...setting.data,
                hasApp: fantasyGameConfig.featureConfig.hasApp,
                appleLogin: fantasyGameConfig.featureConfig.appleLogin
            }));
        }
    }, [setting.data]);

    useEffect(() => {
        getMyProfile();
    }, []);

    if (!settings.status) return null;

    return (
        <MainProvider>
            <Switch>
                <Route exact={!isNewUser} path={ROUTER_PATH_CONFIG.home}>
                    <Home key={currentSport} />
                </Route>
                <Route path={ROUTER_PATH_CONFIG.myMatch.index}>
                    <MyMatches />
                </Route>
                <Route exact path={ROUTER_PATH_CONFIG.result.index}>
                    <ResultMatches />
                </Route>
                <Route exact path={ROUTER_PATH_CONFIG.startedMatchDetail}>
                    <MyStartedMatchDetail />
                </Route>
                <Route path={ROUTER_PATH_CONFIG.matchDetail}>
                    <MatchDetailFlow />
                </Route>
                <Route path={ROUTER_PATH_CONFIG.result.detail}>
                    <ResultMatchDetail />
                </Route>
                <Route path={ROUTER_PATH_CONFIG.joinContest}>
                    <JoinContestFlow />
                </Route>
                <Route path={ROUTER_PATH_CONFIG.wallet}>
                    <WalletHistory />
                </Route>
                <Route path={ROUTER_PATH_CONFIG.coupon}>
                    <MyCoupon />
                </Route>
                <Route path={ROUTER_PATH_CONFIG.slate.myNineCatSlateDetail}>
                    <MyNineCatSwitch />
                </Route>
                <Route path={ROUTER_PATH_CONFIG.slate.index}>
                    <NineCatPage />
                </Route>
                {
                    settings.questEnabled &&
                    <Route path={ROUTER_PATH_CONFIG.task.index}>
                        <Task />
                    </Route>
                }
                {
                    isB2B
                        ? <B2BRoutes />
                        : <B2CRoutes />
                }
                <Redirect to="/" />
            </Switch>
            <LiveChat />
            <AppPopupsFlow />
        </MainProvider>
    );
};

const B2BRoutes = () => {
    return <Switch>
        <Route path={ROUTER_PATH_CONFIG.guide}>
            <HowToPlayPage />
        </Route>
        <Redirect to={ROUTER_PATH_CONFIG.home} />
    </Switch>;
};

export default Main;
