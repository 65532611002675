import { DarkTopNavbar } from "components/container/navbar/TopNavbar";
import B2BHomeNavbar from "domain/home/components/FantasyHomeNavbar/B2BHomeNavbar/B2BHomeNavbar";
import { HomeNavWrapper } from "domain/home/HomeNavWrapper";
import { SportTabs } from "domain/home/SportTabs";
import { ResultMatchesNavbarWrapper } from "domain/result/components/ResultMatchesNavbarStyle";
import NewWalletButton from "domain/wallet/components/button/TopNavbarWalletButton";
import { cn } from "helpers/cn";
import useUserIdentity from "hooks/useUserIdentity";
import React, { useRef } from "react";
import { FormattedMessage } from "react-intl";
import { HowToPlayNavbarButton } from "domain/home/components/FantasyHomeNavbar/HowToPlayNavbarButton";
import { IconButtonFor } from "components/buttons/IconButton";

interface ResultMatchesNavbarProps {
    onFilterClick: () => void;
    isTournamentSelectAll: boolean;
}

const SportsWrapper: React.FC<{
    children: React.ReactNode;
    className?: string;
}> = ({ children, className }) => {

    return <div className={cn(
        "tw-flex tw-justify-between tw-items-center tw-px-4 tw-pt-2 tw-shadow-navbar tw-bg-grey-0",
        className
    )}>
        {children}
    </div>;
};


const ResultMatchesNavbar: React.FC<ResultMatchesNavbarProps> = ({ onFilterClick, isTournamentSelectAll }) => {
    const { isB2B } = useUserIdentity();
    const resultMatchesNavbarRef = useRef<HTMLDivElement>(null);


    return (isB2B
            ? <HomeNavWrapper>
                <B2BHomeNavbar />
                <SportsWrapper>
                    <SportTabs onClick={onFilterClick} shouldShowDot={!isTournamentSelectAll} />
                </SportsWrapper>
            </HomeNavWrapper>
            : <ResultMatchesNavbarWrapper $height={resultMatchesNavbarRef.current?.clientHeight ?? 0}>
                <div ref={resultMatchesNavbarRef}
                     className="tw-fixed tw-top-0 tw-left-0 tw-z-3 tw-w-full tw-max-w-app-max">
                    <DarkTopNavbar>
                        <div
                            className="tw-w-full tw-text-grey-0 tw-text-h6 tw-font-bold tw-text-center tw-relative">
                            <FormattedMessage id="main_page_bottom_bar_tab_result" />
                            <div className={cn("tw-absolute tw-top-0 tw-right-0", "tw-flex tw-gap-1")}>
                                {/*// TODO: only for verify & daily, and will be removed.*/}
                                <HowToPlayNavbarButton for={IconButtonFor.ON_DARK}
                                                       className="tw-right-0 tw-text-primary-600"
                                                       background={true}
                                />
                                <NewWalletButton />
                            </div>
                        </div>
                    </DarkTopNavbar>
                    <SportsWrapper className={cn("tw-px-4 header-line")}>
                        <SportTabs onClick={onFilterClick} shouldShowDot={!isTournamentSelectAll} />
                    </SportsWrapper>
                </div>
            </ResultMatchesNavbarWrapper>
    );
};

export default ResultMatchesNavbar;
