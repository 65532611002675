import _ from "lodash";
import { ERROR_CODE } from "../../../helpers/ErrorHelper";
import { MyApOverview } from "../../../types/activityPoint/MyApOverview";
import { CONTEST_TYPE_FROM_API, ENTRY_FEE_TYPE_FROM_API } from "../../../types/contest/Contest";
import { CONTEST_ENTRY_TYPE, NineCatContestVo } from "../data/ComponentTypes";

export class ContestEntry {
    public entryFeeType: ENTRY_FEE_TYPE_FROM_API;
    private contestType: CONTEST_TYPE_FROM_API;


    constructor(entryFeeType: ENTRY_FEE_TYPE_FROM_API, contestType: CONTEST_TYPE_FROM_API) {
        this.entryFeeType = entryFeeType;
        this.contestType = contestType;
    }

    isRuby() {
        return this.entryFeeType === ENTRY_FEE_TYPE_FROM_API.RUBY && this.contestType !== CONTEST_TYPE_FROM_API.PRACTICE;
    }

    isPractice() {
        return this.contestType === CONTEST_TYPE_FROM_API.PRACTICE;
    }

    isCoin() {
        return this.entryFeeType === ENTRY_FEE_TYPE_FROM_API.DEPOSIT && this.contestType !== CONTEST_TYPE_FROM_API.PRACTICE;
    }
}

export function getContestEntryType(contestEntry: ContestEntry): CONTEST_ENTRY_TYPE {
    if (contestEntry.isRuby()) {
        return CONTEST_ENTRY_TYPE.RUBY;
    }
    if (contestEntry.isCoin()) {
        return CONTEST_ENTRY_TYPE.COIN;
    }
    return CONTEST_ENTRY_TYPE.FREE;
}

export function isSpotsFilled(chosenContest: Pick<NineCatContestVo, "spots" | "slipCount">) {
    return chosenContest.spots === chosenContest.slipCount;
}

export function isTeamSizeValid(selectedTeamSize: number, myJoinedTeamsSize: number, chosenContest: Pick<NineCatContestVo, "entryLimit">) {
    return selectedTeamSize > 0 && selectedTeamSize + myJoinedTeamsSize <= chosenContest.entryLimit;
}

export function getAlreadyJoinedTeams(chosenContest: Pick<NineCatContestVo, "myJoinedTeams">, selectedTeamIds: number[]) {
    const myJoinedTeamIds = chosenContest.myJoinedTeams.map(team => team.id);
    const alreadyJoinedTeamIds = _.intersection(myJoinedTeamIds, selectedTeamIds);
    return chosenContest.myJoinedTeams.filter(team => alreadyJoinedTeamIds.includes(team.id));
}

export function getApLevelRequiredDepositCent(contest: Pick<NineCatContestVo, "entryFeeCent"> | undefined | null, coinCent, selectedTeamIds: number[]) {
    if (!contest) return 0;
    return coinCent - selectedTeamIds.length * contest.entryFeeCent;
}

export function checkJoinable(notJoinableContestId: number | undefined, contestId: number) {
    if (notJoinableContestId === undefined) return true;
    return notJoinableContestId !== contestId;
}

export function checkTeamsNotJoined(chosenContest: NineCatContestVo, selectedTeamIds: number[]) {
    const alreadyJoinedTeams = getAlreadyJoinedTeams(chosenContest, selectedTeamIds);
    if (alreadyJoinedTeams.length > 0) {
        throw new Error(ERROR_CODE.DUPLICATE_TEAMS);
    }
}

export function checkSpotsNotFilled(chosenContest: NineCatContestVo) {
    if (isSpotsFilled(chosenContest)) {
        throw new Error(ERROR_CODE.CONTEST_SPOT_FULL);
    }
}

export function checkMinApLevelSufficient(myApOverview: MyApOverview, chosenContest: NineCatContestVo) {
    if (chosenContest.minApLevelWeight > myApOverview.currentLevelWeight) {
        throw new Error(ERROR_CODE.AP_LEVEL_INSUFFICIENT);
    }
}

export function checkTeamSizeValid(teamSize: number, myJoinedTeamsSize: number, entryLimit: number) {
    if (!isTeamSizeValid(teamSize, myJoinedTeamsSize, { entryLimit })) {
        throw new Error(ERROR_CODE.CONTEST_JOIN_TEAM_SIZE_INVALID);
    }
}

export const getRenderElementsWithRemains = <Element extends any>(elements: Element[], maxElementsAmount: number) => {
    const remainsAmount = elements.length - (maxElementsAmount - 1);
    const isSquadsMoreThanMaxRenderAmount = elements.length > maxElementsAmount;
    const renderElements = isSquadsMoreThanMaxRenderAmount ? elements.slice(0, maxElementsAmount - 1) : elements;

    return { shouldRenderRemainsAmount: isSquadsMoreThanMaxRenderAmount, renderElements, remainsAmount };
};
