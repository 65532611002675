import { ajax } from "apis/ApiUtils";
import { validateSession } from "apis/ApiUrlConfig";

export const fetchValidateSession = async (sessionId: string): Promise<{
    sessionId: string
}> => {
    const { data } = await ajax<{ sessionId: string }>({
        method: "POST",
        url: validateSession,
        data: {
            sessionId
        }
    });
    return data.data;
};
