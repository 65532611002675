import { fetchMyApOverview } from "apis/ap/ActivityPointApi";
import { FirstTimePurchaseDto } from "apis/deposit/DepositApi";
import { MeRepository } from "data/repository/me/MeRepository";
import { ReferralRepository } from "data/repository/referral/ReferralRepository";
import { CareerStatsVo } from "data/vo/me/CareerStatsVo";
import { FollowVo } from "data/vo/me/FollowVo";
import { RefereeVo } from "data/vo/referral/RefereeVo";
import { MyProfileVo } from "data/vo/user/MyProfileVo";
import useUpdateWallet from "domain/wallet/useUpdateWallet";
import useFantasyQuery from "hooks/useFantasyQuery";
import { QUERY_KEY } from "hooks/useFantasyQuery/type";
import useFirstTimePurchase from "hooks/useFirstTimePurchase";
import useLatestJoinedReferee from "hooks/useLatestRefereeJoin";
import useMyProfile from "hooks/useMyProfile";
import React, { createContext, useContext, useEffect, useMemo, useRef, useState, } from "react";
import { MyApOverview } from "types/activityPoint/MyApOverview";
import { useSelector } from "store";

interface IMeContext {
    myProfile: MyProfileVo;
    depositCent: number;
    total: number;
    myApOverview?: MyApOverview;
    follow?: FollowVo;
    referee?: RefereeVo;
    careerStats?: CareerStatsVo;
    firstTimePurchase?: FirstTimePurchaseDto;

    userInfoRef: any;
    openDownloadModal: boolean;
    setOpenDownloadModal: (boolean) => void;
    hasLastRefereeJoin: boolean;
}

const MeContext = createContext<IMeContext | null>(null);

interface MeProviderProps {
    children: React.ReactNode;
}

export const MeProvider: React.FC<MeProviderProps> = ({ children }) => {
    const userInfoRef = useRef<undefined | HTMLDivElement>(undefined);
    const { myProfile } = useMyProfile();
    const firstTimePurchase = useFirstTimePurchase();
    const updateWallet = useUpdateWallet();
    const { WalletState: { coinCent, total }, } = useSelector(store => store);

    const meRepository = new MeRepository();
    const referralRepository = new ReferralRepository();

    const [openDownloadModal, setOpenDownloadModal] = useState(false);

    const myApOverview = useFantasyQuery(
        [QUERY_KEY.MY_AP_OVERVIEW],
        fetchMyApOverview,
    );
    const follow = useFantasyQuery([QUERY_KEY.MY_FOLLOW], () =>
        meRepository.getFollow(myProfile.id),
    );
    const referee = useFantasyQuery(
        [QUERY_KEY.MY_REFEREE],
        referralRepository.getReferee,
    );
    const careerStats = useFantasyQuery(
        [QUERY_KEY.MY_CAREER_STATS],
        meRepository.getCareerStats,
    );

    const { hasNewRefereeJoined } = useLatestJoinedReferee(referee.data?.lastRefereeJoinTime ?? 0);

    useEffect(() => {
        updateWallet();
    }, [updateWallet]);

    const value = useMemo<IMeContext>(() => {
        return {
            myProfile,
            depositCent: coinCent,
            total,
            myApOverview: myApOverview.data,
            follow: follow.data,
            referee: referee.data,
            careerStats: careerStats.data,
            firstTimePurchase,

            userInfoRef,
            openDownloadModal,
            setOpenDownloadModal,
            hasLastRefereeJoin: hasNewRefereeJoined,
        };
    }, [
        myProfile,
        coinCent,
        total,
        myApOverview,
        follow.data,
        referee.data,
        careerStats.data,
        firstTimePurchase,

        userInfoRef,
        openDownloadModal,
        setOpenDownloadModal,
        hasNewRefereeJoined,
    ]);

    return <MeContext.Provider value={value}>{children}</MeContext.Provider>;
};

export const useMe = () => {
    const context = useContext(MeContext);

    if (!context) {
        throw new Error("useMe must be used within a MeProvider");
    }

    return context;
};
