import { getMatchDetailUrl } from "apis/ApiUrlConfig";
import { UpcomingMatchVO } from "apis/match/type";
import Countdown from "components/countdown/Countdown";
import { MatchEmptyDisplay } from "components/empty/MatchEmptyDisplay";
import { MatchCard, MatchCardFooter, MatchCardHeader, MatchCardTournament } from "components/matchCards/MatchCardStyle";
import { UpcomingMatchCardBody } from "components/matchCards/UpcomingMatchCardBody";
import AmountDisplay from "containers/amount/AmountDisplay";
import { getCustomIconSuit } from "containers/amount/CurrencyIconSuitHelper";
import { UpcomingMatchVo } from "data/vo/match/UpcomingMatchVo";
import { LineupsOut } from "domain/match/components/LineupsOut";
import { MATCH_DETAIL_TAB_KEY } from "domain/match/pages/upcoming/MatchDetailFlow";
import { analyticsEvent, logCustomEvent } from "ga";
import { Icon } from "helpers/IconHelper";
import { enrichUpcomingMatchesWithStartDate, isMatchKickoff, isMatchPreparing } from "helpers/match/MatchHelper";
import { formatTo24HrTime } from "helpers/TimeHelper";
import { Tournament } from "helpers/TournamentHelper";
import useCurrency from "hooks/useCurrency/useCurrency";
import useFantasyHistory from "hooks/useFantasyHistory";
import { useMatchPreparingToast } from "hooks/useToast";
import React, { useMemo } from "react";
import { MdOutlineTimelapse } from "react-icons/md";
import { FormattedMessage } from "react-intl";
import { CURRENCY_ICON_SUIT_NAME } from "store/reducer/config/FantasyGameConfigState";
import { ENTRY_FEE_TYPE_FROM_API } from "types/contest/Contest";
import { useSetting } from "hooks/useSetting";
import Bonus = Icon.Bonus;

export const JoinedText = ({ myJoined, joined }) => {
    const { currentCurrencyObject: { toFormattedNumber } } = useCurrency();

    if (myJoined === 0) {
        return <div data-testid="joined-text">
            <span className="tw-font-bold tw-text-caption-1">{toFormattedNumber(joined)}</span>
            &nbsp;
            <span className="tw-text-grey-650"><FormattedMessage id="match_card_label_joined" /></span>
        </div>;
    }

    if (joined === myJoined) {
        return <div data-testid="joined-text">
            <span className="tw-font-bold tw-text-caption-1"><FormattedMessage id="label_you" /></span>
            &nbsp;
            <FormattedMessage id="match_card_label_joined" />
        </div>;
    }

    return <div data-testid="joined-text">
        <span>
            <span className="tw-font-bold tw-text-caption-1"><FormattedMessage id="label_you" /></span>
            &nbsp;
            <span className="tw-text-grey-650"><FormattedMessage id="label_and" /></span></span>&nbsp;
        <span className="tw-font-bold tw-text-caption-1">{toFormattedNumber(joined - myJoined)}</span>
        &nbsp;
        <span className="tw-text-grey-650">
            <FormattedMessage id="match_card_label_joined" />
        </span>
    </div>;
};

export const KickoffCountdown = ({ matchStartsAt, handleTimeout, fontStyle = "fz-sf-bold-subhead" }) => {
    const now = new Date().getTime();
    const { settingState: { match: { kickoffDurationMilliseconds } } } = useSetting();

    if (now > matchStartsAt + kickoffDurationMilliseconds) {
        return null;
    }

    return <div className={` d-flex align-items-center gap-1 ${fontStyle}`}
                data-testid="kickoff-countdown">
        <MdOutlineTimelapse size="1.4rem" />
        <Countdown finishAt={matchStartsAt + kickoffDurationMilliseconds}
                   handleTimeout={handleTimeout} />
    </div>;
};

const UpcomingMatchCardCenter = ({ match, handleTimeout }) => {

    const { matchStartsAt, matchStatus, matchId } = match;

    if (isMatchKickoff(matchStatus)) {
        return <KickoffCountdown key={`${matchId}-${matchStartsAt}`}
                                 matchStartsAt={matchStartsAt}
                                 handleTimeout={handleTimeout} />;
    }

    return <div className="text-end fz-sf-regular-subhead text-text-tertiary-1">
        {formatTo24HrTime(match.matchStartsAt)}
    </div>;
};

interface UpcomingCardProps {
    match: UpcomingMatchVO;
    handleTimeout: () => void;
}

const TotalPrize: React.FC<{ prizeCent: number, entryFeeType: ENTRY_FEE_TYPE_FROM_API }> = ({
                                                                                                prizeCent,
                                                                                                entryFeeType
                                                                                            }) => {

    const suitMap = useMemo(() => getCustomIconSuit(entryFeeType, {
        [CURRENCY_ICON_SUIT_NAME.DEFAULT]: {
            [ENTRY_FEE_TYPE_FROM_API.DEPOSIT]: <Icon.Winning data-testid="winning-icon"
                                                             size="1.2rem"
                                                             className="tw-mr-[0.4rem]" />,
            [ENTRY_FEE_TYPE_FROM_API.RUBY]: <Icon.Ruby data-testid="ruby-icon"
                                                       size="1.2rem"
                                                       className="tw-mr-[0.4rem]" />
        },
        [CURRENCY_ICON_SUIT_NAME.PESO]: {
            [ENTRY_FEE_TYPE_FROM_API.DEPOSIT]: <Icon.Peso data-testid="winning-icon"
                                                          size="1.3rem"
                                                          className="tw-mr-[0.4rem] tw-text-warning-900"
            />,
            [ENTRY_FEE_TYPE_FROM_API.RUBY]: <Icon.DailyRuby data-testid="ruby-icon"
                                                            size="1.4rem"
                                                            className="tw-mr-[0.4rem] tw-text-error-600"
            />,
        },
    }), [entryFeeType]);

    const { currentCurrencyObject: { toPeriodNumber } } = useCurrency();

    if (prizeCent > 0) {
        return (
            <AmountDisplay
                currencyIconSuit={suitMap}
                className="gap-0 tw-font-bold tw-text-grey-800 tw-mr-[0.6rem]"
            >
                {toPeriodNumber(prizeCent)}
            </AmountDisplay>
        );
    }

    return null;

};

const TotalPrizeWithTag = ({ hasHostDonatedPool, matchTotalWinningPrizeCent, matchTotalRubyPrizeCent }) => {
    return (
        <div data-testid="total-prize" className="tw-flex tw-items-center tw-text-caption-1">
            {hasHostDonatedPool && <div
                className="tw-mr-[0.6rem] tw-px-[0.4rem] tw-rounded-[0.5rem] tw-flex tw-items-center tw-text-white tw-border tw-border-primary-600 tw-font-bold tw-bg-primary-600">
                <Bonus size="1.2rem" className="tw-mr-[0.4rem]" />
                <FormattedMessage id="match_card_label_bonus" />
            </div>}
            <TotalPrize prizeCent={matchTotalWinningPrizeCent}
                        entryFeeType={ENTRY_FEE_TYPE_FROM_API.DEPOSIT} />
            <TotalPrize prizeCent={matchTotalRubyPrizeCent}
                        entryFeeType={ENTRY_FEE_TYPE_FROM_API.RUBY} />
        </div>
    );
};

const UpcomingCard: React.FC<UpcomingCardProps> = ({ match, handleTimeout }) => {
    const history = useFantasyHistory();
    const handleMatchPreparingToast = useMatchPreparingToast();

    const { matchId, sport, matchStatus, contests, isLineupsOut } = match;

    const matchTotalWinningPrizeCent = contests
        .filter((contest) => contest.entryFeeType === ENTRY_FEE_TYPE_FROM_API.DEPOSIT)
        .reduce((result, contest) => result + contest.regularPoolCent + contest.hostDonatedPoolCent, 0);

    const matchTotalRubyPrizeCent = contests
        .filter((contest) => contest.entryFeeType === ENTRY_FEE_TYPE_FROM_API.RUBY)
        .reduce((result, contest) => result + contest.hostDonatedPoolCent + contest.regularPoolCent, 0);

    const hasHostDonatedPool = contests.some((contest) => contest.hostDonatedPoolCent > 0);

    const joined = contests.reduce((result, contest) => result + contest.teams, 0);

    const myJoined = contests.reduce((result, contest) => result + contest.myJoinedTeamsNames.length, 0);

    const onMatchCardClick = (matchStatus) => {
        logCustomEvent(analyticsEvent.webEnterMatchDetail);

        if (isMatchPreparing(matchStatus)) {
            handleMatchPreparingToast();
            return;
        }

        history.push(getMatchDetailUrl(sport, matchId), {
            tab: MATCH_DETAIL_TAB_KEY.publicContests,
            navbarBackGoTo: history.location.pathname
        });
    };

    return (
        <MatchCard $isPreparing={isMatchPreparing(matchStatus)}
                   data-testid={`matchId-${matchId}`}
                   className="animate__animated animate__fadeIn"
                   onClick={() => onMatchCardClick(matchStatus)}
        >
            <MatchCardHeader>
                <MatchCardTournament>{match.tournament}</MatchCardTournament>
                <LineupsOut matchStatus={matchStatus} isLineupsOut={isLineupsOut} />
            </MatchCardHeader>
            <UpcomingMatchCardBody match={match} handleTimeout={handleTimeout}>
                <UpcomingMatchCardCenter match={match} handleTimeout={handleTimeout} />
            </UpcomingMatchCardBody>
            <MatchCardFooter>
                <div className="tw-flex tw-justify-between tw-w-full tw-pt-[0.4rem] tw-px-[1.6rem]">
                    <TotalPrizeWithTag hasHostDonatedPool={hasHostDonatedPool}
                                       matchTotalWinningPrizeCent={matchTotalWinningPrizeCent}
                                       matchTotalRubyPrizeCent={matchTotalRubyPrizeCent} />
                    <JoinedText joined={joined} myJoined={myJoined} />
                </div>
            </MatchCardFooter>
        </MatchCard>
    );
};

interface UpcomingMatchesProps {
    tournaments: Tournament[];
    matches: UpcomingMatchVo[];
    handleMatchTimeout: () => void;
}


const UpcomingMatches: React.FC<UpcomingMatchesProps> = ({
                                                             tournaments,
                                                             matches,
                                                             handleMatchTimeout
                                                         }) => {
    if (matches.length === 0) {
        return <MatchEmptyDisplay tournaments={tournaments} />;
    }

    return <>
        {enrichUpcomingMatchesWithStartDate(matches)
            .map((match, index) => {
                return <React.Fragment key={`match_${index}`}>
                    {match.getStartDateLabel("tw-text-start tw-font-bold")}
                    <UpcomingCard
                        match={match}
                        handleTimeout={handleMatchTimeout} />
                </React.Fragment>;
            })}
    </>;
};


export default UpcomingMatches;
