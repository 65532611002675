import { WITHDRAW_STEP } from "domain/withdraw/WithdrawCurrentStepVo";
import { useCallback, useMemo, useState } from "react";

export function useStep(defaultStep: WITHDRAW_STEP) {
    const [step, setStep] = useState<WITHDRAW_STEP>(defaultStep);

    const isFirstStep = useMemo(() => step === WITHDRAW_STEP.step1, [step]);
    const isFinalStep = useMemo(() => step === WITHDRAW_STEP.step4, [step]);

    const handleStepNext = useCallback(() => {
        setStep(_step => _step + 1);
    }, []);

    const handleStepBack = useCallback(() => {
        setStep(_step => _step - 1);
    }, []);

    const handleGoToFirstStep = useCallback(() => {
        setStep(WITHDRAW_STEP.step1);
    }, []);

    return useMemo(() => ({
        step,
        setStep,
        isFirstStep,
        isFinalStep,
        handleStepNext,
        handleStepBack,
        handleGoToFirstStep
    }), [
        step,
        setStep,
        isFirstStep,
        isFinalStep,
        handleStepNext,
        handleStepBack,
        handleGoToFirstStep
    ]);
}
