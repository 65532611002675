import { Callback } from "Common";
import React from "react";
import { FormattedMessage } from "react-intl";
import { FantasyPopupProps, POPUP_SIZE } from "../../../components/popup/FantasyBasicPopup";
import { BUTTON_PATTERN, NEW_BUTTON_VARIANTS } from "../../../designToken/button/types";
import { cn } from "../../../helpers/cn";

export interface TeamsAlreadyJoinedDialogProps {
    joinedTeamNames: string[];
    onClose: Callback;
}

export function makeTeamsAlreadyJoinedDialogProps(props: TeamsAlreadyJoinedDialogProps): FantasyPopupProps {
    return ({
            show: true,
            popupSize: POPUP_SIZE.MD,
            title: (
                <FormattedMessage id={"dialog_teams_already_joined_title"} />
            ),
            content: (
                <div className={cn(
                    "tw-flex tw-flex-col tw-items-center tw-gap-4"
                )}>
                    <p className={"tw-text-subtitle-1 tw-whitespace-pre-line"}>
                        <FormattedMessage id={"dialog_teams_already_joined_content_1"} />
                    </p>
                    <div className={
                        cn("tw-max-w-[17.2rem] tw-py-1.5 tw-px-6",
                            "tw-bg-grey-100 tw-text-secondary-600",
                            "tw-text-h6 tw-font-bold",
                            "tw-rounded-[0.8rem]"
                        )
                    }>
                        {props.joinedTeamNames.join(", ")}
                    </div>
                    <p className={"tw-text-subtitle-1 tw-whitespace-pre-line"}>
                        <FormattedMessage id={"dialog_teams_already_joined_content_2"} />
                    </p>
                </div>
            ),
            buttonSetting: {
                confirmButtonVariant: NEW_BUTTON_VARIANTS.primaryDark,
                buttonPattern: BUTTON_PATTERN.pill,
                confirmButtonText:
                    <FormattedMessage id={"button_change_team_selection"} />,
                onConfirm: props.onClose,
                cancelButton: false,
            },
            onHide: props.onClose
        }
    );
}
