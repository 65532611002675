import { zodResolver } from "@hookform/resolvers/zod";
import Button from "designToken/button/Button";
import { BUTTON_PATTERN, BUTTON_SIZE, NEW_BUTTON_VARIANTS } from "designToken/button/types";
import { FantasyPasswordField } from "components/reactHookFormField/FantasyPasswordField";
import { FantasySelectField } from "components/reactHookFormField/FantasySelectField";
import { FantasyTextField } from "components/reactHookFormField/FantasyTextField";
import { auth, signInWithApple, signInWithGoogle } from "domain/auth/AuthConfig";
import { AuthWrapper } from "domain/auth/components/AuthWrapper";
import Divider from "domain/auth/components/Divider";
import { ThirdPartyButtons } from "domain/auth/components/ThirdPartyButtons";
import { ResponsibleGamingPopup } from "domain/auth/signUp/components/responsibleGamingPopup/ResponsibleGamingPopup";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { analyticsEvent, logCustomEvent } from "ga";
import { FIREBASE_ERROR_CODE, getFirebaseErrorMessage } from "helpers/ErrorHelper";
import { Icon } from "helpers/IconHelper";
import SYSTEM_LINKS from "helpers/LinkHelper";
import { getRandomInt } from "helpers/number/NumberHelper";
import useFantasyHistory from "hooks/useFantasyHistory";
import { useLocaleProvider } from "LocaleProvider";
import { throttle } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { NavLink } from "react-router-dom";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";
import { z } from "zod";
import { useAuth } from "../AuthProvider";
import { TEXT_FIELD_VARIANT } from "components/input/FantasyText";

export interface SignUpForm {
    email: string;
    password: string;
    confirmPassword: string;
    siteId: number;
}

const SignUp: React.FC = () => {
    const intl = useIntl();
    const history = useFantasyHistory();
    const { preLoginSetting, setSelectedSiteId } = useAuth();
    const { locale } = useLocaleProvider();

    const [signUpFailMessage, setSignUpFailMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const siteOptions = useMemo(() => {
        return preLoginSetting?.sites?.map(site => ({
            label: site.name,
            value: site.id
        })) || [];
    }, [preLoginSetting?.sites]);

    const form = useForm<SignUpForm>({
        resolver: zodResolver(z.object({
                email: z.string()
                    .min(1, { message: intl.formatMessage({ id: "login_page_label_email_is_required" }) })
                    .email({ message: intl.formatMessage({ id: "login_page_label_provide_a_valid_email" }) }),
                password: z.string()
                    .min(1, { message: intl.formatMessage({ id: "login_page_label_password_is_required" }) })
                    .min(6, { message: intl.formatMessage({ id: "login_page_label_password_6_chars" }) }),
                confirmPassword: z.string()
                    .min(1, { message: intl.formatMessage({ id: "login_page_label_password_is_required" }) })
                    .min(6, { message: intl.formatMessage({ id: "login_page_label_password_6_chars" }) }),
                siteId: z.number().optional()
            })
                .refine(({ password, confirmPassword }) => {
                    return password === confirmPassword;
                }, {
                    message: intl.formatMessage({ id: "login_page_label_password_format_invalid" }),
                    path: ["confirmPassword"]
                })
        ),
        defaultValues: {
            email: "",
            password: "",
            confirmPassword: "",
            siteId: 0
        },
        mode: "onChange"
    });

    useEffect(() => {
        const subscription = form.watch((value, { name, type }) => {
            if (name === "password" && value.confirmPassword) {
                form.trigger("confirmPassword");
            }
        });

        return () => subscription.unsubscribe();
    }, [form.watch, form.trigger]);

    useEffect(() => {
        if (preLoginSetting?.isPagcor && siteOptions.length > 0) {
            const index = getRandomInt(0, siteOptions.length - 1);
            form.setValue("siteId", siteOptions[index].value);
        }
    }, [preLoginSetting]);

    const handleSignUpFlow = throttle(async (callback, data) => {
        if (isLoading) return;
        setIsLoading(true);
        setSignUpFailMessage(null);

        try {
            await callback(...data);
            logCustomEvent(analyticsEvent.webSignUpSuccess);
        } catch (_error: any) {
            const errorMessage = getFirebaseErrorMessage(_error.code);
            switch (_error.code) {
                case FIREBASE_ERROR_CODE.EMAIL_ALREADY_IN_USE:
                    form.setError("email", { message: errorMessage });
                    break;
                default:
                    setSignUpFailMessage(errorMessage);
            }
        } finally {
            setIsLoading(false);
        }
    }, 100);

    const handleSignUp = form.handleSubmit(async data => {
        setSelectedSiteId(data.siteId);
        await handleSignUpFlow(createUserWithEmailAndPassword, [auth, data.email, data.password]);
    });

    const handleGoogleLogin = async () => {
        await handleSignUpFlow(signInWithGoogle, []);
    };

    const handleAppleLogin = async () => {
        await handleSignUpFlow(signInWithApple, []);
    };

    return <>
        <AuthWrapper onNavBack={() => {
            history.replace(ROUTER_PATH_CONFIG.auth.index);
        }}>
            <div>
                <h1 className="tw-text-h3 tw-font-bold tw-text-grey-1000">
                    <FormattedMessage id="login_page_title_sign_up" />
                </h1>
                <p className="tw-text-body-2 tw-text-grey-550">
                    <FormattedMessage id="login_page_description" />
                </p>
            </div>
            <div className="tw-flex tw-flex-col tw-gap-4 tw-flex-grow">
                {
                    preLoginSetting?.isNormal && (
                        <>
                            <ThirdPartyButtons
                                disabled={isLoading}
                                onGoogleClick={handleGoogleLogin}
                                onAppleClick={handleAppleLogin}
                                googleLabelId="login_page_button_google_sign_up"
                                appleLabelId="login_page_button_apple_sign_up"
                            />

                            <Divider>
                                <FormattedMessage id="login_page_or_register_with" />
                            </Divider>
                        </>
                    )
                }

                <FormProvider {...form}>
                    <form onSubmit={handleSignUp}>
                        <div className="tw-flex tw-flex-col tw-gap-4">
                            <FantasyTextField
                                name="email"
                                variant={TEXT_FIELD_VARIANT.OUTLINED}
                                inputProps={{
                                    type: "email",
                                    placeholder: intl.formatMessage({ id: "label_email" }),
                                    className: "tw-placeholder-grey-600",
                                    autoComplete: "email",
                                    "data-testid": "fantasy-input-email"
                                }}
                                iconLeft={<Icon.Mail className="tw-text-grey-250" />}
                            />
                            <FantasyPasswordField
                                name="password"
                                variant={TEXT_FIELD_VARIANT.OUTLINED}
                                inputProps={{
                                    placeholder: intl.formatMessage({ id: "label_password" }),
                                    className: "tw-placeholder-grey-600",
                                    autoComplete: "new-password",
                                    "data-testid": "fantasy-input-password"
                                }}
                            />
                            <FantasyPasswordField
                                name="confirmPassword"
                                variant={TEXT_FIELD_VARIANT.OUTLINED}
                                inputProps={{
                                    placeholder: intl.formatMessage({ id: "login_page_label_confirm_password" }),
                                    className: "tw-placeholder-grey-600",
                                    autoComplete: "new-password",
                                    "data-testid": "fantasy-input-confirmPassword"
                                }}
                            />

                            {
                                preLoginSetting?.isPagcor && (
                                    <div>
                                        <FantasySelectField
                                            name="siteId"
                                            variant={TEXT_FIELD_VARIANT.OUTLINED}
                                            inputProps={{
                                                placeholder: intl.formatMessage({ id: "placeholder_date" }),
                                                className: "tw-placeholder-grey-600",
                                                "data-testid": "fantasy-select-site-selected"
                                            }}
                                            options={siteOptions}
                                        />
                                        <div className="tw-text-grey-600 tw-text-caption-1">
                                            <FormattedMessage id="register_selected_site_label" />
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        <Button
                            disabled={isLoading}
                            variant={NEW_BUTTON_VARIANTS.primaryDark}
                            size={BUTTON_SIZE.lg}
                            pattern={BUTTON_PATTERN.pill}
                            className="w-100 mt-5"
                        >
                            <FormattedMessage id="login_page_label_sign_up" />
                        </Button>
                        <div className="tw-text-body-2 tw-text-center tw-my-4">
                            <FormattedMessage id="login_page_label_agree_rule"
                                              values={{
                                                  termsAndConditions:
                                                      <a target="_blank"
                                                         className="tw-text-info-700"
                                                         href={SYSTEM_LINKS.termsAndConditions(locale)}
                                                         rel="noreferrer">
                                                          <FormattedMessage
                                                              id="me_page_menu_label_terms_and_conditions" />
                                                      </a>
                                                  ,
                                                  privacyAndPolicy:
                                                      <a target="_blank"
                                                         className="tw-text-info-700"
                                                         href={SYSTEM_LINKS.privacyPolicy(locale)} rel="noreferrer">
                                                          <FormattedMessage id="me_page_menu_label_privacy_policy" />
                                                      </a>
                                              }} />
                        </div>
                        <div className="tw-text-center tw-text-body-1 tw-mt-4 tw-text-error-700">
                            {signUpFailMessage}
                        </div>
                    </form>
                </FormProvider>
            </div>
            <div
                className="tw-text-body-2 tw-text-grey-550 tw-flex tw-justify-center tw-items-center tw-gap-2">
                <FormattedMessage id="login_page_label_already_have_account" tagName="div" />
                <NavLink className="tw-text-info-700" to={ROUTER_PATH_CONFIG.auth.login}>
                    <FormattedMessage id="login_page_label_log_in" />
                </NavLink>
            </div>
        </AuthWrapper>
        {
            preLoginSetting?.isPagcor && <ResponsibleGamingPopup />
        }
    </>;
};

export default SignUp;
