import { TournamentFilter } from "components/tournament/TournamentFilter";
import { TournamentFilterButtons } from "components/tournament/TournamentFilterButtons";
import { GenerateDisplayTextProps } from "components/tournament/types";
import Switch from "designToken/checkbox/Switch";
import Dialog, { DIALOG_SIZE } from "designToken/dialog/Dialog";
import {
    CloseButton,
    ModalContainer,
    ModalTitle,
    ModalTitleWrapper,
    PreparingWrapper,
    Text
} from "domain/home/components/tournamentFilterDialog/TournamentFilterDialogStyle";
import { useHomeProvider } from "domain/home/HomeProvider";
import { storageHelper } from "helpers/localStorage/LocalStorageHelper";
import { LOCAL_STORAGE_KEYS } from "helpers/localStorage/types";
import { getCheckboxState, Tournament } from "helpers/TournamentHelper";
import React, { useEffect, useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import { IoClose } from "react-icons/io5";
import { FormattedMessage } from "react-intl";
import { useCurrentSport } from "domain/sport/useCurrentSport";


const TournamentFilterDialog = () => {
    const {
        tournaments,
        isTournamentFilterDialogShow,
        handleTournamentFilterDialogClose,
        handleFilterTournament
    } = useHomeProvider();

    const [filteredTournaments, setFilteredTournaments] = useState<Tournament[]>([]);
    const { currentSport } = useCurrentSport();
    const initShowPreparingMatches = storageHelper(LOCAL_STORAGE_KEYS.SHOW_PREPARING, currentSport).get() ?? true;

    const [isPreparingMatchesSwitchOn, setIsPreparingMatchesSwitchOn] = useState(initShowPreparingMatches);
    const onClose = () => {
        setIsPreparingMatchesSwitchOn(initShowPreparingMatches);
        handleTournamentFilterDialogClose();
    };

    const onApply = () => {
        handleFilterTournament({
            skippedTournaments: filteredTournaments,
            isPreparingMatchesShowed: isPreparingMatchesSwitchOn
        });
    };

    const selectAllState = useMemo(() => {
        const checkedCount = filteredTournaments.filter(item => item.checked).length;
        const itemsCount = tournaments.length;

        return getCheckboxState(checkedCount, itemsCount);
    }, [filteredTournaments, tournaments.length]);

    const generateDisplayText = ({
                                     modalShowPreparingMatches,
                                     name,
                                     scheduledMatches,
                                     preparingMatches
                                 }: GenerateDisplayTextProps) => {
        return modalShowPreparingMatches
            ? `${name} (${scheduledMatches} | ${preparingMatches})`
            : `${name} (${scheduledMatches})`;
    };


    useEffect(() => {
        setFilteredTournaments(tournaments);
    }, [tournaments]);


    return (
        <Dialog show={isTournamentFilterDialogShow} size={DIALOG_SIZE.LG} onHide={onClose}>
            <ModalContainer>
                <ModalTitleWrapper className="mb-4">
                    <ModalTitle><FormattedMessage id="tournament_filter_dialog_title" /></ModalTitle>
                    <CloseButton onClick={onClose}><IoClose /></CloseButton>
                </ModalTitleWrapper>
                <PreparingWrapper>
                    <Text>
                        <FormattedMessage id="tournament_filter_dialog_label_preparing_switch" />
                    </Text>
                    <Form>
                        <Switch
                            checked={isPreparingMatchesSwitchOn}
                            onChange={e => setIsPreparingMatchesSwitchOn(e.target.checked)}
                            dataTestId="showPreparingSwitch"
                        />
                    </Form>
                </PreparingWrapper>
                <TournamentFilter
                    selectAllState={selectAllState}
                    tournaments={filteredTournaments}
                    setFilteredTournaments={setFilteredTournaments}
                    generateDisplayText={generateDisplayText}
                    isPreparingMatchesSwitchOn={isPreparingMatchesSwitchOn}
                />
                <TournamentFilterButtons onClose={onClose} onApply={onApply} />
            </ModalContainer>
        </Dialog>
    );
};

export default TournamentFilterDialog;
