export interface Bank {
    code: string;
    bank: string;
}

export class BanksVo {
    private readonly _banks: Bank[];

    constructor(banks: Bank[]) {
        this._banks = banks;
    }

    public get banks(): Bank[] {
        return this._banks;
    }

    public getBankNameByCode(code: string): string {
        return this._banks.find(bank => bank.code === code)?.bank || "";
    }
}
