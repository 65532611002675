import {
    Background,
    BackgroundWrapper,
    LayoutMainWrapper,
    LayoutWrapper,
    Logo,
    LogoWrapper,
} from "components/layout/LayoutStyle";
import AppQrCode from "components/qrcode/AppQrCode";
import B2CWrapper from "containers/b2cWrapper/B2CWrapper";
import {
    logoTertiaryHorizontal,
    systemBackgroundBasketball,
    systemBackgroundCricket,
    systemBackgroundFootball
} from "helpers/IconHelper";
import React, { useRef } from "react";
import { useSelector } from "store";
import { CSSTransition } from "react-transition-group";
import { SPORT } from "types/sports/Sport";

function useMainLayoutBg(sport: SPORT, bg: string) {
    const ref = useRef(null);

    return {
        ref,
        bg,
        sport
    };
}

const MainLayout = ({ children }) => {
    const { SportState: { currentSport } } = useSelector(store => store);

    const bgs = [
        useMainLayoutBg(SPORT.CRICKET, systemBackgroundCricket),
        useMainLayoutBg(SPORT.FOOTBALL, systemBackgroundFootball),
        useMainLayoutBg(SPORT.BASKETBALL, systemBackgroundBasketball)
    ];

    return <LayoutWrapper>
        <LayoutMainWrapper>
            {children}
        </LayoutMainWrapper>
        <BackgroundWrapper>
            {
                bgs.map(({ sport, bg, ref }) => {
                    return <CSSTransition
                        in={currentSport === sport}
                        timeout={400}
                        classNames="main-bg-layout"
                        nodeRef={ref}
                        unmountOnExit
                        key={sport}
                    >
                        <Background
                            ref={ref}
                            $bg={bg} />
                    </CSSTransition>;
                })
            }
            {
                <B2CWrapper>
                    <LogoWrapper>
                        <Logo src={logoTertiaryHorizontal} />
                    </LogoWrapper>
                    <AppQrCode />
                </B2CWrapper>
            }
        </BackgroundWrapper>
    </LayoutWrapper>;
};

export default MainLayout;
