import { ApiError } from "apis/Api";
import { ErrorMessage } from "components/error/ErrorMessage";
import { HINT_CARD_VARIANT, HintCard } from "components/hintcard/HintCard";
import { FantasyText, TEXT_FIELD_VARIANT } from "components/input/FantasyText";
import { FantasySelect } from "components/reactHookFormField/FantasySelect";
import Button from "designToken/button/Button";
import { NEW_BUTTON_VARIANTS } from "designToken/button/types";
import { useKycLeavePopup } from "domain/kyc/components/kycLeavePopup/KycLeavePopupProvider";
import { StepProgressBar } from "domain/kyc/components/stepProgressBar/StepProgressBar";
import { useKycOtp } from "domain/kyc/pages/kycOtp/KycOtpProvider";
import { KYC_STEP } from "domain/kyc/pages/kycVerificationForm/KycVerificationForm";
import { OtpSendValidators } from "helpers/ErrorHelper";
import { Icon } from "helpers/IconHelper";
import useHandleApiError, { ERROR_HANDLER_FLOW } from "hooks/useHandleApiError";
import React, { useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";

const philippinesCountryCode = "+63";

export const MobileNumber: React.FC = () => {
    const intl = useIntl();
    const handleApiError = useHandleApiError();
    const { toVerifyOtp, sendOtp, error, setOtpSendError, responsibleGamingRegistration } = useKycOtp();
    const kycLeavePopup = useKycLeavePopup();

    const handleSendOtp = useCallback(async () => {
        try {
            setOtpSendError(null);
            await sendOtp();
            toVerifyOtp();
        } catch (e) {
            await handleApiError(e as ApiError, (error) => {
                if (OtpSendValidators.some((validator) => validator(error))) {
                    setOtpSendError(error.response!.data.errorCode);
                    return ERROR_HANDLER_FLOW.STOP;
                }

                return ERROR_HANDLER_FLOW.COUNTINUE;
            });
        }
    }, []);

    return <>
        <div>
            <div className="tw-flex tw-items-start">
                <Icon.DailyLogo className="tw-px-[0.4rem] tw-py-[0.6rem] tw-mr-[0.8rem]" />
                <div className="tw-text-h6-medium">
                    <FormattedMessage id="kyc_verification_welcome_title" />
                </div>
            </div>
            <div className="tw-text-body-1 tw-pt-[0.4rem] tw-whitespace-pre-line">
                <FormattedMessage id="kyc_verification_process_welcome_content" />
            </div>
        </div>
        <StepProgressBar currentStep={KYC_STEP.OTP} />
        <div>
            <div className="tw-text-subtitle-1 tw-font-medium tw-text-grey-1000">
                <FormattedMessage id="otp_mobile_number_title" />
            </div>
            <div className="tw-text-subtitle-2 tw-text-grey-1000">
                <FormattedMessage id="otp_mobile_number_description" />
            </div>
            <div className="tw-pt-6 tw-pb-[6.8rem] tw-space-y-1 tw-relative">
                <div className="tw-text-body-1 tw-text-grey-1000">
                    <FormattedMessage id="label_mobile_number" />
                </div>
                <div className="tw-flex tw-gap-1">
                    <FantasySelect
                        variant={TEXT_FIELD_VARIANT.STANDARD}
                        inputProps={{
                            value: responsibleGamingRegistration?.countryCode,
                            className: "tw-placeholder-grey-600 tw-w-20 tw-flex-shrink-0 tw-bg-grey-100",
                        }}
                        options={[philippinesCountryCode]}
                        showErrorMessage={false}
                        disabled
                    />
                    <div className="tw-flex-1">
                        <FantasyText
                            variant={TEXT_FIELD_VARIANT.STANDARD}
                            showErrorMessage={false}
                            inputProps={{
                                value: responsibleGamingRegistration?.phoneNumber,
                                className: "tw-placeholder-grey-600 tw-bg-grey-100",
                                placeholder: intl.formatMessage({ id: "label_mobile_number" }),
                                "data-testid": "kyc-otp-mobile-number",
                            }}
                            disabled
                        />
                    </div>
                </div>
                {
                    error.isOtpMobileNumberInUse &&
                    <ErrorMessage errorMessage={intl.formatMessage({ id: "otp_error_mobile_number_in_use" })} />
                }
                {
                    error.isOtpSendExceedDailyLimit && (
                        <div className="tw-absolute tw-w-full tw-pt-6">
                            <HintCard
                                variant={HINT_CARD_VARIANT.ERROR}
                                hintText={intl.formatMessage({ id: "otp_error_mobile_number_exceed_daily_limit_hint" })}
                                prefixIcon={Icon.Speedometer}
                            />
                        </div>
                    )
                }
                {
                    error.isOtpSendExceedIntervalLimit && (
                        <div className="tw-absolute tw-w-full tw-pt-6">
                            <HintCard
                                variant={HINT_CARD_VARIANT.PRIMARY_LIGHT}
                                hintText={intl.formatMessage({ id: "otp_error_mobile_number_exceed_interval_limit_hint" })}
                                prefixIcon={Icon.Hourglass}
                            />
                        </div>
                    )
                }
            </div>
        </div>
        <div className="tw-flex tw-flex-row tw-space-x-[0.8rem]">
            <Button
                dataTestId="kyc-otp-exit"
                className="tw-flex-1 tw-text-h6 tw-font-medium"
                variant={NEW_BUTTON_VARIANTS.greyDark}
                onClick={kycLeavePopup.open}
            >
                <FormattedMessage id="label_exit" />
            </Button>
            <Button
                dataTestId="kyc-otp-send-code"
                className="tw-flex-1 tw-text-h6 tw-font-medium"
                disabled={error.isOtpSendExceedDailyLimit || error.isOtpMobileNumberInUse}
                variant={NEW_BUTTON_VARIANTS.primaryDark}
                onClick={handleSendOtp}
            >
                <FormattedMessage id="label_send_code" />
            </Button>
        </div>
    </>;
};
