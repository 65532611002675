import { coinsFew, coinsLots, coinsMedium } from "helpers/IconHelper";
import _ from "lodash";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";

export enum ADD_DEPOSIT_PAGE {
    PRODUCTS = "product",
    METHODS = "methods",
    RESULT = "result",
    APP_DOWNLOAD = "APP_DOWNLOAD"
}

export enum PAYMENT_STATUS {
    SELECT = "select",
    SUCCESS = "success",
    CANCEL = "cancel"
}

export enum ORDER_STATUS {
    PURCHASED = "PURCHASED",
    PENDING = "PENDING",
    CANCELLED = "CANCELLED"
}

export const TripleAPaymentCallbackUrls = {
    successUrl: `${window.location.origin}${ROUTER_PATH_CONFIG.deposit.index.replace(":status", PAYMENT_STATUS.SUCCESS)}`,
    cancelUrl: `${window.location.origin}${ROUTER_PATH_CONFIG.deposit.index.replace(":status", PAYMENT_STATUS.CANCEL)}`
};

export const depositSuccessModal = "depositSuccessModal";

export const cryptocurrencyDetails = {
    BTC: {
        image: "/images/cryptocurrency_bitcoin_icon.png",
        name: "Bitcoin (BTC)"
    },
    ETH: {
        image: "/images/cryptocurrency_ethereum_icon.png",
        name: "Ethereum (ETH)"
    },
    USDC: {
        image: "/images/cryptocurrency_usd_coin_icon.png",
        name: "USD Coin (USDC)"
    },
    USDT: {
        image: "/images/cryptocurrency_tether_icon.png",
        name: "Tether (USDT-ERC20)"
    },
    USDT_TRC20: {
        image: "/images/cryptocurrency_trc20_icon.png",
        name: "Tether (USDT-TRC20)"
    },
    TEST_BTC: {
        image: "/images/cryptocurrency_bitcoin_icon.png",
        name: "Testnet Bitcoin (TEST-BTC)"
    }
};

export const UpayMethodDetails = {
    instaPay: {
        image: "/images/instaPay.png",
        name: "InstaPay"
    }
};


export const isPaymentSuccessful = (status: PAYMENT_STATUS) => status === PAYMENT_STATUS.SUCCESS;
export const isPaymentCancelled = (status: PAYMENT_STATUS) => status === PAYMENT_STATUS.CANCEL;
export const haveResult = (status: PAYMENT_STATUS) => isPaymentSuccessful(status) || isPaymentCancelled(status);

export const isOrderPurchased = (status: ORDER_STATUS) => status === ORDER_STATUS.PURCHASED;
export const isOrderPending = (status: ORDER_STATUS) => status === ORDER_STATUS.PENDING;
export const isOrderCancelled = (status: ORDER_STATUS) => status === ORDER_STATUS.CANCELLED;

export const isProductSelected = (selectedProduct, product) => _.isEqual(selectedProduct, product);

export const getProductByRequiredCoin = (products, requiredDepositCent) => {
    const productsSortByDepositCentAsc = _.sortBy(products, "coinCent");

    return productsSortByDepositCentAsc.find(product => product.coinCent >= requiredDepositCent) || _.findLast(productsSortByDepositCentAsc);
};

export const toSelectedTeamIdsString = (selectedTeamsIds) => selectedTeamsIds && selectedTeamsIds.join(",");

export const toSelectedTeamIdsArray = (selectedTeamsIdsString) => selectedTeamsIdsString && selectedTeamsIdsString.split(",").map(idString => +idString);

export function getProductImgs(productLength) {

    const countsOfIcon = 3;
    const fews: string[] = [];
    const meds: string[] = [];
    const lots: string[] = [];

    const quotient = Math.floor(productLength / countsOfIcon);
    const remainder = productLength % countsOfIcon;

    for (let i = 0; i < quotient; i++) {
        fews.push(coinsFew);
        meds.push(coinsMedium);
        lots.push(coinsLots);
    }

    if (remainder === 1) {
        lots.push(coinsLots);
    } else if (remainder === 2) {
        meds.push(coinsMedium);
        lots.push(coinsLots);
    }

    return [...fews, ...meds, ...lots];

}
