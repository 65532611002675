import { Contest } from "apis/match/type";
import { ContestDetailVo } from "data/vo/contest/ContestDetailVo";
import Button from "designToken/button/Button";
import { BUTTON_PATTERN, BUTTON_SIZE, BUTTON_VARIANTS } from "designToken/button/types";
import { checkIsContestJoinable, isContestJoinable, } from "helpers/ContestHelper";
import { isMatchJoinable, isMatchPreparing } from "helpers/match/MatchHelper";
import { useMatchPreparingToast } from "hooks/useToast";
import { has } from "lodash";
import React, { useMemo } from "react";
import { FixedFooter } from "styles/component/GeneralStyle";
import { CONTEST_TYPE_FROM_API, ENTRY_FEE_TYPE_FROM_API, } from "types/contest/Contest";
import { ContestDetailVO } from "types/contest/ContestDetail";
import { MatchVO } from "types/match/Match";
import { LeaderboardTeamVo } from "data/vo/leaderboard/LeaderboardTeamVo";
import { useSetting } from "hooks/useSetting";

interface IJoinContestButtonLayout {
    children: React.ReactElement;
    contest: ContestDetailVO | ContestDetailVo;
    myJoinedTeams: LeaderboardTeamVo[];
    match: MatchVO;
    onJoinButtonClick: (event?, currentContest?: Contest | ContestDetailVO | ContestDetailVo,) => void;
}

export const JoinContestButtonLayout: React.FC<IJoinContestButtonLayout> = ({
                                                                                children,
                                                                                contest,
                                                                                myJoinedTeams,
                                                                                match,
                                                                                onJoinButtonClick,
                                                                            }) => {
    const { settingState: SettingsState } = useSetting();
    const handleMatchPreparingToast = useMatchPreparingToast();

    const buttonVariant = useMemo<BUTTON_VARIANTS>(() => {
        if (myJoinedTeams.length > 0) {
            return BUTTON_VARIANTS.secondary;
        }

        if (contest.type === CONTEST_TYPE_FROM_API.PRACTICE) {
            return BUTTON_VARIANTS.tertiary;
        }

        if (contest.entryFeeType === ENTRY_FEE_TYPE_FROM_API.RUBY) {
            return BUTTON_VARIANTS.danger;
        }

        return BUTTON_VARIANTS.primary;
    }, [contest]);

    const teams = useMemo(() => {
        if (has(contest, "joinedTeams")) {
            return (contest as ContestDetailVo).joinedTeams || 0;
        }
        return (contest as ContestDetailVO).rankedTeams?.length || 0;
    }, [contest]);

    const isJoinable = useMemo(() => {
        return (
            isMatchJoinable(match.matchStatus) &&
            isContestJoinable(contest.status) &&
            checkIsContestJoinable({
                SettingsState,
                spots: contest.spots,
                teams,
                allowMultiple: contest.allowMultiple,
                myJoinedTeamsCount: myJoinedTeams.length,
            })
        );
    }, [match.matchStatus, contest, SettingsState, teams]);

    return (
        <FixedFooter>
            {isMatchPreparing(match.matchStatus)
                ? (<Button
                    className="w-100"
                    pattern={BUTTON_PATTERN.pill}
                    variant={buttonVariant}
                    size={BUTTON_SIZE.lg}
                    onClick={handleMatchPreparingToast}
                >
                    {children}
                </Button>)
                : (<Button dataTestId="join-button"
                           className="w-100"
                           pattern={BUTTON_PATTERN.pill}
                           variant={buttonVariant}
                           size={BUTTON_SIZE.lg}
                           disabled={!isJoinable}
                           onClick={onJoinButtonClick}
                >
                    {children}
                </Button>)
            }
        </FixedFooter>
    );
};
