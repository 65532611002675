import React from "react";

export enum BOLD_WEIGHT {
    BOLD = "tw-font-bold",
    EXTRA_BOLD = "tw-font-extrabold",
}

interface BoldTextProps {
    text: string;
    weight?: BOLD_WEIGHT;
}

export const BoldText: React.FC<BoldTextProps> = ({ text, weight = BOLD_WEIGHT.BOLD }) => {
    const parts = text.split(/<b>(.*?)<\/b>/);

    return (
        <>
            {
                parts.map((part, index) => {
                    const isRegularText = index % 2 === 0;
                    return isRegularText
                        ? part
                        : <span key={index} className={weight}>{part}</span>;
                })
            }
        </>
    );
};
