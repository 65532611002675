import React from "react";
import { useIntl } from "react-intl";
import { useController } from "react-hook-form";
import { cn } from "helpers/cn";
import { FantasyCheckboxField } from "components/reactHookFormField/FantasyCheckboxField";
import { CHECKBOX_SHAPE, CHECKBOX_VARIANTS } from "components/checkbox/types";
import { cannotEdit } from "helpers/IconHelper";
import { BoldText } from "components/boldText/BoldText";
import { ErrorMessage } from "components/error/ErrorMessage";

export const DataCannotEditConfirmCheckbox: React.FC = () => {
    const formFieldName = "isInfoConfirmed";

    const intl = useIntl();
    const { fieldState: { error } } = useController({ name: formFieldName });

    return <div className="tw-space-y-1">
        <div className={cn("tw-flex tw-flex-col tw-gap-4 tw-bg-grey-50 tw-px-3 tw-py-4 tw-rounded-[1.2rem]", {
            "tw-bg-error-50": !!error
        })}>
            <FantasyCheckboxField
                name={formFieldName}
                variant={CHECKBOX_VARIANTS.secondary}
                shape={CHECKBOX_SHAPE.circle}
                showErrorMessage={false}
            >
                <div className="tw-flex tw-gap-1 tw-items-center">
                    <img src={cannotEdit} alt="cannot-edit-confirm" />
                    <p className="tw-text-caption-1">
                        <BoldText text={intl.formatMessage({ id: "responsible_gaming_dialog_confirm_data_label" })} />
                    </p>
                </div>
            </FantasyCheckboxField>
        </div>
        <ErrorMessage errorMessage={error?.message} />
    </div>;
};
