import { LOCALE } from "./locales";

declare global {
    interface Window {
        gtag: Function;
    }
}

export const analyticsEvent = {
    webBanner: "web_banner",
    webClickAddDeposit01: "web_click_add_deposit_01",
    webClickAddDeposit02: "web_click_add_deposit_02",
    webClickLetStart: "web_click_let_start",
    webMatchPage: "web_match_page",
    webLogInSuccess: "web_log_in_success",
    webSignUpSuccess: "web_sign_up_success",
    webEnterMatchDetail: "web_enter_match_detail",
    webTryToJoinContest: "web_try_to_join_contest",
    webConfirmToJoinContest: "web_confirm_to_join_contest",
    webSportType: "web_sport_type",
    webHomeIcon: "web_home_icon",
    webClickPromotionalIcon: "web_click_redemption_icon",
    webClickRedeemButton: "web_click_redeem_button",
    webClickTaskIcon: "web_click_task_icon",
    webClickTaskLevel: "web_click_task_level",
    webTaskWatchTutorials: "web_task_watch_tutorials",
    webTaskUpgrading: "web_task_upgrading",
    webTaskRuby: "web_task_ruby",
    webTaskDeposit: "web_task_deposit",
    webClickResultIcon: "web_click_result_icon",
    webClickCreateTeam: "web_click_create_team",
    webClickEdit: "web_click_edit",
    webClickCopy: "web_click_copy",
    webClickInfoAdd: "web_click_info_add",
    webSignUpClickGoogle: "web_sign_up_click_google",
    webSignUpClickApple: "web_sign_up_click_apple",
    webLanguage: (language: LOCALE) => `web_start_language_${language}`,
    webRegisterGiftTake: "web_regist_gift_take",
    webRegisterGiftCancel: "web_regist_gift_cancel",
    webClickFeaturedContest: (sport: string) => `web_banner_fea_cont_${sport.toLowerCase()}`,
};

export const analyticsParameterKey = {
    action: "action",
    sport: "sport"
};

const gtag = window?.gtag || (() => {});
export const logCustomEvent = (eventName, params?: Record<string, string>) => {
    gtag("event", eventName, params);
};

export const logPageViewEvent = () => {
    gtag("config", window.FANTASY_GAME_CONFIG().firebase.measurementId);
};

export const logUserId = (userId) => {
    gtag("config", window.FANTASY_GAME_CONFIG().firebase.measurementId, {
        "user_id": userId
    });
};
