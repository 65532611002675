import { FormattedMessage } from "react-intl";
import SYSTEM_LINKS from "helpers/LinkHelper";
import React from "react";

export const ContactInfoWithLink = () =>
    <div>
                <span className="fz-sf-regular-subhead text-text-secondary-1">
                    <FormattedMessage id="label_login_trouble" />
                    </span>
        <a className="fz-sf-regular-subhead text-sys-info-1 ms-2"
           href={SYSTEM_LINKS.contactUs}
           target="_blank"
           rel="noreferrer"
        >
            <FormattedMessage id="label_contact_support" />
        </a>
    </div>;
