import { auth } from "domain/auth/AuthConfig";
import {
    CancelButton,
    CancelButtonWrapper,
    EmailCheckPageWrapper,
    MainImage,
    MainImageWrapper,
    Text,
    Title
} from "domain/unauthorized/notification/EmailCheckPageStyle";
import { signOut } from "firebase/auth";
import { checkEmailImage, navCloseIcon } from "helpers/IconHelper";
import useFantasyHistory from "hooks/useFantasyHistory";
import { FormattedMessage } from "react-intl";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";
import { storageHelper } from "helpers/localStorage/LocalStorageHelper";
import { LOCAL_STORAGE_KEYS } from "helpers/localStorage/types";

export const legendFantasyEmail = "no-reply@legend-fantasy.com";
const EmailCheckPage = () => {
    const history = useFantasyHistory();

    return <EmailCheckPageWrapper>
        <CancelButtonWrapper onClick={async () => {
            await signOut(auth);
            storageHelper(LOCAL_STORAGE_KEYS.SESSION_ID).remove();
            history.replace(ROUTER_PATH_CONFIG.auth.index);
        }}>
            <CancelButton src={navCloseIcon} />
        </CancelButtonWrapper>
        <MainImageWrapper>
            <MainImage src={checkEmailImage} />
        </MainImageWrapper>
        <Title>
            <FormattedMessage id="check_email_page_title" />
        </Title>
        <Text>
            <FormattedMessage id="check_email_page_label_check_email_from_1" values={{ email: legendFantasyEmail }} />
            &nbsp;
            <FormattedMessage id="check_email_page_label_check_email_2"
                              values={{ email: legendFantasyEmail }} />
            &nbsp;
            <FormattedMessage id="check_email_page_label_check_email_3" />
        </Text>
    </EmailCheckPageWrapper>;
};

export default EmailCheckPage;
