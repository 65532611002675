import Dialog from "designToken/dialog/Dialog";
import { cn } from "helpers/cn";
import React, { SyntheticEvent, useEffect, useRef, useState } from "react";
import { MENU_ITEM_VARIANT, MenuItem } from "designToken/menuItem/MenuItem";
import { FantasyText, FantasyTextProps, TEXT_FIELD_SIZE, TEXT_FIELD_VARIANT } from "../input/FantasyText";
import { IoChevronDown } from "react-icons/io5";

const DIALOG_PADDING = 8;
const TOP_PADDING = 32;

export enum FantasySelectAlignment {
    center = "center",
    attach = "attach",
}

export interface FantasySelectProps<T extends string | number> extends FantasyTextProps {
    onSelect?: (value: T) => void;
    options: T[] | { label: string; value: T; }[],
    align?: FantasySelectAlignment,
    disabled?: boolean,
}

export const FantasySelect = <T extends string | number>({
                                                             size = TEXT_FIELD_SIZE.Medium,
                                                             variant = TEXT_FIELD_VARIANT.STANDARD,
                                                             iconLeft,
                                                             iconRight,
                                                             inputProps,
                                                             maxLength,
                                                             options,
                                                             align = FantasySelectAlignment.center,
                                                             disabled,
                                                             onSelect,
                                                             errorMessage,
                                                             isError,
                                                             showErrorMessage = true,
                                                         }: FantasySelectProps<T>) => {
    const container = useRef<HTMLDivElement>(null);
    const [showOptions, setShowOptions] = useState(false);
    const [containerWidth, setContainerWidth] = useState(0);
    const [containerCenterX, setContainerCenterX] = useState(0);
    const [containerCenterY, setContainerCenterY] = useState(0);
    const [containerRect, setContainerRect] = useState(container.current?.getBoundingClientRect());

    const updateContainerRect = () => {
        if (container.current) {
            setContainerRect(container.current!.getBoundingClientRect());
        }
    };

    window.addEventListener("scroll", updateContainerRect, true);

    useEffect(() => updateContainerRect(), []);

    useEffect(() => {
        if (containerRect) {
            setContainerWidth(Math.floor(containerRect.width));
            setContainerCenterX(containerRect.x + DIALOG_PADDING);
            setContainerCenterY(containerRect.y - TOP_PADDING);
        }
    }, [containerRect, container]);


    const handleSelect = (value: T) => {
        onHide();

        if (onSelect) {
            onSelect(value);
        }
    };

    const onHide = () => setShowOptions(false);

    const onFocus: React.EventHandler<SyntheticEvent<HTMLInputElement>> = (e) => {
        e.preventDefault();
        if (disabled) return;

        setShowOptions(true);
        (e.target as HTMLInputElement).blur();
    };

    return <div ref={container}>
        <FantasyText
            size={size}
            variant={variant}
            isError={isError}
            errorMessage={errorMessage}
            showErrorMessage={showErrorMessage}
            iconLeft={iconLeft}
            iconRight={<IoChevronDown size={18} onClick={() => {
                if (disabled) return;

                setShowOptions(true);
            }} />}
            maxLength={maxLength}
            disabled={disabled}
            inputProps={{
                ...inputProps,
                onFocus: onFocus,
            }}
        />
        <Dialog show={showOptions}
                onHide={onHide}
                dialogClassName={"tw-mx-0 tw-max-w-full"}
                contentClassName={"tw-my-[3.2rem] tw-w-min tw-bg-transparent tw-shadow-none tw-border-none"}
                backdropClassName={"tw-bg-transparent"}
                centered={align !== FantasySelectAlignment.attach}
        >
            <div
                className={cn(
                    "tw-rounded-[1.2rem] tw-overflow-auto tw-shadow-[0_8px_64px_0_#0000001A]",
                    "tw-max-h-[var(--dialogHeight)]",
                    "tw-w-[var(--containerWidth)]",
                    "tw-relative tw-left-[var(--containerCenterX)]",
                    align === FantasySelectAlignment.attach ? "tw-top-[var(--containerCenterY)]" : "",
                )}
                style={{
                    "--dialogHeight": `${(window.innerHeight - 64)}px`,
                    "--containerWidth": `${containerWidth - 2 * DIALOG_PADDING}px`,
                    "--containerCenterX": `${containerCenterX}px`,
                    "--containerCenterY": `${containerCenterY}px`,
                } as any}>
                {
                    options.map(((option, i) => {
                        const originValue = inputProps?.value;
                        const label = typeof option === "object" ? option.label : option;
                        const value = typeof option === "object" ? option.value : option;
                        return <MenuItem
                            key={`select-option-${i}`}
                            variant={originValue === value ? MENU_ITEM_VARIANT.selected : MENU_ITEM_VARIANT.default}
                            onClick={() => handleSelect(value)}
                            label={label}
                        />;
                    }))
                }
            </div>
        </Dialog>
    </div>;
};
