import React from "react";
import { FormattedMessage } from "react-intl";
import { MatchComponentStatusEnum } from "../../../data/vo/match/MyMatchVo";
import { MyNineCatVo } from "../../../data/vo/nineCat/MyNineCatVo";
import { sortBySlateStartsAt, sortBySlateStartsAtAtDesc } from "../../../helpers/SortingHelper";
import { getMyMatchCompletedDate, getMyMatchUpcomingDate } from "../../../helpers/TimeHelper";
import useFantasyHistory from "../../../hooks/useFantasyHistory";
import { getSlateContestDetailPageUrl } from "../../../router/RouterUrls";
import { SPORT } from "../../../types/sports/Sport";
import MyMatchItemWrapper from "../../match/components/myMatches/MyMatchItemWrapper";
import JoinedContestCardItem from "./JoinedContestCardItem";

interface MyMatchNIneCatCardsProps {
    dataSource: MyNineCatVo[];
    status: MatchComponentStatusEnum;
}


const StatusMap: {
    [key: string]: {
        title: JSX.Element;
        testId: string;
        sortCallback: (data: MyNineCatVo[]) => MyNineCatVo[];
        dateCallback: (slateStartsAt: number) => string;
    }
} = {
    [MatchComponentStatusEnum.LIVE]: {
        title: <FormattedMessage id="label_live_matches" />,
        testId: "my-matches-title-live",
        sortCallback: sortBySlateStartsAtAtDesc,
        dateCallback: getMyMatchUpcomingDate,
    },
    [MatchComponentStatusEnum.UPCOMING]: {
        title: <FormattedMessage id="label_upcoming_matches" />,
        testId: "my-matches-title-upcoming",
        sortCallback: sortBySlateStartsAt,
        dateCallback: getMyMatchUpcomingDate,
    },
    [MatchComponentStatusEnum.COMPLETED]: {
        title: <FormattedMessage id="label_completed_matches" />,
        testId: "my-matches-title-completed",
        sortCallback: sortBySlateStartsAtAtDesc,
        dateCallback: getMyMatchCompletedDate,
    },
};

const MyMatchNIneCatCards: React.FC<MyMatchNIneCatCardsProps> = ({
                                                                     dataSource,
                                                                     status
                                                                 }) => {
    const history = useFantasyHistory();

    const { title, testId, sortCallback, dateCallback } = StatusMap[status];
    const shouldShowStartDate = (slate: MyNineCatVo, lastSlate: MyNineCatVo) => {
        if (!lastSlate) return true;

        return dateCallback(slate.slateStartsAt) !== dateCallback(lastSlate.slateStartsAt);
    };

    if (dataSource.length === 0) {
        return null;
    }

    return (
        <MyMatchItemWrapper
            title={title}
            titleTestId={testId}
        >
            {
                sortCallback(dataSource).map((myNineCatSlate: MyNineCatVo, index: number, _myNineCatSlates: MyNineCatVo[]) => (
                    <React.Fragment key={myNineCatSlate.id}>
                        {shouldShowStartDate(myNineCatSlate, _myNineCatSlates[index - 1]) && (
                            <div
                                className="tw-text-body-2 tw-text-center tw-font-thin tw-mt-2"
                                key={myNineCatSlate.slateStartsAt}
                            >
                                {dateCallback(myNineCatSlate.slateStartsAt)}
                            </div>
                        )}

                        <JoinedContestCardItem
                            entryFee={myNineCatSlate.entryFee}
                            entryFeeType={myNineCatSlate.entryFeeType}
                            firstPrize={myNineCatSlate.firstPrize}
                            entryLimit={myNineCatSlate.entryLimit}
                            spots={myNineCatSlate.spots}
                            allowDiscountWithRuby={myNineCatSlate.allowDiscountWithRuby}
                            minApLevel={myNineCatSlate.minApLevel}
                            myJoinedTeams={myNineCatSlate.myJoinedTeams}
                            contestType={myNineCatSlate.contestType}
                            slipCount={myNineCatSlate.slipCount}
                            slateStatus={myNineCatSlate.slateStatus}
                            status={myNineCatSlate.status}
                            slateStartsAt={myNineCatSlate.slateStartsAt}
                            onClick={() => {
                                history.push(getSlateContestDetailPageUrl(SPORT.BASKETBALL, myNineCatSlate.nineCatId, myNineCatSlate.code));
                            }}
                        />
                    </React.Fragment>
                ))
            }
        </MyMatchItemWrapper>
    );
};

export default MyMatchNIneCatCards;