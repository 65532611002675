import React from "react";
import { FormattedMessage } from "react-intl";
import { cn } from "../../../../helpers/cn";
import { nineCatJoinContestBg } from "../../../../helpers/IconHelper";
import ContestCardItem, { ContestCardItemProps } from "../ContestCardItem";

export interface JoinContestPanelProps {
    contests: Omit<ContestCardItemProps, "onOpenPrizeDistribution" | "onJoinContest">[];
    onOpenPrizeDistribution: ContestCardItemProps["onOpenPrizeDistribution"];
    onJoinContest: ContestCardItemProps["onJoinContest"];
}

const JoinContestPanel: React.FC<JoinContestPanelProps> = (props) => {
    return (
        <div className={
            cn("tw-w-full tw-rounded-tl-[2.4rem] tw-rounded-tr-[2.4rem]",
                "tw-h-full tw-overflow-auto",
                "tw-flex tw-flex-col tw-flex-nowrap tw-gap-2",
                "tw-px-4 tw-py-4 tw-pb-0",
                "tw-bg-cover"
            )
        } style={{
            backgroundImage: `url("${nineCatJoinContestBg}")`
        }}>
            <h2 className={"tw-text-subtitle-1 tw-font-bold tw-text-white tw-text-center"}>
                <FormattedMessage id={"join_contest_label_join_contests_with_selected_teams"} />
            </h2>
            <div className={cn(
                "tw-flex tw-flex-col tw-flex-nowrap tw-gap-2",
                "tw-overflow-auto",
                "tw-h-full tw-pb-2"
            )}>
                {props.contests.map((contest, i) => (
                    <ContestCardItem key={i} {...contest}
                                     onOpenPrizeDistribution={props.onOpenPrizeDistribution}
                                     onJoinContest={props.onJoinContest}
                    />
                ))}
            </div>
        </div>
    );
};

export default JoinContestPanel;
