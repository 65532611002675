import React from "react";
import { PaymentMethods } from "./components/PaymentMethods";
import { creditCardFilled } from "helpers/IconHelper";
import { useAddCoinFlow } from "domain/coin/AddCoinFlow";
import { FormattedMessage } from "react-intl";

const CREDIT_CARD_METHOD = "Credit Card";

const paymentMethodDetails = {
    [CREDIT_CARD_METHOD]: {
        name: <FormattedMessage id={"payment_credit_card_method_button"} />,
        image: creditCardFilled
    }
};

const CreditCardMethods: React.FC = () => {
    const { goToAppDownloadPage } = useAddCoinFlow();

    const handleMethodClick = () => {
        goToAppDownloadPage();
    };

    return (
        <PaymentMethods
            title={<FormattedMessage id="payment_credit_card_method_title" />}
            paymentInfo={<FormattedMessage id="payment_app_download_title" />}
            methods={[CREDIT_CARD_METHOD]}
            paymentMethodDetails={paymentMethodDetails}
            onMethodClick={handleMethodClick}
        />
    );
};

export default CreditCardMethods;
