import { getSportKey } from "helpers/SportHelper";
import { UPDATE_CURRENT_SPORT } from "store/Constants";
import { SPORT } from "types/sports/Sport";

interface SportStateData {
    currentSport: SPORT;
}

interface SportStateAction {
    type: typeof UPDATE_CURRENT_SPORT,
    payload: SPORT;
}

const initState: SportStateData = {
    currentSport: getSportKey()
};

const SportState = (prevState = initState, action: SportStateAction) => {
    const { type, payload } = action;

    switch (type) {
        case UPDATE_CURRENT_SPORT:
            return {
                currentSport: payload
            };
        default:
            return prevState;
    }
};

export default SportState;
