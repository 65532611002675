import Button from "designToken/button/Button";
import { NEW_BUTTON_VARIANTS } from "designToken/button/types";
import { ArrowRight, Check } from "lucide-react";
import { FormattedMessage } from "react-intl";
import React from "react";

const btnComponentPropsMap = {
    onBack: ButtonBack,
    onNext: ButtonNext,
    onDone: ButtonDone,
    onExit: ButtonExit,
};

interface KycVerificationFormActionsProps {
    onBack?: () => void;
    onNext?: () => void;
    onDone?: () => void;
    onExit?: () => void;
}

export const KycVerificationFormActions: React.FC<KycVerificationFormActionsProps> = (props) => {

    return <div className={"tw-flex tw-flex-row tw-space-x-[0.8rem]"}>
        {
            Object.keys(props).map((propKey) => {
                const BtnComponent = btnComponentPropsMap[propKey];
                const prop = props[propKey];
                return prop ? <BtnComponent key={propKey} onClick={prop} /> : null;
            })
        }
    </div>;
};

const buttonChildrenClassName = "tw-grow tw-flex tw-gap-2 tw-justify-center tw-items-center";

interface ButtonProps {
    onClick: () => void;
}

function ButtonDone(props: ButtonProps) {
    return <Button
        dataTestId="kyc-verification-done"
        className={"tw-flex-1"}
        variant={NEW_BUTTON_VARIANTS.primaryDark}
        onClick={props.onClick}>
        <div className={buttonChildrenClassName}>
            <p className={"tw-text-h6-medium"}>
                <FormattedMessage id="button_done" />
            </p>
            <Check size={20} />
        </div>
    </Button>;
}

function ButtonNext(props: ButtonProps) {
    return <Button
        dataTestId="kyc-verification-next"
        className={"tw-flex-1"}
        variant={NEW_BUTTON_VARIANTS.primaryDark}
        onClick={props.onClick}>
        <div className={buttonChildrenClassName}>
            <p className={"tw-text-h6-medium"}>
                <FormattedMessage id={"button_next"} />
            </p>
            <ArrowRight size={20} />
        </div>
    </Button>;
}

function ButtonBack(props: ButtonProps) {
    return <Button
        dataTestId="kyc-verification-back"
        className={"tw-flex-1"}
        variant={NEW_BUTTON_VARIANTS.grey}
        onClick={props.onClick}>

        <p className={"tw-text-h6-medium"}>
            <FormattedMessage id={"dialog_button_back"} />
        </p>
    </Button>;
}

function ButtonExit(props: ButtonProps) {
    return <Button
        dataTestId="kyc-verification-exit"
        className={"tw-flex-1"}
        variant={NEW_BUTTON_VARIANTS.greyDark}
        onClick={props.onClick}>
        <p className={"tw-text-h6-medium"}>
            <FormattedMessage id={"label_exit"} />
        </p>
    </Button>;
}
