import { useLoadingToggle } from "components/loading/Loading";
import { ReferralRepository } from "data/repository/referral/ReferralRepository";
import { MyRefereeSummaryVo } from "data/vo/referral/MyRefereeSummaryVo";
import { divideByOneHundred } from "helpers/number/NumberHelper";
import useCurrency from "hooks/useCurrency/useCurrency";
import useFantasyQuery from "hooks/useFantasyQuery";
import { QUERY_KEY } from "hooks/useFantasyQuery/type";
import React, { useContext, useEffect, useMemo } from "react";
import { useSetting } from "hooks/useSetting";

interface ReferralContextProps {
    myReferralCode: string;
    myRefereeSummaries: MyRefereeSummaryVo[];
    returnToReferrerPercentage: number;
    maxRubyPerRefereeText: string;
    maxRubyPerReferee: number;
    latestReferralTime: number;
    totalReceivedRuby: number;
}

const ReferralContext = React.createContext<ReferralContextProps | null>(null);

function getLatestReferralAt(summaries: MyRefereeSummaryVo[]) {
    return summaries.reduce((acc, cur) => {
        return acc > cur.referralAt ? acc : cur.referralAt;
    }, 0);
}


function getTotalRubyCent(myRefereeSummaries: MyRefereeSummaryVo[]) {
    return myRefereeSummaries.reduce((acc, cur) => {
        return acc + cur.rubyCent;
    }, 0);
}

interface ReferralProviderProps {
    children: React.ReactNode;
}

const ReferralProvider: React.FC<ReferralProviderProps> = ({ children }) => {
    const referralRepository = new ReferralRepository();

    const toggleLoading = useLoadingToggle();

    const { currentCurrencyObject: { toFormattedNumber } } = useCurrency();

    const { settingState: { referrer: { returnToReferrerPercentage, maxRubyPerReferee } } } = useSetting();

    const fetchAll = useFantasyQuery([QUERY_KEY.MY_REFERRAL_CODE, QUERY_KEY.MY_REFEREE_SUMMARY], async (): Promise<[string, MyRefereeSummaryVo[]]> => {
        return Promise.all([
            referralRepository.getMyReferralCode(),
            referralRepository.getMyRefereeRubySummaries()
        ]);
    });

    const value = useMemo<ReferralContextProps>(() => {

        if (fetchAll.isLoading) {
            return {
                myReferralCode: "",
                myRefereeSummaries: [],
                returnToReferrerPercentage,
                maxRubyPerReferee,
                maxRubyPerRefereeText: "",
                latestReferralTime: 0,
                totalReceivedRuby: 0
            };
        }

        const [myReferralCode, myRefereeSummaries] = fetchAll.data!;

        return ({
            myReferralCode: myReferralCode,
            myRefereeSummaries: myRefereeSummaries,
            returnToReferrerPercentage,
            maxRubyPerReferee,
            maxRubyPerRefereeText: toFormattedNumber(maxRubyPerReferee),
            latestReferralTime: getLatestReferralAt(myRefereeSummaries),
            totalReceivedRuby: divideByOneHundred(getTotalRubyCent(myRefereeSummaries)
            )
        });
    }, [fetchAll, returnToReferrerPercentage, toFormattedNumber, maxRubyPerReferee]);


    useEffect(() => {
        toggleLoading(fetchAll.isLoading);
    }, [fetchAll.isLoading, toggleLoading]);

    if (fetchAll.isLoading) {
        return null;
    }

    return (
        <ReferralContext.Provider value={value}>
            {children}
        </ReferralContext.Provider>
    );
};

export const useReferralProvider = () => {
    const context = useContext(ReferralContext);

    if (!context) {
        throw new Error(
            "useReferralProvider must be used within a ReferralContext",
        );
    }

    return context;
};

export default ReferralProvider;
