import { OrderStatus, WALLET_API_RESPONSE_EVENT, WALLET_TYPE } from "data/dto/wallet/walletHistoryDto";
import {
    walletDepositMinusPiggyBankIcon,
    walletDepositPlusPiggyBankIcon,
    walletRubyMinusIcon,
    walletRubyPlusIcon,
    walletWinningMinusIcon,
    walletWinningPlusIcon
} from "helpers/IconHelper";
import { multiplyByOneHundred } from "helpers/number/NumberHelper";
import _ from "lodash";
import React from "react";
import { FormattedMessage } from "react-intl";

export const walletRecordEvent = {
    [WALLET_TYPE.COIN]: {
        [WALLET_API_RESPONSE_EVENT.USER_REGISTER]: () => <FormattedMessage id="wallet_record_event_user_register" />,
        [WALLET_API_RESPONSE_EVENT.JOIN_CONTEST]: () => <FormattedMessage id="wallet_record_event_join_contest" />,
        [WALLET_API_RESPONSE_EVENT.REFUND_CONTEST]: () => <FormattedMessage id="wallet_record_event_refund_contest" />,
        [WALLET_API_RESPONSE_EVENT.REFUND_DEPOSIT_CONTEST]: () => <FormattedMessage
            id="wallet_record_event_refund_contest" />,
        [WALLET_API_RESPONSE_EVENT.REFUND_DEPOSIT]: () => <FormattedMessage id="wallet_record_event_refund_deposit" />,
        [WALLET_API_RESPONSE_EVENT.USER_DEPOSIT]: () => <FormattedMessage id="label_add_deposit" />,
        [WALLET_API_RESPONSE_EVENT.USER_WITHDRAW_COIN]: (history, isB2B) =>
            isB2B
                ? <FormattedMessage id="wallet_record_event_withdraw_deposit" />
                : <FormattedMessage id="wallet_record_event_user_withdraw" />,
        [WALLET_API_RESPONSE_EVENT.PURCHASE_REWARD_REDEPOSIT]: () => <FormattedMessage
            id="wallet_record_event_add_deposit_from_store" />,
        [WALLET_API_RESPONSE_EVENT.REDEEM]: () => <FormattedMessage id="wallet_record_event_redeem_deposit" />,
        [WALLET_API_RESPONSE_EVENT.GAME_TRIER_DAILY_DEPOSIT]: () => <FormattedMessage
            id="wallet_record_event_game_trier_daily_deposit" />,
        [WALLET_API_RESPONSE_EVENT.REVOKE_GAME_TRIER_DAILY_DEPOSIT]: () => <FormattedMessage
            id="wallet_record_event_revoke_game_trier_daily_deposit" />,
        [WALLET_API_RESPONSE_EVENT.REDEMPTION_CODE_DEPOSIT]: () => <FormattedMessage
            id="wallet_record_event_redemption" />,
        [WALLET_API_RESPONSE_EVENT.MANUAL_ADD_DEPOSIT]: () => <FormattedMessage
            id="wallet_record_event_manual_add_deposit" />,
        [WALLET_API_RESPONSE_EVENT.CLAIM_TASK]: () => <FormattedMessage id="wallet_record_event_claim_task" />,
        [WALLET_API_RESPONSE_EVENT.CONVERTED_TO_WINNING]: () => <FormattedMessage
            id="wallet_record_event_converted_to_winning" />
    },
    [WALLET_TYPE.WINNING]: {
        [WALLET_API_RESPONSE_EVENT.USER_REGISTER]: () => <FormattedMessage id="wallet_record_event_user_register" />,
        [WALLET_API_RESPONSE_EVENT.PURCHASE_REWARD_REDEPOSIT]: () => <FormattedMessage
            id="wallet_record_event_purchase_reward" />,
        [WALLET_API_RESPONSE_EVENT.PURCHASE_REWARD_VOUCHER]: () => <FormattedMessage
            id="wallet_record_event_purchase_reward" />,
        [WALLET_API_RESPONSE_EVENT.WIN_CONTEST]: () => <FormattedMessage id="wallet_record_event_win_contest" />,
        [WALLET_API_RESPONSE_EVENT.USER_TRANSFER_WINNING_OUT]: ({ transferredTo }) => <FormattedMessage
            id="wallet_record_event_user_transfer" values={{ transferredTo }} />,
        [WALLET_API_RESPONSE_EVENT.USER_TRANSFER_WINNING_IN]: ({ transferredFrom }) => <FormattedMessage
            id="wallet_record_event_user_transfer_winning_in_with_var" values={{ transferredFrom }} />,
        [WALLET_API_RESPONSE_EVENT.REDEEM]: () => <FormattedMessage id="wallet_record_event_redeem_winning" />,
        [WALLET_API_RESPONSE_EVENT.REFUND_REWARD_ORDER]: () => <FormattedMessage
            id="wallet_record_event_refund_reward_order" />,
        [WALLET_API_RESPONSE_EVENT.MANUAL_ADD_WINNING]: () => <FormattedMessage
            id="wallet_record_event_manual_add_winning" />,
        [WALLET_API_RESPONSE_EVENT.USER_WITHDRAW_WINNING]: ({ orderStatus }) =>
            orderStatus === OrderStatus.CANCELLED
                ? <FormattedMessage
                    id="wallet_record_event_user_withdraw_winning_cancelled" />
                : <FormattedMessage
                    id="wallet_record_event_user_withdraw_winning" />,
        [WALLET_API_RESPONSE_EVENT.CANCELLED_USER_WITHDRAW_WINNING]: () => <FormattedMessage
            id="wallet_record_event_cancelled_user_withdraw_winning" />,
        [WALLET_API_RESPONSE_EVENT.CONVERTED_FROM_COIN]: () => <FormattedMessage
            id="wallet_record_event_converted_from_coin" />
    },
    [WALLET_TYPE.RUBY]: {
        [WALLET_API_RESPONSE_EVENT.USER_REGISTER]: () => <FormattedMessage id="wallet_record_event_user_register" />,
        [WALLET_API_RESPONSE_EVENT.JOIN_CONTEST_RETURN_TO_REFERRER]: ({ refereeUserCode }) => <><FormattedMessage
            id="wallet_record_event_join_contest" />&nbsp;({refereeUserCode})</>,
        [WALLET_API_RESPONSE_EVENT.JOIN_CONTEST]: () => <FormattedMessage id="wallet_record_event_discount_contest" />,
        [WALLET_API_RESPONSE_EVENT.JOIN_DEPOSIT_CONTEST]: () => <FormattedMessage
            id="wallet_record_event_discount_contest" />,
        [WALLET_API_RESPONSE_EVENT.JOIN_RUBY_CONTEST]: () => <FormattedMessage id="wallet_record_event_join_contest" />,
        [WALLET_API_RESPONSE_EVENT.WIN_CONTEST]: () => <FormattedMessage id="wallet_record_event_win_contest" />,
        [WALLET_API_RESPONSE_EVENT.REFUND_CONTEST]: () => <FormattedMessage id="wallet_record_event_refund_contest" />,
        [WALLET_API_RESPONSE_EVENT.REFUND_DEPOSIT_CONTEST]: () => <FormattedMessage
            id="wallet_record_event_refund_contest" />,
        [WALLET_API_RESPONSE_EVENT.REFUND_RUBY_CONTEST]: () => <FormattedMessage
            id="wallet_record_event_refund_contest" />,
        [WALLET_API_RESPONSE_EVENT.INVITED_BY_FRIEND]: ({ referrerUserCode }) => (
            <FormattedMessage
                id="wallet_record_event_invite_by_friend_with_var" values={{ referrerUserCode }} />
        ),
        [WALLET_API_RESPONSE_EVENT.FIRST_DEPOSIT]: () => <FormattedMessage id="wallet_record_event_first_deposit" />,
        [WALLET_API_RESPONSE_EVENT.REDEMPTION_CODE_RUBY]: ({ redemptionCode }) => <><FormattedMessage
            id="wallet_record_event_redemption" />{redemptionCode ? <>&nbsp;({redemptionCode})</> : ""}</>,
        [WALLET_API_RESPONSE_EVENT.MANUAL_ADD_RUBY]: () => <FormattedMessage
            id="wallet_record_event_manual_add_ruby" />,
        [WALLET_API_RESPONSE_EVENT.ADD_BY_TENANT]: () => <FormattedMessage
            id="wallet_record_event_manual_add_ruby" />,
        [WALLET_API_RESPONSE_EVENT.CLAIM_TASK]: () => <FormattedMessage id="wallet_record_event_claim_task" />,
    }
};

export const getWalletEventText = (
    walletType: WALLET_TYPE,
    history: {
        event: WALLET_API_RESPONSE_EVENT,
        referrerUserCode?: string,
        redemptionCode?: string,
        refereeUserCode?: string,
        transferredFrom?: string,
        transferredTo?: string,
        orderStatus?: OrderStatus,
    },
    isB2B: boolean
) =>
    _.has(walletRecordEvent[walletType], history.event)
        ? walletRecordEvent[walletType][history.event](history, isB2B)
        : "";

export const hasDifferentExpectedAmountCent = (historyRecord) => {
    return typeof historyRecord.expectedAmountCent === "number"
        && historyRecord.expectedAmountCent !== historyRecord.amountCent;
};

export const isAddDeposit = (event: WALLET_API_RESPONSE_EVENT) => event === WALLET_API_RESPONSE_EVENT.USER_DEPOSIT;
export const isJoinContest = (event: WALLET_API_RESPONSE_EVENT) => event === WALLET_API_RESPONSE_EVENT.JOIN_CONTEST;
export const isRefundContest = (event: WALLET_API_RESPONSE_EVENT) => event === WALLET_API_RESPONSE_EVENT.REFUND_CONTEST;

export const plusIcons = {
    [WALLET_TYPE.COIN]: walletDepositPlusPiggyBankIcon,
    [WALLET_TYPE.WINNING]: walletWinningPlusIcon,
    [WALLET_TYPE.RUBY]: walletRubyPlusIcon
};

export const minusIcons = {
    [WALLET_TYPE.COIN]: walletDepositMinusPiggyBankIcon,
    [WALLET_TYPE.WINNING]: walletWinningMinusIcon,
    [WALLET_TYPE.RUBY]: walletRubyMinusIcon
};

export const getMaxRubyDiscountCent = (rubyCent: number, rubyExchangeRateCent: number) => {
    return multiplyByOneHundred(Math.floor(rubyCent / rubyExchangeRateCent));
};

export const DISPLAY_DEPOSIT_LIMIT = 999_999;
export const isExceedDisplayDepositLimit = (amount: number): boolean =>
    amount > DISPLAY_DEPOSIT_LIMIT;
