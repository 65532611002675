import { useAuth } from "domain/auth/AuthProvider";
import { pagcorLogo, responsibleGamingLogo } from "helpers/IconHelper";
import React from "react";

const PagcorLogoSection = () => {
    const { preLoginSetting } = useAuth();

    if (preLoginSetting?.isPagcor) {
        return (
            <div className="tw-flex tw-flex-row tw-items-center tw-justify-center tw-gap-1">
                <img className="tw-h-[4rem]" src={pagcorLogo} alt="PAGCOR" />
                <img className="tw-h-[2.8rem]" src={responsibleGamingLogo} alt="RESPONSIBLE_GAMING" />
            </div>
        );
    }

    return null;
};

export default PagcorLogoSection;
