import { Icon } from "helpers/IconHelper";
import React, { ReactNode } from "react";
import { useIntl } from "react-intl";
import { SPORT } from "types/sports/Sport";
import { orderBy } from "lodash";
import { useSetting } from "hooks/useSetting";

interface SportTab {
    key: SPORT;
    name: string;
    icon: (size?: string, classname?: string) => ReactNode;
    enabled: boolean,
    message: string,
    order: number
}

const tabSettings = [
    {
        key: SPORT.CRICKET,
        intlId: "sport_tab_label_cricket",
        icon: Icon.CricketIcon,
    },
    {
        key: SPORT.FOOTBALL,
        intlId: "sport_tab_label_football",
        icon: Icon.FootballIcon,
    },
    {
        key: SPORT.BASKETBALL,
        intlId: "sport_tab_label_basketball",
        icon: Icon.BasketballIcon,
    }
];

export const useSportConfigs = (): SportTab[] => {
    const intl = useIntl();
    const { settingState: { sports: sportSettings } } = useSetting();

    const sportTabs = tabSettings.map(tab => {
        const setting = sportSettings.find(sportSetting => sportSetting.sport === tab.key);

        return ({
            key: tab.key,
            name: intl.formatMessage({ id: tab.intlId }),
            icon: (size?: string, className?: string) => <tab.icon size={size} className={className} />,
            order: setting?.order ?? 1,
            enabled: setting?.enabled ?? false,
            message: setting?.message ?? "",
        });
    });

    return orderBy(sportTabs, "order");
};
