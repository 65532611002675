import { BalanceSection } from "domain/contest/components/ConfirmationDialog/ConfirmationModal/BalanceSection";
import { CoinBalance } from "domain/contest/components/ConfirmationDialog/ConfirmationModal/CoinBalance";
import { TermsAndCondition } from "domain/contest/components/ConfirmationDialog/ConfirmationModal/TermsAndCondition";
import { DiscountSection } from "domain/contest/components/DiscountSection";
import { Icon } from "helpers/IconHelper";
import { divideByOneHundred } from "helpers/number/NumberHelper";
import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { cn } from "../../../../../helpers/cn";
import useCurrency from "../../../../../hooks/useCurrency/useCurrency";
import { useSelector } from "../../../../../store";
import { ContestDetailVO } from "../../../../../types/contest/ContestDetail";
import { EnrichedCoupon } from "../../../../../types/coupon/Coupon";
import { DISCOUNT_TYPE } from "../types";
import ConfirmationModalButtonFactory from "./ConfirmationModalButtonFactory";
import { CurrentBalanceDivider } from "./CurrentBalanceDivider";
import { EntrySectionDivider } from "./EntrySectionDivider";
import { RubyBalance } from "./RubyBalance";

export interface ConfirmationProps {
    selectedTeamCount: number,
    contest: Pick<ContestDetailVO, "entryFeeCent" | "type">,
    selectedCoupons: EnrichedCoupon[],
    myUnusedCoupon: EnrichedCoupon[],
    useRuby: boolean,
    useCoupon: boolean,
    canUseRuby: boolean,
    couponDiscountCent: number,
    rubyDiscountToDepositCent: number,
    entryFeeDeductDiscountCent: number,
    balanceDepositCent: number,
    balanceRubyCent: number,
    onClose: () => void,
    onToggleRuby: () => void,
    onOpenSelectCouponModal: () => void,
    onConfirmationButtonClick: () => void,
    onAddDepositButtonClick: () => void
    onSetDiscountType: (discountType: DISCOUNT_TYPE) => void
}


export const CoinConfirmationModal: React.FC<ConfirmationProps> = (props) => {
    const { coinCent, rubyCent } = useSelector(state => state.WalletState);
    const { currentCurrencyObject: { toWalletNumber, toFormattedNumber } } = useCurrency();

    const {
        balanceDepositCent,
        balanceRubyCent,
        canUseRuby,
        contest,
        couponDiscountCent,
        entryFeeDeductDiscountCent,
        myUnusedCoupon,
        rubyDiscountToDepositCent,
        selectedCoupons,
        selectedTeamCount,
        useCoupon,
        useRuby,
        onAddDepositButtonClick,
        onClose,
        onConfirmationButtonClick,
        onOpenSelectCouponModal,
        onSetDiscountType,
    } = props;

    const handleUseRuby = useCallback(() => {
        onSetDiscountType(DISCOUNT_TYPE.RUBY);
    }, []);

    const handleUseCoupon = useCallback(() => {
        onSetDiscountType(DISCOUNT_TYPE.COUPON);
    }, []);

    const handleNotUseDiscount = useCallback(() => {
        onSetDiscountType(DISCOUNT_TYPE.NONE);
    }, []);

    return <div className={"tw-p-4 tw-bg-grey-50 tw-rounded-[1.6rem]"}>
        <div className={"tw-flex tw-justify-end"}>
            <button onClick={onClose}>
                <Icon.Close />
            </button>
        </div>
        <div className={"tw-pb-3 tw-text-center tw-text-h6 tw-font-bold"}>
            <FormattedMessage id="confirmation_dialog_title" />
        </div>
        <div className={"tw-flex tw-flex-col tw-gap-2"}>
            <div data-testid={"currentBalance"}
                 className={cn(
                     "tw-flex tw-flex-col tw-gap-1",
                     "tw-w-[22.4rem] tw-m-auto",
                     "tw-py-3 tw-px-6",
                     "tw-rounded-lg",
                     "tw-bg-grey-100"
                 )}>
                <CurrentBalanceDivider>
                    <FormattedMessage id="confirmation_dialog_label_current_balance" />
                </CurrentBalanceDivider>
                <CoinBalance value={divideByOneHundred(coinCent)} />
                <RubyBalance value={divideByOneHundred(rubyCent)} />
            </div>
            <div className={
                cn(
                    "tw-flex tw-flex-col tw-gap-2",
                    "tw-px-5 tw-py-3"
                )
            }>
                <div data-testid="entryFee" className={
                    cn("tw-flex tw-justify-between",
                        "tw-text-subtitle-1"
                    )
                }>
                    <p>
                        <FormattedMessage id={"fantasy_sport_label_entry_fee"} />
                        &nbsp;(
                        <FormattedMessage id={"label_coin"} />
                              )
                    </p>
                    <p className={"tw-text-nowrap"}>
                        {toFormattedNumber(divideByOneHundred(contest.entryFeeCent))} × {selectedTeamCount} =&nbsp;
                        {toWalletNumber(divideByOneHundred(contest.entryFeeCent * selectedTeamCount))}
                    </p>
                </div>
                <EntrySectionDivider />
                <DiscountSection
                    contest={contest}
                    selectedCoupons={selectedCoupons}
                    myUnusedCoupon={myUnusedCoupon}
                    openSelectCouponModal={onOpenSelectCouponModal}
                    isUseRuby={useRuby}
                    isUseCoupon={useCoupon}
                    canUseRuby={canUseRuby}
                    couponDiscountCent={couponDiscountCent}
                    rubyDiscountToDepositCent={rubyDiscountToDepositCent}
                    onUseRuby={handleUseRuby}
                    onUseCoupon={handleUseCoupon}
                    onResetDiscount={handleNotUseDiscount}
                />
                <div className={"tw-flex tw-justify-between tw-text-h6 tw-font-bold"}>
                    <p>
                        <FormattedMessage id="label_total" />
                    </p>
                    <p data-testid={"total"}>
                        {toWalletNumber(divideByOneHundred(entryFeeDeductDiscountCent))}
                    </p>
                </div>
            </div>
            <BalanceSection
                balanceDepositCent={balanceDepositCent}
                balanceRubyCent={balanceRubyCent}
            />
            <TermsAndCondition />
        </div>
        <div className={"tw-pt-4"}>
            <ConfirmationModalButtonFactory
                balanceDepositCent={balanceDepositCent}
                onConfirmationButtonClick={onConfirmationButtonClick}
                onAddDepositButtonClick={onAddDepositButtonClick} />
        </div>
    </div>;
};
