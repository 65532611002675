import { zodResolver } from "@hookform/resolvers/zod";
import { Form, FormField, FormItem } from "components/ui/form";
import React from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { z } from "zod";
import { pictureWithId } from "helpers/IconHelper";
import { DEFAULT_IMG_MAX_FILE_SIZE_MB, ImageUploader } from "domain/kyc/components/ImageUploader";
import { FormErrorMessage } from "domain/kyc/pages/kycVerificationForm/components/FormErrorMessage";
import { KycVerificationProps } from "domain/kyc/pages/kycVerificationForm/type/kyc_verification_props";
import { KycVerificationFormActions } from "domain/kyc/pages/kycVerificationForm/components/KycVerificationFormActions";

const identityProofPhotoName = "identityProofPhoto";

interface Step4FormInput {
    identityProofPhoto: File | null
}

export const Step4: React.FC<KycVerificationProps> = (props) => {
    const { kycData, onBack, onDone } = props;
    const intl = useIntl();

    const requiredError = intl.formatMessage({ id: "required_error_upload_picture_with_id" });
    const resolver = zodResolver(z.object({
        identityProofPhoto: z.instanceof(File, {
            message: requiredError
        })
    }));

    const form = useForm<Step4FormInput>({
        resolver: resolver,
        defaultValues: {
            identityProofPhoto: kycData.identityProofPhoto
        },
    });

    const {
        formState: { errors, },
        getValues,
        setValue,
        setError,
        handleSubmit,
    } = form;

    const handleDone = handleSubmit((data) => {
        onDone && onDone(data);
    });


    return (
        <Form {...form}>
            <div data-testid="kyc-verification-step4" className={"tw-flex-col tw-flex tw-gap-[2.8rem]"}>
                <div className={"tw-flex tw-flex-col tw-gap-1"}>
                    <h2 className={"tw-text-subtitle-1 tw-pb-1"}>
                        <FormattedMessage id={"kyc_verification_form_title_picture_with_id"} />
                    </h2>
                    <div className={"tw-w-[154px] tw-m-auto tw-bg-[#d9d9d9] tw-rounded-xl"}>
                        <img src={pictureWithId} alt="with-your-id" />
                    </div>
                    <p className={"tw-text-subtitle-2 tw-whitespace-pre-wrap"}>
                        <FormattedMessage id={"kyc_verification_form_description_picture_with_id"} />
                    </p>
                </div>
                <FormField name={identityProofPhotoName} render={() => (
                    <FormItem className={"tw-flex tw-flex-col"}>
                        <ImageUploader
                            name={identityProofPhotoName}
                            label={
                                <FormattedMessage id={"placeholder_upload_picture_with_id"} />
                            }
                            initFile={getValues("identityProofPhoto")}
                            isError={!!errors.identityProofPhoto}
                            onFileChanged={(e) => {
                                setValue(identityProofPhotoName, e as any, { shouldValidate: true });
                            }}
                            onSetError={() => {
                                setError(identityProofPhotoName, {
                                    message: intl.formatMessage({
                                        id: "invalid_type_error_upload_image"
                                    }, {
                                        fileMaxSizeMB: DEFAULT_IMG_MAX_FILE_SIZE_MB
                                    })
                                });
                            }}
                        />
                        <FormErrorMessage />
                    </FormItem>
                )} />
                <KycVerificationFormActions onBack={onBack} onDone={handleDone} />
            </div>
        </Form>
    );
};
