import React, { useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { useFormContext, useWatch } from "react-hook-form";
import { FormControl, FormField, FormItem } from "components/ui/form";
import { FantasyTextField } from "components/reactHookFormField/FantasyTextField";
import { FormErrorMessage } from "domain/kyc/pages/kycVerificationForm/components/FormErrorMessage";
import { TEXT_FIELD_VARIANT } from "components/input/FantasyText";

export const BirthFormField: React.FC = () => {
    const intl = useIntl();
    const { trigger, control } = useFormContext();
    const isInitialRender = useRef(true);

    const birth = useWatch({
        control,
        name: "birth"
    });

    useEffect(() => {
        if (isInitialRender.current) {
            isInitialRender.current = false;
            return;
        }

        trigger("birth");
        trigger("birth.dayOfBirth");
        trigger("birth.monthOfBirth");
        trigger("birth.yearOfBirth");
    }, [birth, trigger]);

    return <FormField
        name="birth"
        render={() => {
            return <>
                <FormItem>
                    <FormControl>
                        <div className="tw-flex tw-gap-2">
                            <FantasyTextField
                                name="birth.yearOfBirth"
                                showErrorMessage={false}
                                variant={TEXT_FIELD_VARIANT.STANDARD}
                                inputProps={{
                                    type: "number",
                                    placeholder: intl.formatMessage({ id: "placeholder_year" }),
                                    className: "tw-placeholder-grey-600",
                                    tabIndex: 3
                                }} />
                            <FantasyTextField
                                name="birth.monthOfBirth"
                                showErrorMessage={false}
                                variant={TEXT_FIELD_VARIANT.STANDARD}
                                inputProps={{
                                    placeholder: intl.formatMessage({ id: "placeholder_month" }),
                                    className: "tw-placeholder-grey-600",
                                    tabIndex: 4
                                }}
                            />
                            <FantasyTextField
                                name="birth.dayOfBirth"
                                showErrorMessage={false}
                                variant={TEXT_FIELD_VARIANT.STANDARD}
                                inputProps={{
                                    placeholder: intl.formatMessage({ id: "placeholder_date" }),
                                    className: "tw-placeholder-grey-600",
                                    tabIndex: 5
                                }}
                            />
                        </div>
                    </FormControl>

                    <FormErrorMessage />
                </FormItem>
            </>;
        }}
    />;
};
