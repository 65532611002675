import { ApLevelDto, SettingDto } from "apis/setting/SettingApi";
import { divideByOneHundred } from "helpers/number/NumberHelper";
import { sortByNumberKeyAsc } from "helpers/SortingHelper";
import { tenMinutes } from "helpers/TimeHelper";
import { orderBy } from "lodash";
import { Reducer } from "redux";
import { SettingAction } from "store/actions/Actions";
import { UPDATE_SETTING } from "store/Constants";
import { AP_LEVEL } from "types/activityPoint/ApLevel";
import { SPORT, strToSport } from "types/sports/Sport";
import { ADD_COIN_METHOD } from "../config/FantasyGameConfigState";

export interface SettingState {
    sports: {
        sport: SPORT;
        message: string;
        order: number;
        enabled: boolean;
    }[]
    status: boolean,
    contest: {
        spotsToMaxEntries: {
            [key: number]: number
        },
        defaultMaxEntries: number,
        rubyExchangeRateCent: number,
        rubyExchangeRate: number
    },
    referrer: {
        refereeRuby: number,
        maxRubyCentPerReferee: number,
        maxRubyPerReferee: number,
        returnToReferrerPercentage: number,
    },
    match: {
        kickoffDurationMilliseconds: number
    },
    apLevels: {
        level: AP_LEVEL,
        weight: number,
        minAp: number,
    }[],
    following: {
        maxFollowing: number,
    },
    newUserGifts: SettingDto["newUserGifts"],
    link: {
        howToPlay: string,
        termsAndConditions: string,
        privacyPolicy: string
    },
    withdraw: {
        provider: string
        minimumWithdrawAmount: number
        transactionFee: number
    },
    mpblEnabled: boolean,
    questEnabled: boolean,
    paymentMethod: ADD_COIN_METHOD | null
    // TODO: 之後從 FeatureConfig 搬過來用，目標是所有 Setting 的值都從 FantasyGameConfigState 搬過來
}

export const initState: SettingState = {
    sports: [],
    status: false,
    contest: {
        spotsToMaxEntries: {},
        defaultMaxEntries: 0,
        rubyExchangeRateCent: 0,
        rubyExchangeRate: 0
    },
    referrer: {
        refereeRuby: 0,
        maxRubyCentPerReferee: 0,
        maxRubyPerReferee: 0,
        returnToReferrerPercentage: 0,
    },
    match: {
        kickoffDurationMilliseconds: tenMinutes
    },
    apLevels: [],
    following: {
        maxFollowing: 0
    },
    newUserGifts: {
        rubyCent: 0,
        couponDiscountPercentage: 0
    },
    link: {
        howToPlay: "",
        termsAndConditions: "",
        privacyPolicy: ""
    },
    withdraw: {
        provider: "",
        minimumWithdrawAmount: 0,
        transactionFee: 0,
    },
    mpblEnabled: false,
    questEnabled: false,
    paymentMethod: null
};

const getAvailableApLevels = (apLevels: ApLevelDto[]): ApLevelDto[] => {
    const apLevelKeys = Object.keys(AP_LEVEL);

    return orderBy(apLevels.filter(apLevel => apLevelKeys.includes(apLevel.level)), ["weight"]);
};

const settingState: Reducer<SettingState, SettingAction> = (prevState = initState, action) => {
    const { type, payload } = action;

    switch (type) {
        case UPDATE_SETTING:

            const {
                setting: {
                    contest,
                    referrer,
                    match,
                    apLevels,
                    following,
                    newUserGifts,
                    link,
                    withdraw,
                    mpblEnabled,
                    questEnabled,
                    sports,
                    paymentMethod
                },
                status
            } = payload;

            return {
                sports: sports.map(sport => ({
                    ...sport,
                    sport: strToSport(sport.sport)
                })),
                status: status,
                contest: {
                    spotsToMaxEntries: sortByNumberKeyAsc(contest.spotsToMaxEntries),
                    defaultMaxEntries: contest.defaultMaxEntries,
                    rubyExchangeRateCent: contest.rubyExchangeRateCent,
                    rubyExchangeRate: divideByOneHundred(contest.rubyExchangeRateCent),
                },
                referrer: {
                    refereeRuby: divideByOneHundred(referrer.refereeRubyCent),
                    maxRubyCentPerReferee: referrer.maxRubyCentPerReferee,
                    maxRubyPerReferee: divideByOneHundred(referrer.maxRubyCentPerReferee),
                    returnToReferrerPercentage: referrer.returnToReferrerPercentage
                },
                match,
                apLevels: getAvailableApLevels(apLevels),
                following,
                newUserGifts,
                link,
                withdraw: withdraw.provider === "UNSET" ? initState.withdraw : {
                    ...withdraw,
                    transactionFee: divideByOneHundred(withdraw.handleFeeCent),
                    minimumWithdrawAmount: divideByOneHundred(withdraw.minimumAmountCent)
                },
                mpblEnabled,
                questEnabled,
                paymentMethod: paymentMethod
            };

        default:
            return prevState;
    }
};

export default settingState;
