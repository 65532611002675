import { ADD_DEPOSIT_PAGE, haveResult } from "domain/coin/AddCoinHelper";
import PaymentResult from "domain/coin/pages/PaymentResult";
import SelectCoinProduct from "domain/coin/pages/SelectCoinProduct";
import SelectPaymentMethod from "domain/coin/pages/SelectPaymentMethod/SelectPaymentMethod";
import { useCallback, useMemo, useState } from "react";
import PaymentAppDownload from "domain/coin/pages/SelectPaymentMethod/PaymentAppDownload";


const Page = {
    [ADD_DEPOSIT_PAGE.PRODUCTS]: SelectCoinProduct,
    [ADD_DEPOSIT_PAGE.METHODS]: SelectPaymentMethod,
    [ADD_DEPOSIT_PAGE.RESULT]: PaymentResult,
    [ADD_DEPOSIT_PAGE.APP_DOWNLOAD]: PaymentAppDownload,
};

export const useAddCoinPage = ({ status }) => {
    const initPage = useMemo(() => haveResult(status) ? ADD_DEPOSIT_PAGE.RESULT : ADD_DEPOSIT_PAGE.PRODUCTS, [status]);
    const [page, setPage] = useState(initPage);

    const changePage = useCallback((page: ADD_DEPOSIT_PAGE) => {
        setPage(page);
    }, [setPage]);

    const goToSelectPaymentMethodPage = useCallback(() => {
        changePage(ADD_DEPOSIT_PAGE.METHODS);
    }, [changePage]);

    const goToAppDownloadPage = useCallback(() => {
        changePage(ADD_DEPOSIT_PAGE.APP_DOWNLOAD);
    }, [changePage]);

    const goToInitPage = useCallback(() => {
        changePage(initPage);
    }, [initPage, changePage]);

    return {
        changePage,
        goToInitPage,
        goToSelectPaymentMethodPage,
        goToAppDownloadPage,
        Component: Page[page] || null,
    };
};
