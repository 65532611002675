import {
    dailyCheckInQuestUrl,
    fetchTaskPrizeClaimedUrl,
    fetchTaskProgressUrl,
    fetchTasksUrl,
    getQuestCompleteUrl
} from "apis/ApiUrlConfig";
import { ajax } from "apis/ApiUtils";
import { TaskDto } from "data/dto/task/TaskDto";
import { TaskPrizeClaimedDto } from "data/dto/task/TaskPrizeClaimedDto";
import { TaskProgressDto } from "data/dto/task/TaskProgressDto";
import { DailyCheckInQuestVo } from "../../vo/task/DailyCheckInQuestVo";
import { QUEST_TYPE } from "../../../types/quest/QuestType";

export const fetchTasks = async () => {
    const response = await ajax<TaskDto[]>({ url: fetchTasksUrl });
    return response.data.data;
};

export const fetchTaskPrizeClaimed = async () => {
    const response = await ajax<TaskPrizeClaimedDto>({ url: fetchTaskPrizeClaimedUrl });
    return response.data.data;
};

export const fetchTaskProgress = async () => {
    const response = await ajax<TaskProgressDto[]>({ url: fetchTaskProgressUrl });
    return response.data.data;
};

export const completeQuest = async (taskType: QUEST_TYPE, code: string) => {
    await ajax({ url: getQuestCompleteUrl(taskType, code), method: "POST" });
};

export const fetchDailyCheckInQuest = async () => {
    const response = await ajax<DailyCheckInQuestVo>({ url: dailyCheckInQuestUrl, method: "GET" });
    return response.data.data;
};