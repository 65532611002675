import { Callback } from "Common";
import React from "react";
import { Spinner } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { FantasyPopupProps, POPUP_SIZE } from "../../../components/popup/FantasyBasicPopup";
import { PrizeListVo } from "../../../data/vo/PrizeList/PrizrListVo";
import { BUTTON_PATTERN, NEW_BUTTON_VARIANTS } from "../../../designToken/button/types";
import Prizes from "../../slate/components/Prizes";
import { useQueryPrizeList } from "../../slate/components/PrizesContainer";
import { ContestEntry } from "../../slate/helpers/helpers";

interface MakePrizesAndDistributionDialogPropsParams {
    prizeList: PrizeListVo[];
    contestEntry: ContestEntry;
    onClose: Callback;
}

export interface PrizesAndDistributionDialogProps {
    contestCode: string;
    onClose: Callback;
}

export function makePrizesAndDistributionDialogBaseProps({ onClose }: { onClose: Callback }): FantasyPopupProps {
    return {
        show: true,
        popupSize: POPUP_SIZE.MD,
        title: <FormattedMessage id={"label_prizes_and_distribution"} />,
        buttonSetting: {
            confirmButtonVariant: NEW_BUTTON_VARIANTS.primaryDark,
            buttonPattern: BUTTON_PATTERN.pill,
            confirmButtonText:
                <FormattedMessage id={"button_got_it"} />,
            onConfirm: onClose,
            cancelButton: false,
        },
        onHide: onClose,
    };
}

export function makePrizesAndDistributionDialog(props: MakePrizesAndDistributionDialogPropsParams): FantasyPopupProps {
    return {
        ...makePrizesAndDistributionDialogBaseProps(props),
        content: (
            <Prizes prizes={props.prizeList} contestEntry={props.contestEntry} />
        ),
    };
}

interface PrizesContainerProps {
    contestCode: string;
}

export const PrizesContainer: React.FC<PrizesContainerProps> = (props) => {
    const { prizeList, contestEntry, isLoading } = useQueryPrizeList({
        contestCode: props.contestCode
    });

    if (isLoading) return (
        <div className={"tw-flex tw-justify-center"}>
            <Spinner />
        </div>
    );

    return (
        <Prizes prizes={prizeList} contestEntry={contestEntry} />
    );
};

interface MakePrizesAndDistributionContainerDialogPrams extends PrizesAndDistributionDialogProps {
}

export function makePrizesAndDistributionContainerDialog(props: MakePrizesAndDistributionContainerDialogPrams): FantasyPopupProps {
    return {
        ...makePrizesAndDistributionDialogBaseProps(props),
        content: <PrizesContainer {...props} />,
    };
}
