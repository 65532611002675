import { IStats } from "apis/user/UserApi";
import clsx from "clsx";
import GradientCircleProgressbar from "components/gradientCircleProgressbar/GradientCircleProgressbar";
import { useSportConfigs } from "domain/sport/useSportConfigs";
import { cn } from "helpers/cn";
import { divideByOneHundred } from "helpers/number/NumberHelper";
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { MdInfoOutline } from "react-icons/md";
import { FormattedMessage } from "react-intl";
import { ENTRY_FEE_TYPE_FROM_API } from "types/contest/Contest";
import { SPORT } from "types/sports/Sport";
import {
    StyledCareerStatsData,
    StyledCareerStatsDataSpanText,
    StyledCareerStatsDataText,
    StyledCareerStatsDataTitle,
    StyledCareerStatsDataValue,
    StyledCareerStatsDataValueTitle,
    StyledCareerStatsDataValueTitleWrapper,
    StyledCareerStatsDataValueWrapper,
    StyledCareerStatsDataWrapper,
    StyledCareerStatsDivider,
    StyledCareerStatsProgress,
    StyledCareerStatsProgressContent,
    StyledCareerStatsProgressPr,
    StyledCareerStatsProgressPrLabel,
    StyledCareerStatsSportTitle,
} from "./CareerStatsStyle";

const EntryFeeTypeConfig: Record<
    ENTRY_FEE_TYPE_FROM_API,
    {
        title: React.ReactNode;
        textColor: string;
        gradientProgressStartColor: string;
        gradientProgressEndColor: string;
    }
> = {
    [ENTRY_FEE_TYPE_FROM_API.DEPOSIT]: {
        title: <FormattedMessage id={"label_winning"} />,
        textColor: "#510E59",
        gradientProgressStartColor: "#DC9DE3",
        gradientProgressEndColor: "#772B80",
    },
    [ENTRY_FEE_TYPE_FROM_API.RUBY]: {
        title: <FormattedMessage id={"label_ruby"} />,
        textColor: "#BF2011", //TODO change to Tailwind
        gradientProgressStartColor: "#FCC5C1",
        gradientProgressEndColor: "#F88178",
    },
};

interface ICareerStatsContentProps {
    sport: SPORT;
    value?: IStats[];
}

interface IRenderProgressbar {
    (data: IStats): JSX.Element;
}

const StatsTitle: React.FC<{ sport: SPORT }> = ({ sport }) => {
    const sportTabSettings = useSportConfigs();

    const { icon, name } = sportTabSettings.find((tab) => tab.key === sport.toLocaleLowerCase())!;

    return (
        <div className="tw-flex tw-items-center tw-gap-2">
            <div className={cn("tw-flex tw-items-center tw-justify-center tw-bg-primary-50",
                "tw-w-[3.2rem] tw-h-[3.2rem] tw-rounded-[0.8rem]")}>
                {icon("2.4rem")}
            </div>
            <StyledCareerStatsSportTitle>
                {name}
            </StyledCareerStatsSportTitle>
        </div>
    );
};


const CareerStatsContent: React.FC<ICareerStatsContentProps> = ({
                                                                    sport,
                                                                    value,
                                                                }) => {
    const renderProgressbar: IRenderProgressbar = (data) => {
        const { textColor, gradientProgressStartColor, gradientProgressEndColor } =
            EntryFeeTypeConfig[data.entryFeeType];

        return (
            <StyledCareerStatsProgress>
                <GradientCircleProgressbar
                    percentage={data.isStatsEmpty ? 0 : divideByOneHundred(data.prDimi)}
                    gradientProgressStartColor={gradientProgressStartColor}
                    gradientProgressEndColor={gradientProgressEndColor}
                    progressDefaultColor="#F6F7F7"
                >
                    <StyledCareerStatsProgressContent>
                        <StyledCareerStatsProgressPr
                            $color={data.isStatsEmpty ? "#1C253020" : textColor}
                        >
                            {data.isStatsEmpty ? 0 : divideByOneHundred(data.prDimi)}
                        </StyledCareerStatsProgressPr>
                        <StyledCareerStatsProgressPrLabel>
                            PR(%)
                        </StyledCareerStatsProgressPrLabel>
                    </StyledCareerStatsProgressContent>
                </GradientCircleProgressbar>
            </StyledCareerStatsProgress>
        );
    };

    const renderTopScore = (data) => {
        return (
            <div>
                <StyledCareerStatsDataValueTitle>
                    <FormattedMessage id="me_page_career_stats_label_top_score" />
                </StyledCareerStatsDataValueTitle>
                <StyledCareerStatsDataValue>
                    {data.isStatsEmpty ? "-" : divideByOneHundred(data.topScoreCent)}
                </StyledCareerStatsDataValue>
            </div>
        );
    };

    const renderRoi = (data) => {
        return (
            <div>
                <StyledCareerStatsDataValueTitleWrapper>
                    <StyledCareerStatsDataValueTitle>
                        <FormattedMessage id="label_roi" />
                    </StyledCareerStatsDataValueTitle>
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                <FormattedMessage id="label_return_on_investment" />
                            </Tooltip>
                        }
                    >
                        <div className="d-flex">
                            <MdInfoOutline size="1.2rem"></MdInfoOutline>
                        </div>
                    </OverlayTrigger>
                </StyledCareerStatsDataValueTitleWrapper>
                <StyledCareerStatsDataValue>
                    {`${data.isStatsEmpty ? 0 : divideByOneHundred(data.roiDimi)}%`}
                </StyledCareerStatsDataValue>
            </div>
        );
    };

    const renderLabel = (data) => {
        return (
            <StyledCareerStatsDataText>
                {data.isStatsEmpty ? (
                    <FormattedMessage id="me_page_career_stats_label_no_data" />
                ) : (
                    <FormattedMessage
                        id="me_page_career_stats_label_description"
                        values={{
                            topScore: (
                                <StyledCareerStatsDataSpanText>
                                    {divideByOneHundred(data.topScoreCent)}
                                </StyledCareerStatsDataSpanText>
                            ),
                            pr: (
                                <StyledCareerStatsDataSpanText>
                                    {divideByOneHundred(data.prDimi)}
                                </StyledCareerStatsDataSpanText>
                            ),
                        }}
                    />
                )}
            </StyledCareerStatsDataText>
        );
    };

    const renderStats = (data: IStats, index: number, array: IStats[]) => {
        const { title, textColor } = EntryFeeTypeConfig[data.entryFeeType];
        const isLastItem = index === array.length - 1;

        return (
            <StyledCareerStatsDataWrapper className={clsx({
                "tw-border-system-gray50 tw-border-b-[1px] tw-border-solid": !isLastItem,
            })}
                                          key={data.entryFeeType}>
                {renderProgressbar(data)}
                <StyledCareerStatsData>
                    <StyledCareerStatsDataTitle $color={textColor}>
                        {title}
                    </StyledCareerStatsDataTitle>
                    <StyledCareerStatsDataValueWrapper>
                        {renderTopScore(data)}
                        <StyledCareerStatsDivider></StyledCareerStatsDivider>
                        {renderRoi(data)}
                    </StyledCareerStatsDataValueWrapper>
                    {renderLabel(data)}
                </StyledCareerStatsData>
            </StyledCareerStatsDataWrapper>
        );
    };

    if (!value) {
        return null;
    }

    return (
        <div className="tw-flex-shrink-0 tw-px-4 tw-py-2 tw-w-[26rem] tw-rounded-[0.8rem] tw-bg-white">
            <StatsTitle sport={sport} />
            {value.map(renderStats)}
        </div>
    );
};

export default CareerStatsContent;
