import { HINT_CARD_VARIANT, HintCard } from "components/hintcard/HintCard";
import { useWithdrawSettings } from "domain/withdraw/hooks/useWithdrawSettings";
import { Icon } from "helpers/IconHelper";
import React from "react";
import { FormattedMessage } from "react-intl";

interface WithdrawHintCardProps {
    variant?: HINT_CARD_VARIANT;
}

export const WithdrawHintCard: React.FC<WithdrawHintCardProps> = ({
                                                                      variant = HINT_CARD_VARIANT.PRIMARY_LIGHT
                                                                  }) => {
    const {
        minimumWithdrawAmount,
        transactionFee,
    } = useWithdrawSettings();

    return <HintCard
        className={"tw-justify-start tw-w-full"}
        prefixIcon={Icon.InfoIcon}
        variant={variant}
        hintText={
            <ul className={"tw-list-disc tw-list-inside"}>
                <li className={"marker:tw-text-info-1000 marker:tw-text-sm"}>
                    <FormattedMessage
                        id={"withdraw_to_account_minimum_withdrawal_amount_hint_label"}
                        values={{
                            minimumWithdrawAmount,
                        }} />
                </li>
                <li className={"marker:tw-text-info-1000 marker:tw-text-sm"}>
                    <FormattedMessage
                        id={"withdraw_to_account_transaction_fee_hint_label"}
                        values={{
                            transactionFee,
                        }} />
                </li>
                <li className={"marker:tw-text-info-1000 marker:tw-text-sm"}>
                    <FormattedMessage id="withdraw_to_account_withdrawal_order_hint_label" />
                </li>
            </ul>
        }
    />;
};
