import { getDataFromLocalStorage, setDataToLocalStorage } from "helpers/localStorage/LocalStorageHelper";
import { LOCAL_STORAGE_KEYS } from "helpers/localStorage/types";
import {
    toIndianFormattedNumber,
    toIndianPeriodNumber,
    toIndianWalletNumber,
    toUniversalFormattedNumber,
    toUniversalPeriodNumber,
    toUniversalWalletNumber
} from "helpers/number/NumberHelper";
import useFantasyConfig from "hooks/useFantasyConfig";
import { find } from "lodash";
import { FormattedMessage } from "react-intl";
import { CURRENCY } from "store/reducer/config/FantasyGameConfigState";

interface CurrencyObject {
    key: CURRENCY;
    text: JSX.Element;
    example: string;
    toPeriodNumber: (value: number) => string;
    toFormattedNumber: (value: number, fractionDigits?: number) => string;
    toWalletNumber: (value: number) => string;
}

interface useCurrencyResponse {
    currencies: CurrencyObject[];
    currentCurrencyObject: CurrencyObject;
    onCurrencyChange: (currency: CURRENCY) => void;
}

const useCurrency = (): useCurrencyResponse => {

    const { featureConfig: { defaultCurrencyFormat } } = useFantasyConfig();

    const currencies = [
        {
            key: CURRENCY.INDIAN,
            text: <FormattedMessage id="label_india" />,
            example: "1 lakh",
            toPeriodNumber: toIndianPeriodNumber,
            toFormattedNumber: toIndianFormattedNumber,
            toWalletNumber: toIndianWalletNumber
        },
        {
            key: CURRENCY.UNIVERSAL,
            text: <FormattedMessage id="label_universal" />,
            example: "100 K",
            toPeriodNumber: toUniversalPeriodNumber,
            toFormattedNumber: toUniversalFormattedNumber,
            toWalletNumber: toUniversalWalletNumber
        }
    ];

    const currentCurrencyObject =
        find(currencies, ["key", getDataFromLocalStorage(LOCAL_STORAGE_KEYS.CURRENCY) ?? (defaultCurrencyFormat || CURRENCY.UNIVERSAL)])!;
    const onCurrencyChange = (currency: CURRENCY) => setDataToLocalStorage(LOCAL_STORAGE_KEYS.CURRENCY, currency);

    return {
        currencies,
        currentCurrencyObject,
        onCurrencyChange
    };
};

export default useCurrency;
