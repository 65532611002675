import { ApiError } from "apis/Api";
import { ERROR_HANDLER_FLOW } from "hooks/useHandleApiError";
import { ResponsibleGamingRepository } from "data/repository/responsibleGaming/ResponsibleGamingRepository";
import useFantasyQuery from "hooks/useFantasyQuery";
import { QUERY_KEY } from "hooks/useFantasyQuery/type";
import useFantasyCommand from "hooks/useFantasyCommand";
import {
    AddResponsibleGamingRegistrationRequest
} from "data/dto/responsibleGaming/AddResponsibleGamingRegistrationRequest";
import { useCallback } from "react";

interface useResponsibleGamingRegistrationProps {
    onQueryFail: (error: ApiError) => ERROR_HANDLER_FLOW;
}

export const useResponsibleGamingRegistration = (props?: useResponsibleGamingRegistrationProps) => {
    const responsibleGamingRepository = new ResponsibleGamingRepository();
    const responsibleGamingRegistration = useFantasyQuery([QUERY_KEY.RESPONSIBLE_GAMING_REGISTRATION], responsibleGamingRepository.getResponsibleGamingRegistration, {
        onFail: props?.onQueryFail
    });

    const addRegistration = useFantasyCommand<AddResponsibleGamingRegistrationRequest>(responsibleGamingRepository.addResponsibleGamingRegistration, {
        errorHandle: false
    });

    const handleAddRegistration = useCallback(async (request: AddResponsibleGamingRegistrationRequest) => {
        try {
            await addRegistration.mutateAsync(request);
        } catch (e) {
            throw e;
        }

        await responsibleGamingRegistration.refetch();
    }, [addRegistration, responsibleGamingRegistration]);

    return {
        responsibleGamingRegistration: responsibleGamingRegistration.data,
        addRegistration: handleAddRegistration,
    };
};
