import { useIntl } from "react-intl";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import _ from "lodash";
import { removeAllSpaces } from "helpers/stringFormatHelpers";
import { isAllDigit } from "helpers/RegexHelper";
import { ZodOnChangeValidator } from "components/reactHookFormField/OnChangeValidator";

export const FORM_FIELD_ACCOUNT_NAME = "accountName";
export const FORM_FIELD_ACCOUNT_BANK = "accountBank";
export const FORM_FIELD_ACCOUNT_NUMBER = "accountNumber";

const checkAccountNumberValid = _.flow(removeAllSpaces, isAllDigit);

export const useWithdrawStep1Form = (defaultValues: {
    accountName: string;
    accountNumber: string;
    accountBank: string;
}) => {
    const intl = useIntl();
    const form = useForm({
        resolver: zodResolver(z.object({
            [FORM_FIELD_ACCOUNT_NAME]: z.string().min(1, {
                message: intl.formatMessage({ id: "withdraw_to_account_name_validation_error" })
            }),
            [FORM_FIELD_ACCOUNT_BANK]: z.string().min(1, {
                message: intl.formatMessage({ id: "withdraw_to_account_bank_validation_error" })
            }),
            [FORM_FIELD_ACCOUNT_NUMBER]: z.string()
                .min(1, {
                    message: intl.formatMessage({ id: "withdraw_to_account_number_validation_error" })
                })
                .refine(checkAccountNumberValid, {
                    message: intl.formatMessage({ id: "withdraw_to_account_number_validation_error" })
                })
        })),
        defaultValues
    });
    return form;
};


const schema = z.string().refine(val => (
    !val || checkAccountNumberValid(val)
));
export const accountNumberOnChangeValidator = new ZodOnChangeValidator(schema);
