import { fetchSupportedRegionUrl } from "../ApiUrlConfig";
import { ajax } from "../ApiUtils";
import { SupportedRegion } from "../user/UserApi";
import { KycVerificationVo } from "domain/kyc/types";

export const fetchSupportedRegions = async () => {
    const { data } = await ajax<SupportedRegion[]>({ url: fetchSupportedRegionUrl });
    return data.data;
};


export const sendKycVerification = async (kycData: KycVerificationVo): Promise<void> => {
    const data = new FormData();
    data.append("nationality", kycData.nationality);
    data.append("placeOfBirth", kycData.placeOfBirth);
    data.append("currentAddress", kycData.currentAddress);
    data.append("permanentAddress", kycData.permanentAddress);
    data.append("natureOfWork", kycData.natureOfWork);
    data.append("sourceOfIncome", kycData.sourceOfIncome);
    data.append("documentType", kycData.documentType);
    data.append("idNumber", kycData.idNumber);
    data.append("idFrontSideImage", kycData.idFrontSideImage!);
    data.append("identityProofPhoto", kycData.identityProofPhoto!);

    await ajax({
        url: "/kyc/application",
        method: "POST",
        data,
        customHeaders: {
            "Content-Type": "multipart/form-data",
        }
    });
};
