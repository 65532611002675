import {
    ProgressBarWithHeight,
    RemainingButtonWrapper,
    VideoPlayerMask,
    VideoPlayerWrapper
} from "domain/task/components/TaskVideoPlayer/TaskVideoPlayerStyle";
import { useTaskProvider } from "domain/task/TaskProvider";
import { durationFormat } from "helpers/TimeHelper";
import useDevice from "hooks/useDevice/useDevice";
import { useLockScroll } from "hooks/useLockScroll";
import React, { useCallback, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { MdClear } from "react-icons/md";
import ReactPlayer from "react-player";
import { OnProgressProps } from "react-player/base";
import { APP_MAX_WIDTH_VALUE } from "styles/Constants";

export const TaskVideoPlayer = () => {
    const [duration, setDuration] = useState(0);
    const [playedSeconds, setPlayedSeconds] = useState(0);
    const [isError, setIsError] = useState(false);
    const {
        videoPlayer,
        handleComplete,
        handleIncomplete,
    } = useTaskProvider();
    const { deviceWidth } = useDevice();
    useLockScroll(videoPlayer.show);

    const resetPlayer = () => {
        setIsError(false);
        setDuration(0);
        setPlayedSeconds(0);
    };

    const handleClosePlayer = useCallback(() => {
        handleIncomplete(videoPlayer.taskType!, videoPlayer.code!);
        resetPlayer();
        videoPlayer.stop();
    }, [handleIncomplete, videoPlayer]);

    const handleEnded = useCallback(() => {
        handleComplete(videoPlayer.taskType!, videoPlayer.code!);
        resetPlayer();
        videoPlayer.handleEnd();
    }, [handleComplete, videoPlayer]);

    if (!videoPlayer.show || !videoPlayer.taskType) {
        return null;
    }

    const handleDuration = (duration: number) => {
        setDuration(duration);
    };

    const handleProgress = ({ playedSeconds }: OnProgressProps) => {
        setPlayedSeconds(playedSeconds);
    };


    const videoPlayerWidth = Math.min(deviceWidth, APP_MAX_WIDTH_VALUE);

    const handleError = () => {
        setIsError(true);
    };

    return <VideoPlayerWrapper width={videoPlayerWidth}>
        <RemainingButtonWrapper onClick={handleClosePlayer}>
            <div className="fz-sf-regular-subhead flex-grow-1 text-center">
                {durationFormat(duration - playedSeconds)} left
            </div>
            <div>
                <MdClear size={24} />
            </div>
        </RemainingButtonWrapper>
        <VideoPlayerMask $width={videoPlayerWidth} $background={isError ? "bg-dark" : ""} />
        <ReactPlayer
            playsinline
            url={videoPlayer.url}
            height="100%"
            width="100%"
            playing
            volume={1}
            muted
            onDuration={handleDuration}
            onProgress={handleProgress}
            onEnded={handleEnded}
            onError={handleError}
            config={{
                youtube: {
                    playerVars: {
                        disablekb: 1,
                    }
                }
            }}
        />
        <div className="position-absolute start-0 bottom-0 w-100">
            <ProgressBar className="rounded-0">
                <ProgressBarWithHeight now={playedSeconds} max={duration} $height={0.6} />
            </ProgressBar>
        </div>
    </VideoPlayerWrapper>;
};

