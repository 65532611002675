import { groupBy } from "lodash";
import { IStats } from "apis/user/UserApi";
import { SPORT } from "types/sports/Sport";

export type IStatsMap = {
    [key in SPORT]?: IStats[];
};

export class CareerStatsVo {
    public readonly from: number;
    public readonly until: number;
    public readonly stats: IStatsMap;

    constructor(from: number, until: number, stats: IStats[]) {
        this.from = from;
        this.until = until;
        this.stats = groupBy(stats, "sport");
    }
}
