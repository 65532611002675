export const ROUTER_PATH_CONFIG = {
    auth: {
        index: "/auth",
        language: "/auth/language",
        login: "/auth/login",
        signUp: "/auth/signUp",
        resetPassword: "/auth/reset-password",
    },
    healthCheck: "/hc",
    checkEmail: "/check-email",
    error: "/error",
    fromMobileAppError: "/from-mobile-app-error",

    home: "/",
    myMatch: {
        index: "/match/my",
        myContestDetail: "/match/my/:contestCode",
    },
    startedMatchDetail: "/:sport/started/match/:matchId/detail",
    matchDetail: "/:sport/match/:matchId/detail",
    joinContest: "/contest/:contestCode/detail",
    wallet: "/wallet/history",
    result: {
        index: "/result",
        detail: "/:sport/result/:matchId",
    },

    // daily
    verifyKyc: {
        index: "/kyc",
        form: "/kyc/verify/form",
        progress: "/kyc/verify/progress",
        success: "/kyc/verify/success",
    },

    // b2b
    b2bRedirect: "/login/redirect",
    guide: "/guide",

    // b2c
    me: {
        index: "/me",
        edit: "/me/edit",
        referral: {
            index: "/me/referral",
            bonus: "/me/referral/bonus",
        },
    },
    reward: {
        index: "/reward",
        orders: "/reward/orders",
        item: "/reward/:rewardId",
    },
    chatRoom: "/chat-room",
    userProfile: {
        index: "/user-profile",
        transfer: "/user-profile/transfer",
        list: "/user-profile/list",
        info: "/user-profile/:userId",
    },
    activityPoint: "/activity-point",
    deposit: {
        index: "/deposit/:status",
        select: "/deposit/select",
    },
    mobileAppRedirect: "/app/:path+",
    language: "/language",
    currency: "/useCurrency",
    coupon: "/coupon",
    task: {
        index: "/task",
    },
    deleteUser: {
        index: "/user-delete",
        check: "/user-delete/:code/check",
    },
    officialWebsite: {
        index: "/official-website",
        featureContest: "/official-website/feature-contest",
    },
    slate: {
        index: "/slate/:slateId",
        myNineCatSlateDetail: "/slate/:sport/:nineCatId/:contestCode",
    },
    withdraw: "/withdraw"
};
