import Navbar from "components/container/navbar/Navbar";
import Button from "designToken/button/Button";
import { BUTTON_PATTERN, BUTTON_SIZE } from "designToken/button/types";
import AboutMe from "domain/me/components/AboutMe";
import Nickname from "domain/me/components/Nickname";
import Password from "domain/me/components/Password";
import {
    EditMeContainer,
    EditMeWrapper,
    FormContainer,
    MyProfileId,
    MyProfileIdWrapper,
    NavbarCenter,
    StyledFormInput,
    StyledFormLabel
} from "domain/me/profile/ProfileStyle";
import useProfile from "domain/me/profile/useProfile";
import useCurrency from "hooks/useCurrency/useCurrency";
import { useWarningDialog } from "hooks/useDialog";
import useFantasyHistory from "hooks/useFantasyHistory";
import useMyProfile from "hooks/useMyProfile";
import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import { MdOutlineContentCopy, MdOutlineEdit } from "react-icons/md";
import { FormattedMessage, useIntl } from "react-intl";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";
import { SYSTEM_COLORS } from "styles/Constants";
import { useSelector } from "store";


export const emailSentMessage = <FormattedMessage id="change_password_success_toast_label" />;

const DeleteAccountDialogButton = () => {
    const intl = useIntl();
    const history = useFantasyHistory();
    const handleShowDialog = useWarningDialog();

    const {
        currentCurrencyObject: { toWalletNumber },
    } = useCurrency();
    const {
        WalletState: { coin, winning, ruby },
    } = useSelector(store => store);

    const handleOpenDialog = () => handleShowDialog({
        title: (
            <h3 className="text-sys-danger-1 fz-sf-bold-title-3">
                {intl.formatMessage({
                    id: "user_delete_warn_dialog_title"
                })}
            </h3>
        ),
        content: (
            <p data-testid="user-delete-warn-dialog-content" style={{
                whiteSpace: "pre-wrap"
            }}>
                {intl.formatMessage({
                    id: "user_delete_warn_dialog_description",
                }, {
                    deposit: toWalletNumber(coin),
                    winning: toWalletNumber(winning),
                    ruby: toWalletNumber(ruby),
                })}
            </p>
        ),
        cancelButton: {
            text: intl.formatMessage({ id: "leaving_dialog_button_cancel" })
        },
        confirmButton: {
            onClick: () => history.push(ROUTER_PATH_CONFIG.deleteUser.index),
            text: intl.formatMessage({ id: "leaving_dialog_button_sure" })
        }
    });

    return (
        <div className="position-absolute bottom-0 start-50 translate-middle-x pb-4">
            <button className="text-sys-danger-1 clickable fz-sf-regular-subhead border-0 bg-transparent"
                    onClick={handleOpenDialog}>
                <FormattedMessage id="label_deactivate_account_message" />
            </button>
        </div>
    );
};

const Profile = () => {
    const { myProfile, getMyProfile } = useMyProfile();
    const {
        navbarRef,
        containerHeight,
        isEditing,
        setEditing,
        nicknameErrorMessage,
        profileData,
        onCopy,
        handleUpdateAboutMe,
        handleUpdateDescription,
        handleUpdateNickname,
    } = useProfile({
        myProfile,
        getMyProfile,
    });

    return (
        <EditMeWrapper>
            <Navbar ref={navbarRef}
                    navBackIconColor={SYSTEM_COLORS.primary80}
                    navbarCenter={
                        <NavbarCenter>
                            <FormattedMessage
                                id={isEditing ? "edit_profile_page_title_editing" : "edit_profile_page_title"} />
                        </NavbarCenter>
                    }
                    endIcon={
                        <>
                            {
                                !isEditing && (
                                    <MdOutlineEdit data-testid={"profile-edit-btn"}
                                                   size={"2.4rem"}
                                                   onClick={() => setEditing(true)} />
                                )
                            }
                        </>
                    }
            />

            <EditMeContainer $height={containerHeight}>
                <Nickname
                    isEditing={isEditing}
                    nickname={myProfile.nickname}
                    errorMessage={nicknameErrorMessage}
                    onChangeNickname={handleUpdateNickname}
                />
                <FormContainer>
                    <StyledFormLabel>
                        <FormattedMessage id="label_id" />
                    </StyledFormLabel>
                    <MyProfileIdWrapper>
                        <MyProfileId>{myProfile.userCode}</MyProfileId>
                        <CopyToClipboard text={myProfile.userCode} onCopy={onCopy}>
                            <MdOutlineContentCopy className="tw-text-primary-600 tw-cursor-pointer" size={"1.6rem"} />
                        </CopyToClipboard>
                    </MyProfileIdWrapper>
                </FormContainer>
                <FormContainer>
                    <StyledFormLabel><FormattedMessage id="label_email" /></StyledFormLabel>
                    <StyledFormInput type="text" defaultValue={myProfile.email} disabled />
                </FormContainer>
                {myProfile.canChangePassword &&
                    <Password email={myProfile.email}
                              canChangePassword={myProfile.canChangePassword} />}
                <AboutMe
                    isEditing={isEditing}
                    description={profileData.description}
                    onChangeDescription={handleUpdateDescription}
                />
                <div className={"d-flex justify-content-center"}>
                    {
                        isEditing &&
                        <Button
                            dataTestId={"confirm-btn"}
                            size={BUTTON_SIZE.lg}
                            pattern={BUTTON_PATTERN.pill}
                            disabled={!!nicknameErrorMessage}
                            onClick={handleUpdateAboutMe}
                        >
                            <FormattedMessage
                                id="button_confirm" />
                        </Button>
                    }
                </div>
                <DeleteAccountDialogButton />
            </EditMeContainer>
        </EditMeWrapper>

    );
};

export default Profile;
