import { FormattedMessage } from "react-intl";
import React from "react";
import { TransactionDetailVo } from "data/vo/wallet/TransactionDetailVo";
import { QUEST_TYPE } from "../../../types/quest/QuestType";

export const ClaimTaskNote = ({ taskType }: TransactionDetailVo) => {

    if (taskType === QUEST_TYPE.WATCH_AD) {
        return <FormattedMessage id="daily_task_item_title_watch_ad" />;
    }

    return <></>;
};
