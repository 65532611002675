import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import reducers, { RootStoreState } from "./reducer";
import { TypedUseSelectorHook, useSelector as useReduxSelector } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import WalletReducer from "store/reducer/wallet/WalletReducer";

const store = createStore(reducers, applyMiddleware(thunk));
export const _store = configureStore({
    reducer: {
        wallet: WalletReducer,
    },
});

export const useSelector: TypedUseSelectorHook<RootStoreState> = useReduxSelector;

export default store;
