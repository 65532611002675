import { WALLET_TYPE } from "data/dto/wallet/walletHistoryDto";
import Button from "designToken/button/Button";
import { NEW_BUTTON_VARIANTS } from "designToken/button/types";
import { useWithdrawContext } from "domain/withdraw/WithdrawProvider";
import { cn } from "helpers/cn";
import { Icon } from "helpers/IconHelper";
import { formatToTextGroups } from "helpers/stringFormatHelpers";
import useFantasyHistory from "hooks/useFantasyHistory";
import React, { ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";
import DailyFantasyWatermark = Icon.DailyFantasyWatermark;

export interface Step4ReceiptLayoutProps {
    withdrawInfoSlot: ReactNode;
}

const Step4ReceiptLayout: React.FC<Step4ReceiptLayoutProps> = (props) => {
    const history = useFantasyHistory();
    const { withdrawForm } = useWithdrawContext();

    function handleGoToTransactionHistory() {
        history.push(ROUTER_PATH_CONFIG.wallet, {
            category: WALLET_TYPE.WINNING,
            navbarBackGoTo: history.location.pathname,
        });
    }

    return (
        <div
            className={cn("tw-bg-white tw-rounded-lg", "tw-w-[60%] tw-min-w-[27rem]", "tw-p-8 tw-pb-3", "xsm:tw-p-10 xsm:tw-pb-[1.8rem]", "tw-flex tw-flex-col tw-gap-4 xsm:tw-gap-5", "tw-text-grey-800")}>
            <div className={"tw-flex tw-flex-col tw-gap-4 xsm:tw-gap-8"}>
                <div className={cn("tw-flex tw-flex-col tw-gap-1", "tw-text-body-2 xsm:tw-text-subtitle-1 tw-px-2")}>
                    <p> {withdrawForm.accountName} </p>
                    <p> {withdrawForm.selectedBankName} </p>
                    <p> {formatToTextGroups(withdrawForm.accountNumber)} </p>
                </div>
                <div
                    className={cn(
                        "tw-h-[1px]",
                        "tw-relative",
                    )}
                    style={{
                        backgroundImage: "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23A6A6A6' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='8' stroke-linecap='square'/%3e%3c/svg%3e\")"
                    }}
                >
                    <div
                        className={cn("tw-w-8 tw-h-8 tw-bg-grey-50 tw-rounded-full", "tw-absolute -tw-top-4 -tw-left-12 xsm:-tw-left-14")} />
                    <div
                        className={cn("tw-w-8 tw-h-8 tw-bg-grey-50 tw-rounded-full", "tw-absolute -tw-top-4 -tw-right-12 xsm:-tw-right-14")} />
                </div>
                <div className={cn("tw-flex tw-flex-col tw-gap-1", "tw-text-body-2 tw-px-2")}>
                    {props.withdrawInfoSlot}
                </div>
            </div>
            <Button onClick={handleGoToTransactionHistory} variant={NEW_BUTTON_VARIANTS.greyLight}
                    className={"tw-w-full"}>
                <FormattedMessage id="label_transaction_history" />
            </Button>
            <div className={"tw-flex tw-justify-center"}>
                <div className={"tw-w-[5.6rem] xsm:tw-w-[6.8rem]"}>
                    <DailyFantasyWatermark size={"100%"} className={"tw-w-full"} />
                </div>
            </div>
        </div>
    );
};

export default Step4ReceiptLayout;
