import { useDispatch } from "react-redux";
import { useCallback } from "react";
import { login, LoginData } from "apis/login/LoginApi";
import { storageHelper } from "helpers/localStorage/LocalStorageHelper";
import { LOCAL_STORAGE_KEYS } from "helpers/localStorage/types";
import { updateSessionId } from "store/actions/Actions";
import { useSelector } from "store";
import useFantasyCommand from "hooks/useFantasyCommand";

export function useLoginAndUpdateSession() {
    const dispatch = useDispatch();

    const { selectedSiteId } = useSelector((store) => store.UserState);
    const loginData: LoginData | undefined = selectedSiteId ? { siteId: selectedSiteId } : undefined;

    const handleAwaitLogin = useCallback(async () => {
        const loginResult = await login(loginData);

        storageHelper(LOCAL_STORAGE_KEYS.SESSION_ID).put(loginResult.sessionId);

        dispatch(updateSessionId(loginResult.sessionId));
    }, [dispatch, loginData]);

    return useFantasyCommand(handleAwaitLogin);
}
