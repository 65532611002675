import React, { useRef } from "react";
import { InputGroup } from "react-bootstrap";
import { ErrorMessage, FormButton, InputGroupWrapper, Label, TextField } from "designToken/textFields/Styles";
import { hasError } from "designToken/textFields/TextFields.helper";


const Input: React.FC<InputProps> = ({
                                         label = "",
                                         value,
                                         inputType = "text",
                                         onChange,
                                         placeholder = "",
                                         button = "",
                                         onButtonClick = () => {},
                                         errorMessage = "",
                                         disabled = false
                                     }) => {

    const inputRef = useRef<HTMLInputElement>(null);

    const focusToInput = () => {
        inputRef.current?.focus();
    };

    return <InputGroupWrapper className={hasError(errorMessage)}>
        <Label onClick={focusToInput}>{label}</Label>
        <InputGroup>
            <TextField ref={inputRef}
                       value={value}
                       type={inputType}
                       onChange={onChange}
                       placeholder={placeholder}
            />
            {
                button
                && <FormButton onClick={onButtonClick}>{button}</FormButton>
            }
        </InputGroup>
        {
            errorMessage
            && <ErrorMessage>{errorMessage}</ErrorMessage>
        }
    </InputGroupWrapper>;

};

interface InputProps {
    label: string,
    inputType?: string,
    placeholder?: string,
    value: string,
    onChange?: () => void,
    button?: string | React.ReactNode,
    onButtonClick?: void,
    errorMessage?: string,
    disabled?: boolean
}

export default Input;
