import { LoadingPage } from "components/loading/Loading";
import { useAddCoinFlow } from "domain/coin/AddCoinFlow";
import { cn } from "helpers/cn";
import _ from "lodash";
import React from "react";
import { Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { PrimarySolidButton } from "styles/component/ButtonsStyle";
import useFirstTimePurchase from "../../../../hooks/useFirstTimePurchase";
import { Promo } from "domain/coin/component/product/components/Promo";
import { Products } from "domain/coin/component/product/components/Products";
import { Demarcation } from "components/Demarcation/Demarcation";
import { CoinProductVo } from "data/vo/coin/CoinProductVo";

const Total = (props: {
    currentProduct: CoinProductVo | null
    onClick: () => void
}) => {
    return <div className={cn("tw-flex tw-justify-between tw-items-center tw-bg-grey-0 tw-px-6 tw-py-3 ",
        "tw-shadow-add-coin-button-wrapper",
        "tw-fixed tw-bottom-0 tw-w-full tw-max-w-app-max tw-z-1"
    )}>
        <div className="tw-w-[20rem] tw-ml-auto">
            <PrimarySolidButton
                disabled={_.isNull(props.currentProduct)}
                onClick={props.onClick}
            >
                <FormattedMessage id="button_confirm" />
            </PrimarySolidButton>
        </div>
    </div>;
};

const CoinProducts = () => {
    const { currentProduct, goToSelectPaymentMethodPage, products } = useAddCoinFlow();
    const firstTimePurchase = useFirstTimePurchase();

    if (!firstTimePurchase) {
        return <LoadingPage />;
    }

    return (
        <div className="tw-pb-[8rem]">
            <div className="tw-p-4">
                <Row className="tw-gap-3">
                    <Promo />
                    <div>
                        {products.hasOnSale && <>
                            <Demarcation
                                className="tw-text-caption-1 tw-font-bold tw-text-secondary-700"
                                lineClassName="tw-bg-primary-200"
                            >
                                <FormattedMessage id="buy_coin_special_label" />
                            </Demarcation>

                            <Products data={products.getOnSale()} />

                            <div className={"tw-h-12"} />

                            <Demarcation
                                className="tw-text-caption-1 tw-font-bold tw-text-grey-550"
                                lineClassName="tw-bg-grey-150"
                            >
                                <FormattedMessage id="buy_coin_standard_label" />
                            </Demarcation>
                        </>}

                        <Products data={products.getStandard()} />
                    </div>
                </Row>
            </div>

            <Total currentProduct={currentProduct} onClick={goToSelectPaymentMethodPage} />
        </div>
    );
};

export default CoinProducts;
