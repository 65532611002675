import { FantasyTextField, FantasyTextFieldProps } from "components/reactHookFormField/FantasyTextField";
import React, { useCallback, useMemo, useState } from "react";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { Icon } from "helpers/IconHelper";
import { FieldPath, FieldValues, UseControllerProps } from "react-hook-form";

export const FantasyPasswordField = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(props: FantasyTextFieldProps & UseControllerProps<TFieldValues, TName>) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
    const type = useMemo(() => isPasswordVisible ? "text" : "password", [isPasswordVisible]);
    const SuffixIcon = useMemo(() => isPasswordVisible ? MdVisibility : MdVisibilityOff, [isPasswordVisible]);

    const handleToggle = useCallback(() => {
        setIsPasswordVisible(prevState => !prevState);
    }, []);

    return <FantasyTextField
        {...props}
        inputProps={{
            ...props.inputProps,
            type
        }}
        iconLeft={<Icon.KeyHoleLock size="1.8rem"
                                    className="tw-text-grey-250" />}
        iconRight={<SuffixIcon size="1.8rem"
                               onClick={handleToggle}
                               className="tw-text-grey-250 clickable" />}
    />;
};
