import { useSelector } from "store";
import { useCallback } from "react";
import { updateSettings } from "store/actions/Actions";
import { useDispatch } from "react-redux";
import { SettingDto } from "apis/setting/SettingApi";

export const useSetting = () => {
    const dispatch = useDispatch();
    const settingState = useSelector(state => state.SettingsState);

    const updateSettingState = useCallback((setting: SettingDto) => {
        dispatch(updateSettings({ setting: setting, status: true }));
    }, [dispatch]);

    return {
        settingState,
        updateSettingState
    };
};
