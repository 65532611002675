import { fetchMyApOverview } from "apis/ap/ActivityPointApi";
import { Callback } from "Common";
import JoinContestPanel from "domain/slate/components/contest/JoinContestPanel";
import { NineCatContestVo } from "domain/slate/data/ComponentTypes";
import {
    checkJoinable,
    checkMinApLevelSufficient,
    checkSpotsNotFilled,
    checkTeamSizeValid,
    checkTeamsNotJoined,
    getAlreadyJoinedTeams,
    getApLevelRequiredDepositCent
} from "domain/slate/helpers/helpers";
import { useJoinNineCatContestDialog } from "domain/slate/hooks/useJoinNineCatContestDialog";
import { useNineCatContext } from "domain/slate/providers/NineCatProvider";
import { ERROR_CODE } from "helpers/ErrorHelper";
import useFantasyQuery from "hooks/useFantasyQuery";
import { QUERY_KEY } from "hooks/useFantasyQuery/type";
import React from "react";
import { useSelector } from "store";
import { MyApOverview } from "types/activityPoint/MyApOverview";

export interface JoinContestPanelContainerProps {
    selectedTeamIds: number[];
    notJoinableContestId: NineCatContestVo["id"] | undefined;
    onSetNotJoinableContestTemporarily: Callback<[NineCatContestVo["id"]]>;
    onSetContest: Callback<[NineCatContestVo]>;
}

interface JoinContestError {
    message: string;
}

const JoinContestPanelContainer: React.FC<JoinContestPanelContainerProps> = (props) => {
    const { selectedTeamIds } = props;
    const { publicContests: contests } = useNineCatContext();
    const { WalletState: { coinCent, }, } = useSelector(state => state);

    const myApOverview = useFantasyQuery<MyApOverview>([QUERY_KEY.MY_AP_OVERVIEW], fetchMyApOverview, {
        options: { placeholderData: () => ({}) as MyApOverview }
    });

    const {
        handleUpgradeApLevel,
        handleOpenSpotsFilledDialog,
        handleOpenApLevelInsufficientDialog,
        handleOpenTeamsAlreadyJoinedDialog,
        handleOpenPrizeDistribution,
    } = useJoinNineCatContestDialog();

    function handleCheckBeforeJoinContest(chosenContest: NineCatContestVo) {
        checkSpotsNotFilled(chosenContest);

        checkMinApLevelSufficient(myApOverview.data!, chosenContest);

        checkTeamsNotJoined(chosenContest, selectedTeamIds);

        checkTeamSizeValid(selectedTeamIds.length, chosenContest.myJoinedTeams.length, chosenContest.entryLimit);
    }

    function handleShowJoinError(e: { message: string }, chosenContest: NineCatContestVo) {
        if (e.message === ERROR_CODE.CONTEST_SPOT_FULL) {
            handleOpenSpotsFilledDialog();
            return;
        }
        if (e.message === ERROR_CODE.CONTEST_JOIN_TEAM_SIZE_INVALID) {
            props.onSetNotJoinableContestTemporarily(chosenContest.id);
            return;
        }
        if (e.message === ERROR_CODE.DUPLICATE_TEAMS) {
            const alreadyJoinedTeams = getAlreadyJoinedTeams(chosenContest, selectedTeamIds);
            handleOpenTeamsAlreadyJoinedDialog(alreadyJoinedTeams.map(team => team.name));
            return;
        }
        if (e.message === ERROR_CODE.AP_LEVEL_INSUFFICIENT) {
            const balanceDepositCent = getApLevelRequiredDepositCent(chosenContest, coinCent, selectedTeamIds);
            handleOpenApLevelInsufficientDialog(myApOverview.data?.currentLevel!, chosenContest.minApLevel, () => handleUpgradeApLevel(balanceDepositCent));
            return;
        }
    }

    function handleJoinContest(chosenContest: NineCatContestVo) {
        if (!myApOverview.data) return;

        try {
            handleCheckBeforeJoinContest(chosenContest);

            props.onSetContest(chosenContest);
        } catch (e: unknown) {
            handleShowJoinError(e as JoinContestError, chosenContest);
        }

    }

    return (
        <JoinContestPanel
            {...props}
            contests={contests.map(contest => ({
                ...contest,
                isJoinable: checkJoinable(props.notJoinableContestId, contest.id),
            }))}
            onJoinContest={handleJoinContest}
            onOpenPrizeDistribution={handleOpenPrizeDistribution}
        />
    );
};

export default JoinContestPanelContainer;
