import { omit } from "lodash";
import React, { useState } from "react";
import { FieldPath, FieldValues, useController, UseControllerProps, useFormContext, useWatch } from "react-hook-form";
import { Textarea } from "components/ui/textarea";
import { FormErrorMessage } from "domain/kyc/pages/kycVerificationForm/components/FormErrorMessage";
import { cn } from "helpers/cn";
import { FormFieldProvider } from "components/ui/form";

export enum TEXT_AREA_VARIANT {
    WHITE = "white",
    GRAY = "gray",
}

export interface ShadCNTextAreaProps {
    autoResizeRow?: boolean;
    inputProps?: Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, "name">;
    showErrorMessage?: boolean;
    showErrorIcon?: boolean;
    maxLength?: number;
    variant?: TEXT_AREA_VARIANT;
    onFocus?: () => void;
}

const variantClassNameMap = {
    [TEXT_AREA_VARIANT.WHITE]: {
        borderColor: "tw-border-transparent",
        backgroundColor: "tw-bg-background",
    },
    [TEXT_AREA_VARIANT.GRAY]: {
        borderColor: "tw-border-transparent focus:tw-border-info-700",
        backgroundColor: "tw-bg-grey-50",
    },
};

export const FantasyTextAreaField = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
      name,
      control,
      autoResizeRow = true,
      inputProps,
      showErrorMessage = true,
      showErrorIcon = true,
      maxLength,
      variant = TEXT_AREA_VARIANT.WHITE,
      onFocus,
  }: ShadCNTextAreaProps & UseControllerProps<TFieldValues, TName>) => {
    const { register } = useFormContext<TFieldValues>();
    const { fieldState } = useController<TFieldValues, TName>({ name });
    const fieldValue = useWatch({ name });

    const field = register(name);

    const [isFocused, setIsFocused] = useState(false);
    const { borderColor, backgroundColor } = variantClassNameMap[variant];

    return <FormFieldProvider name={name}>
        <div className="tw-relative">
            <Textarea
                {...field}
                value={fieldValue}
                onFocus={() => {
                    setIsFocused(true);
                    if (onFocus) onFocus();
                }}
                onBlur={(event) => {
                    setIsFocused(false);
                    field.onBlur(event);
                }}
                className={cn(
                    "tw-w-full tw-rounded-[0.8rem] tw-text-body-1",
                    "tw-px-[1.2rem] tw-py-[0.8rem]",
                    "focus-visible:tw-outline-0 focus-visible:tw-ring-offset-0 focus-visible:tw-ring-transparent focus-visible:tw-ring-offset-transparent",
                    "tw-border tw-min-h-[4.8rem]",
                    backgroundColor,
                    fieldState.error ? "tw-border-error-700" : borderColor,
                    autoResizeRow ? "tw-resize-none" : "",
                    inputProps?.className ?? "",
                )}
                {...omit(inputProps, "className")}
                rows={autoResizeRow ? fieldValue.split("\n").length : undefined}
            />
        </div>
        {showErrorMessage &&
            <FormErrorMessage showIcon={showErrorIcon} />
        }
        {
            maxLength && isFocused && !fieldState.error &&
            <div className="tw-text-caption-1 tw-text-start tw-text-info-700 tw-pt-1">
                {fieldValue.length}/{maxLength}
            </div>}

    </FormFieldProvider>;
};
