import {
    Contest,
    ContestBodyRow,
    ContestCardInfo,
    ContestFooterRow,
    PracticeContestName
} from "domain/match/pages/upcoming/contest/card/ContestCardStyle";
import { isMatchJoinable } from "helpers/match/MatchHelper";
import useCurrency from "hooks/useCurrency/useCurrency";
import React from "react";
import { useIntl } from "react-intl";
import CopyInvitationCodeButtonWithModal from "components/copyCode/CopyInvitationCodeButtonWithModal";
import { CreateContestCardTags, TagVO } from "helpers/ContestHelper";
import useUserIdentity from "hooks/useUserIdentity";
import JoinContestButton from "domain/match/pages/upcoming/contest/card/button/JoinContestButton";
import { ContestCardFactoryProps } from "domain/match/pages/upcoming/contest/card/ContestCardFactory";
import MyJoinedTeamList from "domain/match/pages/upcoming/contest/card/joined/MyJoinedTeamList";
import ContestInfoTagList from "domain/match/pages/upcoming/contest/card/tag/ContestInfoTagList";
import { useSetting } from "hooks/useSetting";

interface ContestCardLayoutProps extends ContestCardFactoryProps {
    tags: TagVO[];
}

const ContestCardLayout: React.FC<ContestCardLayoutProps> = ({
                                                                 matchStatus,
                                                                 contest,
                                                                 tags,
                                                                 onContestClick,
                                                                 openContestPanel
                                                             }) => {
    const { name, myJoinedTeamsNames } = contest;

    return (
        <Contest
            className="clickable animate__animated animate__fadeIn"
            onClick={(e) => onContestClick(e, contest)}
        >
            <ContestBodyRow>
                <ContestCardInfo>
                    <PracticeContestName>{name}</PracticeContestName>
                </ContestCardInfo>
                <JoinContestButton contest={contest}
                                   matchStatus={matchStatus}
                                   openContestPanel={openContestPanel} />
            </ContestBodyRow>
            <ContestFooterRow>
                <ContestInfoTagList tags={tags}>
                    {
                        isMatchJoinable(matchStatus) &&
                        <CopyInvitationCodeButtonWithModal contestCode={contest.code} contestId={contest.id} />
                    }
                </ContestInfoTagList>
            </ContestFooterRow>
            <MyJoinedTeamList myJoinedTeamsNames={myJoinedTeamsNames} />
        </Contest>
    );
};


const PracticeContestCard: React.FC<ContestCardFactoryProps> = ({
                                                                    matchStatus,
                                                                    contest,
                                                                    onContestClick,
                                                                    openContestPanel
                                                                }) => {
    const { settingState: { contest: { spotsToMaxEntries, defaultMaxEntries } } } = useSetting();
    const { isB2B } = useUserIdentity();
    const intl = useIntl();
    const {
        currentCurrencyObject: { toPeriodNumber },
    } = useCurrency();
    const getContestCardTags = CreateContestCardTags(!isB2B, intl.formatMessage, toPeriodNumber);
    const tags = getContestCardTags(contest, spotsToMaxEntries, defaultMaxEntries, matchStatus);

    return <ContestCardLayout matchStatus={matchStatus}
                              contest={contest}
                              tags={tags}
                              onContestClick={onContestClick}
                              openContestPanel={openContestPanel} />;
};

export default PracticeContestCard;
