import FantasyDialog from "components/dialog/FantasyDialog";
import CommonImageSection from "components/dialog/imageSection/CommonImageSection";
import { LoadingPage } from "components/loading/Loading";
import AppQrCode from "components/qrcode/AppQrCode";
import { auth } from "domain/auth/AuthConfig";
import Entry from "domain/auth/entry/Entry";
import { shouldSendCheckEmail } from "domain/auth/helper/AuthHelper";
import Language from "domain/auth/language/Language";
import Login from "domain/auth/login/Login";
import ResetPassword from "domain/auth/resetPassword/ResetPassword";
import SignUp from "domain/auth/signUp/SignUp";
import { useDetectTabFocus } from "domain/auth/useDetectTabFocus";
import { sendEmailVerification, signOut } from "firebase/auth";
import { createErrorObject, FIREBASE_ERROR_CODE } from "helpers/ErrorHelper";
import { authSignUpEmailImg, loginBackground } from "helpers/IconHelper";
import { storageHelper } from "helpers/localStorage/LocalStorageHelper";
import { LOCAL_STORAGE_KEYS } from "helpers/localStorage/types";
import useFantasyHistory from "hooks/useFantasyHistory";
import { useLoginAndUpdateSession } from "hooks/useLoginAndUpdateSession";
import { useLocaleProvider } from "LocaleProvider";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useIntl } from "react-intl";
import { Redirect, Route, Switch } from "react-router-dom";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";
import { useSelector } from "store";
import { AuthProvider, useAuth } from "./AuthProvider";

function createErrorObjectByFirebase(e: any) {
    return {
        message: e.message,
        status: 400
    };
}

function useCheckComponentUnmount() {
    const isComponentUnmount = useRef(false);

    useEffect(() => {
        return () => {
            isComponentUnmount.current = true;
        };
    }, []);

    return { isComponentUnmount };
}

const AuthPage = () => {
    const { sessionId } = useSelector((store) => store.UserState);

    const history = useFantasyHistory();
    const { isComponentUnmount } = useCheckComponentUnmount();
    const focus = useDetectTabFocus();
    const intl = useIntl();
    const { redirectUrl } = useAuth();

    const { isLanguageInitialized } = useLocaleProvider();

    const [user, loading] = useAuthState(auth);
    const [isSignUpSuccessDialogOpen, setIsSignUpSuccessDialogOpen] = useState<boolean>(false);

    const handleRedirectToHome = () => {
        history.push(ROUTER_PATH_CONFIG.home);
    };

    const authSignUpDialogProps = (email: string, onConfirm: () => void) => ({
        imageSection: <CommonImageSection src={authSignUpEmailImg} />,
        title: intl.formatMessage({ id: "check_email_page_title" }),
        text: <>
            {intl.formatMessage({ id: "check_email_page_label_check_email_1" }, {
                email: <span className="text-secondary-1">{email}</span>
            })}
            &nbsp;
            {intl.formatMessage({ id: "check_email_page_label_check_email_2" }, {
                email: <span className="text-secondary-1">{email}</span>
            })}
            &nbsp;
            {intl.formatMessage({ id: "check_email_page_label_check_email_3" })}
        </>
        ,
        confirmButtonText: intl.formatMessage({ id: "send_reset_password_dialog_back_to_login" }),
        onConfirm,
        cancelButton: false
    });

    const handleCheckEmail = useCallback(async (user) => {
        try {
            await sendEmailVerification(user, { url: redirectUrl });
            setIsSignUpSuccessDialogOpen(true);
        } catch (e: any) {
            if (e.code === FIREBASE_ERROR_CODE.TOO_MANY_REQUESTS) {
                setIsSignUpSuccessDialogOpen(true);
                return;
            }

            history.push(ROUTER_PATH_CONFIG.error, {
                error: e.code
                    ? createErrorObjectByFirebase(e)
                    : createErrorObject(e)
            });
        }
    }, [history, redirectUrl]);

    const handleDialogConfirm = async () => {
        await signOut(auth);
        storageHelper(LOCAL_STORAGE_KEYS.SESSION_ID).remove();
        history.replace(ROUTER_PATH_CONFIG.auth.login);
    };

    const [loginLoading, setLoginLoading] = useState(false);
    const loginAndUpdateSession = useLoginAndUpdateSession();

    useEffect(() => {
        if (isComponentUnmount.current) return;
        if (!user) return;

        if (!focus) return;

        if (shouldSendCheckEmail(user)) {
            handleCheckEmail(user);
            return;
        }

        if (!sessionId) {
            setLoginLoading(true);
            loginAndUpdateSession.mutate();
            return;
        }

        setLoginLoading(false);
        handleRedirectToHome();
    }, [user, focus, sessionId]);

    if (loginLoading) return <LoadingPage />;

    if (loading) return null;

    return (
        <div className="tw-flex tw-h-full tw-bg-cover tw-bg-no-repeat tw-bg-right-bottom"
             style={{
                 backgroundImage: `url(${loginBackground})`
             }}>
            <div
                className="tw-flex tw-flex-col bg-light-primary tw-w-full tw-max-w-app-max tw-h-full tw-overflow-y-auto">
                <Switch>
                    <Route exact path={ROUTER_PATH_CONFIG.auth.index} render={() =>
                        isLanguageInitialized
                            ? <Entry />
                            : <Redirect to={ROUTER_PATH_CONFIG.auth.language} />} />
                    <Route exact path={ROUTER_PATH_CONFIG.auth.language}>
                        <Language />
                    </Route>
                    <Route exact path={ROUTER_PATH_CONFIG.auth.login}>
                        <Login />
                    </Route>
                    <Route exact path={ROUTER_PATH_CONFIG.auth.resetPassword}>
                        <ResetPassword />
                    </Route>
                    <Route exact path={ROUTER_PATH_CONFIG.auth.signUp}>
                        <SignUp />
                    </Route>
                </Switch>
                {
                    user && <FantasyDialog
                        show={isSignUpSuccessDialogOpen} {...authSignUpDialogProps(user.email!, handleDialogConfirm)} />
                }
            </div>
            <AppQrCode />
        </div>
    );
};

const RootPage = () => (
    <AuthProvider>
        <AuthPage />
    </AuthProvider>
);

export default RootPage;
