import { ApiExecuteContext } from "apis/ApiContext";
import { fetchRewardList } from "apis/reward/RewardApi";
import clsx from "clsx";
import NegativeDepositAlert from "components/alert/NegativeDepositAlert";
import Navbar from "components/container/navbar/Navbar";
import { useLoadingToggle } from "components/loading/Loading";
import Button from "designToken/button/Button";
import { BUTTON_PATTERN, BUTTON_SIZE, BUTTON_VARIANTS } from "designToken/button/types";
import PurchaseModal from "domain/reward/component/PurchaseModal";
import RewardCoins from "domain/reward/component/RewardCoins";
import RewardQuantity from "domain/reward/component/RewardQuantity";
import { ShowNewOrderDotContext } from "domain/reward/RewardContainer";
import {
    filterByCountry,
    getLabelI18nText,
    isCoinReward,
    isOutOfStock,
    PRIORITY_REWARD_COUNTRIES,
    REWARD_COUNTRY_COINS
} from "domain/reward/RewardHelper";
import {
    ActiveRewardTab,
    InactiveRewardTab,
    NewOrderSignal,
    NoRewardImage,
    NoRewardText,
    NoRewardWrapper,
    OrderIcon,
    RewardDescription,
    RewardIconWrapper,
    RewardImageWrapper,
    RewardItemCol,
    RewardItemRow,
    RewardListItem,
    RewardListItemBody,
    RewardListItemFooter,
    RewardListWrapper,
    RewardTabWrapper,
    Text,
    Title,
    WinningContent,
    WinningIcon,
    WinningTitle,
    WinningWrapper
} from "domain/reward/RewardListStyle";
import useUpdateWallet from "domain/wallet/useUpdateWallet";
import { analyticsEvent, logCustomEvent } from "ga";
import FantasyIcon from "helpers/FantasyIcon";
import { defaultRewardImage, getCountryIcon, Icon, noRewardImage, winningRounded } from "helpers/IconHelper";
import { divideByOneHundred, isNegative } from "helpers/number/NumberHelper";
import useCurrency from "hooks/useCurrency/useCurrency";
import { useDragToScrollX } from "hooks/useDragToScrollX";
import useFantasyHistory from "hooks/useFantasyHistory";
import { APP_ROUTE, useMobileApp } from "hooks/useMobileApp";
import useScrollDetector from "hooks/useScrollDetector";
import { orderBy } from "lodash";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { MdChevronRight, MdOutlineInfo, MdOutlineReceiptLong, MdOutlineShoppingCart } from "react-icons/md";
import { FormattedMessage } from "react-intl";
import { useSelector } from "store";
import { useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";
import { SYSTEM_COLORS } from "styles/Constants";
import { REWARD_TYPE, RewardVO } from "types/reward/Reward";
import AmountDisplay from "containers/amount/AmountDisplay";
import { getCurrencyIconSuitByEntryFeeType } from "containers/amount/CurrencyIconSuitHelper";
import { ENTRY_FEE_TYPE_FROM_API } from "types/contest/Contest";
import { WALLET_TYPE } from "data/dto/wallet/walletHistoryDto";

const winningDisplayLimit = 99_999;

const RewardTabs = ({ tabs, activeTab, onTabClick }) => {
    const rewardTabsRef = useRef<HTMLDivElement | null>(null);
    useDragToScrollX(rewardTabsRef);

    return <RewardTabWrapper ref={rewardTabsRef}>
        {
            tabs.map((tab) => {
                const isActiveTab = activeTab === tab;
                const TabItem = isActiveTab ? ActiveRewardTab : InactiveRewardTab;
                const iconClass = clsx({ "tw-filter tw-grayscale tw-opacity-50": !isActiveTab });

                return (
                    <TabItem
                        key={tab}
                        data-testid={`reward-tab-${tab}`}
                        onClick={() => onTabClick(tab)}
                    >
                        <RewardIconWrapper>
                            {
                                isCoinReward(tab)
                                    ? <>
                                        <Icon.StoreCoinExchange
                                            size="2.6rem"
                                            draggable={false}
                                            className={iconClass}
                                        />
                                        <FormattedMessage id={"label_coins"} />
                                    </>
                                    : <>
                                        <FantasyIcon
                                            src={getCountryIcon(tab)}
                                            size="2.6rem"
                                            draggable={false}
                                            className={iconClass}
                                        />
                                        {getLabelI18nText(tab)}
                                    </>
                            }
                        </RewardIconWrapper>
                    </TabItem>
                );
            })
        }
    </RewardTabWrapper>;
};

const Winning = () => {
    const history = useFantasyHistory();
    const { currentCurrencyObject: { toWalletNumber } } = useCurrency();
    const winning = useSelector(store => store.WalletState.winning);
    const { isFromMobileApp, goToApp } = useMobileApp();

    const toWinningHistory = () => {
        if (isFromMobileApp()) {
            return goToApp(APP_ROUTE.walletHistoryWinning);
        }

        return history.push(ROUTER_PATH_CONFIG.wallet, {
            category: WALLET_TYPE.WINNING
        });
    };

    const toHome = () => {
        if (isFromMobileApp()) {
            return goToApp(APP_ROUTE.home);
        }

        return history.push(ROUTER_PATH_CONFIG.home);
    };

    return (
        <WinningWrapper>
            <WinningTitle>
                <WinningIcon />
                <div className="fz-sf-bold-body-2">
                    <FormattedMessage id={"label_winning"} />
                </div>
                <OverlayTrigger placement="bottom"
                                overlay={
                                    <Tooltip><FormattedMessage
                                        id="winning_tooltip_dialog_label"></FormattedMessage>
                                    </Tooltip>
                                }>
                    <div><MdOutlineInfo className="cursor-default" size="1.6rem" /></div>
                </OverlayTrigger>
            </WinningTitle>
            <WinningContent>
                <div className="clickable fz-sf-bold-title-1 tw-flex tw-items-center tw-gap-0.5"
                     onClick={toWinningHistory}>
                    <AmountDisplay
                        currencyIconSuit={getCurrencyIconSuitByEntryFeeType(ENTRY_FEE_TYPE_FROM_API.DEPOSIT)}>
                        {toWalletNumber(winning)}
                    </AmountDisplay>
                    <MdChevronRight />
                </div>
                <Button variant={BUTTON_VARIANTS.outlineDark}
                        size={BUTTON_SIZE.md}
                        pattern={BUTTON_PATTERN.pill}
                        onClick={toHome}>
                    <FormattedMessage id="reward_list_page_button_win_more" />
                </Button>
            </WinningContent>
        </WinningWrapper>
    );
};

const RewardListNavbar = ({ rewardList, goToRewardOrders, activeCountryTab, onTabClick }) => {
    const { WalletState: { winning } } = useSelector(store => store);
    const { currentCurrencyObject: { toFormattedNumber, toWalletNumber } } = useCurrency();

    const displayWinning = winning > winningDisplayLimit ? toFormattedNumber(winningDisplayLimit) + "+" : toWalletNumber(winning);

    const showNewOrderDot = useContext(ShowNewOrderDotContext);

    const smallNavbarRef = useRef(null);

    const shouldShowSmallNavbar = useScrollDetector(60);

    const countries = [...new Set([
        ...PRIORITY_REWARD_COUNTRIES,
        ...rewardList.map(reward => reward.country)])
    ];


    return <>
        <Navbar className="position-relative base-shadow-light-60"
                navBackIconColor={SYSTEM_COLORS.primary80}
                navbarCenter={
                    <div className={"position-relative h-100"}>
                        <Title><FormattedMessage id="reward_list_page_title" /></Title>
                        <div className={"position-absolute end-0 top-50 translate-middle-y"}>
                            <OrderIcon onClick={goToRewardOrders}>
                                <MdOutlineReceiptLong size="2.4rem" />
                                <NewOrderSignal $show={showNewOrderDot} />
                            </OrderIcon>
                        </div>
                    </div>
                }
                navbarBottom={
                    <RewardTabs tabs={countries}
                                activeTab={activeCountryTab}
                                onTabClick={onTabClick} />
                }
                shouldCalculatePaddingTop={false}
        />
        <CSSTransition in={shouldShowSmallNavbar}
                       nodeRef={smallNavbarRef}
                       classNames="my-matches-navbar"
                       timeout={400}
                       unmountOnExit
        >
            <Navbar ref={smallNavbarRef}
                    navBackIconColor={SYSTEM_COLORS.primary80}
                    navbarCenter={
                        <div className={"position-relative h-100"}>
                            <Title><FormattedMessage id="reward_list_page_title" /></Title>
                            <div
                                className="d-flex align-items-center position-absolute end-0 top-50 translate-middle-y">
                                <div className="d-flex align-items-center me-3">
                                    <div className="fz-sf-bold-footnote me-2">{displayWinning}</div>
                                    <div>
                                        <img src={winningRounded} alt="winning-rounded" />
                                    </div>
                                </div>
                                <OrderIcon onClick={goToRewardOrders}>
                                    <MdOutlineReceiptLong size="2.4rem" />
                                    <NewOrderSignal $show={showNewOrderDot} />
                                </OrderIcon>
                            </div>
                        </div>
                    }
                    className="base-shadow-light-60"
                    shouldCalculatePaddingTop={false}
            />
        </CSSTransition>
    </>;
};


const RewardList = () => {
    const history = useFantasyHistory();
    const location = useLocation();
    const apiExecutor = useContext(ApiExecuteContext);
    const updateWallet = useUpdateWallet();
    const toggleLoading = useLoadingToggle();

    const { WalletState: { coinCent } } = useSelector(store => store);

    const [rewardList, setRewardList] = useState<RewardVO[]>([]);
    const [rewardItem, setRewardItem] = useState<RewardVO | null>(null);
    const [activeCountryTab, setActiveCountryTab] = useState(location?.state?.currentCountry || REWARD_COUNTRY_COINS);


    const onRewardClick = (reward) => {
        if (reward.type === REWARD_TYPE.REDEPOSIT) {
            logCustomEvent(analyticsEvent.webClickAddDeposit01);
        }
        setRewardItem(reward);
    };

    const handleRewardList = useCallback(() => {
        apiExecutor(
            fetchRewardList(),
            {
                onSuccess: (res) => setRewardList(res.filter(reward => !isOutOfStock(reward.quantity))),
                onFinally: () => toggleLoading(false)
            }
        );
    }, [apiExecutor, setRewardList, toggleLoading]);

    const onPurchasedSuccess = () => {
        handleRewardList();
        setRewardItem(null);
    };

    const onSoldOut = () => {
        handleRewardList();
        setRewardItem(null);
    };

    const goToRewardOrders = useCallback(() => {
        history.push(ROUTER_PATH_CONFIG.reward.orders);
    }, [history]);

    const onTabClick = useCallback((clickedCountry) => {
        setActiveCountryTab(clickedCountry);
        history.replace({
            pathname: location.pathname,
            search: location.search,
            state: {
                currentCountry: clickedCountry
            }
        });
    }, [setActiveCountryTab, history, location]);

    useEffect(() => {
        (async () => {
            toggleLoading(true);
            await updateWallet();
            handleRewardList();
        })();
    }, [toggleLoading, handleRewardList, updateWallet]);


    const renderedList = orderBy(filterByCountry(rewardList, activeCountryTab), "weight", "desc");

    return (
        <>
            <RewardListNavbar rewardList={rewardList}
                              goToRewardOrders={goToRewardOrders}
                              activeCountryTab={activeCountryTab}
                              onTabClick={onTabClick} />
            {
                isNegative(coinCent) && <NegativeDepositAlert />
            }
            <RewardListWrapper>
                <Winning />
                {
                    renderedList.length > 0
                        ? <>
                            <Text><FormattedMessage id="reward_list_page_label_remind_country" /></Text>
                            <RewardItemRow>
                                {
                                    renderedList.map(reward => (
                                            <RewardItemCol key={reward.id}>
                                                <RewardListItem data-testid={`reward-${reward.id}`}
                                                                to={`${ROUTER_PATH_CONFIG.reward.index}/${reward.id}`}
                                                                pointerEvents={isNegative(coinCent) ? "none" : "auto"}>
                                                    <RewardListItemBody>
                                                        <RewardImageWrapper data-testid={`reward-${reward.id}-preview-img`}>
                                                            <img src={reward?.previewUrl || defaultRewardImage}
                                                                 alt="reward" />
                                                        </RewardImageWrapper>
                                                        <RewardQuantity quantity={reward.quantity}
                                                                        iconSize="1.6rem"
                                                                        fontStyle="fz-sf-regular-caption-2"
                                                        />
                                                        <RewardCoins price={divideByOneHundred(reward.priceCent)} />
                                                        <RewardDescription>{reward.title}</RewardDescription>
                                                    </RewardListItemBody>
                                                    <RewardListItemFooter onClick={(e) => {
                                                        e.preventDefault();
                                                        onRewardClick(reward);
                                                    }}>
                                                        <FormattedMessage id="reward_list_page_button_buy_now" />
                                                        <MdOutlineShoppingCart size="1.5rem" />
                                                    </RewardListItemFooter>
                                                </RewardListItem>
                                            </RewardItemCol>
                                        )
                                    )
                                }
                            </RewardItemRow>
                        </>
                        : <NoRewardWrapper>
                            <NoRewardImage src={noRewardImage} />
                            <NoRewardText><FormattedMessage id="reward_list_page_label_empty_reward" /></NoRewardText>
                        </NoRewardWrapper>
                }
            </RewardListWrapper>
            {
                rewardItem &&
                <PurchaseModal rewardItem={rewardItem}
                               onSuccess={onPurchasedSuccess}
                               onSoldOut={onSoldOut}
                               closeModal={() => setRewardItem(null)} />
            }
        </>
    );
};

export default RewardList;
