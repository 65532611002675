import React from "react";
import { ScrollingPopupFooter } from "components/popup/FantasyScrollingPopup";
import Button from "designToken/button/Button";
import { BUTTON_PATTERN, BUTTON_SIZE, NEW_BUTTON_VARIANTS } from "designToken/button/types";
import { FormattedMessage } from "react-intl";

interface FooterProps {
    onSubmit: () => void;
}

export const Footer: React.FC<FooterProps> = ({ onSubmit }) => {
    return <ScrollingPopupFooter>
        <Button
            variant={NEW_BUTTON_VARIANTS.tertiaryLight}
            pattern={BUTTON_PATTERN.rounded}
            size={BUTTON_SIZE.lg}
            className="w-100"
            onClick={onSubmit}
        >
            <FormattedMessage id="label_start_play" />
        </Button>
    </ScrollingPopupFooter>;
};
