import { NineCatTeamSelector } from "domain/slate/components/NineCatTeamSelector";
import { useNineCatContext } from "domain/slate/providers/NineCatProvider";
import React from "react";
import { FormattedMessage } from "react-intl";
import { cn } from "../../../helpers/cn";
import { Icon } from "../../../helpers/IconHelper";
import { NineCatContestVo } from "../data/ComponentTypes";

export interface MyTeamsSectionProps {
    selectedTeamIds: number[];
    notJoinableContestId: NineCatContestVo["id"] | undefined;
    onSelectTeam: (teamId: number) => void;
    onUnselectTeam: (teamId: number) => void;
}

const CreateNewTeamButton = () => {
    const { toCreateSlateTeamPage } = useNineCatContext();
    return <div
        className="tw-py-1.5 tw-w-fit tw-mx-auto tw-flex tw-justify-center tw-items-center tw-text-grey-1000 tw-text-caption-2 tw-gap-1 tw-cursor-pointer tw-bg-grey-50"
        onClick={toCreateSlateTeamPage}
    >
        <Icon.Plus size={"1.2rem"} />
        <div className={"tw-underline tw-decoration-solid"}>
            <FormattedMessage id="label_create_a_new_team_style_2" />
        </div>
    </div>;
};

const MyTeamsSection: React.FC<MyTeamsSectionProps> = (props) => {
    const { myTeams } = useNineCatContext();

    return (
        <div className={cn("tw-flex tw-flex-col tw-gap-2 tw-h-[83%]",
        )}>
            <div className="tw-text-subtitle-1 tw-font-[300] tw-text-grey-1000 tw-text-center">
                <FormattedMessage id={"label_selected_teams_for_contest"} />
            </div>
            <div className={
                cn(
                    "tw-p-4 tw-pb-2 ",
                    "tw-overflow-auto",
                    "tw-flex tw-flex-col tw-gap-2",
                    props.notJoinableContestId !== undefined ? "tw-bg-error-50" : ""
                )
            }>
                {
                    myTeams.map((team) => {
                        const checked = props.selectedTeamIds.includes(team.id);
                        return (
                            <div
                                key={team.id}
                                className={
                                    cn(
                                        "tw-border-transparent tw-border-[1px] tw-border-solid tw-rounded-[1.6rem]",
                                        props.notJoinableContestId !== undefined ? "tw-border-error-300" : "",)
                                }>
                                <NineCatTeamSelector key={team.id}
                                                     team={team}
                                                     onClick={checked ? props.onUnselectTeam : props.onSelectTeam}
                                                     checked={checked} /></div>
                        );
                    })
                }
            </div>
            <div className={"tw-pb-6"}>
                <CreateNewTeamButton />
            </div>
        </div>);
};

export default MyTeamsSection;
