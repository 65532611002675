import { useLoadingToggle } from "components/loading/Loading";
import { PaymongoWithdrawRepository } from "data/repository/withdraw/PaymongoWithdrawRepository";
import { Bank } from "data/vo/payment/banksVo";
import useUpdateWallet from "domain/wallet/useUpdateWallet";
import { useStep } from "domain/withdraw/hooks/useStep";
import { useWithdrawForm, WithdrawForm } from "domain/withdraw/hooks/useWithdrawForm";
import { useWithdrawSettings } from "domain/withdraw/hooks/useWithdrawSettings";
import { WITHDRAW_STEP } from "domain/withdraw/WithdrawCurrentStepVo";
import { removeAllSpaces } from "helpers/stringFormatHelpers";
import useFantasyCommand from "hooks/useFantasyCommand";
import useFantasyHistory from "hooks/useFantasyHistory";
import useFantasyQuery from "hooks/useFantasyQuery";
import { QUERY_KEY } from "hooks/useFantasyQuery/type";
import React, { createContext, useContext, useEffect, useMemo } from "react";

interface Step1Data {
    accountName: string
    accountNumber: string
    selectedBankCode: string
}

interface Step2Data {
    amount: number
}

interface WithdrawContextState {
    withdrawForm: WithdrawForm
    submitCommand: {
        isError: boolean
        isSuccess: boolean
    }
    step: WITHDRAW_STEP
    isFinalStep: boolean

    banks: Bank[]
    withdrawSettings: {
        transactionFee: number
        minimumWithdrawAmount: number
    },

    handleGoToFirstStep: () => void
    handleGoBack: () => void
    handeStep1Submit: (data: Step1Data) => void
    handeStep2Submit: (data: Step2Data) => void
    handleStep3Submit: () => void
    handleBackToFirstStep: () => void
}

const WithdrawContext = createContext<WithdrawContextState | null>(null);

interface WithdrawProviderProps {
    children: React.ReactNode;
}

export const WithdrawProvider: React.FC<WithdrawProviderProps> = ({ children }) => {
    const history = useFantasyHistory();

    const updateWallet = useUpdateWallet();
    const withdrawSettings = useWithdrawSettings();
    const toggleLoading = useLoadingToggle();

    const paymongoWithdrawRepository = new PaymongoWithdrawRepository();
    const paymongoReceivingInstitutions = useFantasyQuery([QUERY_KEY.PAYMONGO_RECEIVING_INSTITUTIONS], paymongoWithdrawRepository.getPayMongoReceivingInstitutions);
    const banks = paymongoReceivingInstitutions.data?.banks ?? [];

    const {
        step,
        isFirstStep,
        isFinalStep,
        handleStepNext,
        handleStepBack,
        handleGoToFirstStep,
    } = useStep(WITHDRAW_STEP.step1);

    const {
        withdrawForm,
        handleSetBank,
        handleSetAmount,
        handleResetForm,
    } = useWithdrawForm();

    const formData = useMemo(() => ({
        amount: withdrawForm.amount,
        account: {
            bankCode: withdrawForm.selectedBankCode,
            number: removeAllSpaces(withdrawForm.accountNumber),
            name: withdrawForm.accountName,
        }
    }), [withdrawForm]);

    const submitCommand = useFantasyCommand(() => paymongoWithdrawRepository.withdrawBegin(formData), { errorHandle: false });

    useEffect(() => {
        updateWallet();
    }, []);

    const handleGoBack = () => {
        if (isFirstStep || isFinalStep) {
            history.goBack();
            return;
        }
        handleStepBack();
    };

    const handeStep1Submit = (data: Step1Data) => {
        handleSetBank({
            accountName: data.accountName,
            accountNumber: data.accountNumber,
            selectedBankCode: data.selectedBankCode,
            selectedBankName: paymongoReceivingInstitutions.data?.getBankNameByCode(data.selectedBankCode) ?? ""
        });

        handleStepNext();
    };

    const handeStep2Submit = (data: Step2Data) => {
        handleSetAmount({
            amount: data.amount,
            transactionFee: withdrawSettings.transactionFee,
            receiveAmount: data.amount - withdrawSettings.transactionFee
        });

        handleStepNext();
    };

    const handleStep3Submit = async () => {
        toggleLoading(true);
        try {
            await submitCommand.mutateAsync();
        } catch (error) {
            console.error(error);
        } finally {
            toggleLoading(false);
            updateWallet();
            handleStepNext();
        }
    };

    const handleBackToFirstStep = () => {
        handleResetForm();
        handleGoToFirstStep();
    };

    const context = useMemo<WithdrawContextState>(() => ({
        withdrawForm,
        submitCommand,
        step,
        isFinalStep,
        banks,
        withdrawSettings,
        handleGoBack,
        handleGoToFirstStep,
        handeStep1Submit,
        handeStep2Submit,
        handleStep3Submit,
        handleBackToFirstStep,
    }), [
        withdrawForm,
        submitCommand,
        step,
        isFinalStep,
        banks,
        withdrawSettings,
        handleStepBack,
        handleGoToFirstStep,
        handeStep1Submit,
        handeStep2Submit,
        handleStep3Submit,
        handleBackToFirstStep,
    ]);

    return <WithdrawContext.Provider value={context}>
        {children}
    </WithdrawContext.Provider>;
};

export const useWithdrawContext = () => {
    const context = useContext(WithdrawContext);

    if (!context) {
        throw new Error("useWithdrawProvider must be used within a WithdrawContext");
    }

    return context;
};

export const withWithdrawProvider = <TProps extends React.PropsWithChildren>(Component: React.FC<TProps>) => {
    return (props: TProps) => {
        return (
            <WithdrawProvider>
                <Component {...props} />
            </WithdrawProvider>
        );
    };
};
