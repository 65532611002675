import { fetchMyApOverview } from "apis/ap/ActivityPointApi";
import { useLoadingToggle } from "components/loading/Loading";
import { TaskRepository } from "data/repository/task/TaskRepository";
import { TaskPrizeClaimedVo } from "data/vo/task/TaskPrizeClaimedVo";
import { TaskVo } from "data/vo/task/TaskVo";
import { UserTaskProgressVo } from "data/vo/task/UserTaskProgressVo";
import { TOAST_TYPE } from "designToken/alert/components/AlertHelper";
import { useVideoPlayer, VideoPlayer } from "domain/task/components/TaskVideoPlayer/hooks/useVideoPlayer";
import { storageHelper } from "helpers/localStorage/LocalStorageHelper";
import { LOCAL_STORAGE_KEYS } from "helpers/localStorage/types";
import useFantasyCommand from "hooks/useFantasyCommand";
import useFantasyQuery from "hooks/useFantasyQuery";
import { QUERY_KEY } from "hooks/useFantasyQuery/type";
import { useToast } from "hooks/useToast";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { MdMood } from "react-icons/md";
import { MyApOverview } from "types/activityPoint/MyApOverview";
import { OperateTaskRequest } from "../../data/dto/task/OperateTaskRequest";
import useFantasyHistory from "../../hooks/useFantasyHistory";
import { QUEST_TYPE } from "../../types/quest/QuestType";
import { useSetting } from "hooks/useSetting";


interface TaskContextProps {
    appDownloadDialogShow: boolean;
    isTutorialShow: boolean;
    videoPlayer: VideoPlayer;
    taskPrizeClaimed?: TaskPrizeClaimedVo;
    refreshTaskPrizeClaimed: () => void;
    tasks?: TaskVo[];
    userTaskProgress?: UserTaskProgressVo;
    refreshUserTaskProgress: () => void;
    apOverview?: MyApOverview;

    myLevelIndex: number;
    currentLevelIndex: number;
    currentCarouselIndex: number;
    handleTutorialClose: () => void;
    handleCarouselIndexChange: (index: number | ((pre: number) => number)) => void;
    handleAppDownloadDialogOpen: () => void;
    handleAppDownloadDialogClose: () => void;
    handleComplete: (taskType: QUEST_TYPE, code: string) => void;
    handleIncomplete: (taskType: QUEST_TYPE, code: string) => void;
}

const TaskContext = React.createContext<TaskContextProps | null>(null);

interface TaskProviderProps {
    children: React.ReactNode;
}

export const TaskProvider: React.FC<TaskProviderProps> = ({ children }) => {
    const history = useFantasyHistory();
    const toast = useToast();
    const toggleLoading = useLoadingToggle();
    const [appDownloadDialogShow, setAppDownloadDialogShow] = useState(false);
    const [currentCarouselIndex, setCurrentCarouselIndex] = useState(0);
    const taskTutorial = useMemo(() => storageHelper(LOCAL_STORAGE_KEYS.TASKS_TUTORIAL_DONE), []);
    const [isTutorialShow, setIsTutorialShow] = useState(!taskTutorial.get());
    const { settingState: { apLevels } } = useSetting();
    const taskRepository = new TaskRepository();

    const taskPrizeClaimed = useFantasyQuery([QUERY_KEY.TASK_PRIZE_CLAIMED], taskRepository.getPrizeClaimed);
    const tasks = useFantasyQuery([QUERY_KEY.TASKS], taskRepository.getTasks);
    const userTaskProgress = useFantasyQuery([QUERY_KEY.USER_TASK_PROGRESS], taskRepository.getProgress);
    const apOverview = useFantasyQuery([QUERY_KEY.MY_AP_OVERVIEW], fetchMyApOverview);

    const completeQuest = useFantasyCommand<OperateTaskRequest>(taskRepository.completeQuest);

    const myLevelIndex = useMemo(() => {
        return apLevels.findIndex(apLevel => apLevel.weight === apOverview.data?.currentLevelWeight);
    }, [apLevels, apOverview.data?.currentLevelWeight]);

    const currentLevelIndex = useMemo(() => {
        const initApLevel = history.getState("apLevel");
        if (initApLevel) {
            return apLevels.findIndex(apLevel => apLevel.level === initApLevel);
        }

        return myLevelIndex;
    }, [apLevels, apOverview.data?.currentLevelWeight]);

    const videoPlayer = useVideoPlayer();

    const handleCarouselIndexChange = useCallback((index: number | ((pre: number) => number)) => {
        setCurrentCarouselIndex(index);
    }, []);

    const handleTutorialClose = useCallback(() => {
        taskTutorial.put(true);
        setIsTutorialShow(false);
    }, [taskTutorial]);

    const handleAppDownloadDialogOpen = useCallback(() => {
        setAppDownloadDialogShow(true);
    }, []);

    const handleAppDownloadDialogClose = useCallback(() => {
        setAppDownloadDialogShow(false);
    }, []);

    const handleComplete = useCallback(async (taskType: QUEST_TYPE, code: string) => {
        if (!userTaskProgress.data?.getTaskProgress(taskType, code).isAvailable) {
            return null;
        }

        await completeQuest.mutateAsync({ taskType, code });
        toast(
            "daily_task_page_task_complete",
            TOAST_TYPE.SUCCESS,
            <MdMood className="toast-success-dark" size="2rem" />
        );

        await userTaskProgress.refetch();
    }, [toast, userTaskProgress]);

    const handleIncomplete = useCallback(async (taskType: QUEST_TYPE, code: string) => {
        if (!userTaskProgress.data?.getTaskProgress(taskType, code).isAvailable) {
            return null;
        }

        toast(
            "daily_task_page_task_incomplete",
            TOAST_TYPE.DANGER
        );
    }, [toast, userTaskProgress.data]);

    useEffect(() => {
        toggleLoading(completeQuest.isLoading);
    }, [completeQuest.isLoading, toggleLoading]);

    useEffect(() => {
        handleCarouselIndexChange(currentLevelIndex);
    }, [currentLevelIndex, handleCarouselIndexChange]);

    const value = useMemo<TaskContextProps>(() => ({
        appDownloadDialogShow,
        isTutorialShow,
        videoPlayer,
        taskPrizeClaimed: taskPrizeClaimed.data,
        refreshTaskPrizeClaimed: taskPrizeClaimed.refetch,
        tasks: tasks.data,
        userTaskProgress: userTaskProgress.data,
        refreshUserTaskProgress: userTaskProgress.refetch,
        apOverview: apOverview.data,
        myLevelIndex,
        currentLevelIndex,
        currentCarouselIndex,
        handleTutorialClose,
        handleCarouselIndexChange,
        handleAppDownloadDialogOpen,
        handleAppDownloadDialogClose,
        handleComplete,
        handleIncomplete
    }), [appDownloadDialogShow, isTutorialShow, videoPlayer, taskPrizeClaimed.data, taskPrizeClaimed.refetch, tasks.data, userTaskProgress.data, userTaskProgress.refetch, apOverview.data, currentLevelIndex, currentCarouselIndex, handleTutorialClose, handleCarouselIndexChange, handleAppDownloadDialogOpen, handleAppDownloadDialogClose, handleIncomplete]);

    return <TaskContext.Provider value={value}>
        {children}
    </TaskContext.Provider>;
};

export const useTaskProvider = () => {
    const context = useContext(TaskContext);

    if (!context) {
        throw new Error("useTaskProvider must be used within a TaskContext");
    }

    return context;
};
