import { loginInfoUrl, loginUrl } from "apis/ApiUrlConfig";
import { ajax } from "apis/ApiUtils";

export interface LoginData {
    siteId: number
}

export const getLoginInfo = async (data?: LoginData): Promise<LoginResultDto> => {
    return new Promise((resolve, reject) => {
        ajax<LoginResultDto>({
            method: "GET",
            url: loginInfoUrl,
            data
        })
            .then(({ data }) => resolve(data.data))
            .catch(error => reject(error));
    });
};


interface LoginResultDto {
    id: string,
    loginType: string,
    newUser: boolean,
}

export const login = async (loginData?: LoginData) => {
    const { tenantApiKey } = window.FANTASY_GAME_CONFIG();

    const { data } = await ajax<{
        sessionId: string
    }>({
        method: "POST",
        url: loginUrl,
        data: {
            ...loginData,
            registerKey: tenantApiKey ?? ""
        },
    });
    return data.data;
};
