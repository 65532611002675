import { Callback } from "Common";
import React from "react";
import { UseFormReturn } from "react-hook-form";
import { useIntl } from "react-intl";
import { Form } from "components/ui/form";
import { FantasyTextField } from "components/reactHookFormField/FantasyTextField";
import { Icon } from "helpers/IconHelper";
import { NINE_CAT_STAT } from "domain/slate/data/ComponentTypes";
import { OPTION_ALL, options, roleOptions } from "domain/slate/views/config";
import { FantasyDropdownMenu, FantasyDropdownMenuItem } from "domain/slate/views/FantasyDropdownMenu";
import { TEXT_FIELD_SIZE } from "components/input/FantasyText";

interface FeatureButtonGroupProps {
    form: UseFormReturn<{ search: string, order: NINE_CAT_STAT, filter: string[] }, any, undefined>
    onToggleFilterOptions: Callback<[string]>
}

const FeatureButtonGroup: React.FC<FeatureButtonGroupProps> = ({
                                                                   form,
                                                                   onToggleFilterOptions
                                                               }) => {
    const intl = useIntl();

    const orderVal = form.watch("order");
    const filterVal = form.watch("filter");
    const searchVal = form.watch("search");

    const isFilterHaveVals = filterVal.length > 0;
    const filterLabelText = isFilterHaveVals ? filterVal.join(", ") : intl.formatMessage({ id: "label_filter" });

    const handleClearSearch = () => {
        form.setValue("search", "");
    };

    return <Form {...form}>
        <div className={"tw-flex tw-justify-center tw-gap-2 tw-max-w-[36rem] tw-m-auto"}>
            <FantasyTextField
                name={"search"}
                size={TEXT_FIELD_SIZE.Small}
                iconLeft={<Icon.Search size={"1.2rem"} />}
                iconRight={
                    <>
                        {searchVal &&
                            <Icon.Close size={"1.2rem"} className={"tw-cursor-pointer"} onClick={handleClearSearch} />
                        }
                    </>
                }
                inputProps={{
                    className: "tw-bg-grey-50 tw-w-[20.8rem]",
                    placeholder: intl.formatMessage({ id: "label_search" })
                }} />
            <FantasyDropdownMenu
                selected={!!orderVal}
                labelComponent={
                    <>
                        <Icon.ArrowDownWideNarrowSort />
                        {orderVal}
                    </>
                }
            >
                {options.map((option) =>
                    (
                        <FantasyDropdownMenuItem
                            key={option}
                            selected={orderVal === option}
                            onClick={() => form.setValue("order", option)}
                        >
                            {option}
                        </FantasyDropdownMenuItem>
                    ))}
            </FantasyDropdownMenu>
            <FantasyDropdownMenu
                selected={isFilterHaveVals}
                labelComponent={
                    <div className={"tw-flex"}>
                        {isFilterHaveVals ? <Icon.FilterFilled /> : <Icon.FilterEmpty />}
                        <span className={"tw-truncate tw-max-w-[70%]"}>
                            {filterLabelText}
                        </span>
                    </div>
                }
            >
                {roleOptions.map((option, i) => {
                    const isAllOptionSelected = option === OPTION_ALL && filterVal.length === 0;
                    const selected = filterVal.includes(option) || isAllOptionSelected;
                    return (
                        <FantasyDropdownMenuItem
                            key={option}
                            selected={selected}
                            className={i === 0 ? "tw-px-4 tw-justify-start tw-font-bold" : "tw-px-6"}
                            onClick={() => onToggleFilterOptions(option)}
                        >
                            {option}
                        </FantasyDropdownMenuItem>
                    );
                })}
            </FantasyDropdownMenu>
        </div>
    </Form>;
};

export default FeatureButtonGroup;
