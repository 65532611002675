import { useSportConfigs } from "domain/sport/useSportConfigs";
import { cn } from "helpers/cn";
import React from "react";
import { useMyMatch } from "../providers/MyMatchProvider";

const MyMatchSportFilter: React.FC = () => {
    const { filterSport, handleToggleFilterSport } = useMyMatch();
    const sportTabs = useSportConfigs();

    return (
        <div className="tw-mb-4 tw-flex tw-justify-center tw-gap-2.5">
            {
                sportTabs.map(({ key, name, icon }) => (
                    <div
                        className={cn("tw-h-7 tw-px-3 tw-bg-white tw-flex tw-items-center tw-gap-1 tw-text-black tw-text-body-2 tw-rounded-[0.8rem] tw-border tw-border-transparent tw-cursor-pointer", {
                            "tw-border-grey-1000": filterSport.includes(key),
                        })}
                        key={key}
                        onClick={() => {
                            handleToggleFilterSport(key);
                        }}
                        data-testid={`my-match-sport-filter-${key}`}
                    >
                        {filterSport.includes(key) && icon()}
                        {name}
                    </div>
                ))
            }
        </div>
    );
};

export default MyMatchSportFilter;
