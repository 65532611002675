import { appStoreQRCode, googlePlayQRCode } from "helpers/IconHelper";
import { useSelector } from "store";
import { QRCodeWrapper } from "./AppQrCodeStyle";

const AppQrCode = () => {
    const { featureConfig } = useSelector(store => store.FantasyGameConfigState);

    if (!featureConfig.hasApp) return null;

    return (
        <QRCodeWrapper>
            <div className="tw-flex tw-gap-8">
                <img data-testid="google-play-icon" className="tw-w-[15rem]" src={googlePlayQRCode} alt="google-play" />
                <img data-testid="app-store-icon" className="tw-w-[15rem]" src={appStoreQRCode} alt="app-store" />
            </div>
        </QRCodeWrapper>
    );
};
export default AppQrCode;
