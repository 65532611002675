import { useDispatch } from "react-redux";
import { SPORT } from "types/sports/Sport";
import { useSelector } from "store";
import { updateCurrentSport } from "store/actions/Actions";

export const useCurrentSport = () => {
    const { currentSport } = useSelector(state => state.SportState);
    const dispatch = useDispatch();

    const changeCurrentSport = (sport: SPORT) => {
        dispatch(updateCurrentSport(sport));
    };

    return {
        currentSport,
        changeCurrentSport
    };
};
