import { fetchSupportedRegions, sendKycVerification } from "apis/kyc/KycApi";
import MdCloseButton from "components/buttons/MdCloseButton";
import { useLoadingToggle } from "components/loading/Loading";
import { useKycLeavePopup } from "domain/kyc/components/kycLeavePopup/KycLeavePopupProvider";
import { StepProgressBar } from "domain/kyc/components/stepProgressBar/StepProgressBar";
import { Step1 } from "domain/kyc/pages/kycVerificationForm/Step1";
import { Step2 } from "domain/kyc/pages/kycVerificationForm/Step2";
import { Step3 } from "domain/kyc/pages/kycVerificationForm/Step3";
import { Step4 } from "domain/kyc/pages/kycVerificationForm/Step4";
import { KycVerificationVo } from "domain/kyc/types";
import { Icon } from "helpers/IconHelper";
import useFantasyCommand from "hooks/useFantasyCommand";
import { QUERY_KEY } from "hooks/useFantasyQuery/type";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import useFantasyHistory from "hooks/useFantasyHistory";
import useFantasyQuery from "hooks/useFantasyQuery";
import { useKycData } from "domain/kyc/pages/kycVerificationForm/hooks/useKycData";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";
import { addErrorPopup } from "store/actions/AlertActions";
import { createErrorObject, ErrorResponse } from "helpers/ErrorHelper";
import { useResponsibleGamingRegistration } from "hooks/UseResponsibleGamingRegistration";

export enum KYC_STEP {
    OTP,
    STEP_1,
    STEP_2,
    STEP_3,
    STEP_4,
}

const stepComponentMap = {
    [KYC_STEP.STEP_1]: Step1,
    [KYC_STEP.STEP_2]: Step2,
    [KYC_STEP.STEP_3]: Step3,
    [KYC_STEP.STEP_4]: Step4,
};


const KycVerificationForm = () => {
    const { kycData, updateKycData } = useKycData();
    const { responsibleGamingRegistration } = useResponsibleGamingRegistration();
    const [currentStep, setCurrentStep] = useState<KYC_STEP>(KYC_STEP.STEP_1);

    const history = useFantasyHistory();
    const dispatch = useDispatch();
    const toggleLoading = useLoadingToggle();
    const keyLeavePopup = useKycLeavePopup();


    const supportedRegionResponse = useFantasyQuery([QUERY_KEY.SUPPORTED_REGION], fetchSupportedRegions);

    const kycSubmit = useFantasyCommand(sendKycVerification, { errorHandle: false });
    const supportedRegions = supportedRegionResponse.data ?? [];

    const handleNext = (userInputKycData: Partial<KycVerificationVo>) => {
        updateKycData(userInputKycData);
        setCurrentStep((currentStep) => currentStep + 1);
    };

    const handleBack = () => setCurrentStep((currentStep) => currentStep - 1);

    const handleDone = async (userInputKycData: Partial<KycVerificationVo>) => {
        toggleLoading(true);
        const newKycData = updateKycData(userInputKycData);

        try {
            await kycSubmit.mutateAsync(newKycData);
            toggleLoading(false);
            history.replace(ROUTER_PATH_CONFIG.verifyKyc.progress);
        } catch (error) {
            toggleLoading(false);
            dispatch(addErrorPopup(createErrorObject(error as ErrorResponse)));
        }
    };

    const CurrentStepComponent = stepComponentMap[currentStep];

    return <>
        <div className={"tw-flex tw-flex-col tw-px-[2rem] tw-pt-[1.2rem] tw-pb-[4rem] tw-gap-y-[2.8rem]"}>
            <div className={"tw-flex tw-flex-row-reverse"}>
                <MdCloseButton onClose={keyLeavePopup.open} />
            </div>

            <div className="tw-flex tw-items-start">
                <Icon.DailyLogo className="tw-px-[0.4rem] tw-py-[0.6rem] tw-mr-[0.8rem]" />
                <div className="tw-text-h6-medium">
                    <FormattedMessage id="kyc_verification_title" />
                </div>
            </div>

            <StepProgressBar currentStep={currentStep} />
            {responsibleGamingRegistration && <CurrentStepComponent
                kycData={kycData}
                responsibleGamingRegistration={responsibleGamingRegistration}
                supportedRegions={supportedRegions}
                onBack={handleBack}
                onNext={handleNext}
                onExit={keyLeavePopup.open}
                onDone={handleDone}
            />}
        </div>
    </>;
};

export default KycVerificationForm;
