import { ApiError } from "apis/Api";
import { HINT_CARD_VARIANT, HintCard } from "components/hintcard/HintCard";
import Button from "designToken/button/Button";
import { NEW_BUTTON_VARIANTS } from "designToken/button/types";
import OtpInput from "domain/kyc/components/OtpInput";
import { OtpResendSection } from "domain/kyc/components/OtpResendSection";
import { StepProgressBar } from "domain/kyc/components/stepProgressBar/StepProgressBar";
import { useKycOtp } from "domain/kyc/pages/kycOtp/KycOtpProvider";
import { KYC_STEP } from "domain/kyc/pages/kycVerificationForm/KycVerificationForm";
import { cn } from "helpers/cn";
import { OtpVerifyValidators } from "helpers/ErrorHelper";
import { Icon } from "helpers/IconHelper";
import useHandleApiError, { ERROR_HANDLER_FLOW } from "hooks/useHandleApiError";
import useMyProfile from "hooks/useMyProfile";
import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import IconButton, { IconButtonFor } from "components/buttons/IconButton";

export const OtpCode: React.FC = () => {
    const intl = useIntl();
    const { getMyProfile } = useMyProfile();
    const { toEditMobileNumber, verifyOtp, responsibleGamingRegistration , setOtpVerifyError, error } = useKycOtp();
    const handleApiError = useHandleApiError();
    const [otp, setOtp] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const isValid = useMemo(() => otp.match(/^\d{6}$/) !== null, [otp]);

    const handleVerifyOtp = async () => {
        if (!isValid) return;

        try {
            setOtpVerifyError(null);
            await verifyOtp({ code: otp });
            await getMyProfile();
        } catch (e) {
            await handleApiError(e as ApiError, (error) => {
                if (OtpVerifyValidators.some((validator) => validator(error))) {
                    setOtpVerifyError(error.response!.data.errorCode);
                    return ERROR_HANDLER_FLOW.STOP;
                }

                return ERROR_HANDLER_FLOW.COUNTINUE;
            });
        }
    };

    const handleEditMobileNumber = () => {
        if (error.isOtpSendExceedDailyLimit) return;

        toEditMobileNumber();
    };

    useEffect(() => {
        if (error.isOtpVerifyFailed || error.isOtpVerifyExceedAttemptLimit) {
            setErrorMessage(intl.formatMessage({ id: "otp_error_verify_code_incorrect" }));
            return;
        }
        if (error.isOtpCodeExpired) {
            setErrorMessage(intl.formatMessage({ id: "otp_error_verify_code_expired" }));
            return;
        }
        setErrorMessage("");
    }, [error.isOtpVerifyFailed, error.isOtpVerifyExceedAttemptLimit, error.isOtpCodeExpired]);

    function handleChangeOtp(otp: string) {
        setErrorMessage("");
        setOtp(otp);
    }

    return <>
        <div className="tw-flex tw-items-start">
            <Icon.DailyLogo className="tw-px-[0.4rem] tw-py-[0.6rem] tw-mr-[0.8rem]" />
            <div className="tw-text-h6-medium">
                <FormattedMessage id="kyc_verification_title" />
            </div>
        </div>
        <StepProgressBar currentStep={KYC_STEP.OTP} />
        <div className="tw-min-h-[32rem]">
            <div className="tw-text-subtitle-1 tw-font-medium tw-text-grey-1000 tw-flex tw-gap-1 tw-items-center">
                <p>
                    <FormattedMessage id="otp_verify_code_title"
                                      values={{ mobileNumber: `${responsibleGamingRegistration?.countryCode} ${responsibleGamingRegistration?.phoneNumber}` }} />
                </p>
                <IconButton
                    for={IconButtonFor.ON_LIGHT}
                    background={false}
                    icon={Icon.EditFilled}
                    iconSize="1.8rem"
                    disabled={error.isOtpSendExceedDailyLimit}
                    className={cn({ "*:!tw-text-primary-600": !error.isOtpSendExceedDailyLimit })}
                    onClick={handleEditMobileNumber}
                />
            </div>
            <div className="tw-text-subtitle-2 tw-text-grey-1000">
                <FormattedMessage id="otp_verify_code_description" />
            </div>
            <div className="tw-pt-6 tw-space-y-2">
                <div className="tw-space-y-1">
                    <div className="tw-text-body-1 tw-text-grey-1000">
                        <FormattedMessage id="label_otp_code" />
                    </div>
                    <OtpInput onChangeOtp={handleChangeOtp} errorMessage={errorMessage} />
                </div>

                {
                    error.isOtpCodeExpired && (
                        <HintCard
                            variant={HINT_CARD_VARIANT.WARNING}
                            hintText={intl.formatMessage({ id: "otp_error_verify_code_expired_hint" })}
                            prefixIcon={Icon.ArrowCircleDownRight}
                        />
                    )
                }

                {
                    error.isOtpVerifyExceedAttemptLimit && (
                        <HintCard
                            variant={HINT_CARD_VARIANT.WARNING}
                            hintText={intl.formatMessage({ id: "otp_error_verify_code_exceed_attempt_limit_hint" })}
                            prefixIcon={Icon.ArrowCircleDownRight}
                        />
                    )
                }
                <OtpResendSection />
                {
                    error.isOtpSendExceedDailyLimit && (
                        <HintCard
                            variant={HINT_CARD_VARIANT.ERROR}
                            hintText={intl.formatMessage({ id: "otp_error_mobile_number_exceed_daily_limit_hint" })}
                            prefixIcon={Icon.Speedometer}
                        />
                    )
                }
            </div>
        </div>
        <div className="tw-flex tw-flex-row tw-space-x-[0.8rem]">
            <Button
                className="tw-flex-1 tw-text-h6 tw-font-medium"
                variant={NEW_BUTTON_VARIANTS.primaryDark}
                onClick={handleVerifyOtp}
                disabled={!isValid || error.isOtpCodeExpired || error.isOtpVerifyExceedAttemptLimit}
            >
                <FormattedMessage id="label_verify" />
            </Button>
        </div>
    </>;
};
