import { ajax } from "apis/ApiUtils";

export const sendOtp = async () => {
    await ajax({
        url: "/kyc/otp/send",
        method: "POST",
    });
};

export const verifyOtp = async (code: string) => {
    await ajax({
        url: "/kyc/otp/verify",
        method: "POST",
        data: { code }
    });
};
