import React, { useState } from "react";
import { OnChangeValidator } from "components/reactHookFormField/OnChangeValidator";
import clsx from "clsx";
import { Input } from "components/ui/input";
import { omit } from "lodash";

export enum TEXT_FIELD_SIZE {
    Small,
    Medium
}

export enum TEXT_FIELD_VARIANT {
    STANDARD,
    FILLED,
    OUTLINED
}

export interface FantasyTextProps {
    size?: TEXT_FIELD_SIZE;
    variant?: TEXT_FIELD_VARIANT;
    name?: string;
    inputProps?: Omit<React.InputHTMLAttributes<HTMLInputElement>, "name"> & {
        "data-testid"?: string
    };
    showErrorMessage?: boolean;
    errorMessage?: React.ReactNode;
    iconLeft?: React.ReactNode;
    iconRight?: React.ReactNode;
    maxLength?: number;
    onChangeValidator?: OnChangeValidator;
    disabled?: boolean;
    isError?: boolean;
}

const sizeClassNameMap = {
    [TEXT_FIELD_SIZE.Small]: {
        fontSize: "tw-text-caption-2",
        height: "tw-h-[3.1rem]",
        paddingLeft: "tw-pl-[2.8rem]",
    },
    [TEXT_FIELD_SIZE.Medium]: {
        fontSize: "tw-text-body-1",
        height: "tw-h-[4.8rem]",
        paddingLeft: "tw-pl-[3.4rem]",
    },
};
export const variantClassNameMap = {
    [TEXT_FIELD_VARIANT.STANDARD]: {
        borderColor: "tw-border-transparent",
        backgroundColor: "tw-bg-background",
    },
    [TEXT_FIELD_VARIANT.FILLED]: {
        borderColor: "tw-border-transparent focus:tw-border-info-700",
        backgroundColor: "tw-bg-grey-50",
    },
    [TEXT_FIELD_VARIANT.OUTLINED]: {
        borderColor: "tw-border-grey-200",
        backgroundColor: "tw-bg-background",
    },
};

export const FantasyText = React.forwardRef<HTMLInputElement, FantasyTextProps>(({
                                                                                     size = TEXT_FIELD_SIZE.Medium,
                                                                                     variant = TEXT_FIELD_VARIANT.STANDARD,
                                                                                     iconLeft,
                                                                                     iconRight,
                                                                                     showErrorMessage = true,
                                                                                     errorMessage,
                                                                                     isError,
                                                                                     disabled,
                                                                                     maxLength,
                                                                                     inputProps,
                                                                                 }, ref) => {
    const [isFocused, setIsFocused] = useState(false);
    const iconBasicClassName = "tw-absolute tw-top-0 tw-bottom-0 tw-flex tw-items-center tw-justify-center";

    const sizeClassName = sizeClassNameMap[size] ?? {
        fontSize: "tw-text-body-1",
        paddingLeft: "",
        height: ""
    };

    const { borderColor, backgroundColor } = variantClassNameMap[variant];

    return <>
        <div className="tw-relative">
            {iconLeft && (
                <div
                    className={clsx(iconBasicClassName, "tw-left-3")}>{iconLeft}</div>
            )}
            <Input
                ref={ref}
                {...omit(inputProps, "className")}
                onFocus={(event) => {
                    setIsFocused(true);
                    if (inputProps && inputProps?.onFocus) {
                        inputProps.onFocus(event);
                    }
                }}
                onBlur={(event) => {
                    setIsFocused(false);
                    if (inputProps && inputProps?.onBlur) {
                        inputProps.onBlur(event);
                    }
                }}
                className={clsx(
                    "tw-w-full tw-rounded-[0.8rem]",
                    "tw-px-[1.2rem] tw-py-[0.8rem]",
                    "focus-visible:tw-outline-0",
                    "tw-border",
                    sizeClassName.fontSize,
                    sizeClassName.height,
                    iconLeft ? sizeClassName.paddingLeft : "",
                    backgroundColor,
                    isError ? "tw-border-error-700" : borderColor,
                    inputProps?.className ?? "",
                )}
                disabled={disabled}
            />
            {iconRight && (
                <div
                    className={clsx(iconBasicClassName, "tw-right-3")}>{iconRight}</div>
            )}
        </div>
        {showErrorMessage && isError && errorMessage}
        {
            maxLength && isFocused && !isError &&
            <div className="tw-text-caption-1 tw-text-start tw-text-info-700 tw-pt-1">
                {inputProps?.value}/{maxLength}
            </div>
        }
    </>;
});
