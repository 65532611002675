import { zodResolver } from "@hookform/resolvers/zod";
import { orderBy } from "lodash";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { z } from "zod";
import { FantasySelectField } from "components/reactHookFormField/FantasySelectField";
import { FantasyTextField } from "components/reactHookFormField/FantasyTextField";
import { Icon } from "helpers/IconHelper";
import { KycVerificationProps } from "domain/kyc/pages/kycVerificationForm/type/kyc_verification_props";
import { KycVerificationFormActions } from "domain/kyc/pages/kycVerificationForm/components/KycVerificationFormActions";

const getNumberArray = (n: number) => {
    return [...Array(n).keys()].map((v) => (v + 1).toString());
};

interface Step1FormInput {
    firstName: string;
    lastName: string;
    yearOfBirth: string;
    monthOfBirth: string;
    dayOfBirth: string;
    nationality: string;
    placeOfBirth: string;
}

const PhilippinesFirst = (region: string) => {
    if (region === "Philippines") {
        return 0;
    }
    return 1;
};

export const Step1: React.FC<KycVerificationProps> = ({
                                                          kycData,
                                                          onNext,
                                                          onExit,
                                                          supportedRegions,
                                                          responsibleGamingRegistration
                                                      }) => {

    const intl = useIntl();
    const form = useForm<Step1FormInput>({
        resolver: zodResolver(z.object({
            firstName: z.string().min(1, {
                message: intl.formatMessage({
                    id: "required_error_first_name",
                })
            }),
            lastName: z.string().min(1, {
                message: intl.formatMessage({
                    id: "required_error_last_name",
                })
            }),
            yearOfBirth: z.coerce.number().min(1900).max(9999),
            monthOfBirth: z.coerce.number().min(1),
            dayOfBirth: z.coerce.number().min(1),
            nationality: z.string().min(1, {
                message: intl.formatMessage({
                    id: "required_error_nationality"
                })
            }),
            placeOfBirth: z.string().min(1, {
                message: intl.formatMessage({
                    id: "required_error_place_of_birth"
                })
            }),
        })),
        defaultValues: {
            firstName: responsibleGamingRegistration.firstName,
            lastName: responsibleGamingRegistration.lastName,
            yearOfBirth: responsibleGamingRegistration.yearOfBirth.toString(),
            monthOfBirth: responsibleGamingRegistration.monthOfBirth.toString(),
            dayOfBirth: responsibleGamingRegistration.dayOfBirth.toString(),
            nationality: kycData.nationality,
            placeOfBirth: kycData.placeOfBirth
        }
    });

    const errors = form.formState.errors;

    const validateAndNext = form.handleSubmit(onNext);

    const nationalities = orderBy(supportedRegions.map(region => region.name), [PhilippinesFirst]);

    return <>
        <FormProvider {...form}>
            <div data-testid="kyc-verification-step1" className="tw-flex tw-flex-col tw-gap-y-[1.2rem]">
                <div className="tw-text-subtitle-1 tw-text-grey-1000">
                    <FormattedMessage id="kyc_verification_form_title_part_with_var" values={{ part: 1, }} />
                </div>

                <div>
                    <div className="tw-text-body-1 tw-text-grey-1000 tw-pb-[0.4rem]">
                        <FormattedMessage id="label_first_name" />
                        *
                    </div>
                    <FantasyTextField
                        name="firstName"
                        disabled
                        inputProps={{
                            className: "tw-placeholder-grey-600 tw-bg-grey-100",
                        }}
                    />
                </div>

                <div>
                    <div className="tw-text-body-1 tw-text-grey-1000 tw-pb-[0.4rem]">
                        <FormattedMessage id="label_last_name" />
                        *
                    </div>
                    <FantasyTextField
                        name="lastName"
                        disabled
                        inputProps={{
                            className: "tw-placeholder-grey-600 tw-bg-grey-100",
                        }}
                    />
                </div>

                <div className="tw-w-full tw-flex tw-flex-col tw-gap-[0.4rem]">
                    <div className="tw-text-body-1 tw-text-grey-1000">
                        <FormattedMessage id="label_date_of_birth" />
                        *
                    </div>
                    <div className="tw-flex tw-gap-[0.4rem]">
                        <div className="tw-grow">
                            <FantasyTextField name="yearOfBirth"
                                              disabled
                                              showErrorMessage={false}
                                              inputProps={{
                                                  className: "tw-placeholder-grey-600 tw-bg-grey-100",
                                                  type: "number",
                                                  placeholder: intl.formatMessage({
                                                      id: "placeholder_year"
                                                  }),
                                                  maxLength: 4
                                              }} />
                        </div>
                        <FantasySelectField name="monthOfBirth"
                                            disabled
                                            inputProps={{
                                                className: "tw-placeholder-grey-600 tw-bg-grey-100",
                                                placeholder: intl.formatMessage({
                                                    id: "placeholder_month"
                                                })
                                            }}
                                            showErrorMessage={false}
                                            options={getNumberArray(12)} />
                        <FantasySelectField name="dayOfBirth"
                                            disabled
                                            inputProps={{
                                                className: "tw-placeholder-grey-600 tw-bg-grey-100",
                                                placeholder: intl.formatMessage({
                                                    id: "placeholder_date"
                                                })
                                            }}
                                            showErrorMessage={false}
                                            options={getNumberArray(31)} />
                    </div>
                    {
                        (errors.yearOfBirth || errors.monthOfBirth || errors.dayOfBirth) &&
                        <div className="tw-flex tw-gap-2 tw-text-caption-1 tw-text-error-700">
                            <Icon.FormMessageError className="tw-text-error-700" />
                            <FormattedMessage id="required_error_date_of_birth" />
                        </div>
                    }
                </div>

                <div>
                    <div className="tw-text-body-1 tw-text-grey-1000 tw-pb-[0.4rem]">
                        <FormattedMessage id="nationality_title" />
                        *
                    </div>
                    <FantasySelectField name="nationality" options={nationalities} />
                </div>

                <div>
                    <div className="tw-text-body-1 tw-text-grey-1000 tw-pb-[0.4rem]">
                        <FormattedMessage id="place_of_birth_title" />
                        *
                    </div>
                    <FantasySelectField name="placeOfBirth" options={nationalities} />
                </div>
            </div>
        </FormProvider>
        <div className={"tw-pt-[2.8rem]"}>
            <KycVerificationFormActions onExit={onExit} onNext={validateAndNext} />
        </div>
    </>;
};
