import { useCallback, useState } from "react";

export type WithdrawForm = BankData & AmountData;

export interface BankData {
    accountName: string;
    accountNumber: string;
    selectedBankCode: string;
    selectedBankName: string;
}

export interface AmountData {
    amount: number;
    transactionFee: number;
    receiveAmount: number;
}

const initWithdrawForm = {
    accountName: "",
    accountNumber: "",
    selectedBankCode: "",
    selectedBankName: "",
    amount: 0,
    transactionFee: 0,
    receiveAmount: 0
};

export const useWithdrawForm = () => {
    const [withdrawForm, setWithdrawForm] = useState<WithdrawForm>({ ...initWithdrawForm });

    const handleSetBank = useCallback((data: BankData) => {
        setWithdrawForm(prevState => ({
            ...prevState,
            ...data
        }));
    }, []);

    const handleSetAmount = useCallback((data: AmountData) => {
        setWithdrawForm(prevState => ({
            ...prevState,
            ...data
        }));
    }, []);

    const handleResetForm = useCallback(() => {
        setWithdrawForm({ ...initWithdrawForm });
    }, []);

    return {
        withdrawForm,
        handleSetBank,
        handleSetAmount,
        handleResetForm,
    };
};
