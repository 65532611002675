import { fetchPreLoginSettingUrl } from "apis/ApiUrlConfig";
import { ajax } from "apis/ApiUtils";
import { PreLoginSettingDto } from "data/dto/prelogin/PreLoginSettingDto";

export const fetchPreLoginSetting = async (): Promise<PreLoginSettingDto> => {
    const { tenantApiKey } = window.FANTASY_GAME_CONFIG();

    const { data: { data } } = await ajax<PreLoginSettingDto>({
        url: fetchPreLoginSettingUrl,
        customHeaders: {
            Authorization: tenantApiKey ? `Bearer ${tenantApiKey}` : ""
        }
    });
    return data;
};
