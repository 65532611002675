import { fetchMyApOverview } from "apis/ap/ActivityPointApi";
import { ApiExecuteContext } from "apis/ApiContext";
import { joinContest, joinRubyContest } from "apis/contest/ContestApi";
import { fetchMatchDetail, fetchMyContest, fetchPublicContest, fetchSortedMyTeam } from "apis/match/MatchApi";
import { Contest } from "apis/match/type";
import { Timer } from "components/countdown/Countdown";
import { useLoadingToggle } from "components/loading/Loading";
import { fetchContestDetail, fetchMyContestJoinedTeam } from "data/api/contest/ContestApi";
import { LeaderboardTeamDto } from "data/dto/leaderboard/LeaderboardTeamDto";
import useUpdateCoupon from "domain/coupon/useUpdateCoupon";
import useUpdateWallet from "domain/wallet/useUpdateWallet";
import { isMatchKickoff } from "helpers/match/MatchHelper";
import useKycRestriction from "hooks/useCheckKycRestriction/useKycRestriction";
import { useStopJoiningDialog } from "hooks/useDialog";
import useHandleJoinContestError from "hooks/useHandleJoinContestError/useHandleJoinContestError";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { updateSelectTeamPanelData } from "store/actions/Actions";
import { MyApOverview } from "types/activityPoint/MyApOverview";
import { MyContestVO } from "types/contest/Contest";
import { ContestDetailVO } from "types/contest/ContestDetail";
import { MATCH_STATUS_FROM_API, MatchVO } from "types/match/Match";
import { MyTeamVO } from "types/team/MyTeam";
import { useSetting } from "hooks/useSetting";

interface StartedMatchDetailDataProps {
    hasData: boolean
    updateMyTeam: (callback: (myTeams: MyTeamVO[]) => void) => void
    updateContest: (code: string) => void
    updateData: () => void
    contestJoiningCallbacks: (callback: () => void) => (joinContestProps: {
        contestId: number,
        selectedTeamIds: number[],
        useRuby: boolean,
        couponIds: number[]
    }) => void
    handleJoinRubyContest,
    publicContests: Contest[] | null
    match: MatchVO | null
    myContests: MyContestVO[] | null
    myTeams: MyTeamVO[] | null
    contest: ContestDetailVO | null
    myApOverview: MyApOverview | null
}

const StartedMatchDetailDataContext = React.createContext<StartedMatchDetailDataProps | null>(null);

export const StartedMatchDetailDataProvider = ({ children }) => {
    const { sport, matchId } = useParams();
    const dispatch = useDispatch();
    const apiExecutor = useContext(ApiExecuteContext);
    const toggleLoading = useLoadingToggle();
    const updateWallet = useUpdateWallet();
    const updateCoupon = useUpdateCoupon();
    const handleStopJoiningDialog = useStopJoiningDialog();
    const handleJoinContestError = useHandleJoinContestError();
    const isRestrictedByKyc = useKycRestriction();
    const { settingState: { match: { kickoffDurationMilliseconds } } } = useSetting();

    const timer = useRef<Timer>();

    const [publicContests, setPublicContests] = useState<Contest[] | null>(null);
    const [match, setMatch] = useState<MatchVO | null>(null);
    const [myContests, setMyContests] = useState<MyContestVO[] | null>(null);
    const [myTeams, setMyTeams] = useState<MyTeamVO[] | null>(null);
    const [contest, setContest] = useState<ContestDetailVO | null>(null);
    const [myApOverview, setMyApOverview] = useState<MyApOverview | null>(null);

    const hasData = useMemo(() => !!match && !!myContests && !!myTeams, [match, myContests, myTeams]);

    const setMatchStatusToLiveDialog = useCallback(() => {
        handleStopJoiningDialog(() => {
            setMatch((prevMatch) => {
                if (!prevMatch) return null;

                return ({
                    ...prevMatch,
                    matchStatus: MATCH_STATUS_FROM_API.LIVE
                });
            });
        });
    }, [handleStopJoiningDialog]);

    const updateMyTeam = useCallback(async (callback) => {
        toggleLoading(true);
        const myTeams = await apiExecutor(fetchSortedMyTeam(sport, matchId));
        setMyTeams(myTeams);
        callback(myTeams);
        toggleLoading(false);
    }, [sport, matchId, apiExecutor, toggleLoading]);

    const updateContest = useCallback(async (code) => {
        toggleLoading(true);
        const [newContest, myJoinedTeams] = await apiExecutor(Promise.all([
            fetchContestDetail(code),
            fetchMyContestJoinedTeam(code)
        ]));
        setContest({ ...newContest, myJoinedTeams: myJoinedTeams });
        dispatch(updateSelectTeamPanelData({
            contest: newContest,
            joinedTeamIds: myJoinedTeams.map((item: LeaderboardTeamDto) => item.teamId)
        }));
        toggleLoading(false);
    }, [apiExecutor, toggleLoading]);


    const updateData = useCallback(async () => {
        toggleLoading(true);

        const [match, myContests, myTeams, myApOverview] = await apiExecutor(
            Promise.all([
                fetchMatchDetail(sport, matchId),
                fetchMyContest(sport, matchId),
                fetchSortedMyTeam(sport, matchId),
                fetchMyApOverview(),
                updateWallet(),
                updateCoupon()
            ])
        );

        if (myTeams.length === 0) {
            handleStopJoiningDialog();
            return;
        }

        if (isMatchKickoff(match.matchStatus)) {
            const now = new Date().getTime();
            const restTimeToJoin = kickoffDurationMilliseconds - (now - match.matchStartsAt);

            if (restTimeToJoin > 0) {
                timer.current = setTimeout(setMatchStatusToLiveDialog, restTimeToJoin);
            } else {
                match.matchStatus = MATCH_STATUS_FROM_API.LIVE;
            }
            const publicContests = await apiExecutor(fetchPublicContest(sport, matchId));
            setPublicContests(publicContests);
        }

        setMatch(match);
        setMyContests(myContests);
        setMyTeams(myTeams);
        setMyApOverview(myApOverview);

        toggleLoading(false);
    }, [sport, apiExecutor, matchId, toggleLoading, kickoffDurationMilliseconds, updateWallet, updateCoupon]);

    const contestJoiningCallbacks = useCallback((callback) => async ({
                                                                         contestId,
                                                                         selectedTeamIds,
                                                                         useRuby,
                                                                         couponIds
                                                                     }) => {
        if (await isRestrictedByKyc()) {
            return;
        }
        toggleLoading(true);
        apiExecutor(joinContest(contestId, selectedTeamIds, useRuby, couponIds), {
            onSuccess: () => {
                callback();
                setContest(null);
                updateData();
            },
            onFail: (_error) => {
                handleJoinContestError(_error);
            },
            onFinally: () => {
                toggleLoading(false);
            }
        });
    }, [apiExecutor, toggleLoading, handleJoinContestError, updateData, isRestrictedByKyc]);

    const handleJoinRubyContest = useCallback((callback) => ({
                                                                 contestId,
                                                                 selectedTeamIds,
                                                             }) => {
        toggleLoading(true);
        apiExecutor(joinRubyContest(contestId, selectedTeamIds), {
            onSuccess: () => {
                callback();
                setContest(null);
                updateData();
            },
            onFail: (_error) => {
                handleJoinContestError(_error);
            },
            onFinally: () => {
                toggleLoading(false);
            }
        });
    }, [apiExecutor, toggleLoading, handleJoinContestError, updateData]);

    useEffect(() => {
        updateData();

        return () => {
            clearTimeout(timer.current);
        };
    }, [updateData]);

    const contextValue = useMemo(() => ({
        updateData,
        updateMyTeam,
        updateContest,
        contestJoiningCallbacks,
        handleJoinRubyContest,
        hasData,
        publicContests,
        match,
        myContests,
        myTeams,
        contest,
        myApOverview
    }), [updateData, updateMyTeam, updateContest, contestJoiningCallbacks, hasData, publicContests, match, myContests, myTeams, contest, myApOverview, handleJoinRubyContest]);

    return <StartedMatchDetailDataContext.Provider value={contextValue}>
        {children}
    </StartedMatchDetailDataContext.Provider>;
};

export const useStartedMatchDetailData = () => {
    const context = useContext(StartedMatchDetailDataContext);

    if (!context) {
        throw new Error("useStartedMatchDetailData must be used within a StartedMatchDetailDataContext");
    }

    return context;
};
