import { CoinProductVo } from "data/vo/coin/CoinProductVo";
import { ADD_DEPOSIT_PAGE, PAYMENT_STATUS, toSelectedTeamIdsArray } from "domain/coin/AddCoinHelper";
import { useAddCoinPage } from "domain/coin/hooks/useAddCoinPage";
import { useFetchProducts } from "domain/coin/hooks/useFetchProducts";
import { getQuery } from "helpers/queryString";
import useFantasyHistory from "hooks/useFantasyHistory";
import React, { createContext, useCallback, useContext, useMemo, useState } from "react";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";
import { CoinProductsVo } from "data/vo/coin/CoinProductsVo";


export interface AddDepositFlowProps {
    status: PAYMENT_STATUS;
    products: CoinProductsVo;
    currentProduct: CoinProductVo | null;
    setCurrentProduct: (product: CoinProductVo | null) => void;
    goToSelectPaymentMethodPage: () => void;
    goToAppDownloadPage: () => void;
    goToInitPage: () => void;
    cancelAddDepositFlow: () => void;
    onResultPageClose: () => void;
    restartAddDepositFlow: () => void;
}

export const AddCoinFlowContext = createContext<AddDepositFlowProps | null>(null);


const AddCoinFlow = ({ status }) => {
    const {
        changePage,
        goToInitPage,
        goToSelectPaymentMethodPage,
        goToAppDownloadPage,
        Component
    } = useAddCoinPage({ status });

    const { products } = useFetchProducts();

    const history = useFantasyHistory();


    const [selectedProduct, setSelectedProduct] = useState<CoinProductVo | null>(null);

    const {
        addDepositFlowFinishedGoTo,
        navbarBackGoTo,
        contestCode,
        selectedTeamsIds: selectedTeamsIdsString
    } = getQuery();


    const cancelAddDepositFlow = useCallback(() => {
        if (history.location?.state?.addDepositFlowFinishedGoTo) {
            history.replace(history.location?.state?.addDepositFlowFinishedGoTo, {
                navbarBackGoTo: history.location?.state?.navbarBackGoTo,
                contestCode: history.location?.state?.contestCode,
                selectedTeamsIds: history.location?.state?.selectedTeamsIds
            });

        } else {
            history.goBack();
        }
    }, [history]);

    const onResultPageClose = useCallback(() => {
        history.replace(addDepositFlowFinishedGoTo
                ? addDepositFlowFinishedGoTo as string
                : ROUTER_PATH_CONFIG.home,
            {
                navbarBackGoTo,
                contestCode,
                selectedTeamsIds: toSelectedTeamIdsArray(selectedTeamsIdsString)
            });
    }, [history, navbarBackGoTo, contestCode, addDepositFlowFinishedGoTo, selectedTeamsIdsString]);

    const restartAddDepositFlow = useCallback(() => {
        history.replace(ROUTER_PATH_CONFIG.deposit.select, {
            addDepositFlowFinishedGoTo,
            navbarBackGoTo,
            contestCode,
            selectedTeamsIds: toSelectedTeamIdsArray(selectedTeamsIdsString)
        });

        changePage(ADD_DEPOSIT_PAGE.PRODUCTS);
    }, [history, addDepositFlowFinishedGoTo, navbarBackGoTo, contestCode, selectedTeamsIdsString]);

    const providedValue = useMemo(() => ({
        status,
        products: products ?? CoinProductsVo.empty(),
        currentProduct: selectedProduct,
        setCurrentProduct: setSelectedProduct,
        goToSelectPaymentMethodPage,
        goToAppDownloadPage,
        cancelAddDepositFlow,
        goToInitPage,
        onResultPageClose,
        restartAddDepositFlow
    }), [
        status,
        products,
        selectedProduct,
        setSelectedProduct,
        goToSelectPaymentMethodPage,
        goToAppDownloadPage,
        cancelAddDepositFlow,
        goToInitPage,
        onResultPageClose,
        restartAddDepositFlow
    ]);

    return <AddCoinFlowContext.Provider value={providedValue}>
        <div className="bg-light-primary tw-h-full">
            <Component />
        </div>
    </AddCoinFlowContext.Provider>;
};

export const useAddCoinFlow = () => {
    const addCoinFlow = useContext(AddCoinFlowContext);

    if (!addCoinFlow) {
        throw new Error("useAddCoinFlow must be used within a AddCoinFlow");
    }

    return addCoinFlow;
};

export default AddCoinFlow;
