import PayMongoPaymentMethods from "domain/coin/pages/SelectPaymentMethod/components/PayMongoPaymentMethods";
import { TripleAPaymentMethods } from "domain/coin/pages/SelectPaymentMethod/components/TripleAPaymentMethods";
import useFantasyConfig from "hooks/useFantasyConfig";
import React from "react";
import { ADD_COIN_METHOD } from "store/reducer/config/FantasyGameConfigState";
import CreditCardMethods from "../CreditCardMethod";
import { useSetting } from "hooks/useSetting";

export const AddCoinMethods = ({ productId }) => {
    const { featureConfig: { hasApp } } = useFantasyConfig();

    return (
        <>
            <AddCoinMethod productId={productId} />
            {hasApp && (
                <CreditCardMethods />
            )}
        </>
    );
};

const addCoinMethodMap = {
    [ADD_COIN_METHOD.PAY_MONGO]: PayMongoPaymentMethods,
    [ADD_COIN_METHOD.TRIPLEA]: TripleAPaymentMethods,
    // TODO: UPay
    // [ADD_COIN_METHOD.UPAY]: UpayPaymentMethods,
};

const AddCoinMethod = ({ productId }) => {
    const { settingState: { paymentMethod } } = useSetting();

    if (!paymentMethod) return null;

    const Component = addCoinMethodMap[paymentMethod];

    return Component ? <Component productId={productId} /> : null;
};
