import {
    CoinConfirmationModal
} from "domain/contest/components/ConfirmationDialog/ConfirmationModal/CoinConfirmationModal";
import {
    useCoinConfirmationModal
} from "domain/contest/components/ConfirmationDialog/ConfirmationModal/useCoinConfirmationModal";
import SelectCouponModal from "domain/contest/components/ConfirmationDialog/SelectCouponModal/SelectCouponModal";
import React, { useCallback, useMemo, useState } from "react";
import PageViewRecorder from "../../../../components/ga/PageViewRecorder";
import Dialog, { DIALOG_SIZE } from "../../../../designToken/dialog/Dialog";
import { storageHelper } from "../../../../helpers/localStorage/LocalStorageHelper";
import { LOCAL_STORAGE_KEYS } from "../../../../helpers/localStorage/types";
import { ContestDetailVO } from "../../../../types/contest/ContestDetail";
import { EnrichedCoupon } from "../../../../types/coupon/Coupon";
import { SelectedTeam } from "../../../../types/team/SelectedTeam";
import { useJoinContestCalc } from "../../hooks/useJoinContestCalc";
import { ConfirmationDialogContestVO, DISCOUNT_TYPE } from "./types";


export type JoinContestOnSubmitType = (selectedTeamIds: number[], couponIds: number[], useRuby: boolean, currentWinningCent: number, balanceWinningCent: number) => void;

export enum OPEN_MODEL_TYPE {
    SELECT_COUPON = "SELECT_COUPON",
    CONFIRMATION = "CONFIRMATION"
}

export interface DepositConfirmationDialogProps {
    pageTitle?: string
    selectedTeams: SelectedTeam[],
    onHide: () => void,
    onSubmit: JoinContestOnSubmitType,
    myUnusedCoupon: EnrichedCoupon[],
    contest: ConfirmationDialogContestVO,
}

export function useDepositConfirmationDiscount(contest: Pick<ContestDetailVO, "entryFeeCent" | "allowDiscountWithRuby">, rubyStorageCanUseRuby: boolean) {
    const { entryFeeCent, allowDiscountWithRuby: canUseRuby } = contest;

    const [discountType, setDiscountType] = useState<DISCOUNT_TYPE>((canUseRuby && rubyStorageCanUseRuby) ? DISCOUNT_TYPE.RUBY : DISCOUNT_TYPE.COUPON);
    const [openModelType, setOpenModelType] = useState<OPEN_MODEL_TYPE>(OPEN_MODEL_TYPE.CONFIRMATION);
    const [selectedCoupons, setSelectedCoupons] = useState<EnrichedCoupon[]>([]);

    const isUseRuby = useMemo(() => discountType === DISCOUNT_TYPE.RUBY, [discountType]);
    const isUseCoupon = useMemo(() => discountType === DISCOUNT_TYPE.COUPON, [discountType]);
    const isSelectCouponModalShow = useMemo(() => openModelType === OPEN_MODEL_TYPE.SELECT_COUPON, [openModelType]);

    const handleSelectCoupon = useCallback((selectedCoupons) => {
        setSelectedCoupons(selectedCoupons);
        setDiscountType(DISCOUNT_TYPE.COUPON);
    }, []);

    const handleOpenSelectCoupon = useCallback(() => {
        setOpenModelType(OPEN_MODEL_TYPE.SELECT_COUPON);
    }, []);

    const handleCloseSelectCoupon = useCallback(() => {
        setOpenModelType(OPEN_MODEL_TYPE.CONFIRMATION);
    }, []);
    return {
        entryFeeCent,
        canUseRuby,
        discountType,
        setDiscountType,
        selectedCoupons,
        isUseRuby,
        isUseCoupon,
        isSelectCouponModalShow,
        handleSelectCoupon,
        handleOpenSelectCoupon,
        handleCloseSelectCoupon
    };
}

const DepositConfirmationDialog: React.FC<DepositConfirmationDialogProps> = ({
                                                                                 selectedTeams,
                                                                                 myUnusedCoupon,
                                                                                 onHide,
                                                                                 onSubmit,
                                                                                 contest,
                                                                                 pageTitle = "Confirm Join Contest"
                                                                             }) => {
    const rubyStorage = storageHelper(LOCAL_STORAGE_KEYS.USE_RUBY);
    const {
        entryFeeCent,
        canUseRuby,
        discountType,
        setDiscountType,
        selectedCoupons,
        isUseRuby,
        isUseCoupon,
        isSelectCouponModalShow,
        handleSelectCoupon,
        handleOpenSelectCoupon,
        handleCloseSelectCoupon
    } = useDepositConfirmationDiscount(contest, rubyStorage.get());

    const joinContestCalc = useJoinContestCalc({
            discountType,
            entryFeeCent,
            selectedTeams,
            selectedCoupons,
            isUseRuby,
        }
    );

    const {
        props: depositConfirmationModalProps
    } = useCoinConfirmationModal({
        setDiscountType,
        contest,
        selectedTeams,
        selectedCoupons,
        isUseRuby,
        isUseCoupon,
        canUseRuby,
        joinContestCalc,
        onSubmit,
        onHide,
        myUnusedCoupon,
        onOpenSelectCouponModal: handleOpenSelectCoupon
    });


    return <>
        <PageViewRecorder title={pageTitle} />
        <Dialog show={true} animation={false} size={DIALOG_SIZE.LG}>
            {
                isSelectCouponModalShow ?
                    <SelectCouponModal
                        entryFeeCent={contest.entryFeeCent}
                        myUnusedCoupon={myUnusedCoupon}
                        selectedCouponLimit={selectedTeams.length}
                        selectedCoupons={selectedCoupons}
                        onHide={handleCloseSelectCoupon}
                        onSelectedCoupons={handleSelectCoupon}

                    /> :
                    <CoinConfirmationModal {...depositConfirmationModalProps} />
            }

        </Dialog>


    </>;
};

export default DepositConfirmationDialog;
