import { useAppPopups } from "domain/popup/AppPopupsProvider";
import React, { useEffect } from "react";
import { SettingState } from "store/reducer/setting/SettingsState";
import { useSetting } from "hooks/useSetting";

type FeatureConfigPopupWrapperProps = {
    children: React.ReactNode;
    getSetting: (settings: SettingState) => boolean;
}

const SettingsPopupWrapper: React.FC<FeatureConfigPopupWrapperProps> = ({
                                                                            getSetting,
                                                                            children
                                                                        }) => {

    const popups = useAppPopups();
    const { settingState: settings } = useSetting();

    const enabled = getSetting(settings);

    useEffect(() => {
        if (!enabled) {
            popups.done();
        }
    }, [enabled]);

    if (!enabled) {
        return null;
    }

    return <>{children}</>;
};

export default SettingsPopupWrapper;
