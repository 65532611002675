import { zodResolver } from "@hookform/resolvers/zod";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { z } from "zod";
import { FantasySelectField } from "components/reactHookFormField/FantasySelectField";
import { FantasyTextAreaField } from "components/reactHookFormField/FantasyTextAreaField";
import { KycVerificationProps } from "domain/kyc/pages/kycVerificationForm/type/kyc_verification_props";
import { KycVerificationFormActions } from "domain/kyc/pages/kycVerificationForm/components/KycVerificationFormActions";
import { FantasyCheckbox } from "components/checkbox/FantasyCheckbox";
import { CHECKBOX_VARIANTS } from "components/checkbox/types";
import { FantasyText } from "components/input/FantasyText";

interface Step2FormInput {
    currentAddress: string,
    permanentAddress: string,
    countryCode: string,
    phoneNumber: string,
    natureOfWork: string,
    sourceOfIncome: string,
}

export const Step2: React.FC<KycVerificationProps> = ({
                                                          kycData,
                                                          responsibleGamingRegistration,
                                                          onBack,
                                                          onNext,
                                                          supportedRegions
                                                      }) => {
    const intl = useIntl();
    const [sameAboveChecked, setSameAboveChecked] = useState(false);
    const form = useForm<Step2FormInput>({
        resolver: zodResolver(z.object({
            currentAddress: z.string().min(1, {
                message: intl.formatMessage({
                    id: "required_error_current_address"
                })
            }),
            permanentAddress: z.string().min(1, {
                message: intl.formatMessage({
                    id: "required_error_permanent_address"
                })
            }),
            countryCode: z.coerce.string().min(1),
            phoneNumber: z.coerce.number().min(1).positive({
                message: intl.formatMessage({
                    id: "invalid_type_error_contact_information"
                })
            }),
            natureOfWork: z.string().min(1, {
                message: intl.formatMessage({
                    id: "required_error_nature_of_work"
                })
            }),
            sourceOfIncome: z.string().min(1, {
                message: intl.formatMessage({
                    id: "required_error_source_of_income"
                })
            }),
        })),
        defaultValues: {
            currentAddress: kycData.currentAddress,
            permanentAddress: kycData.permanentAddress,
            countryCode: responsibleGamingRegistration.countryCode,
            phoneNumber: responsibleGamingRegistration.phoneNumber,
            natureOfWork: kycData.natureOfWork,
            sourceOfIncome: kycData.sourceOfIncome
        },
    });

    const validateAndNext = form.handleSubmit(onNext);

    const handleAboveAddress = () => {
        const currentAddress = form.watch("currentAddress");
        form.setValue("permanentAddress", currentAddress);
        setSameAboveChecked(true);
    };

    return <>
        <FormProvider {...form}>
            <div data-testid="kyc-verification-step2" className="tw-flex tw-flex-col tw-gap-y-[1.2rem]">
                <div className="tw-text-subtitle-1 tw-text-grey-1000">
                    <FormattedMessage id="kyc_verification_form_title_part_with_var" values={{ part: 2 }} />
                </div>


                <div>
                    <div className="tw-text-body-1 tw-text-grey-1000 tw-pb-[0.4rem]">
                        <FormattedMessage id="label_current_address" />*
                    </div>
                    <FantasyTextAreaField
                        name="currentAddress"
                        onFocus={() => setSameAboveChecked(false)}
                    />
                </div>

                <div>
                    <div className="tw-text-body-1 tw-text-grey-1000 tw-pb-[0.4rem]">
                        <FormattedMessage id="label_permanent_address" />*
                    </div>
                    <FantasyTextAreaField
                        name="permanentAddress"
                        onFocus={() => setSameAboveChecked(false)}
                    />
                </div>

                <AboveCheckBox checked={sameAboveChecked} onClick={handleAboveAddress} />

                <div className="tw-w-full tw-flex tw-flex-col tw-gap-[0.4rem]">
                    <div className="tw-text-body-1 tw-text-grey-1000">
                        <FormattedMessage id="label_contact_information" />
                    </div>
                    <FantasyText
                        inputProps={{
                            value: `${form.watch("countryCode")} ${form.watch("phoneNumber")}`,
                            className: "tw-placeholder-grey-600 tw-bg-grey-100"
                        }}
                        disabled
                    />
                </div>

                <div>
                    <div className="tw-text-body-1 tw-text-grey-1000 tw-pb-[0.4rem]">
                        <FormattedMessage id="label_nature_of_work" />
                        *
                    </div>
                    <FantasySelectField name="natureOfWork" options={natureOfWorkOptions} />
                </div>

                <div>
                    <div className="tw-text-body-1 tw-text-grey-1000 tw-pb-[0.4rem]">
                        <FormattedMessage id="label_source_of_income" />
                        *
                    </div>
                    <FantasySelectField name="sourceOfIncome" options={sourceOfIncomeOptions} />
                </div>
            </div>
            <div className="tw-pt-[2.8rem]">
                <KycVerificationFormActions onBack={onBack} onNext={validateAndNext} />
            </div>
        </FormProvider>
    </>;
};

const natureOfWorkOptions = [
    "Administrative & Managerial",
    "Agriculture & Fishing",
    "Creative, Arts & Design",
    "Finance & Accounting",
    "Healthcare, Education & Training",
    "Legal Services",
    "Operations, Logistics & Transportation",
    "Sales, Marketing & Customer Service",
    "Security Services",
    "Technical, Engineering & R&D",
    "Other"
];

const sourceOfIncomeOptions = [
    "Employment",
    "Investment & Overseas Income",
    "Relatives (Support Family Members)",
    "Retired (Pension)",
    "Self-Employment/Business",
    "Unemployed",
    "Other"
];


const AboveCheckBox = (props: { checked: boolean, onClick: () => void }) => {
    return <div className="tw-text-caption-1">
        <FantasyCheckbox
            checked={props.checked}
            onClick={props.onClick}
            variant={CHECKBOX_VARIANTS.primary}
        >
            <FormattedMessage id="label_same_as_above" />

        </FantasyCheckbox>
    </div>;
};
