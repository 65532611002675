import React, { useEffect } from "react";
import { ReminderRepository } from "data/repository/reminder/ReminderRepository";
import { QUERY_KEY } from "hooks/useFantasyQuery/type";
import { useAppPopups } from "domain/popup/AppPopupsProvider";
import useFantasyQuery from "hooks/useFantasyQuery";
import DailyCheckInPopupProvider from "domain/quest/DailyCheckInPopup/DailyCheckInPopupProvider";
import MainDailyCheckInDefaultPopup from "domain/popup/dailyCheckIn/components/MainDailyCheckInDefaultPopup";
import DailyCheckInUpgradePopup, {
    dailyCheckInUpgradePopupConfirmButton
} from "domain/quest/DailyCheckInPopup/component/DailyCheckInUpgradePopup";
import { TodayPrizeOverlay } from "domain/quest/DailyCheckInPopup/component/TodayPrizeOverlay";
import SettingsPopupWrapper from "domain/popup/popupWrappers/SettingsPopupWrapper";
import MainPagePopupWrapper from "domain/popup/popupWrappers/MainPagePopupWrapper";

const MainDailyCheckInPopup = () => {
    const reminderRepository = new ReminderRepository();

    const popups = useAppPopups();
    const dailyCheckInReminder = useFantasyQuery([QUERY_KEY.DAILY_CHECK_IN_REMINDER], reminderRepository.getDailyCheckInReminder, {
        options: { cacheTime: 0 }
    });

    useEffect(() => {
        const isRead = dailyCheckInReminder.data?.unread === false;

        if (isRead) {
            popups.keep();
        }
    }, [dailyCheckInReminder]);

    const shouldNotShow = !dailyCheckInReminder.data || !dailyCheckInReminder.data.unread;

    if (shouldNotShow) {
        return null;
    }

    return <DailyCheckInPopupProvider>
        <MainDailyCheckInDefaultPopup />
        <DailyCheckInUpgradePopup
            confirmButtonSetting={dailyCheckInUpgradePopupConfirmButton.compare}
        />
        <TodayPrizeOverlay />
    </DailyCheckInPopupProvider>;
};

const MainDailyCheckInPopupWithWrapper = () => {
    return <SettingsPopupWrapper getSetting={(settings) => settings.questEnabled}>
        <MainPagePopupWrapper>
            <MainDailyCheckInPopup />
        </MainPagePopupWrapper>
    </SettingsPopupWrapper>;
};

export default MainDailyCheckInPopupWithWrapper;
