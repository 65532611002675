import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import {
    isDataNotFound,
    isInvalidResponsibleGamingRegistrationMobileNumber,
    isInvalidResponsibleGamingRegistrationUserAge,
    isOtpMobileInUse
} from "helpers/ErrorHelper";
import useHandleApiError, { ERROR_HANDLER_FLOW } from "hooks/useHandleApiError";
import { useAppPopups } from "domain/popup/AppPopupsProvider";
import { useResponsibleGamingRegistration } from "hooks/UseResponsibleGamingRegistration";
import { ApiError } from "apis/Api";
import FeatureConfigPopupWrapper from "domain/popup/popupWrappers/FeatureConfigPopupWrapper";
import { getRightSubString } from "helpers/string/StringHelper";
import { responsibleGamingRegistrationFormSchema } from "domain/responsibleGaming/schema";
import FantasyScrollingPopup, { POPUP_BACKGROUND, ScrollingPopupBody } from "components/popup/FantasyScrollingPopup";
import { BOLD_WEIGHT, BoldText } from "components/boldText/BoldText";
import { Header } from "domain/responsibleGaming/components/Header";
import { Footer } from "domain/responsibleGaming/components/Footer";
import { Form } from "domain/responsibleGaming/components/Form";

const MOBILE_NUMBER_LENGTH = 10;

export interface ResponsibleGamingRegistrationForm {
    firstName: string;
    lastName: string;
    birth: {
        yearOfBirth: string;
        monthOfBirth: string;
        dayOfBirth: string;
    }
    countryCode: string;
    mobileNumber: string;
    isInfoConfirmed: boolean;
}

const ResponsibleGamingRegistrationPopup = () => {
    const intl = useIntl();
    const handleApiError = useHandleApiError();
    const popups = useAppPopups();
    const [showRegistration, setShowRegistration] = useState(false);
    const {
        responsibleGamingRegistration,
        addRegistration
    } = useResponsibleGamingRegistration({
        onQueryFail: (error) => {
            if (isDataNotFound(error)) {
                setShowRegistration(true);
                return ERROR_HANDLER_FLOW.STOP;
            }

            return ERROR_HANDLER_FLOW.COUNTINUE;
        }
    });

    useEffect(() => {
        if (responsibleGamingRegistration) {
            popups.done();
        }
    }, [responsibleGamingRegistration]);

    const form = useForm<ResponsibleGamingRegistrationForm>({
        resolver: responsibleGamingRegistrationFormSchema(({ id }) => intl.formatMessage({ id })),
        defaultValues: {
            firstName: "",
            lastName: "",
            birth: {
                yearOfBirth: "",
                monthOfBirth: "",
                dayOfBirth: "",
            },
            countryCode: "+63",
            mobileNumber: "",
            isInfoConfirmed: false,
        },
        mode: "onChange"
    });

    const handleSubmit = form.handleSubmit(async (data) => {
        try {
            await addRegistration({
                firstName: data.firstName,
                lastName: data.lastName,
                yearOfBirth: parseInt(data.birth.yearOfBirth, 10),
                monthOfBirth: parseInt(data.birth.monthOfBirth, 10),
                dayOfBirth: parseInt(data.birth.dayOfBirth, 10),
                countryCode: data.countryCode,
                phoneNumber: getRightSubString(data.mobileNumber, MOBILE_NUMBER_LENGTH),
            });
        } catch (error) {
            await handleApiError(error as ApiError, (error) => {
                if (isOtpMobileInUse(error) || isInvalidResponsibleGamingRegistrationMobileNumber(error)) {
                    form.setError("mobileNumber", {
                        message: intl.formatMessage({ id: "error_mobile_number_in_use" })
                    });
                    return ERROR_HANDLER_FLOW.STOP;
                }

                if (isInvalidResponsibleGamingRegistrationUserAge(error)) {
                    form.setError("birth", {
                        message: intl.formatMessage({ id: "required_error_age_under_21" })
                    });

                    form.setError("birth.yearOfBirth", {});
                    form.setError("birth.monthOfBirth", {});
                    form.setError("birth.dayOfBirth", {});
                    return ERROR_HANDLER_FLOW.STOP;
                }

                return ERROR_HANDLER_FLOW.COUNTINUE;
            });
        }
    });

    return <FantasyScrollingPopup
        open={showRegistration}
        background={POPUP_BACKGROUND.GREY_100}
        hasHeaderIcon={true}
        title={<Header onClose={handleSubmit} />}
        body={<ScrollingPopupBody className="tw-flex tw-flex-col tw-gap-4 tw-px-6 tw-pb-3">
            <div
                className="tw-bg-clip-text tw-bg-gradient-to-r tw-from-secondary-500 tw-to-primary-600 tw-text-transparent tw-text-subtitle-1">
                <BoldText
                    text={intl.formatMessage({ id: "responsible_gaming_dialog_description" })}
                    weight={BOLD_WEIGHT.EXTRA_BOLD}
                />
            </div>
            <Form form={form} />
        </ScrollingPopupBody>}
        footer={<Footer onSubmit={handleSubmit} />}
        onOpenChange={() => {
        }}
    />;
};

export default () => {
    return <FeatureConfigPopupWrapper getFeature={(config) => config.kyc}>
        <ResponsibleGamingRegistrationPopup />
    </FeatureConfigPopupWrapper>;
};
