import { useSetting } from "hooks/useSetting";

export const useWithdrawSettings = () => {
    const { settingState } = useSetting();

    const { transactionFee, minimumWithdrawAmount } = settingState.withdraw;

    return {
        transactionFee: transactionFee,
        minimumWithdrawAmount: minimumWithdrawAmount,
    };
};
