import { contestRubyNormalIcon } from "helpers/IconHelper";
import { Col, Container } from "react-bootstrap";
import styled from "styled-components";
import { APP_MAX_WIDTH } from "styles/Constants";

export const AddDepositContainer = styled(Container).attrs({})`
    padding: 0 1.6rem;

    @media (min-width: ${APP_MAX_WIDTH}) {
        padding: 0 3.2rem;
    }
`;

export const AmountOptionCol = styled(Col).attrs({
    className: "position-relative col-12"
})`
    margin: .6rem 0;
`;

export const AmountOption = styled.div.attrs({
    className: "d-flex justify-content-between align-items-center "
})`
    border-width: 1px;
    border-style: solid;
    border-radius: .8rem;
    padding: .8rem 1.7rem;
    cursor: pointer;

    @media (min-width: ${APP_MAX_WIDTH}) {
        padding: 1.2rem 2.4rem;
    }
`;


export const FirstDepositRubyWrapper = styled.div.attrs({
    className: "w-100 d-flex align-items-center fz-sf-regular-footnote text-sys-danger-1"
})`
    text-indent: .4rem;
    gap: .2rem;
`;
export const FirstDepositBonusIcon = styled.div`
    width: 1rem;
    padding-bottom: 1rem;
    background-image: url(${contestRubyNormalIcon});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center center;
`;


