import * as DialogPrimitive from "@radix-ui/react-dialog";
import { FocusOutsideEvent } from "@radix-ui/react-dismissable-layer";
import { cn } from "helpers/cn";
import * as React from "react";
import { ReactNode, useEffect, useRef, useState } from "react";


const Dialog = DialogPrimitive.Root;

const DialogTrigger = DialogPrimitive.Trigger;

const DialogPortal = DialogPrimitive.Portal;

const DialogClose = DialogPrimitive.Close;

const DialogOverlay = React.forwardRef<
    React.ElementRef<typeof DialogPrimitive.Overlay>,
    React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
    <DialogPrimitive.Overlay
        ref={ref}
        className={cn(
            "tw-absolute tw-inset-0 tw-z-50 tw-bg-[#000000b3]",
            "tw-backdrop-default-animate-in-out",
            className,
        )}
        {...props}
    />
));
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;

type DialogContentProps = React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content> & {
    onHide?: () => void,
    offset?: boolean,
    overlaySection?: ReactNode,
}

type PopupChildren = { overlay: Element, content: Element };

export type PopupRef = {
    ref: React.RefObject<HTMLDivElement>,
    children: PopupChildren | null,
};

export const usePopupRef: () => PopupRef = () => {
    const ref = useRef<HTMLDivElement>(null);
    const [children, setChildren] = useState<PopupChildren | null>(null);

    useEffect(() => {
        if (ref.current === null) {
            return;
        }

        const children = Array.from(ref.current.children);

        const overlayElement = children.find((element) => element.classList.contains("dialog-overlay"));
        const contentElement = children.find((element) => element.classList.contains("dialog-content"));

        if (!overlayElement || !contentElement) {
            return;
        }

        setChildren({
            overlay: overlayElement,
            content: contentElement,
        });
    }, [ref.current]);

    return { ref, children };
};

const DialogContent = React.forwardRef<
    React.ElementRef<typeof DialogPrimitive.Content>,
    DialogContentProps
>(({
       className,
       children,
       onHide,
       offset = false,
       overlaySection,
       ...props
   }, ref) => {
    return (
        <DialogPortal>
            <div className={cn(
                "tw-fixed tw-max-w-app-max tw-w-full tw-h-full tw-z-[9999] tw-left-0 tw-top-0",
                { "double-app-max:tw-left-[550px]": offset })}
                 ref={ref}
            >
                <DialogOverlay className="dialog-overlay" onClick={onHide} />
                <DialogPrimitive.Content
                    className={cn(
                        "dialog-content",
                        "tw-absolute tw-rounded-2xl tw-left-[50%] tw-top-[50%] tw-z-50 tw-bg-transparent tw-grid tw-w-full tw-max-w-app-max tw-translate-x-[-50%] tw-translate-y-[-50%] tw-gap-4 tw-outline-0 sm:tw-rounded-lg",
                        "tw-popup-default-animate-in-out",
                        className,
                    )}
                    onOpenAutoFocus={(event) => event.preventDefault()}
                    onCloseAutoFocus={(event) => event.preventDefault()}
                    onPointerDownOutside={(event: FocusOutsideEvent) => event.preventDefault()}
                    {...props}
                >
                    {children}
                    {/*<DialogPrimitive.Close*/}
                    {/*    className="tw-absolute tw-right-4 tw-top-4 tw-rounded-sm tw-opacity-70 tw-ring-offset-background tw-transition-opacity hover:tw-opacity-100 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-ring focus:tw-ring-offset-2 disabled:tw-pointer-events-none data-[state=open]:tw-bg-accent data-[state=open]:tw-text-muted-foreground">*/}
                    {/*    <X className="tw-h-4 tw-w-4" />*/}
                    {/*    <span className="tw-sr-only">Close</span>*/}
                    {/*</DialogPrimitive.Close>*/}
                </DialogPrimitive.Content>
                {overlaySection}
            </div>
        </DialogPortal>
    );
});
DialogContent.displayName = DialogPrimitive.Content.displayName;

const DialogHeader = ({
                          className,
                          ...props
                      }: React.HTMLAttributes<HTMLDivElement>) => (
    <div
        className={cn(
            "tw-flex tw-flex-col tw-space-y-1.5 tw-text-center sm:tw-text-left",
            className,
        )}
        {...props}
    />
);
DialogHeader.displayName = "DialogHeader";

const DialogFooter = ({
                          className,
                          ...props
                      }: React.HTMLAttributes<HTMLDivElement>) => (
    <div
        className={cn(
            "tw-flex tw-flex-col-reverse sm:tw-flex-row sm:tw-justify-end sm:tw-space-x-2",
            className,
        )}
        {...props}
    />
);
DialogFooter.displayName = "DialogFooter";

const DialogTitle = React.forwardRef<
    React.ElementRef<typeof DialogPrimitive.Title>,
    React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
    <DialogPrimitive.Title
        ref={ref}
        className={cn(
            "tw-text-lg tw-font-semibold tw-leading-none tw-tracking-tight",
            className,
        )}
        {...props}
    />
));
DialogTitle.displayName = DialogPrimitive.Title.displayName;

const DialogDescription = React.forwardRef<
    React.ElementRef<typeof DialogPrimitive.Description>,
    React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => (
    <DialogPrimitive.Description
        ref={ref}
        className={cn("tw-text-sm tw-text-muted-foreground", className)}
        {...props}
    />
));
DialogDescription.displayName = DialogPrimitive.Description.displayName;

export {
    Dialog,
    DialogPortal,
    DialogOverlay,
    DialogClose,
    DialogTrigger,
    DialogContent,
    DialogHeader,
    DialogFooter,
    DialogTitle,
    DialogDescription,
};
