import { AuthModeEnum, PreLoginSettingDto, SiteDto } from "data/dto/prelogin/PreLoginSettingDto";

export class PreLoginSettingVo {
    mode: AuthModeEnum;
    sites: SiteVo[];

    constructor(data: PreLoginSettingDto) {
        this.mode = data.mode;
        this.sites = data.sites && data.sites.map(site => new SiteVo(site));
    }

    get isPagcor() {
        return this.mode === AuthModeEnum.PAGCOR;
    }

    get isNormal() {
        // TODO, for login and sign up developing
        if (window.FANTASY_GAME_CONFIG().devToggle && localStorage.getItem("auth-mode-toggle")) {
            return true;
        }
        return this.mode === AuthModeEnum.NORMAL;
    }
}


class SiteVo {
    id: number;
    name: string;

    constructor(site: SiteDto) {
        this.id = site.id;
        this.name = site.name;
    }
}
