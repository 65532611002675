import { fetchMyApInfo } from "apis/ap/ActivityPointApi";
import { ReferralCodeInput } from "components/dialog/component/ReferralCodeInput";
import { FANTASY_DIALOG_VARIANT, FantasyDialogProps } from "components/dialog/FantasyDialog";
import CommonImageSection from "components/dialog/imageSection/CommonImageSection";
import AwardCoupon from "components/dialog/imageSection/redemption/AwardCoupon";
import AwardDeposit from "components/dialog/imageSection/redemption/AwardDeposit";
import AwardRuby from "components/dialog/imageSection/redemption/AwardRuby";
import InsufficientAp from "components/dialog/imageSection/redemption/InsufficientAp";
import NoAward from "components/dialog/imageSection/redemption/NoAward";
import { FantasyPopupProps } from "components/popup/FantasyBasicPopup";
import { BUTTON_PATTERN, BUTTON_SIZE, BUTTON_VARIANTS, NEW_BUTTON_VARIANTS } from "designToken/button/types";
import { DIALOG_SIZE } from "designToken/dialog/Dialog";
import { apLevelConfig } from "domain/ap/ActivityPointHelper";
import { analyticsEvent, logCustomEvent } from "ga";
import { getCurrentAp, getRequiredDeposit } from "helpers/ApLevelHelper";
import { authResetPasswordEmailImg, meMenuReferralInvitedImg, presentCoupon, presentRuby } from "helpers/IconHelper";
import { multiplyByOneHundred } from "helpers/number/NumberHelper";
import useCurrency from "hooks/useCurrency/useCurrency";
import useFantasyHistory from "hooks/useFantasyHistory";
import { capitalize } from "lodash";
import React from "react";
import { useIntl } from "react-intl";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";
import { AP_LEVEL } from "types/activityPoint/ApLevel";

import { WALLET_TYPE } from "data/dto/wallet/walletHistoryDto";
import { useSetting } from "hooks/useSetting";

interface FantasyDialogPropsWithoutShow extends Omit<FantasyDialogProps, "show"> {
}

export interface FantasyPopupPropsWithoutShow extends Omit<FantasyPopupProps, "show"> {
}

const useDialogProps = () => {
    const intl = useIntl();
    const history = useFantasyHistory();
    const { settingState: { referrer: { refereeRuby } } } = useSetting();
    const { currentCurrencyObject: { toFormattedNumber } } = useCurrency();

    const redeemedDialogProps = (onCancel: () => void): FantasyDialogPropsWithoutShow => ({
        title: intl.formatMessage({ id: "redemption_panel_error_title_oops" }),
        text: intl.formatMessage({ id: "redemption_panel_error_code_redeemed" }),
        confirmButton: false,
        cancelButtonText: intl.formatMessage({ id: "label_cancel" }),
        onCancel
    });

    const noQuotaProps = (onCancel: () => void): FantasyDialogPropsWithoutShow => ({
        imageSection: <NoAward />,
        title: intl.formatMessage({ id: "redemption_panel_error_title_missing_by_step" }),
        text: intl.formatMessage({ id: "redemption_panel_error_no_quota" }),
        confirmButton: false,
        cancelButtonText: intl.formatMessage({ id: "label_cancel" }),
        onCancel
    });

    const insufficientApDialogProps = (onCancel: () => void,
                                       currentLevel: AP_LEVEL,
                                       minLevel: AP_LEVEL,
                                       showConfirmButton: boolean = true
    ): FantasyDialogPropsWithoutShow => ({
        variant: FANTASY_DIALOG_VARIANT.FANTASY_BASIC_POPUP,
        imageSection: <InsufficientAp />,
        title: intl.formatMessage({ id: "redemption_panel_error_title_insufficient_ap" }),
        text: intl.formatMessage({ id: "redemption_panel_error_insufficient_ap" }, {
            currentLevel: apLevelConfig[currentLevel].text(),
            minLevel: apLevelConfig[minLevel].text(),
        }),
        confirmButtonText: intl.formatMessage({ id: "redemption_panel_error_insufficient_ap_upgrade_button" }),
        confirmButtonVariant: BUTTON_VARIANTS.tertiary,
        cancelButtonText: intl.formatMessage({ id: "label_cancel" }),
        cancelButtonClassName: "border-0",
        confirmButton: showConfirmButton,
        onConfirm: async () => {
            try {
                const myApInfo = await fetchMyApInfo();
                const upgrade = myApInfo.upgrades.find(upgrade => upgrade.level === minLevel)!;
                history.replace(ROUTER_PATH_CONFIG.deposit.select, {
                    addDepositFlowFinishedGoTo: history.location.pathname,
                    requiredDepositCent: multiplyByOneHundred(getRequiredDeposit(getCurrentAp(myApInfo), upgrade.minAp))
                });
            } catch (e) {
                history.replace(ROUTER_PATH_CONFIG.deposit.select, {
                    addDepositFlowFinishedGoTo: history.location.pathname
                });
            }
        },
        onCancel
    });

    const noAwardDialogProps = (onCancel: () => void): FantasyDialogPropsWithoutShow => ({
        imageSection: <NoAward />,
        title: intl.formatMessage({ id: "redemption_panel_error_title_oops" }),
        text: intl.formatMessage({ id: "redemption_panel_no_reward_result" }),
        confirmButton: false,
        cancelButtonText: intl.formatMessage({ id: "label_cancel" }),
        onCancel
    });

    const rubyDialogProps = (onCancel: () => void, ruby: number): FantasyDialogPropsWithoutShow => ({
        imageSection: <AwardRuby />,
        title: intl.formatMessage({ id: "redemption_panel_ruby_result" }, { ruby }),
        confirmButtonText: intl.formatMessage({ id: "button_view_more" }),
        cancelButtonText: intl.formatMessage({ id: "label_cancel" }),
        cancelButtonClassName: "border-0",
        onConfirm: () => {
            history.push(ROUTER_PATH_CONFIG.wallet, {
                category: WALLET_TYPE.RUBY,
                navbarBackGoTo: history.getState("pathname")
            });
        },
        onCancel
    });

    const couponDialogProps = (onCancel: () => void, discountPercentage: number): FantasyDialogPropsWithoutShow => ({
        imageSection: <AwardCoupon />,
        title: intl.formatMessage({ id: "redemption_panel_coupon_result" }, { discountPercentage }),
        confirmButtonText: intl.formatMessage({ id: "button_view_more" }),
        cancelButtonText: intl.formatMessage({ id: "label_cancel" }),
        cancelButtonClassName: "border-0",
        onConfirm: () => {
            history.push(ROUTER_PATH_CONFIG.coupon);
        },
        onCancel
    });

    const newUserCouponDialogProps = (onClose: () => void): FantasyDialogPropsWithoutShow => ({
        imageSection: <AwardCoupon />,
        title: intl.formatMessage({ id: "new_user_dialog_title" }),
        text: intl.formatMessage({ id: "redemption_panel_coupon_result" }, { discountPercentage: 20 }),
        confirmButtonText: intl.formatMessage({ id: "welcome_page_button_earn_from_tutorial" }),
        cancelButtonText: intl.formatMessage({ id: "label_cancel" }),
        cancelButtonClassName: "border-0",
        onConfirm: () => {
            logCustomEvent(analyticsEvent.webRegisterGiftTake);
            onClose();
            history.push(ROUTER_PATH_CONFIG.task.index);
        },
        onCancel: () => {
            logCustomEvent(analyticsEvent.webRegisterGiftCancel);
            onClose();
        },
        onHide: onClose,
    });

    const newUserCouponPopupProps = (discountPercentage: number, onClose: () => void): FantasyPopupPropsWithoutShow => ({
        imageSection: <img src={presentCoupon} alt="gift-coupon" className="tw-max-w-[85%]" />,
        title: intl.formatMessage({ id: "new_user_coupon_dialog_title" }),
        texts: [
            {
                text: intl.formatMessage({ id: "new_user_coupon_dialog_description" }, { discountPercentage }),
            }
        ],
        buttonSetting: {
            confirmButtonVariant: NEW_BUTTON_VARIANTS.tertiaryLight,
            cancelButtonVariant: NEW_BUTTON_VARIANTS.text,
            confirmButtonText: intl.formatMessage({ id: "new_user_coupon_dialog_confirm_btn" }),
            cancelButtonText: intl.formatMessage({ id: "new_user_coupon_dialog_cancel_btn" }),
            onConfirm: () => {
                logCustomEvent(analyticsEvent.webRegisterGiftTake);
                onClose();
                history.push(ROUTER_PATH_CONFIG.task.index);
            },
            onCancel: () => {
                logCustomEvent(analyticsEvent.webRegisterGiftCancel);
                onClose();
            }
        },
        onHide: onClose,
    });

    const newUserRubyPopupProps = (ruby: number, onClose: () => void): FantasyPopupPropsWithoutShow => ({
        imageSection: <img src={presentRuby} alt="gift-ruby" className="tw-max-w-[85%]" />,
        title: intl.formatMessage({ id: "new_user_ruby_popup_title" }),
        texts: [
            {
                text: intl.formatMessage({ id: "new_user_ruby_dialog_description" }, { ruby }),
            },
        ],
        buttonSetting: {
            confirmButtonVariant: NEW_BUTTON_VARIANTS.tertiaryLight,
            confirmButtonText: intl.formatMessage({ id: "new_user_ruby_dialog_confirm_btn" }),
            cancelButton: false,
            onConfirm: onClose,
            onCancel: onClose
        },
        onHide: onClose,
    });

    const depositDialogProps = (onCancel: () => void, deposit: number): FantasyDialogPropsWithoutShow => ({
        imageSection: <AwardDeposit />,
        title: intl.formatMessage({ id: "redemption_panel_deposit_result" }, { deposit }),
        confirmButtonText: intl.formatMessage({ id: "button_view_more" }),
        cancelButtonText: intl.formatMessage({ id: "label_cancel" }),
        cancelButtonClassName: "border-0",
        onConfirm: () => {
            history.push(ROUTER_PATH_CONFIG.wallet, {
                category: WALLET_TYPE.COIN,
                navbarBackGoTo: history.getState("pathname")
            });
        },
        onCancel
    });

    const authResetPasswordDialogProps: FantasyDialogPropsWithoutShow = {
        imageSection: <CommonImageSection src={authResetPasswordEmailImg} />,
        title: intl.formatMessage({ id: "send_reset_password_dialog_title" }),
        text: intl.formatMessage({ id: "send_reset_password_dialog_description" }),
        confirmButtonText: intl.formatMessage({ id: "send_reset_password_dialog_back_to_login" }),
        onConfirm: () => {
            history.replace(ROUTER_PATH_CONFIG.auth.login);
        },
        cancelButton: false
    };

    const meMenuEnterReferralCodeDialogProps = (onCancel: () => void): FantasyDialogPropsWithoutShow => ({
        imageSection: <CommonImageSection src={meMenuReferralInvitedImg} />,
        title: capitalize(intl.formatMessage({ id: "add_referral_page_title" })),
        text: intl.formatMessage({ id: "enter_referral_code_label" }, { rubies: toFormattedNumber(refereeRuby) }),
        textClassName: "mb-4",
        buttonPattern: BUTTON_PATTERN.rounded,
        buttonSize: BUTTON_SIZE.lg,
        confirmButton: false,
        cancelButtonText: intl.formatMessage({ id: "add_referral_page_button_no_referral_code" }),
        cancelButtonClassName: "border-0",
        bodyComponent: <ReferralCodeInput
            buttonPattern={BUTTON_PATTERN.rounded}
            buttonText={intl.formatMessage({ id: "add_referral_page_button_enter_code" })}
            onCancel={onCancel}
        />,
        onCancel,
        size: DIALOG_SIZE.LG
    });

    return {
        redeemedDialogProps,
        noQuotaProps,
        insufficientApDialogProps,
        noAwardDialogProps,
        rubyDialogProps,
        couponDialogProps,
        depositDialogProps,
        newUserCouponDialogProps,
        newUserCouponPopupProps,
        newUserRubyPopupProps,
        authResetPasswordDialogProps,
        meMenuEnterReferralCodeDialogProps,
    };
};


export default useDialogProps;
