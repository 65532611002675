import { Callback } from "Common";
import { useNineCatContext } from "domain/slate/providers/NineCatProvider";
import React, { useEffect } from "react";
import { SPORT } from "../../../types/sports/Sport";
import { useConfirmationDialog } from "../../contest/components/ConfirmationDialog/useConfirmationDialog";
import { NineCatContestVo } from "../data/ComponentTypes";
import { useSubmitJoinContest } from "../hooks/useSubmitJoinContest";
import { JoinNineCatContestConfirmationDialog } from "./JoinNineCatContestConfirmationDialog";

export interface JoinNineCatContestConfirmationDialogContainerProps {
    contest: NineCatContestVo;
    selectedTeamIds: number[];
    onResetAfterJoined: Callback;
}

const JoinNineCatContestConfirmationDialogContainer: React.FC<JoinNineCatContestConfirmationDialogContainerProps> = (props) => {
    const {
        contest,
        selectedTeamIds,
        onResetAfterJoined
    } = props;

    const {
        myTeams,
    } = useNineCatContext();

    const {
        handleSubmitJoin,
        handleSubmitJoinRubyContest
    } = useSubmitJoinContest({
        contest,
        selectedTeamIds,
        onResetAfterJoined: onResetAfterJoined,
    });

    const {
        isDepositConfirmationModalOpened,
        isRubyConfirmationModalOpened,
        props: confirmationDialogProps,
        openConfirmation: handleOpenConfirmationDialog,
    } = useConfirmationDialog({
        sport: SPORT.BASKETBALL,
        contest,
        myTeams,
        onSubmit: handleSubmitJoin,
        selectedTeamIds: selectedTeamIds,
        autoOpenConfirmation: false,
    });

    useEffect(() => {
        handleOpenConfirmationDialog();
    }, [contest]);

    return <JoinNineCatContestConfirmationDialog
        depositConfirmationModalOpened={isDepositConfirmationModalOpened}
        rubyConfirmationModalOpened={isRubyConfirmationModalOpened}
        contest={contest}
        confirmationDialogProps={confirmationDialogProps}
        onJoinRubyContest={handleSubmitJoinRubyContest} />;
};

export default JoinNineCatContestConfirmationDialogContainer;
