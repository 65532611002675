import { TaskVo } from "data/vo/task/TaskVo";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import SwiperCore from "swiper";
import AppDownloadDialog from "../../../../components/appDownloadDialog/AppDownloadDialog";
import Carousel from "../../../../components/carousel";
import { useTaskProvider } from "../../TaskProvider";
import { DailyTaskCard } from "./DailyTaskCard";
import { CarouselWrapper } from "./DailyTaskStyle";
import { SwiperSlide } from "swiper/react";
import { DailyCheckInTaskCardWithPopup } from "./DailyCheckInTaskCard";
import { useSetting } from "hooks/useSetting";

export const DailyTasks = () => {
    const { settingState: { apLevels } } = useSetting();

    const {
        tasks,
        userTaskProgress,
        currentCarouselIndex,
        currentLevelIndex,
        appDownloadDialogShow,
        handleAppDownloadDialogClose,
    } = useTaskProvider();
    const [swiper, setSwiper] = useState<SwiperCore | null>(null);
    const intl = useIntl();

    useEffect(() => {
        if (swiper) {
            swiper.slideTo(currentCarouselIndex);
        }
    }, [currentCarouselIndex, swiper]);

    if (!tasks || !userTaskProgress) {
        return null;
    }

    const sortedTask = TaskVo.sortTask(tasks, userTaskProgress, (id: string) => intl.formatMessage({ id }));

    const handelSwiperInit = (swiper: SwiperCore) => setSwiper(swiper);

    return <>
        <CarouselWrapper>
            <Carousel
                initialSlide={currentLevelIndex}
                allowTouchMove={false}
                slidesPerView={1}
                centeredSlides
                onInit={handelSwiperInit}
            >
                {
                    apLevels.map((apLevel, index) => (
                        <SwiperSlide key={index}>
                            <DailyCheckInTaskCardWithPopup apLevel={apLevel.level} />
                            {
                                sortedTask.map((task, index) =>
                                    <DailyTaskCard key={index} task={task} apLevel={apLevel.level} />,
                                )
                            }
                        </SwiperSlide>
                    ))
                }
            </Carousel>
        </CarouselWrapper>
        <AppDownloadDialog
            show={appDownloadDialogShow}
            onHide={handleAppDownloadDialogClose}
        />
    </>;

};

