import { KycRepository } from "data/repository/kyc/KycRepository";
import { ERROR_CODE } from "helpers/ErrorHelper";
import useFantasyCommand from "hooks/useFantasyCommand";
import React, { useCallback, useMemo, useState } from "react";
import { useResponsibleGamingRegistration } from "hooks/UseResponsibleGamingRegistration";

export enum KYC_OTP_STEP {
    MOBILE_NUMBER,
    OTP_CODE,
}

export interface SendOtpRequest {
    countryCode: string;
    mobileNumber: string;
}

export interface VerifyOtpRequest {
    code: string;
}

interface KycOtpContextState {
    currentStep: KYC_OTP_STEP;
    error: {
        isOtpMobileNumberInUse: boolean;
        isOtpSendExceedIntervalLimit: boolean;
        isOtpSendExceedDailyLimit: boolean;
        isOtpCodeExpired: boolean;
        isOtpVerifyFailed: boolean;
        isOtpVerifyExceedAttemptLimit: boolean;
    }
    sendOtp: () => Promise<void>;
    verifyOtp: (data: VerifyOtpRequest) => Promise<void>;
    toVerifyOtp: () => void;
    toEditMobileNumber: () => void;
    setOtpSendError: (error: ERROR_CODE | null) => void;
    setOtpVerifyError: (error: ERROR_CODE | null) => void;
    responsibleGamingRegistration?: { phoneNumber: string, countryCode: string};
}

const KycOtpContext = React.createContext<KycOtpContextState | null>(null);


interface KycOtpProviderProps {
    children: React.ReactNode;
}

const kycRepository = new KycRepository();

export const KycOtpProvider: React.FC<KycOtpProviderProps> = ({ children }) => {
    const { responsibleGamingRegistration } = useResponsibleGamingRegistration();

    const [currentStep, setCurrentStep] = useState<KYC_OTP_STEP>(KYC_OTP_STEP.MOBILE_NUMBER);
    const [otpSendError, setOtpSendError] = useState<ERROR_CODE | null>(null);
    const [otpVerifyError, setOtpVerifyError] = useState<ERROR_CODE | null>(null);

    const error = useMemo(() => ({
        isOtpMobileNumberInUse: otpSendError === ERROR_CODE.OTP_MOBILE_NUMBER_IN_USE,
        isOtpSendExceedIntervalLimit: otpSendError === ERROR_CODE.OTP_SEND_EXCEED_INTERVAL_LIMIT,
        isOtpSendExceedDailyLimit: otpSendError === ERROR_CODE.OTP_SEND_EXCEED_DAILY_LIMIT,
        isOtpCodeExpired: otpVerifyError === ERROR_CODE.OTP_CODE_EXPIRED,
        isOtpVerifyFailed: otpVerifyError === ERROR_CODE.OTP_VERIFY_FAILED,
        isOtpVerifyExceedAttemptLimit: otpVerifyError === ERROR_CODE.OTP_VERIFY_EXCEED_ATTEMPT_LIMIT
    }), [otpSendError, otpVerifyError]);


    const sendOtp = useFantasyCommand<void>(() => kycRepository.sendOtp(), {
        errorHandle: false
    });

    const verifyOtp = useFantasyCommand<VerifyOtpRequest>(({ code }) => kycRepository.verifyOtp(code), {
        errorHandle: false
    });

    const resetError = () => {
        setOtpSendError(null);
        setOtpVerifyError(null);
    };

    const toVerifyOtp = useCallback(() => {
        setCurrentStep(KYC_OTP_STEP.OTP_CODE);
        resetError();
    }, []);

    const toEditMobileNumber = useCallback(() => {
        setCurrentStep(KYC_OTP_STEP.MOBILE_NUMBER);
        resetError();
    }, []);

    const value = useMemo(() => ({
        currentStep,
        error,
        sendOtp: sendOtp?.mutateAsync,
        verifyOtp: verifyOtp?.mutateAsync,
        toVerifyOtp,
        toEditMobileNumber,
        setOtpSendError,
        setOtpVerifyError,
        responsibleGamingRegistration
    }), [
        currentStep,
        error,
        sendOtp?.mutateAsync,
        verifyOtp?.mutateAsync,
        toVerifyOtp,
        toEditMobileNumber,
        responsibleGamingRegistration
    ]);

    return <KycOtpContext.Provider value={value}>
        {children}
    </KycOtpContext.Provider>;
};

export const useKycOtp = () => {
    const context = React.useContext(KycOtpContext);
    if (context === null) {
        throw new Error("useKycOtp must be used within a KycOtpProvider");
    }
    return context;
};
