import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, OAuthProvider, signInWithPopup, signInWithRedirect } from "firebase/auth";


const initFirebaseAuth = () => window.FANTASY_GAME_CONFIG().firebase ? getAuth(initializeApp(window.FANTASY_GAME_CONFIG().firebase)) : undefined;

const auth = initFirebaseAuth()!;

const getGoogleProvider = () => {
    const googleProvider = new GoogleAuthProvider();
    googleProvider.addScope("email");
    googleProvider.setCustomParameters({
        prompt: "select_account"
    });
    return googleProvider;
};

function checkIsLocalhost() {
    return window.location.hostname.indexOf("localhost") !== -1;
}

const signInWithGoogle = async () => {
    if (checkIsLocalhost()) {
        return signInWithPopup(auth, getGoogleProvider());
    }

    return signInWithRedirect(auth, getGoogleProvider());
};


const getAppleIdProvider = () => {
    const appleIdProvider = new OAuthProvider("apple.com");
    appleIdProvider.addScope("email");

    return appleIdProvider;
};

const signInWithApple = async () => {
    if (checkIsLocalhost()) {
        return signInWithPopup(auth, getAppleIdProvider());
    }

    return signInWithRedirect(auth, getAppleIdProvider());
};

export {
    auth,
    signInWithGoogle,
    signInWithApple
};
