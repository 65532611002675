import { analyticsEvent, logCustomEvent } from "ga";
import { getFeatureContestCardBg, Icon } from "helpers/IconHelper";
import { isMatchKickoff } from "helpers/match/MatchHelper";
import useFantasyHistory from "hooks/useFantasyHistory";
import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { getContestDetailPageUrl } from "router/RouterUrls";
import Countdown from "../../../../components/countdown/Countdown";
import SquadLogo from "../../../../components/image/SquadLogo";
import AmountDisplay from "../../../../containers/amount/AmountDisplay";
import { getCustomIconSuit } from "../../../../containers/amount/CurrencyIconSuitHelper";
import { FeaturedContestVo } from "../../../../data/vo/contest/FeaturedContestVo";
import { CURRENCY_ICON_SUIT_NAME } from "../../../../store/reducer/config/FantasyGameConfigState";
import { ENTRY_FEE_TYPE_FROM_API } from "../../../../types/contest/Contest";
import { SPORT } from "../../../../types/sports/Sport";
import MatchStatusWithDot from "../../../match/components/status/MatchStatusWithDot";
import { useHomeProvider } from "../../HomeProvider";
import { KickoffCountdown } from "../upcoming/UpcomingMatches";
import { CountdownWrapper, EntryFee, EntryFeeWrapper, FeaturedContestCardWrapper, SquadWrapper } from "./style";
import { useCurrentSport } from "domain/sport/useCurrentSport";

const ShadowColorMap = {
    [SPORT.CRICKET]: "#04055A3D",
    [SPORT.FOOTBALL]: "#004F593D",
    [SPORT.BASKETBALL]: "#6835003D",
};

interface FeaturedContestCardProps {
    featuredContest: FeaturedContestVo;
}

export const FeaturedContestCard: React.FC<FeaturedContestCardProps> = ({ featuredContest }) => {
    const history = useFantasyHistory();
    const { currentSport } = useCurrentSport();
    const { updateFeaturedContests } = useHomeProvider();
    const renderCountdown = useCallback(() => {
        if (isMatchKickoff(featuredContest.matchStatus)) {
            return (
                <>
                    <MatchStatusWithDot matchStatus={featuredContest.matchStatus}
                                        shouldBlink={false} />
                    <KickoffCountdown matchStartsAt={featuredContest.matchStartsAt}
                                      handleTimeout={updateFeaturedContests}
                                      fontStyle="fz-sf-regular-footnote"
                    />
                </>
            );
        }
        return <Countdown finishAt={featuredContest.matchStartsAt} handleTimeout={updateFeaturedContests} />;
    }, [featuredContest, updateFeaturedContests]);

    const onClick = () => {
        logCustomEvent(analyticsEvent.webClickFeaturedContest(currentSport));
        history.push(getContestDetailPageUrl(featuredContest.code));
    };
    return <FeaturedContestCardWrapper onClick={onClick}
                                       $bgUrl={getFeatureContestCardBg(currentSport)}
                                       $shadowColor={ShadowColorMap[currentSport]}
                                       data-testid={`featured-contest-${featuredContest.code}`}
    >
        <EntryFeeWrapper>
            <EntryFee>
                <AmountDisplay
                    currencyIconSuit={getCustomIconSuit(featuredContest.entryFeeType, {
                        [CURRENCY_ICON_SUIT_NAME.DEFAULT]: {
                            [ENTRY_FEE_TYPE_FROM_API.DEPOSIT]: <Icon.Coin size="0.8rem" />,
                            [ENTRY_FEE_TYPE_FROM_API.RUBY]: <Icon.Ruby size="0.8rem" />
                        },
                        [CURRENCY_ICON_SUIT_NAME.PESO]: {
                            [ENTRY_FEE_TYPE_FROM_API.DEPOSIT]: <Icon.Peso size="1.3rem"
                                                                          className="tw-text-warning-800" />,
                            [ENTRY_FEE_TYPE_FROM_API.RUBY]: <Icon.DailyRuby size="1.3rem"
                                                                            className="tw-text-error-600" />
                        }
                    })}
                    className="tw-text-inherit tw-gap-0"
                >
                    {featuredContest.entryFee}
                </AmountDisplay>
            </EntryFee>
            <span className="fz-sf-regular-caption-2">
                <FormattedMessage id="featured_contest_card_entry" />
            </span>
        </EntryFeeWrapper>
        <div className="d-flex flex-column gap-2 align-items-center justify-content-center w-100">
            <SquadWrapper>
                <SquadLogo name={featuredContest.squadAName} url={featuredContest.squadALogoUrl} />
                <p className="text-truncate mb-0">
                    {featuredContest.squadAName}
                </p>
            </SquadWrapper>
            <SquadWrapper>
                <SquadLogo name={featuredContest.squadBName} url={featuredContest.squadBLogoUrl} />
                <p className="text-truncate mb-0">
                    {featuredContest.squadBName}
                </p>
            </SquadWrapper>
            <CountdownWrapper>
                {renderCountdown()}
            </CountdownWrapper>
        </div>
    </FeaturedContestCardWrapper>;
};
