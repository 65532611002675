import FantasyIcon, { Colorize, FantasyIconProps } from "helpers/FantasyIcon";
import React from "react";
import { IconBaseProps } from "react-icons";
import { IoGameController } from "react-icons/io5";
import { LuBan, LuUndo2 } from "react-icons/lu";
import { MdCancel, MdCheckCircle, MdKeyboardArrowRight, MdMail } from "react-icons/md";
import { PiClockFill } from "react-icons/pi";
import { RiUserFill } from "react-icons/ri";
import { SPORT } from "types/sports/Sport";

const resourceUrl = window.FANTASY_GAME_CONFIG().resourceUrl;
const name = window.FANTASY_GAME_CONFIG().name;

const B2CResourceUrl = `${resourceUrl}/game/legend`;
const B2BResourceUrl = `${resourceUrl}/game/power11`;
const DailyResourceUrl = `${resourceUrl}/game/daily`;

// ICON
export const infoIconBlack = "/images/info_icon_black.png";
export const infoIcon = "/images/info_icon.png";
export const closeIconPrimary = "/images/close_icon_primary.png";
export const copyIcon = "/images/copy_icon.png";

export const chevronRightBlackIcon = `${resourceUrl}/game/chevronRight.png`;
export const chevronUpIcon = "/images/chevron_up_icon.png";
export const chevronDownIcon = "/images/chevron_down_icon.png";
export const chevronDownSecondaryIcon =
    "/images/chevron_down_secondary_icon.png";
export const sortUpIcon = "/images/sort_up_icon.png";
export const sortDownIcon = "/images/sort_down_icon.png";
export const mobileVerifiedIcon = "/images/mobileVerified.png";
export const mobileUnverifiedIcon = "/images/mobileUnverified.png";
export const transferIcon = "/images/transfer_icon.png";
export const circleCloseIcon = "/images/circle_close_icon.png";

export const cricketGrayIcon = `${resourceUrl}/game/cricket_gray_icon.png`;
export const cricketWhiteIcon = `${resourceUrl}/game/cricket_white_icon.png`;
export const cricketBlackIcon = `${resourceUrl}/game/cricket_black_icon.png`;
export const footballGrayIcon = `${resourceUrl}/game/football_gray_icon.png`;
export const footballWhiteIcon = `${resourceUrl}/game/football_white_icon.png`;
export const footballBlackIcon = `${resourceUrl}/game/football_black_icon.png`;
export const basketballGrayIcon = `${resourceUrl}/game/basketball_gray_icon.png`;
export const basketballBlackIcon = `${resourceUrl}/game/basketball_black_icon.png`;
export const promotionalCodeIcon = `${resourceUrl}/campaign/redemption_code.svg`;
export const awardCouponIcon = `${resourceUrl}/campaign/award_coupon.png`;
export const awardCoinIcon = `${resourceUrl}/campaign/fantasy_award_coin.png`;
export const awardRubyIcon = `${resourceUrl}/campaign/award_ruby.png`;
export const awardNoneIcon = `${resourceUrl}/campaign/award_none.png`;
export const insufficientApIcon = `${resourceUrl}/campaign/insufficient_ap.png`;
export const awardBackground = `${resourceUrl}/campaign/ribbon.svg`;
export const awardCircleBackground = `${resourceUrl}/campaign/circle.png`;
export const navCloseIcon = "/images/nav_close.png";
export const popupPrimaryIcon = "/images/popup_primary_icon.png";
export const popupSuccessIcon = "/images/popup_success_icon.png";
export const popupWarningIcon = "/images/popup_warning_icon.png";
export const popupDangerIcon = "/images/popup_danger_icon.png";
export const googleIcon = "/images/google_icon.png";
export const closeIcon = "/images/close_icon_primary.png";
export const overTenTagsIcon = "/images/over_ten_tags_icon.png";
export const copyButtonActive = "/images/copy_button_icon_active.png";
export const copyButtonDisabled = "/images/copy_button_icon_disabled.png";
export const shareIcon = "/images/share_button_icon.svg";
export const usersIcon = "/images/users_icon.png";
export const unchangedIcon = "/images/unchanged_icon.png";
export const arrowsDownGrowthIcon = "/images/arrows_down_growth_icon.png";
export const arrowsGrowthIcon = "/images/arrows_growth_icon.png";
export const leftArrowIcon = `${resourceUrl}/game/left_arrow_icon.svg`;
export const meMenuReferralInvitedImg = `${resourceUrl}/game/referral_invited.png`;
// wallet
export const storeIcon = "/images/me_store_icon.png";
export const depositIcon = `${resourceUrl}/game/deposit_icon.png`;
export const winningIcon = `${resourceUrl}/game/winning_icon.png`;
export const walletDepositPlusPiggyBankIcon = `${resourceUrl}/game/wallet/wallet_deposit_plus_icon.svg`;
export const editIcon = `${resourceUrl}/game/edit_icon.svg`;
export const b2bAvatar = `${resourceUrl}/game/b2b_avatar.svg`;
export const power11Avatar = `${resourceUrl}/game/power11_avatar.svg`;
export const homeGradientBg = `${resourceUrl}/game/home_gradient_bg.png`;
export const walletDepositMinusPiggyBankIcon = `${resourceUrl}/game/wallet/wallet_deposit_minus_icon.svg`;

export const walletWinningPlusIcon = `${resourceUrl}/game/wallet_winning_plus_icon.svg`;
export const walletWinningMinusIcon = `${resourceUrl}/game/wallet_winning_minus_icon.svg`;
export const walletRubyPlusIcon = `${resourceUrl}/game/wallet_ruby_plus_icon.svg`;
export const walletRubyMinusIcon = `${resourceUrl}/game/wallet_ruby_minus_icon.svg`;
// contest card tag


export const firstIcon = "/images/contest_first_icon.svg";
export const singleEntryIcon = "/images/contest_single_entry_icon.svg";
export const winRateIcon = "/images/contest_win_rate_icon.svg";
export const multipleEntryIcon = "/images/contest_multiple_entry_icon.svg";
export const mobileIcon = "/images/contest_mobile_icon.svg";
export const vipIcon = "/images/contest_vip_icon.svg";
export const oneTeamIcon = "/images/contest_one_team_icon.svg";
export const firstBlackIcon = "/images/contest_first_black_icon.png";
export const singleEntryBlackIcon =
    "/images/contest_single_entry_black_icon.png";
export const winRateBlackIcon = "/images/contest_win_rate_black_icon.png";
export const multipleEntryBlackIcon =
    "/images/contest_multiple_entry_black_icon.png";
export const mobileBlackIcon = "/images/mobile_black_icon.png";
export const vipBlackIcon = "/images/contest_vip_black_icon.png";
export const plusIcon = `${resourceUrl}/game/plus_icon.svg`;
export const refreshIcon = `${resourceUrl}/game/refresh_icon.svg`;
export const arrowLeftIcon = `${resourceUrl}/game/arrow_left_icon.svg`;
export const arrowDownWideNarrowIcon = `${resourceUrl}/game/arrow_down_wide_narrow_icon.svg`;
export const filterEmptyIcon = `${resourceUrl}/game/filter_empty_icon.svg`;
export const filterFilledIcon = `${resourceUrl}/game/filter_filled_icon.svg`;
export const searchIcon = `${resourceUrl}/game/search_icon.svg`;
export const coinWinning = `${resourceUrl}/game/coin_winning.svg`;
export const rewardCoinWinning = `${resourceUrl}/game/fantasy_winning_icon.svg`;
export const winningRounded = `${resourceUrl}/game/winning_rounded.svg`;
export const oneTeamBlackIcon = "/images/contest_one_team_black_icon.png";
export const getFakeUserAvatar = (idx) =>
    `${resourceUrl}/game/fake_user_avatar_${idx}.png`;
// ap
export const apIcon = `${resourceUrl}/game/apLevel/ap_icon.svg`;
export const buyCoinIcon = `${resourceUrl}/game/apLevel/buy_coin_icon.svg`;
export const inviteBlue = `${resourceUrl}/game/invite_blue.svg`;
// country
export const getCountryIcon = (country) => `${resourceUrl}/country/${country.toLowerCase()}.png`;
export const getTeamUnselectedIcon = (number) => `${resourceUrl}/game/team/team_unselected_${number}.png`;
export const teamSelectedIcon = `${resourceUrl}/game/team_selected_icon.png`;

export const DEFAULT_PLAYER_AVATAR_SIZE = 5;
export const playerDefaultAvatar = (index) => `${resourceUrl}/game/default_player_avatar/default_player_avatar_${index}.png`;

export const specialPlayerDefaultAvatar = `${resourceUrl}/game/default_player_avatar/default_special_player_avatar.png`;
// IMAGE
export const emptyTactics = `${resourceUrl}/game/empty_tactics.png`;
export const coinsFew = `${resourceUrl}/game/fantasy_coins_few.png`;
export const coinsMedium = `${resourceUrl}/game/fantasy_coins_medium.png`;
export const coinsLots = `${resourceUrl}/game/fantasy_coins_lots.png`;
export const emptyMatchUpcoming = `${resourceUrl}/game/empty_upcoming_matches_v2.png`;
export const emptyGeneral = `${resourceUrl}/game/empty_general.svg`;
export const noLabelSelected = `${resourceUrl}/game/no_label_selected.svg`;
export const emptyMyContest = `${resourceUrl}/game/empty_upcoming_matches_v2.png`;
export const defaultSquadLogo = "/images/default_squad_logo.png";
export const shortLegendLogo = "/images/short_legend_logo.png";
export const checkEmailImage = "/images/email.png";
export const emptyMyTeam = "/images/empty_private_team.png";
export const crown = `${resourceUrl}/game/crown.png`;
export const winnerTakesAllWinning = `${resourceUrl}/game/winner_takes_all_winning.png`;
export const winnerTakesAllRuby = `${resourceUrl}/game/winner_takes_all_ruby.png`;
export const freeContestImage = "/images/free_contest.png";
// reward
export const defaultRewardImage = "/images/store_no_order.png";
export const noRewardImage = "/images/reward_no_orders.png";
export const googlePlayQRCode = `${resourceUrl}/game/google_play_qrcode_2x.png`;
export const appStoreQRCode = `${resourceUrl}/game/app_store_qrcode_2x.png`;
export const emptyCouponImg = `${resourceUrl}/game/empty_coupon.png?v=1`;
export const systemBackgroundCricket = `${resourceUrl}/game/system_bg_cricket.jpg`;
export const systemBackgroundFootball = `${resourceUrl}/game/system_bg_football.jpg`;
//todo
export const systemBackgroundBasketball = `${resourceUrl}/game/system_bg_basketball.jpg`;

export const checkMarkPrimaryIcon = "/images/check_mark_primary_icon.png";
export const errorWithErrorCodeImg = `${resourceUrl}/game/error_with_error_code.png`;
export const errorWithoutErrorCodeImg = `${resourceUrl}/game/error_without_error_code.png`;
export const contestFullImg = `${resourceUrl}/game/contest_full.webp`;
export const noCouponImg = `${resourceUrl}/game/no_coupon.png`;
export const noCouponWithTwoCouponsImg = `${resourceUrl}/game/no_coupon_with_two_coupons.png`;
export const allSpotsFilledImg = `${resourceUrl}/game/all_spots_filled.png`;
export const goToNextApLevelImg = `${resourceUrl}/game/go_to_next_ap_level.png`;
export const joinNineCatContestSuccessfullyImg = `${resourceUrl}/game/join_nine_cat_contest_successfully.png`;

export const rubyContestChampionImg = `${resourceUrl}/game/champion_win_illustration.png`;

// B2C, B2B different
export const homeIcon = `${B2CResourceUrl}/bottom_bar_home_icon_v2.png`;
export const B2BHomeIcon = `${B2BResourceUrl}/bottom_bar_home_icon.png`;
export const homeIconActive = `${B2CResourceUrl}/bottom_bar_home_icon_active_v2.png`;
export const B2BHomeIconActive = `${B2BResourceUrl}/bottom_bar_home_icon_active.png`;
export const myMatchIcon = `${B2CResourceUrl}/bottom_bar_my_match_icon_v2.png`;
export const B2BMyMatchIcon = `${B2BResourceUrl}/bottom_bar_my_match_icon.png`;
export const myMatchIconActive = `${B2CResourceUrl}/bottom_bar_my_match_icon_active_v2.png`;
export const B2BMyMatchIconActive = `${B2BResourceUrl}/bottom_bar_my_match_icon_active.png`;
export const resultIcon = `${B2CResourceUrl}/bottom_bar_result_icon_v2.png`;
export const B2BResultIcon = `${B2BResourceUrl}/bottom_bar_result_icon.png`;
export const resultIconActive = `${B2CResourceUrl}/bottom_bar_result_icon_active_v2.png`;
export const B2BResultIconActive = `${B2BResourceUrl}/bottom_bar_result_icon_active.png`;
export const meIcon = `${B2CResourceUrl}/bottom_bar_me_icon_v2.png`;
export const meIconActive = `${B2CResourceUrl}/bottom_bar_me_icon_active_v2.png`;
export const chatRoomIcon = `${B2CResourceUrl}/bottom_bar_chatroom_icon_v2.png`;
export const chatRoomIconActive = `${B2CResourceUrl}/bottom_bar_chatroom_icon_active_v2.png`;
export const B2BTransactionIcon = `${B2BResourceUrl}/bottom_bar_transaction.png`;
export const B2BTransactionIconActive = `${B2BResourceUrl}/bottom_bar_transaction_active.png`;

// logout page
export const closeThisTab = `${B2BResourceUrl}/close_this_tab.png`;
export const b2bGameHollowLogo = `${B2BResourceUrl}/B2B_game_hollow_logo.png`;
export const clickToPlay = `${B2BResourceUrl}/click_to_play.png`;


export const logoPrimaryHorizontal = `${resourceUrl}/game/${name}/logo_primary_horizontal.png`;
export const logoTertiaryHorizontal = `${resourceUrl}/game/${name}/logo_tertiary_horizontal.png`;
export const loginBackground = `${B2CResourceUrl}/login_bg.jpg`;
export const addCoinJustAMoment = `${B2CResourceUrl}/add_coin_just_a_moment.png`;
export const addCoinFailed = `${B2CResourceUrl}/add_coin_failed.png`;

export const teamPreviewCricketBg = `${resourceUrl}/game/${name}/field_cricket.png`;
export const teamPreviewFootballBg = `${resourceUrl}/game/${name}/field_football.png`;
export const teamPreviewBasketballBg = `${resourceUrl}/game/${name}/field_basketball.png`;

// contest
export const contestPromotionalIcon = `${resourceUrl}/game/contest/contest_promotional_icon.svg`;
export const contestPrizeHunterIcon = `${resourceUrl}/game/contest/contest_prize_hunter_icon.svg`;
export const contestCoinNormalIcon = `${resourceUrl}/game/contest_coin_normal_icon_fantasy_sports.svg`;
export const contestRubyNormalIcon = `${resourceUrl}/game/contest/contest_ruby_normal_icon.svg`;
export const currencyRubyIcon = `${resourceUrl}/game/currency_icon_ruby.svg`;
export const contestPracticeIcon = `${resourceUrl}/game/contest/contest_practice_icon.svg`;

export const handOneFingerSwipe = `${resourceUrl}/game/hand_one_finger_swipe.png`;

export const walletDepositImg = `${resourceUrl}/game/wallet_deposit.png`;
export const walletTransferImg = `${resourceUrl}/game/wallet_transfer.png`;
export const walletWithdrawImg = `${resourceUrl}/game/wallet_withdraw.svg`;
export const walletWithdrawLockedImg = `${resourceUrl}/game/wallet_withdraw_locked.svg`;
export const walletApLevelImg = `${resourceUrl}/game/wallet_ap_level.png`;
export const walletStoreImg = `${resourceUrl}/game/wallet_store.png`;
export const walletContestCodeImg = `${resourceUrl}/game/wallet_contest_code.png`;
export const welcomeImg = `${resourceUrl}/game/welcome.png`;
export const downloadOnGooglePlayImg = `${resourceUrl}/game/download_on_google_play.png`;
export const downloadOnAppStoreImg = `${resourceUrl}/game/download_on_app_store.png`;
export const depositBuyCoinWithRubiesImg = `${resourceUrl}/game/buy_coin_with_rubies_bonus.png`;
export const depositFirstPurchaseAndBuyCoinWithRubiesImg = `${resourceUrl}/game/buy_coin_with_rubies_bonus_and_first_buy.png`;
export const upgradeToSteelImg = `${resourceUrl}/game/buy_coin_with_upgrade_to_steel.png`;

// profile
export const profileTransferActiveIcon = `${resourceUrl}/game/profile/transfer_active.png`;
export const profileTransferInactiveIcon = `${resourceUrl}/game/profile/transfer_inactive.png`;

// referral
export const referralColorfulSteamersImg = `${resourceUrl}/game/referral_colorful_streamers.svg`;
export const referralRewardImg = `${resourceUrl}/game/referral_reward.png`;
export const referralGlareImg = `${resourceUrl}/game/referral_glare.png`;
export const referralInviteImg = `${resourceUrl}/game/referral_invite.svg`;
export const referralGiftImg = `${resourceUrl}/game/referral_gift.svg`;
export const bitCoinImg = `${resourceUrl}/game/bitcoin_icon.svg`;

export const responsibleGamingGiftImg = `${resourceUrl}/game/responsible_gaming_gift.png`;

// auth
export const authWelcomeImg = `${resourceUrl}/game/welcome-language-setting.png`;
export const authResetPasswordImg = `${resourceUrl}/game/auth_reset_password.png`;
export const authResetPasswordEmailImg = `${resourceUrl}/game/auth_reset_password_email.png`;
export const authSignUpEmailImg = `${resourceUrl}/game/auth_sign_up_email.png`;
export const emptyNewsImg = `${resourceUrl}/game/empty_news.png`;
export const emptyGameLogsImg = `${resourceUrl}/game/empty_game_logs.png`;
export const emptyPerformance = `${resourceUrl}/game/empty_performance.png`;
export const rotoBallerImg = `${resourceUrl}/logo/RotoBaller_logo.png`;
export const sportsDataIoLogoImg = `${resourceUrl}/logo/sportsdataio_logo.png`;
export const geniusSportsLogoImg = `${resourceUrl}/logo/genius_logo.png`;


// kyc
export const kycUnverified = `${resourceUrl}/game/kyc_unverified.png`;
export const kycUnsuccessful = `${resourceUrl}/game/kyc_unsuccessful.png`;
export const kycVerified = `${resourceUrl}/game/kyc_verified.png`;
export const kycCompleteYourKyc = `${resourceUrl}/game/kyc_complete_your_kyc.png`;
export const kycInitiationWithdraw = `${resourceUrl}/game/kyc_initiation_withdraw.png`;
export const kycArrowWithdraw = `${resourceUrl}/game/kyc_arrow_withdraw.png`;
export const kycWithdraw = `${resourceUrl}/game/kyc_withdraw.png`;
export const kycVerificationInProgress = `${resourceUrl}/game/kyc_verification_in_progres.png`;
export const kycVerificationSuccessful = `${resourceUrl}/game/kyc_verification_successful.png`;

export const presentRuby = `${resourceUrl}/game/present_ruby.png`;
export const presentCoupon = `${resourceUrl}/game/present_coupon.png`;
export const pictureWithId = `${resourceUrl}/game/picture_with_id.svg`;

export const entryTutorialStepImg = (index: number) => `${resourceUrl}/game/entry_tutorial_step${index}.png`;

export const getFeatureContestCardBg = (sport: SPORT) => `${resourceUrl}/game/featured_contest_bg_${sport}.png`;
export const slateBasketballBg = `${resourceUrl}/game/slate_bg_basketball.png`;
export const subtractIcon = `${resourceUrl}/game/subtract_icon.svg`;
export const nineCatJoinContestBg = `${resourceUrl}/game/nine_cat_join_contest_bg.webp`;

export const cricketQuickSummarySyncBg = `${resourceUrl}/game/cricket_quick_summary_sync_bg.png`;
const tabFantasySports = `${resourceUrl}/game/tab_fantasy_sports.svg`;
const tabNineCat = `${resourceUrl}/game/tab_9_cat.svg`;
const cricketIcon = `${resourceUrl}/game/cricket_icon.svg`;
const footballIcon = `${resourceUrl}/game/football_icon.svg`;
const basketballIcon = `${resourceUrl}/game/basketball_icon.svg`;
const fireIcon = `${resourceUrl}/game/fire.svg`;
const championIcon = `${resourceUrl}/game/champion_icon.svg`;
const crownIcon = `${resourceUrl}/game/crown_icon.svg`;
const prizeRubyIcon = `${resourceUrl}/game/prize_ruby_icon.svg`;
export const practicePrizeImg = `${resourceUrl}/game/practice_prize.svg`;

export const emptyFantasySportsImg = `${resourceUrl}/game/empty_fantasy_sports_img.png`;
export const emptyNineCatImg = `${resourceUrl}/game/empty_9_cat.png`;
export const leaderboardPlayer = (player: number) => `${resourceUrl}/game/leaderboard_player${player}.png`;
export const howToPlayStep4Video = `${resourceUrl}/game/how_to_play_choose_captain_and_vice_captain.mp4`;

export const howToPlayBgBasketballStand = `${resourceUrl}/game/how_to_play_bg_basketball_stand.png`;
export const howToPlayBgFootballField = `${resourceUrl}/game/how_to_play_gb_football_field.png`;
export const howToPlayBgCricketPlayer = `${resourceUrl}/game/how_to_play_bg_cricket_player.png`;

export const howToPlayStep1 = `${resourceUrl}/game/how_to_play_step_1.png`;
export const howToPlayStep2 = `${resourceUrl}/game/how_to_play_step_2.png`;
export const howToPlayStep3 = `${resourceUrl}/game/how_to_play_step_3.png`;
export const howToPlayStep5 = `${resourceUrl}/game/how_to_play_step_5.png`;
export const leaderboardBg = `${resourceUrl}/game/leaderboard_not_rank_joinable_bg.png`;
export const withdrawBalance = `${resourceUrl}/game/withdraw_balance.png`;
export const withdrawSuccessChecked = `${resourceUrl}/game/withdraw_success_checked.svg`;
export const withdrawInProgress = `${resourceUrl}/game/withdraw_in_progress.svg`;
export const withdrawDeclined = `${resourceUrl}/game/withdraw_declined.svg`;

export const infoIconSvg = `${resourceUrl}/game/Info_icon.svg`;

export const buyCoinUnsuccessful = `${resourceUrl}/game/buy_coin_unsuccessful.png`;
export const buyCoins = `${resourceUrl}/game/buy_coins.png`;
export const cannotEdit = `${resourceUrl}/game/cannot_edit.png`;
export const creditCardFilled = `${resourceUrl}/game/credit_card_filled.svg`;
export const watermarkDaily = `${resourceUrl}/game/watermark_daily.png`;
export const watermarkText = `${resourceUrl}/game/watermark_text.png`;
export const pagcorLogo = `${resourceUrl}/game/daily/PAGCOR_LOGO.png`;
export const responsibleGamingLogo = `${resourceUrl}/game/daily/GAME_RESPONSIBLY_KIF.png`;

interface IconProps {
    size?: string;
    src?: string;
    colorize?: Colorize.Overwrite;
}

const withProps = <T extends object>(iconProps: IconProps) => {
    return (Component: React.FC<T>): React.FC<T> => {
        const EnhancedComponent = (props: T) => {
            return <Component  {...iconProps} {...props} />;
        };
        EnhancedComponent.displayName = `withProps(${Component.displayName})`;

        return EnhancedComponent;
    };
};

export type IconOmitProps = Omit<FantasyIconProps, "src" | "colorize">

export namespace Icon {
    export const Ruby = withProps<IconOmitProps>({
        size: "1rem",
        src: contestRubyNormalIcon
    })(FantasyIcon);

    export const CurrencyRuby = withProps<IconOmitProps>({
        size: "1.8rem",
        src: currencyRubyIcon
    })(FantasyIcon);

    export const Coin = withProps<IconOmitProps>({
        size: "1rem",
        src: contestCoinNormalIcon
    })(FantasyIcon);

    export const Peso = withProps<IconOmitProps>({
        size: "1rem",
        src: `${resourceUrl}/game/daily/php.svg`,
        colorize: Colorize.Overwrite
    })(FantasyIcon);

    export const PesoThin = withProps<IconOmitProps>({
        size: "1.2rem",
        src: `${resourceUrl}/game/daily/php_thin_icon.svg`,
        colorize: Colorize.Overwrite
    })(FantasyIcon);

    export const DailyRuby = withProps<IconOmitProps>({
        size: "1rem",
        src: `${resourceUrl}/game/daily/ruby.svg`,
        colorize: Colorize.Overwrite
    })(FantasyIcon);

    export const Winning = withProps<IconOmitProps>({
        size: "1rem",
        src: rewardCoinWinning
    })(FantasyIcon);

    export const StoreCoinExchange = withProps<IconOmitProps>({
        size: "1rem",
        src: `${resourceUrl}/game/fantasy_store_coin_exchange.svg`
    })(FantasyIcon);

    export const CricketWhite = withProps<IconOmitProps>({
        size: "1rem",
        src: cricketWhiteIcon
    })(FantasyIcon);

    export const CricketYellow = withProps<IconOmitProps>({
        size: "1rem",
        src: `${resourceUrl}/game/cricket_yellow_icon.png`
    })(FantasyIcon);

    export const FootballWhite = withProps<IconOmitProps>({
        size: "1rem",
        src: footballWhiteIcon
    })(FantasyIcon);

    export const FootballYellow = withProps<IconOmitProps>({
        size: "1rem",
        src: `${resourceUrl}/game/football_yellow_icon.png`
    })(FantasyIcon);

    export const BasketballWhite = withProps<IconOmitProps>({
        size: "1rem",
        src: `${resourceUrl}/game/basketball_white_icon.png`
    })(FantasyIcon);

    export const BasketballYellow = withProps<IconOmitProps>({
        size: "1rem",
        src: `${resourceUrl}/game/basketball_yellow_icon.png`
    })(FantasyIcon);

    export const Locked = withProps<IconOmitProps>({
        size: "2rem",
        src: `${resourceUrl}/game/locked_icon.svg`,
        colorize: Colorize.Overwrite
    })(FantasyIcon);

    export const Verifying = withProps<IconOmitProps>({
        size: "2rem",
        src: `${resourceUrl}/game/verifying_icon.svg`,
        colorize: Colorize.Overwrite
    })(FantasyIcon);

    export const Checked = withProps<IconOmitProps>({
        size: "2rem",
        src: `${resourceUrl}/game/checked_icon.svg`,
        colorize: Colorize.Overwrite
    })(FantasyIcon);

    export const MoveRight = withProps<IconOmitProps>({
        size: "2rem",
        src: `${resourceUrl}/game/move_right_icon.svg`,
        colorize: Colorize.Overwrite
    })(FantasyIcon);

    export const ArrowRight = withProps<IconBaseProps>({
        size: "1.6rem"
    })(MdKeyboardArrowRight);

    export const Close = withProps<IconOmitProps>({
        size: "2.4rem",
        src: `${resourceUrl}/game/close.svg`,
        colorize: Colorize.Overwrite
    })(FantasyIcon);

    export const CheckCircle = withProps<IconBaseProps>({
        size: "1.6rem"
    })(MdCheckCircle);

    export const Cancel = withProps<IconBaseProps>({
        size: "1.6rem"
    })(MdCancel);

    export const Check = withProps<IconOmitProps>({
        size: "75%",
        src: "/images/check.svg",
        colorize: Colorize.Overwrite
    })(FantasyIcon);

    export const FormMessageError = withProps<IconOmitProps>({
        size: "1.4rem",
        src: `${resourceUrl}/game/form_error_message_icon.svg`
    })(FantasyIcon);

    export const PlayerMvp = withProps<IconOmitProps>({
        size: "9rem",
        src: `${resourceUrl}/game/player_mvp.png`
    })(FantasyIcon);

    export const PlusPiggyBank = withProps<IconOmitProps>({
        size: "2.8rem",
        src: walletDepositPlusPiggyBankIcon
    })(FantasyIcon);

    export const WalletBalance = withProps<IconOmitProps>({
        size: "1.6rem",
        src: `${resourceUrl}/game/wallet/wallet_balance_icon.svg`,
        colorize: Colorize.Overwrite
    })(FantasyIcon);

    export const WalletDetailCoin = withProps<IconOmitProps>({
        size: "1.8rem",
        src: `${resourceUrl}/game/wallet/wallet_deposit_piggy_bank_icon.svg`,
        colorize: Colorize.Overwrite
    })(FantasyIcon);

    export const WalletDetailWinning = withProps<IconOmitProps>({
        size: "1.6rem",
        src: `${resourceUrl}/game/wallet_detail_winning_icon.svg`,
        colorize: Colorize.Overwrite
    })(FantasyIcon);

    export const WalletDetailRuby = withProps<IconOmitProps>({
        size: "1.6rem",
        src: `${resourceUrl}/game/wallet_detail_ruby_icon.svg`,
        colorize: Colorize.Overwrite
    })(FantasyIcon);

    export const DailyLogo = withProps<IconOmitProps>({
        size: "2.8rem",
        src: `${DailyResourceUrl}/logo.svg`
    })(FantasyIcon);

    export const DailyFantasyWatermark = withProps<IconOmitProps>({
        size: "5.6rem",
        src: `${DailyResourceUrl}/daily_fantasy_watermark.svg`
    })(FantasyIcon);

    export const Congrats = withProps<IconOmitProps>({
        size: "2rem",
        src: `${resourceUrl}/game/congrats.svg`,
        colorize: Colorize.Overwrite
    })(FantasyIcon);

    export const MyGames = withProps<IconOmitProps>({
        size: "2.4rem",
        src: `${resourceUrl}/game/bottom_bar_my_game.svg`,
        colorize: Colorize.Overwrite
    })(FantasyIcon);

    export const Result = withProps<IconOmitProps>({
        size: "2.4rem",
        src: `${resourceUrl}/game/bottom_bar_result.svg`,
        colorize: Colorize.Overwrite
    })(FantasyIcon);

    export const Chatroom = withProps<IconOmitProps>({
        size: "2.4rem",
        src: `${resourceUrl}/game/bottom_bar_chatroom.svg`,
        colorize: Colorize.Overwrite
    })(FantasyIcon);

    export const Me = withProps<IconOmitProps>({
        size: "2.4rem",
        src: `${resourceUrl}/game/bottom_bar_me.svg`,
        colorize: Colorize.Overwrite
    })(FantasyIcon);

    export const History = withProps<IconOmitProps>({
        size: "2.4rem",
        src: `${resourceUrl}/game/bottom_bar_history.svg`,
        colorize: Colorize.Overwrite
    })(FantasyIcon);

    export const Edit = withProps<IconOmitProps>({
        size: "2.4rem",
        src: `${resourceUrl}/game/edit_icon.svg`,
        colorize: Colorize.Overwrite
    })(FantasyIcon);

    export const EditFilled = withProps<IconOmitProps>({
        size: "2rem",
        src: `${resourceUrl}/game/edit_filled.svg`,
        colorize: Colorize.Overwrite
    })(FantasyIcon);

    export const Calendar = withProps<IconOmitProps>({
        size: "1.6rem",
        src: `${resourceUrl}/game/calendar.svg`,
        colorize: Colorize.Overwrite
    })(FantasyIcon);

    export const Wallet = withProps<IconOmitProps>({
        size: "2.4rem",
        src: `${resourceUrl}/game/wallet.svg`,
        colorize: Colorize.Overwrite
    })(FantasyIcon);

    export const ArrowMoveRight = withProps<IconOmitProps>({
        size: "2.4rem",
        src: `${resourceUrl}/game/arrow_move_right.svg`,
        colorize: Colorize.Overwrite
    })(FantasyIcon);

    export const ArrowMoveRightGradient = withProps<IconOmitProps>({
        size: "2.4rem",
        src: `${resourceUrl}/game/arrow_move_right_gradient.svg`,
    })(FantasyIcon);

    export const Flame = withProps<IconOmitProps>({
        size: "2.4rem",
        src: `${resourceUrl}/game/bottom_bar_home.svg`,
        colorize: Colorize.Overwrite
    })(FantasyIcon);

    export const EyeOn = withProps<IconOmitProps>({
        size: "2.6rem",
        src: `${resourceUrl}/game/eye_on.svg`,
    })(FantasyIcon);

    export const EyeOff = withProps<IconOmitProps>({
        size: "2.6rem",
        src: `${resourceUrl}/game/eye_off.svg`,
    })(FantasyIcon);

    export const WalletBalancePlus = withProps<IconOmitProps>({
        size: "2.4rem",
        src: `${resourceUrl}/game/wallet/wallet_balance_plus_icon.svg`,
    })(FantasyIcon);

    export const WalletRubyPlus = withProps<IconOmitProps>({
        size: "2.4rem",
        src: walletRubyPlusIcon,
    })(FantasyIcon);

    export const Plus = withProps<IconOmitProps>({
        size: "1.2rem",
        colorize: Colorize.Overwrite,
        src: `${resourceUrl}/game/plus_icon.svg`,
    })(FantasyIcon);

    export const Refresh = withProps<IconOmitProps>({
        size: "1.2rem",
        colorize: Colorize.Overwrite,
        src: `${resourceUrl}/game/refresh_icon.svg`,
    })(FantasyIcon);

    export const ArrowLeft = withProps<IconOmitProps>({
        size: "1.2rem",
        colorize: Colorize.Overwrite,
        src: `${resourceUrl}/game/arrow_left_icon.svg`,
    })(FantasyIcon);

    export const ArrowDownWideNarrowSort = withProps<IconOmitProps>({
        size: "1.2rem",
        colorize: Colorize.Overwrite,
        src: `${resourceUrl}/game/arrow_down_wide_narrow_icon.svg`,
    })(FantasyIcon);

    export const FilterEmpty = withProps<IconOmitProps>({
        size: "1.2rem",
        colorize: Colorize.Overwrite,
        src: `${resourceUrl}/game/filter_empty_icon.svg`,
    })(FantasyIcon);

    export const FilterFilled = withProps<IconOmitProps>({
        size: "1.2rem",
        colorize: Colorize.Overwrite,
        src: `${resourceUrl}/game/filter_filled_icon.svg`,
    })(FantasyIcon);

    export const Search = withProps<IconOmitProps>({
        size: "1.2rem",
        colorize: Colorize.Overwrite,
        src: `${resourceUrl}/game/search_icon.svg`,
    })(FantasyIcon);

    export const TextSearch = withProps<IconOmitProps>({
        size: "2.4rem",
        colorize: Colorize.Overwrite,
        src: `${resourceUrl}/game/text_search.svg`,
    })(FantasyIcon);

    export const Subtract = withProps<IconOmitProps>({
        size: "2.4rem",
        colorize: Colorize.Overwrite,
        src: `${resourceUrl}/game/subtract_icon.svg`,
    })(FantasyIcon);

    export const Loader = withProps<IconOmitProps>({
        size: "2rem",
        src: `${resourceUrl}/game/loader.svg`,
    })(FantasyIcon);

    export const Game = withProps<IconOmitProps>({
        size: "2.4rem",
        src: `${resourceUrl}/game/game.svg`,
        colorize: Colorize.Overwrite,
    })(FantasyIcon);

    export const CustomerService = withProps<IconOmitProps>({
        size: "2rem",
        src: `${resourceUrl}/game/customer_service.svg`,
        colorize: Colorize.Overwrite
    })(FantasyIcon);

    export const HandWithCoin = withProps<IconOmitProps>({
        size: "1rem",
        src: `${resourceUrl}/game/hand_with_coin.svg`,
        colorize: Colorize.Overwrite
    })(FantasyIcon);

    export const BarChart = withProps<IconOmitProps>({
        size: "1.2rem",
        src: `${resourceUrl}/game/bar_chart_icon.svg`,
        colorize: Colorize.Overwrite
    })(FantasyIcon);

    export const ApLevel = withProps<IconOmitProps>({
        size: "1.4rem",
        src: `${resourceUrl}/game/ap_icon.svg`,
        colorize: Colorize.Overwrite
    })(FantasyIcon);

    export const Bonus = withProps<IconOmitProps>({
        size: "1.4rem",
        src: `${resourceUrl}/game/icon_bonus.svg`,
    })(FantasyIcon);

    export const Live = withProps<IconOmitProps>({
        size: "1.4rem",
        src: `${resourceUrl}/game/live_icon.svg`,
        colorize: Colorize.Overwrite
    })(FantasyIcon);

    export const Clock = withProps<IconOmitProps>({
        size: "1.4rem",
        src: `${resourceUrl}/game/clock_icon.svg`,
        colorize: Colorize.Overwrite
    })(FantasyIcon);

    export const FantasySports = withProps<IconOmitProps>({
        size: "1.6rem",
        src: tabFantasySports,
        colorize: Colorize.Overwrite
    })(FantasyIcon);

    export const NineCat = withProps<IconOmitProps>({
        size: "1.6rem",
        src: tabNineCat,
        colorize: Colorize.Overwrite
    })(FantasyIcon);

    export const CricketIcon = withProps<IconOmitProps>({
        size: "1.2rem",
        src: cricketIcon,
        colorize: Colorize.Overwrite
    })(FantasyIcon);

    export const FootballIcon = withProps<IconOmitProps>({
        size: "1.2rem",
        src: footballIcon,
        colorize: Colorize.Overwrite
    })(FantasyIcon);

    export const BasketballIcon = withProps<IconOmitProps>({
        size: "1.2rem",
        src: basketballIcon,
        colorize: Colorize.Overwrite
    })(FantasyIcon);

    export const FireIcon = (size = "1.2rem") => withProps<IconOmitProps>({
        size,
        src: fireIcon,
        colorize: Colorize.Overwrite
    })(FantasyIcon);

    export const Info = withProps<IconOmitProps>({
        size: "1.4rem",
        src: infoIconSvg,
        colorize: Colorize.Overwrite
    })(FantasyIcon);

    export const InfoOutlined = withProps<IconOmitProps>({
        size: "1.4rem",
        src: `${resourceUrl}/game/info_outlined.svg`,
        colorize: Colorize.Overwrite
    })(FantasyIcon);

    export const Coupon = withProps<IconOmitProps>({
        size: "1.8rem",
        src: `${resourceUrl}/game/tailored_icon_coupon.svg`,
        colorize: Colorize.Overwrite
    })(FantasyIcon);

    export const ChampionIcon = withProps<IconOmitProps>({
        size: "1.6rem",
        src: championIcon,
        colorize: Colorize.Overwrite
    })(FantasyIcon);

    export const Swap = withProps<IconOmitProps>({
        size: "2rem",
        src: `${resourceUrl}/game/swap_icon.svg`,
        colorize: Colorize.Overwrite
    })(FantasyIcon);

    export const PrizeCoin = withProps<IconOmitProps>({
        size: "1.6rem",
        src: rewardCoinWinning
    })(FantasyIcon);

    export const PrizeRuby = withProps<IconOmitProps>({
        size: "1.6rem",
        src: prizeRubyIcon
    })(FantasyIcon);

    export const Crown = withProps<IconOmitProps>({
        size: "1.6rem",
        src: crownIcon,
        colorize: Colorize.Overwrite
    })(FantasyIcon);

    export const Link = withProps<IconOmitProps>({
        size: "2rem",
        src: `${resourceUrl}/game/link_icon.svg`,
    })(FantasyIcon);

    export const Chat = withProps<IconOmitProps>({
        size: "2rem",
        src: `${resourceUrl}/game/chat_icon.svg`,
    })(FantasyIcon);

    export const HowToPlayCricket = withProps<IconOmitProps>({
        size: "1.6rem",
        src: `${resourceUrl}/game/how_to_play_cricket_icon.svg`,
        colorize: Colorize.Overwrite
    })(FantasyIcon);

    export const HowToPlayFootball = withProps<IconOmitProps>({
        size: "1.6rem",
        src: `${resourceUrl}/game/how_to_play_soccer_icon.svg`,
        colorize: Colorize.Overwrite
    })(FantasyIcon);

    export const HowToPlayBasketball = withProps<IconOmitProps>({
        size: "1.6rem",
        src: `${resourceUrl}/game/how_to_play_basketball_icon.svg`,
        colorize: Colorize.Overwrite
    })(FantasyIcon);

    export const QuestCoin = withProps<IconOmitProps>({
        size: "1.4rem",
        src: `${resourceUrl}/game/wallet_coin.svg`,
    })(FantasyIcon);

    export const Lock = withProps<IconOmitProps>({
        size: "2.4rem",
        src: `${resourceUrl}/game/lock.svg`,
        colorize: Colorize.Overwrite
    })(FantasyIcon);

    export const KeyHoleLock = withProps<IconOmitProps>({
        size: "2.4rem",
        src: `${resourceUrl}/game/key_hole_lock.svg`,
        colorize: Colorize.Overwrite
    })(FantasyIcon);

    export const QuestPrize = {
        Coin: withProps<IconOmitProps>({
            size: "4.4rem",
            src: `${resourceUrl}/game/quest_reward/coin_low.svg`,
        })(FantasyIcon),
        Ruby: withProps<IconOmitProps>({
            size: "4.4rem",
            src: `${resourceUrl}/game/quest_reward/ruby_low.svg`,
        })(FantasyIcon),
        Regular: {
            CoinHigh: withProps<IconOmitProps>({
                size: "4.4rem",
                src: `${resourceUrl}/game/quest_reward/coin_high.svg`,
            })(FantasyIcon),
            CoinMedium: withProps<IconOmitProps>({
                size: "4.4rem",
                src: `${resourceUrl}/game/quest_reward/coin_medium.svg`,
            })(FantasyIcon),
            CoinLow: withProps<IconOmitProps>({
                size: "4.4rem",
                src: `${resourceUrl}/game/quest_reward/coin_low.svg`,
            })(FantasyIcon),
            RubyHigh: withProps<IconOmitProps>({
                size: "4.4rem",
                src: `${resourceUrl}/game/quest_reward/ruby_high.svg`,
            })(FantasyIcon),
            RubyMedium: withProps<IconOmitProps>({
                size: "4.4rem",
                src: `${resourceUrl}/game/quest_reward/ruby_medium.svg`,
            })(FantasyIcon),
            RubyLow: withProps<IconOmitProps>({
                size: "4.4rem",
                src: `${resourceUrl}/game/quest_reward/ruby_low.svg`,
            })(FantasyIcon),
        },
        Max: {
            CoinHigh: withProps<IconOmitProps>({
                src: `${resourceUrl}/game/quest_reward/coin_high_max.svg`,
            })(FantasyIcon),
            CoinMedium: withProps<IconOmitProps>({
                src: `${resourceUrl}/game/quest_reward/coin_medium_max.svg`,
            })(FantasyIcon),
            CoinLow: withProps<IconOmitProps>({
                src: `${resourceUrl}/game/quest_reward/coin_low_max.svg`,
            })(FantasyIcon),
            RubyHigh: withProps<IconOmitProps>({
                src: `${resourceUrl}/game/quest_reward/ruby_high_max.svg`,
            })(FantasyIcon),
            RubyMedium: withProps<IconOmitProps>({
                src: `${resourceUrl}/game/quest_reward/ruby_medium_max.svg`,
            })(FantasyIcon),
            RubyLow: withProps<IconOmitProps>({
                src: `${resourceUrl}/game/quest_reward/ruby_low_max.svg`,
            })(FantasyIcon),
        },
        Video: {
            CoinHigh: withProps<IconOmitProps>({
                src: `${resourceUrl}/game/quest_reward/coin_high_video.svg`,
            })(FantasyIcon),
            CoinMedium: withProps<IconOmitProps>({
                src: `${resourceUrl}/game/quest_reward/coin_medium_video.svg`,
            })(FantasyIcon),
            CoinLow: withProps<IconOmitProps>({
                src: `${resourceUrl}/game/quest_reward/coin_low_video.svg`,
            })(FantasyIcon),
            RubyHigh: withProps<IconOmitProps>({
                src: `${resourceUrl}/game/quest_reward/ruby_high_video.svg`,
            })(FantasyIcon),
            RubyMedium: withProps<IconOmitProps>({
                src: `${resourceUrl}/game/quest_reward/ruby_medium_video.svg`,
            })(FantasyIcon),
            RubyLow: withProps<IconOmitProps>({
                src: `${resourceUrl}/game/quest_reward/ruby_low_video.svg`,
            })(FantasyIcon),
        },
    };

    export const CheckThick = withProps<IconOmitProps>({
        src: `${resourceUrl}/game/check_thick.svg`,
        colorize: Colorize.Overwrite
    })(FantasyIcon);

    export const Unlock = withProps<IconOmitProps>({
        size: "1.2rem",
        src: `${resourceUrl}/game/unlock.svg`,
        colorize: Colorize.Overwrite
    })(FantasyIcon);

    export const Lightning = withProps<IconOmitProps>({
        size: "1.2rem",
        src: `${resourceUrl}/game/lightning.svg`,
        colorize: Colorize.Overwrite
    })(FantasyIcon);

    export const Mail = withProps<IconBaseProps>({
        size: "1.8rem"
    })(MdMail);

    export const LeftRightArrows = withProps<IconOmitProps>({
        size: "1.4rem",
        src: `${resourceUrl}/game/left_right_arrows.svg`,
    })(FantasyIcon);

    export const Ban = withProps<IconBaseProps>({
        size: "1.8rem"
    })(LuBan);

    export const Undo = withProps<IconBaseProps>({
        size: "1.2rem"
    })(LuUndo2);

    export const ClockFill = withProps<IconOmitProps>({
        size: "1.8rem"
    })(PiClockFill);

    export const KycOtpVerified = withProps<IconOmitProps>({
        size: "1.4rem",
        src: `${resourceUrl}/game/kyc_otp_verified.svg`,
    })(FantasyIcon);

    export const KycOtpUnverified = withProps<IconOmitProps>({
        size: "1.4rem",
        src: `${resourceUrl}/game/kyc_otp_unverified.svg`,
    })(FantasyIcon);

    export const BitCoin = withProps<IconOmitProps>({
        size: "2rem",
        src: bitCoinImg,
        colorize: Colorize.Overwrite
    })(FantasyIcon);

    export const InfoIcon = withProps<IconOmitProps>({
        size: "2rem",
        src: infoIconSvg,
        colorize: Colorize.Overwrite
    })(FantasyIcon);

    export const ArrowCircleDownRight = withProps<IconOmitProps>({
        size: "1.6rem",
        src: `${resourceUrl}/game/arrow_circle_down_right.svg`,
        colorize: Colorize.Overwrite
    })(FantasyIcon);

    export const Speedometer = withProps<IconOmitProps>({
        size: "1.6rem",
        src: `${resourceUrl}/game/speedometer.svg`,
        colorize: Colorize.Overwrite
    })(FantasyIcon);

    export const Hourglass = withProps<IconOmitProps>({
        size: "1.6rem",
        src: `${resourceUrl}/game/hourglass.svg`,
        colorize: Colorize.Overwrite
    })(FantasyIcon);

    export const User = withProps<IconBaseProps>({
        size: "1.4rem",
    })(RiUserFill);

    export const GameController = withProps<IconBaseProps>({
        size: "1.4rem",
    })(IoGameController);

    export const AndroidIcon = withProps<IconOmitProps>({
        size: "1.6rem",
        src: `${resourceUrl}/game/android_icon.svg`,
    })(FantasyIcon);

    export const AppleIcon = withProps<IconOmitProps>({
        size: "1.6rem",
        src: `${resourceUrl}/game/app_store_icon.svg`,
    })(FantasyIcon);
}
