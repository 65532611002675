import { CopyInvitationCodeButton } from "components/copyCode/CopyInvitationCodeButton";
import AmountDisplay from "containers/amount/AmountDisplay";
import { getCurrencyIconSuitByEntryFeeType } from "containers/amount/CurrencyIconSuitHelper";
import { ContestDetailVo } from "data/vo/contest/ContestDetailVo";
import ContestMaxPrizePool from "domain/contest/components/ContestMaxPrizePool";
import { ContestCancelledCover } from "domain/contest/components/ContestStyle";
import {
    Contest,
    ContestBodyRow,
    ContestCardInfo,
    ContestFooterRow,
    ContestInfoRow,
    ContestName
} from "domain/match/pages/upcoming/contest/card/ContestCardStyle";
import ContestInfoTagList from "domain/match/pages/upcoming/contest/card/tag/ContestInfoTagList";
import { CreateContestCardTags, isContestCancelled, isPracticeContest } from "helpers/ContestHelper";
import { shareIcon } from "helpers/IconHelper";
import { isMatchJoinable } from "helpers/match/MatchHelper";
import { divideByOneHundred, multiplyByOneHundred } from "helpers/number/NumberHelper";
import useCurrency from "hooks/useCurrency/useCurrency";
import useUserIdentity from "hooks/useUserIdentity";
import React from "react";
import { ProgressBar } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { AP_LEVEL } from "types/activityPoint/ApLevel";
import { CONTEST_STATUS_FROM_API, CONTEST_TYPE_FROM_API, ENTRY_FEE_TYPE_FROM_API } from "types/contest/Contest";
import { MATCH_STATUS_FROM_API } from "types/match/Match";
import { useSetting } from "hooks/useSetting";

export interface ContestDetailCardProps {
    teams: number;
    regularPoolCent: number;
    hostDonatedPoolCent: number;
    entryFeeCent: number;
    entryFeeType: ENTRY_FEE_TYPE_FROM_API;
    name: string;
    spots: number;
    status: CONTEST_STATUS_FROM_API;
    type: CONTEST_TYPE_FROM_API;
    minApLevel?: AP_LEVEL;
    firstPrizeCent?: number;
    minimumSlip?: number;
    allowDiscountWithRuby?: boolean;
    allowMultiple?: boolean;
    prizeTeams?: number;
}

function getProgressBarClassname({
                                     entryFeeCent, entryFeeType
                                 }) {
    if (entryFeeCent === 0)
        return "free";
    if (entryFeeType === ENTRY_FEE_TYPE_FROM_API.RUBY)
        return "ruby";
    return "deposit";
}

const EntryFee = ({ type, cent }) => {
    const { currentCurrencyObject: { toFormattedNumber } } = useCurrency();

    return (
        <div className="tw-flex tw-flex-col tw-items-end">
            <div className="tw-text-caption-1 tw-text-system-labelLightSecondary">
                <FormattedMessage id="fantasy_sport_label_entry_fee" />
            </div>
            <AmountDisplay
                currencyIconSuit={getCurrencyIconSuitByEntryFeeType(type)}
                className="tw-text-title-2 tw-text-system-labelLightPrimary tw-font-bold"
            >
                {toFormattedNumber(divideByOneHundred(cent))}
            </AmountDisplay>
        </div>
    );
};

const ContestInfo = ({ contest }) => {
    const { type, entryFeeCent, entryFeeType } = contest;

    if (isPracticeContest(type)) {
        return <div data-testid="practice-placeholder" className="tw-h-6"></div>;
    }

    return (
        <ContestInfoRow data-testid="non-practice-info">
            <ContestMaxPrizePool contest={contest} />
            <EntryFee type={entryFeeType} cent={entryFeeCent} />
        </ContestInfoRow>
    );
};


export const ContestDetailCard: React.FC<{
    contest: ContestDetailCardProps,
    matchStatus: MATCH_STATUS_FROM_API,
    onCopyModalShow: () => void
}> = ({ contest, onCopyModalShow, matchStatus }) => {
    const { settingState: { contest: { spotsToMaxEntries, defaultMaxEntries } } } = useSetting();
    const { isB2B } = useUserIdentity();

    const { currentCurrencyObject: { toPeriodNumber } } = useCurrency();


    const { name, spots, teams } = contest;

    const remainingSpotsPercentage = Math.floor(multiplyByOneHundred(teams / spots));

    const intl = useIntl();

    const getContestCardTags = CreateContestCardTags(!isB2B, intl.formatMessage, toPeriodNumber);

    return <Contest>
        {
            isContestCancelled(contest.status) &&
            <ContestCancelledCover>
                <FormattedMessage
                    id="label_cancelled"
                />
            </ContestCancelledCover>
        }
        <ContestBodyRow>
            <ContestCardInfo>
                <ContestName className="fz-sf-bold-body-1">{name}</ContestName>
                <ContestInfo contest={contest} />
                {
                    /*
                     //TODO ProgressBar follow design
                         https://www.figma.com/file/A1Pvj9vbqsVY8bZWAicpfO/(N)Mockup-Done?type=design&node-id=2559-136825&mode=design&t=TRcbNoj5b1ooZhnX-0
                     */
                }
                <ProgressBar className={`my-2 ${getProgressBarClassname(contest)}`} now={remainingSpotsPercentage} />
                <ContestInfoRow
                    className="justify-content-between text-light-secondary fz-sf-regular-footnote">
                    <div>
                        <span className="fz-sf-bold-footnote">{teams} </span>
                        <FormattedMessage id="match_card_label_joined" />
                    </div>
                    <div>
                        <span>{spots} </span>
                        <FormattedMessage id="fantasy_sport_label_spots" />
                    </div>
                </ContestInfoRow>
            </ContestCardInfo>
        </ContestBodyRow>
        <ContestFooterRow>
            <ContestInfoTagList tags={getContestCardTags(contest, spotsToMaxEntries, defaultMaxEntries, matchStatus)}>
                {
                    isMatchJoinable(matchStatus) &&
                    <CopyInvitationCodeButton onCopyModalShow={onCopyModalShow} icon={shareIcon} />
                }
            </ContestInfoTagList>
        </ContestFooterRow>
    </Contest>;
};

export const ContestDetailCardWithoutTagList: React.FC<{ contest: ContestDetailVo }> = ({ contest }) => {
    const { entryFeeCent, name, spots, joinedTeams, entryFeeType } = contest;

    const remainingSpotsPercentage = Math.floor(multiplyByOneHundred(joinedTeams / spots));

    return <Contest>
        {
            isContestCancelled(contest.status) &&
            <ContestCancelledCover>Cancelled</ContestCancelledCover>
        }
        <ContestBodyRow>
            <ContestCardInfo>
                <ContestName className="fz-sf-bold-body-1">{name}</ContestName>
                <ContestInfoRow>
                    <ContestMaxPrizePool contest={contest} />
                    <EntryFee type={entryFeeType} cent={entryFeeCent} />
                </ContestInfoRow>
                <ProgressBar className="my-2" now={remainingSpotsPercentage} />
                <ContestInfoRow
                    className="justify-content-between text-light-secondary fz-sf-regular-footnote">
                    <div>
                        <span className="fz-sf-bold-footnote">{joinedTeams}</span>
                        <FormattedMessage
                            id="match_card_label_joined"
                        />
                    </div>
                    <div>{spots}
                        <FormattedMessage
                            id="fantasy_sport_label_spots"
                        />
                    </div>
                </ContestInfoRow>
            </ContestCardInfo>
        </ContestBodyRow>
    </Contest>;
};
