import FantasyDialog from "components/dialog/FantasyDialog";
import useDialogProps from "components/dialog/hook/useDialogProps";
import {
    IconWrapper,
    InfoText,
    ListGroupWrapper,
    ListItem,
    ListName,
    RightWrapper,
} from "domain/me/components/MeMenu/MeMenuStyle";
import { useMe } from "domain/me/me/MeProvider";
import { Icon } from "helpers/IconHelper";
import SYSTEM_LINKS from "helpers/LinkHelper";
import useCurrency from "hooks/useCurrency/useCurrency";
import useFantasyConfig from "hooks/useFantasyConfig";
import useFantasyHistory from "hooks/useFantasyHistory";
import useSignOut from "hooks/useSignOut";
import { useLocaleProvider } from "LocaleProvider";
import { languages } from "locales";
import _ from "lodash";
import React, { useState } from "react";
import {
    MdOutlineArticle,
    MdOutlineChevronRight,
    MdOutlineConfirmationNumber,
    MdOutlineLogout,
    MdOutlineMail,
    MdOutlinePeopleAlt,
    MdOutlinePin,
    MdOutlinePublic,
    MdOutlineReduceCapacity,
    MdOutlineShield,
    MdOutlineSportsEsports,
    MdSettingsCell,
} from "react-icons/md";
import { FormattedMessage } from "react-intl";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";
import { isAwait, isUnsuccessful, isUnverified, isVerified } from "types/kyc/KycStatus";
import useLiveChat from "../../../../hooks/useLiveChat";
import { useSetting } from "hooks/useSetting";

export const MeMenu = () => {
    const history = useFantasyHistory();
    const { openChat } = useLiveChat();
    const { currentCurrencyObject } = useCurrency();
    const signOut = useSignOut();
    const { myProfile } = useMe();
    const { locale } = useLocaleProvider();
    const { meMenuEnterReferralCodeDialogProps } = useDialogProps();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { liveChatLicense, featureConfig } = useFantasyConfig();
    const { settingState: { link } } = useSetting();

    const getCurrentLanguage = () => _.find(languages, ["key", locale])!.text;

    const menu = [
        {
            titleId: "label_verification_completed",
            IconComponent: () => <Icon.Checked className="tw-text-primary-600" />,
            getAttrs: () => ({
                onClick: () => {
                    history.push(ROUTER_PATH_CONFIG.verifyKyc.success);
                },
            }),
            isShow: featureConfig.kyc && isVerified(myProfile.kycStatus),
        },
        {
            titleId: "label_verification_in_progress",
            IconComponent: () => <Icon.Locked className="tw-text-primary-600" />,
            getAttrs: () => ({
                onClick: () =>
                    history.push(ROUTER_PATH_CONFIG.verifyKyc.progress),
            }),
            iconBgColor: "tw-bg-warning-50",
            isShow: featureConfig.kyc && isAwait(myProfile.kycStatus),
        }, {
            titleId: "label_verify_account",
            IconComponent: () => <Icon.Locked className="tw-text-primary-600" />,
            getAttrs: () => ({
                onClick: () =>
                    history.push(ROUTER_PATH_CONFIG.verifyKyc.form),
            }),
            iconBgColor: "tw-bg-warning-50",
            isShow: featureConfig.kyc && (isUnverified(myProfile.kycStatus) || isUnsuccessful(myProfile.kycStatus)),
        },
        {
            titleId: "label_support_link",
            IconComponent: () => <Icon.CustomerService className="tw-text-primary-600" />,
            getAttrs: () => ({
                onClick: () => openChat(),
            }),
            isShow: featureConfig.liveChat && !!liveChatLicense,
        },
        {
            titleId: "label_transaction_history",
            IconComponent: () => <Icon.WalletBalance className="tw-text-primary-700" size={20} />,
            getAttrs: () => ({
                onClick: () =>
                    history.push(ROUTER_PATH_CONFIG.wallet, {
                        navbarBackGoTo: history.location.pathname,
                    }),
            }),
            isShow: true,
        },
        {
            titleId: "label_following",
            IconComponent: MdOutlinePeopleAlt,
            getAttrs: () => ({
                onClick: () => history.push(ROUTER_PATH_CONFIG.userProfile.list),
            }),
            isShow: true,
        },
        {
            titleId: "label_referral_program",
            IconComponent: MdOutlineReduceCapacity,
            getAttrs: () => ({
                onClick: () => history.push(ROUTER_PATH_CONFIG.me.referral.index),
            }),
            isShow: featureConfig.referral,
        },
        {
            titleId: "label_enter_referral_code",
            IconComponent: MdSettingsCell,
            getAttrs: () => ({
                onClick: () => {
                    setIsOpen(true);
                },
            }),
            isShow: featureConfig.referral && !myProfile.hasReferrer,
        },
        {
            titleId: "label_my_coupons",
            IconComponent: MdOutlineConfirmationNumber,
            getAttrs: () => ({
                onClick: () => history.push(ROUTER_PATH_CONFIG.coupon),
            }),
            isShow: true,
        },
        {
            titleId: "me_page_menu_label_how_to_play",
            IconComponent: MdOutlineSportsEsports,
            getAttrs: () => ({
                href: link.howToPlay,
                target: "_blank",
            }),
            isShow: true,
        },
        {
            titleId: "me_page_menu_label_contact_us",
            IconComponent: MdOutlineMail,
            getAttrs: () => ({
                href: SYSTEM_LINKS.contactUs,
                target: "_blank",
            }),
            isShow: !featureConfig.liveChat,
        },
        {
            titleId: "me_page_menu_label_terms_and_conditions",
            IconComponent: MdOutlineArticle,
            getAttrs: () => ({
                href: link.termsAndConditions,
                target: "_blank",
            }),
            isShow: true,
        },
        {
            titleId: "me_page_menu_label_privacy_policy",
            IconComponent: MdOutlineShield,
            getAttrs: () => ({
                href: link.privacyPolicy,
                target: "_blank",
            }),
            isShow: true,
        },
        {
            titleId: "language_setting_page_title",
            IconComponent: MdOutlinePublic,
            getAttrs: () => ({
                onClick: () => history.push(ROUTER_PATH_CONFIG.language),
            }),
            rightTag: <InfoText>{getCurrentLanguage()}</InfoText>,
            isShow: true,
        },
        {
            titleId: "currency_format_setting_page_title",
            IconComponent: MdOutlinePin,
            getAttrs: () => ({
                onClick: () => history.push(ROUTER_PATH_CONFIG.currency),
            }),
            rightTag: <InfoText>{currentCurrencyObject.text}</InfoText>,
            isShow: featureConfig.canUserSetCurrencyFormat,
        },
        {
            titleId: "me_page_menu_label_log_out",
            IconComponent: MdOutlineLogout,
            getAttrs: () => ({
                onClick: signOut,
            }),
            isShow: true,
        },
    ];

    return (
        <>

            <ListGroupWrapper>
                {menu.map(({
                               titleId,
                               getAttrs,
                               rightTag,
                               IconComponent,
                               isShow,
                               iconBgColor
                           }) =>
                    isShow
                        ? (<React.Fragment key={titleId}>
                                <ListItem {...getAttrs()}>
                                    <IconWrapper $bgColor={iconBgColor}>
                                        <IconComponent size="2.0rem" />
                                    </IconWrapper>
                                    <ListName>
                                        <FormattedMessage id={titleId} />
                                    </ListName>
                                    <RightWrapper>
                                        {rightTag}
                                        <MdOutlineChevronRight
                                            size="2.4rem"
                                            className="text-light-tertiary"
                                        />
                                    </RightWrapper>
                                </ListItem>
                            </React.Fragment>
                        )
                        : null)
                }
            </ListGroupWrapper>
            <FantasyDialog
                show={isOpen} {...meMenuEnterReferralCodeDialogProps(() => setIsOpen(false))}
            />
        </>
    );
};
