import { divideByOneHundred, parseNumberToIntegerAndDecimal } from "helpers/number/NumberHelper";
import React from "react";
import { AmountValue, SelectedAmount } from "./TotalSelectedAmountStyle";

const TotalSelectedAmount: React.FC<{ amountCent: number }> = ({ amountCent }) => {
    const { integer, decimal } = parseNumberToIntegerAndDecimal(divideByOneHundred(amountCent || 0));

    return <SelectedAmount>
        $ <AmountValue>{integer}</AmountValue>.{decimal}
    </SelectedAmount>;
};

export default TotalSelectedAmount;