import { PreLoginSettingVo } from "data/vo/prelogin/PreLoginSettingVo";
import { fetchPreLoginSetting } from "data/api/prelogin/PreLoginApi";

export class PreLoginRepository {
    public async getPreLoginSetting() {
        const response = await fetchPreLoginSetting();

        return new PreLoginSettingVo(response);
    };
}
