import Navbar from "components/container/navbar/Navbar";
import PageViewRecorder from "components/ga/PageViewRecorder";
import { useAddCoinFlow } from "domain/coin/AddCoinFlow";
import TotalSelectedAmount from "domain/coin/component/amount/TotalSelectedAmount";
import { AddCoinMethods } from "domain/coin/pages/SelectPaymentMethod/components/AddCoinMethods";
import { Icon, } from "helpers/IconHelper";
import React from "react";
import { FormattedMessage } from "react-intl";

const SelectPaymentMethod = () => {
    const { currentProduct, goToInitPage, cancelAddDepositFlow } = useAddCoinFlow();

    return (
        <>
            <Navbar
                onNavbarBackClick={goToInitPage}
                navbarCenter={
                    <div
                        className="tw-flex tw-justify-center tw-items-center fz-sf-bold-title-3 tw-h-full text-text-primary-1">
                        <TotalSelectedAmount amountCent={currentProduct!.currencyCent} />
                    </div>
                }
                endIcon={<Icon.Close className="tw-cursor-pointer" onClick={cancelAddDepositFlow} />}
                className="!tw-sticky"
                shouldCalculatePaddingTop={false}
            />
            <PageViewRecorder title="Select Payment Method" />
            <div className="tw-flex tw-flex-col tw-gap-3 tw-pt-[2rem] tw-px-[3.2rem]">
                <div className="fz-sf-regular-subhead" data-testid="payment-method-title">
                    <FormattedMessage id="select_payment_page_label_select_method" />
                </div>
                <AddCoinMethods productId={currentProduct!.id} />
            </div>
        </>
    );
};

export default SelectPaymentMethod;
