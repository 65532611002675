import { FirstTimePurchaseDto } from "apis/deposit/DepositApi";
import { MyProfileVo } from "data/vo/user/MyProfileVo";
import { divideByOneHundred } from "helpers/number/NumberHelper";
import { UPDATE_MY_PROFILE, UPDATE_USER_IDENTITY, UPDATE_USER_IS_NEW_USER } from "store/actions/UserActions";
import { SET_FIRST_TIME_PURCHASE, UPDATE_SESSION_ID, UPDATE_SITE_ID, } from "store/Constants";
import { storageHelper } from "helpers/localStorage/LocalStorageHelper";
import { LOCAL_STORAGE_KEYS } from "helpers/localStorage/types";

interface UserState {
    type: null | string;
    myProfile: MyProfileVo | null;
    firstTimePurchase: FirstTimePurchaseDto | null;
    isNewUser: boolean;
    selectedSiteId: number;
    sessionId: string | null
}

const init: UserState = {
    type: null,
    myProfile: null,
    firstTimePurchase: null,
    isNewUser: false,
    selectedSiteId: 0,
    sessionId: storageHelper(LOCAL_STORAGE_KEYS.SESSION_ID).get()
};

const userState = (prevState = init, action) => {
    const { type, payload } = action;

    switch (type) {
        case UPDATE_USER_IDENTITY:
            return {
                ...prevState,
                ...payload,
            };

        case UPDATE_USER_IS_NEW_USER:
            return {
                ...prevState,
                isNewUser: payload,
            };

        case UPDATE_MY_PROFILE:
            return {
                ...prevState,
                myProfile: payload,
            };

        case SET_FIRST_TIME_PURCHASE:
            return {
                ...prevState,
                firstTimePurchase: {
                    ...payload,
                    minCoin: divideByOneHundred(payload.minCoinCent),
                },
            };

        case UPDATE_SITE_ID:
            return {
                ...prevState,
                selectedSiteId: payload
            };

        case UPDATE_SESSION_ID:
            return {
                ...prevState,
                sessionId: payload
            };

        default:
            return prevState;
    }
};

export default userState;
