import { useSelector } from "store";

const useFantasyConfig = () => {
    const config = useSelector(store => store.FantasyGameConfigState);

    return {
        ...config,
        isDaily: config.name === "daily"
    };
};

export default useFantasyConfig;
