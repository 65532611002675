import { FantasyCheckbox } from "components/checkbox/FantasyCheckbox";
import { CHECKBOX_SHAPE, CHECKBOX_VARIANTS } from "components/checkbox/types";
import { FormControl, FormField, FormItem } from "components/ui/form";
import { FONT } from "designToken/font";
import { FormErrorMessage } from "domain/kyc/pages/kycVerificationForm/components/FormErrorMessage";
import React from "react";

interface FantasyCheckboxFieldProps {
    children?: React.ReactNode;
    name: string;
    variant?: CHECKBOX_VARIANTS;
    shape?: CHECKBOX_SHAPE;
    textFont?: FONT;
    showErrorMessage?: boolean;
}

export const FantasyCheckboxField: React.FC<FantasyCheckboxFieldProps> = ({
    name,
    variant = CHECKBOX_VARIANTS.primary,
    shape = CHECKBOX_SHAPE.square,
    textFont,
    showErrorMessage = true,
    children
}) => {
    return <FormField
        name={name}
        render={({ field }) =>
            <FormItem>
                <FormControl>
                    <FantasyCheckbox
                        variant={variant}
                        checked={field.value}
                        textFont={textFont}
                        shape={shape}
                        onCheckedChange={(checked) => field.onChange(checked)}
                    >
                        {children}
                    </FantasyCheckbox>
                </FormControl>

                {showErrorMessage && <FormErrorMessage />}
            </FormItem>
        }
    />;
};
