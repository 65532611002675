import React from "react";
import useFantasyConfig from "hooks/useFantasyConfig";
import { FormattedMessage } from "react-intl";
import Button from "designToken/button/Button";
import { appStoreQRCode, googlePlayQRCode, Icon, IconOmitProps } from "helpers/IconHelper";
import { BUTTON_VARIANTS, NEW_BUTTON_VARIANTS } from "designToken/button/types";
import { IconBaseProps } from "react-icons";
import Navbar from "components/container/navbar/Navbar";
import TotalSelectedAmount from "domain/coin/component/amount/TotalSelectedAmount";
import { useAddCoinFlow } from "domain/coin/AddCoinFlow";
import PageViewRecorder from "components/ga/PageViewRecorder";

const PaymentAppDownload: React.FC = () => {
    const { currentProduct, goToSelectPaymentMethodPage, cancelAddDepositFlow } = useAddCoinFlow();
    const { appDownloadLink } = useFantasyConfig();

    const handleDirectIOSDownload = () => {
        window.open(appDownloadLink.appStore, "_blank");
    };

    const handleDirectAndroidDownload = () => {
        window.open(appDownloadLink.googlePlay, "_blank");
    };

    const handleBack = () => {
        goToSelectPaymentMethodPage();
    };

    const handleCancelAddDepositFlow = () => {
        cancelAddDepositFlow();
    };

    return (
        <div className="tw-bg-black">
            <Navbar
                isHeaderLine={false}
                isTransparent={true}
                isNavbarBottomTransparent={true}
                onNavbarBackClick={handleBack}
                navBackIconColor="tw-text-white"
                navbarCenter={
                    <div
                        className="tw-flex tw-justify-center tw-items-center fz-sf-bold-title-3 tw-h-full tw-text-white">
                        <TotalSelectedAmount amountCent={currentProduct!.currencyCent} />
                    </div>
                }
                endIcon={<Icon.Close className="tw-cursor-pointer tw-text-white"
                                     onClick={handleCancelAddDepositFlow} />}
                className="!tw-sticky tw-bg-black"
                shouldCalculatePaddingTop={false}
            />
            <PageViewRecorder title="Download App" />
            <div data-testid="credit-card-method-app-download"
                 className="tw-bg-primary-850 tw-pt-4 tw-mt-2.5 tw-rounded-tr-[1.6rem] tw-rounded-tl-[1.6rem] tw-text-center tw-h-[calc(100vh-8.4rem)]">
                <div className="tw-pb-10">
                    <h2 className="tw-max-w-[32.6rem] tw-m-auto tw-text-center tw-text-caption-2 tw-text-white">
                        <FormattedMessage id="payment_app_download_title" />
                    </h2>
                </div>

                <div
                    className="tw-flex tw-justify-center tw-m-auto tw-max-w-[32.6rem] tw-mb-4 tw-gap-6 sm:tw-gap-9">
                    <AppDownloadButtonWithQrCode
                        variant={NEW_BUTTON_VARIANTS.tertiaryLight}
                        buttonPrefixIcon={Icon.AndroidIcon}
                        buttonText="Android App"
                        qrCodeSrc={googlePlayQRCode}
                        qrCodeAltText="Android QR Code"
                        onButtonClick={handleDirectAndroidDownload}
                    />
                    <AppDownloadButtonWithQrCode
                        variant={NEW_BUTTON_VARIANTS.primaryLight}
                        buttonPrefixIcon={Icon.AppleIcon}
                        buttonText="IOS App"
                        qrCodeSrc={appStoreQRCode}
                        qrCodeAltText="iOS QR Code"
                        onButtonClick={handleDirectIOSDownload}
                    />
                </div>
            </div>
        </div>
    );
};

interface AppDownloadButtonWithQrCodeProps {
    variant: BUTTON_VARIANTS | NEW_BUTTON_VARIANTS;
    buttonPrefixIcon: React.FC<IconOmitProps> | React.FC<IconBaseProps>;
    buttonText: string;
    qrCodeSrc: string;
    qrCodeAltText: string;
    onButtonClick: () => void;
}

const AppDownloadButtonWithQrCode: React.FC<AppDownloadButtonWithQrCodeProps> = ({
                                                                                     variant,
                                                                                     buttonPrefixIcon,
                                                                                     buttonText,
                                                                                     qrCodeSrc,
                                                                                     qrCodeAltText,
                                                                                     onButtonClick,
                                                                                 }) => (
    <div className="tw-w-full tw-flex tw-flex-col tw-items-center tw-gap-3">
        <Button
            variant={variant}
            className="tw-w-full"
            prefixIcon={buttonPrefixIcon}
            onClick={onButtonClick}
        >
            <p className="tw-text-body-1 tw-font-medium">
                {buttonText}
            </p>
        </Button>
        <div className="tw-w-full tw-rounded-md tw-overflow-hidden">
            <img src={qrCodeSrc} alt={qrCodeAltText} className="tw-w-full tw-h-full" />
        </div>
    </div>
);

export default PaymentAppDownload;
