export const zh ={
  "@@locale": "zh",
  "main_page_bottom_bar_tab_home": "首頁",
  "main_page_bottom_bar_tab_matches": "賽事",
  "main_page_bottom_bar_tab_result": "賽果",
  "main_page_bottom_bar_tab_chat": "聊天",
  "main_page_bottom_bar_tab_me": "關於我",
  "label_upcoming_matches": "即將到來",
  "label_live_matches": "現正開打",
  "label_completed_matches": "已結束",
  "label_completed": "已完成",
  "match_card_label_joined": "已加入",
  "home_page_invite_code_input_label_hint": "輸入邀請碼",
  "tournament_filter_dialog_title": "篩選聯盟",
  "wallet_view_title": "錢包餘額",
  "wallet_view_button_store": "商店",
  "match_detail_page_tab_contests": "全部房間",
  "match_detail_page_my_contests_section_label_empty": "您還沒有加入任何房間！",
  "match_detail_page_my_teams_section_label_empty": "您還沒有創建任何隊伍！",
  "contest_card_button_entry": "入場費",
  "contest_card_label_upto": "{entries} 隊",
  "contest_card_label_otp": "OTP",
  "contest_card_label_single": "1 隊",
  "contest_card_label_prize_hunter": "獎金獵人",
  "share_contest_dialog_title": "分享房間",
  "share_contest_dialog_label_invite": "邀請碼",
  "share_contest_dialog_button_copy": "複製",
  "share_contest_dialog_label_code_copied": "已複製邀請碼！",
  "create_team_success_dialog_title": "創建隊伍成功",
  "join_contest_success_dialog_title": "加入房間成功",
  "edit_team_success_dialog_title": "編輯隊伍成功",
  "contest_detail_page_tab_winnings": "獎金",
  "contest_detail_page_tab_leaderboard": "排行榜",
  "contest_detail_page_leaderboard_label_all_team": "所有隊伍",
  "contest_detail_page_ranking_label_winner_takes_all": "頭獎高達",
  "contest_detail_page_ranking_label_free_contest_ranking": "贏家獲得關注與掌聲。",
  "contest_detail_page_ranking_label_ranking": "#排名",
  "contest_detail_page_ranking_label_prize": "獎金",
  "contest_detail_page_ranking_label_winner_hint": "再有 {teams} 隊加入，將可贏得全部獎金",
  "contest_detail_page_button_join_contest": "加入 {contestName}",
  "select_team_page_title": "選擇隊伍",
  "select_team_page_label_up_to_join_teams_1": "每位玩家可以加入最多",
  "select_team_page_label_up_to_join_teams_2": "隊",
  "select_team_page_label_have_joined_team": "已經加入",
  "max_entries_dialog_label_max_entries": "隊伍上限",
  "button_got_it": "了解！",
  "confirmation_dialog_title": "確認",
  "label_coupon": "優惠券",
  "select_coupon_dialog_button_use": "使用",
  "select_coupon_dialog_label_no_coupon": "沒有優惠券",
  "label_contest_name": "房間名稱",
  "my_matches_page_label_empty_my_matches": "您尚未加入任何房間",
  "my_matches_page_label_empty_join_contest": "立即加入房間！",
  "my_matches_page_started_match_tab_scorecard": "計分板",
  "my_matches_page_started_match_tab_player_stats": "球員數據",
  "my_matches_page_player_stats_label_stats_at_match": "賽事球員數據",
  "my_match_card_label_team": "隊伍",
  "my_match_card_label_contest": "已加入房間",
  "my_matches_page_scorecard_label_batting": "擊球",
  "my_matches_page_scorecard_label_batter": "擊球手",
  "my_matches_page_scorecard_label_bowler": "投球手",
  "my_matches_page_scorecard_label_fall_of_wicket": "三柱門失守",
  "my_matches_page_scorecard_label_title_extras": "附加分",
  "chat_room_page_label_system_announcement_more": "〈瞭解更多〉",
  "chat_room_page_label_chat_room_guideline": "聊天室規章",
  "chat_room_page_message_input_label_hint": "訊息",
  "message_sent_toast_label": "已發送訊息",
  "message_sent_fail_toast_label_forbidden": "被禁止的訊息",
  "id_copied_toast_label": "已複製 ID！",
  "ap_overview_view_label_next_level": "取得 {ap} AP 以達到「{apLevel}」",
  "playing_record_view_participation_times_title": "參加次數",
  "playing_record_view_participation_times_label_hint": "（最近 30 天）",
  "playing_record_view_participation_times_label_joins": "加入",
  "playing_record_view_participation_times_label_cricket_top_1": "板球 Top 1",
  "playing_record_view_participation_times_label_football_top_1": "足球 Top 1",
  "playing_record_view_roi_title": "ROI",
  "playing_record_view_roi_label_hint": "（投資報酬率）",
  "playing_record_view_roi_label_last_24_hrs": "最近 24 小時",
  "playing_record_view_roi_label_last_7_days": "最近 7 天",
  "playing_record_view_roi_label_last_30_days": "最近 30 天",
  "me_page_menu_label_how_to_play": "如何遊玩",
  "me_page_menu_label_contact_us": "聯絡我們",
  "me_page_menu_label_terms_and_conditions": "條款與細則",
  "me_page_menu_label_privacy_policy": "隱私權政策",
  "me_page_menu_label_log_out": "登出",
  "edit_profile_page_title": "個人資料",
  "edit_profile_page_label_username": "使用者名稱",
  "edit_profile_page_label_about_me": "關於我",
  "edit_profile_page_about_me_input_label_hint": "（最多200個字元）",
  "change_password_dialog_title": "變更密碼",
  "change_password_dialog_label": "我們會寄信至以下 Email 以協助您變更密碼。",
  "change_password_dialog_button_send_email": "發送 Email",
  "change_password_success_toast_label": "我們已寄出 Email 以協助您變更密碼。",
  "friend_list_page_label_empty_friends": "您還沒有加入任何好友",
  "search_user_view_title": "搜尋玩家 ID",
  "search_user_view_error_invalid_format": "請輸入六位數字玩家 ID",
  "search_user_view_error_user_not_found": "找不到玩家",
  "my_contest_detail_page_quick_summary_label_yet_to_bat": "Yet to Bat",
  "match_status_label_upcoming": "即將到來",
  "wallet_history_page_label_ruby_expiry": "將在 {date} 到期",
  "wallet_history_page_button_join_contest": "加入房間",
  "wallet_history_page_label_empty_record": "您的帳號沒有交易紀錄",
  "wallet_record_event_join_contest": "加入房間",
  "wallet_record_event_user_register": "註冊",
  "wallet_record_event_refund_contest": "退還入場費",
  "wallet_record_event_user_withdraw": "提領獎金",
  "wallet_record_event_purchase_reward": "在商店購買商品",
  "wallet_record_event_win_contest": "贏得比賽",
  "wallet_record_event_user_transfer": "轉帳 ({transferredTo})",
  "wallet_record_event_user_transfer_with_no_var": "轉帳",
  "wallet_record_event_user_transfer_winning_in": "收到轉帳",
  "wallet_record_event_user_transfer_winning_in_with_var": "收到轉帳 ({transferredFrom})",
  "wallet_record_event_invite_by_friend": "好友邀請",
  "wallet_record_event_invite_by_friend_with_var": "好友邀請 ({referrerUserCode})",
  "deposit_page_label_select_amount": "選擇一個金額",
  "deposit_page_label_google_play_error_1": "或許有臨時的狀況發生，",
  "deposit_page_label_google_play_error_2": "請嘗試再次登入您的 Google Play 或 App Store。",
  "order_processing_dialog_title": "稍等一下",
  "order_processing_dialog_label": "正在處理訂單，這可能需要幾分鐘的時間．請前往交易紀錄追蹤該訂單的狀態。",
  "activity_point_page_title": "如何獲得活動積分(AP)？",
  "activity_point_page_tab_current": "當前",
  "activity_point_page_tab_upgrade": "升級",
  "activity_point_page_current_section_label_transfer_winning_limit": "獎金轉帳額度",
  "activity_point_page_current_section_value_transfer_winning_limit": "一天 {number}",
  "activity_point_page_current_section_label_today_transferred": "當日已轉帳",
  "activity_point_page_current_section_label_accumulation_period": "累計期間",
  "activity_point_page_current_section_label_current_ap": "當前 AP",
  "activity_point_page_current_section_label_ap_join_contest": "AP (加入房間)",
  "activity_point_page_current_section_label_remaining_ap_next_month": "次月剩餘 AP",
  "activity_point_page_upgrade_section_label_transfer_limit": "轉帳額度",
  "activity_point_page_upgrade_section_label_required": "所需 AP",
  "activity_point_page_upgrade_section_label_transfer_limit_by_level": "{number}／日",
  "label_jan": "一月",
  "label_feb": "二月",
  "label_mar": "三月",
  "label_apr": "四月",
  "label_may": "五月",
  "label_jun": "六月",
  "label_jul": "七月",
  "label_aug": "八月",
  "label_sep": "九月",
  "label_oct": "十月",
  "label_nov": "十一月",
  "label_dec": "十二月",
  "label_ap_level_beginner": "新手",
  "label_ap_level_ordinary": "青銅",
  "label_ap_level_silver": "白銀",
  "label_ap_level_gold": "黃金",
  "label_ap_level_platinum": "白金",
  "label_ap_level_emerald": "翡翠",
  "label_ap_level_diamond": "鑽石",
  "label_ap_level_master": "大師",
  "label_ap_level_ultimateMaster": "終極大師",
  "invite_friend_page_label_received": "已收到",
  "invite_friend_page_label_how_it_work": "如何取得獎勵？",
  "invite_friend_page_button_copy_link": "複製連結",
  "copy_referral_code_success_toast_label": "已複製到剪貼簿",
  "ruby_list_page_label_get_more_bonus": "他們加入越多房間，您就獲得越多獎勵！每個朋友最多 {maxRuby} 寶石",
  "ruby_list_page_ruby_item_label_received": "已收到： {number}",
  "ruby_list_page_button_invite_more_friends": "邀請更多好友",
  "my_coupon_page_tab_unused": "未使用",
  "my_coupon_page_tab_used": "已使用",
  "my_coupon_page_tab_expired": "已過期",
  "my_coupon_page_label_empty_coupons": "現在沒有可以使用的優惠券",
  "my_coupon_page_label_reserved_30_days": "紀錄留存 30 天",
  "label_countdown_days": "{countdown} 天",
  "coupon_tooltip_dialog_label_1": "優惠券不可與其他優惠同時使用。",
  "coupon_tooltip_dialog_label_2": "優惠券僅可使用於單一房間與單一隊伍。",
  "coupon_tooltip_dialog_label_3": "每張優惠券均有有效期限。小提醒：請在有效期限內使用。 優惠券在過期日之後將不再有效。",
  "sms_verification_page_title": "簡訊驗證",
  "sms_verification_page_label_enter_mobile_number": "輸入您的手機號碼後，將會收到一次性密碼。",
  "sms_verification_page_mobile_number_input_label": "輸入手機號碼",
  "sms_verification_page_mobile_number_input_button_get_otp": "取得 OTP",
  "sms_verification_page_otp_input_label": "輸入 OTP",
  "sms_verification_page_label_did_not_receive": "沒有收到驗證碼？",
  "sms_verification_page_button_resend": "重新發送 OTP",
  "sms_verification_page_toast_countdown_again": "重新計時",
  "sms_verification_page_label_send_sms_fail": "發送簡訊失敗，請稍候再試",
  "sms_verification_page_label_number_is_invalid": "該號碼無效，請重新嘗試",
  "sms_verification_page_label_number_is_locked": "該號碼已經嘗試太多次，請過 24 小時後再試",
  "sms_verification_page_label_please_correct_otp": "OTP 驗證失敗！請輸入正確的 OTP！",
  "sms_verification_page_label_otp_is_invalid": "OTP 驗證失敗！還剩 {number} 次嘗試！",
  "sms_verification_page_label_otp_fail_too_many_times": "OTP 驗證失敗次數達到 {number} 次，請重新取得 OTP！",
  "leave_sms_verification_dialog_label": "確定放棄簡訊驗證？",
  "otp_verify_dialog_title": "OTP 驗證",
  "otp_verify_dialog_label": "您需要通過 OTP 驗證",
  "otp_verify_success_dialog_label": "手機號碼驗證成功",
  "user_profile_page_button_add_friend": "加為好友",
  "user_profile_page_toast_add_friend_success": "成功加入好友",
  "user_profile_page_toast_remove_friend_success": "成功移除好友",
  "login_page_label_log_in": "登入",
  "login_page_label_sign_up": "註冊",
  "login_page_label_register": "註冊",
  "login_page_label_hint_forgot_password": "忘記密碼？",
  "login_page_label_forgot_password": "忘記密碼",
  "login_page_label_confirm_password": "確認密碼",
  "login_page_button_reset_password": "重設密碼",
  "login_page_label_do_not_have_account": "還沒有帳號？",
  "login_page_label_already_have_account": "已經擁有帳號？",
  "login_page_label_account_or_password_invalid": "密碼無效或帳號不存在",
  "login_page_label_password_format_invalid": "請確認您的密碼一致",
  "login_expired_toast_label": "登入超時，請重新登入",
  "language_setting_page_title": "語言",
  "language_setting_page_label": "請選擇偏好的語言",
  "currency_format_setting_page_title": "數字格式",
  "currency_format_setting_label_choose_format": "請選擇偏好的數字格式",
  "setting_button_save_changed": "儲存變更",
  "check_email_page_title": "驗證您的 Email",
  "add_referral_page_title": "好友推薦",
  "add_referral_page_button_enter_code": "使用推薦碼",
  "add_referral_page_button_no_referral_code": "沒有推薦碼",
  "add_referral_page_label_rule_1": "請盡快輸入推薦碼。 推薦碼將在",
  "add_referral_page_label_rule_2": "{number} 小時後自動消失。",
  "add_referral_page_referral_code_input_label": "輸入推薦碼",
  "add_referral_page_leaving_dialog_label_can_not_enter_again": "當您按下確定，您將無法再次輸入推薦碼。",
  "add_referral_page_warning_dialog_label_duplicated_referral": "您已經是{username}的推薦人，請重新輸入。",
  "add_referral_page_warning_dialog_label_use_myself_code": "您無法使用自己的推薦碼",
  "dialog_button_understand": "我了解",
  "add_referral_page_toast_referred_success": "推薦成功",
  "add_referral_page_label_code_is_invalid": "無效的推薦碼！",
  "select_player_page_player_section_label_player_limit_1": "選擇 {number} 位",
  "select_player_page_player_section_label_player_limit_2": "選擇 {lowerBound} 到 {upperBound} 位",
  "select_special_player_page_label_captain": "隊長",
  "select_special_player_page_label_vice_captain": "副隊長",
  "player_role_cricket_keeper": "守門手",
  "player_role_cricket_batsman": "擊球手",
  "player_role_cricket_bowler": "投球手",
  "player_role_cricket_all_rounder": "全能手",
  "player_role_football_goalkeeper": "守門員",
  "player_role_football_defender": "後衛",
  "player_role_football_midfielder": "中場",
  "player_role_football_forward": "前鋒",
  "choose_recipient_page_title": "選擇轉入對象",
  "transfer_page_title": "輸入金額",
  "transfer_page_label_exceed_quota": "金額超過當日轉帳額度",
  "transfer_page_label_exceed_winning": "金額超過您的獎金：",
  "transfer_page_label_today_quota": "當日轉帳額度： {number}",
  "transfer_page_button_next": "滑動至下一步",
  "confirm_transfer_page_title": "確認轉帳",
  "confirm_transfer_page_button": "送出",
  "label_amount": "金額",
  "label_note": "備註",
  "transfer_success_dialog_title": "轉帳成功",
  "user_locked_dialog_label_1": "您已經被封鎖！",
  "user_locked_dialog_label_2": "請聯繫客服",
  "button_confirm": "確認",
  "button_next": "下一步",
  "fantasy_sport_label_spots": "名額",
  "fantasy_sport_label_entry_fee": "入場費",
  "loading_dialog_label": "載入中...",
  "leaving_dialog_button_cancel": "取消",
  "leaving_dialog_button_sure": "確定",
  "label_my_contests": "已加入的房間",
  "label_my_teams": "我的隊伍",
  "label_my_matches": "我的賽事",
  "label_chat_room": "聊天室",
  "label_my_coupons": "我的優惠券",
  "label_transfer": "轉帳",
  "label_balance": "餘額",
  "label_total": "總計",
  "label_create_team": "創建隊伍",
  "label_credits": "點數",
  "label_players": "球員",
  "label_points": "分",
  "label_score": "積分",
  "label_processing": "處理中",
  "label_cancelled": "已取消",
  "label_invite_friends": "推薦好友",
  "label_transaction_history": "交易紀錄",
  "label_email": "Email",
  "label_password": "密碼",
  "label_cancel": "取消",
  "dialog_button_close": "關閉",
  "dialog_button_back": "返回",
  "dialog_button_apply": "套用",
  "label_date": "日期",
  "label_event": "事件",
  "label_sport": "體育類型",
  "label_match": "賽事",
  "label_contest_cancelled": "房間已取消",
  "label_extra": "附加",
  "label_regular": "常規",
  "login_page_label_email_is_required": "需要 Email",
  "login_page_label_password_is_required": "需要密碼",
  "login_page_label_password_6_chars": "密碼至少六個字元",
  "login_page_label_provide_a_valid_email": "請輸入有效的 Email",
  "insufficient_ap_level_dialog_label": "AP 等級不足，無法參加",
  "button_done": "完成",
  "contest_item_view_label_legend_figure": "在練習房中，無法獲得獎金",
  "contest_item_view_label_first_prize": "頭獎＝ ${prize}",
  "contest_item_view_label_win_rate": "此房間將有 {joined} 隊獲勝",
  "contest_item_view_label_entry": "您最多可加入 {entries} 隊",
  "contest_item_view_label_ap_level": "需要 {apLevel} 等級或以上才能加入",
  "contest_item_view_label_otp": "需要 OTP 驗證",
  "contest_item_view_state_explanation_label_first_prize": "頭獎",
  "contest_item_view_state_explanation_label_win_rate": "此房間的勝率",
  "contest_item_view_state_explanation_label_single_entry": "您可加入一個隊伍",
  "contest_item_view_state_explanation_label_multiple_entry": "您可加入多個隊伍",
  "contest_item_view_state_explanation_label_ap_level": "最低需求等級",
  "contest_item_view_state_explanation_label_otp": "需要 OTP 驗證",
  "match_detail_page_label_prize_is_ideal": "獎金池為理想值",
  "contest_item_view_state_explanation_title": "圖示說明",
  "label_you": "您",
  "label_and": "和 ",
  "match_preparing_toast_label": "這場賽事的房間即將開放，敬請關注！",
  "tournament_filter_dialog_label_preparing_switch": "包含準備中的賽事",
  "tournament_filter_dialog_label_select_all": "選擇全部",
  "select_payment_page_label_select_method": "選擇支付方式",
  "select_payment_page_select_method_label_cryptocurrency": "加密貨幣",
  "payment_notice_modal_title": "用加密貨幣付款",
  "payment_notice_modal_rule_1_1": "我們只支援",
  "payment_notice_modal_rule_1_2": "透過乙太幣主鏈支付",
  "payment_notice_modal_rule_2": "請使用指定乙太幣數量，以及我們提供予您的唯一支付地址",
  "payment_notice_modal_rule_3": "支付地址為時間限制且只能使用一次",
  "payment_notice_modal_rule_4": "將會套用標準的網路費用",
  "payment_notice_modal_rule_5": "處理時間因網路而異。",
  "payment_notice_modal_rule_6": "請支付加密貨幣的總數，避免任何錯誤（無論是少付或多付）還是任何的流程延遲",
  "payment_notice_modal_warning": "警告：任何透過不支援的加密貨幣或網路付款，後果會讓您白白失去這筆錢。",
  "wallet_record_event_redeem": "兌換",
  "store_insufficient_balance": "餘額不足",
  "store_button_buy": "購買！",
  "store_transaction_successful": "交易成功",
  "store_process": "處理此訂單需要 {days}",
  "store_buy_dialog": "確認使用 {winning} 塊獎金來換 {rewardTitle} 嗎？",
  "store_item_sold_out": "此商品已售完",
  "store_button_ok": "OK",
  "store_new_order": "新訂單",
  "reward_list_page_title": "商店",
  "reward_list_page_label_empty_reward": "目前沒有任何商品",
  "reward_list_page_label_remind_country": "為了正確地將商品送到您手上，請確保選擇正確的國家",
  "reward_list_page_label_all_empty": "看起來過去三個月內沒有任何訂單。",
  "reward_list_page_label_processing_empty": "沒有任何處理中的訂單",
  "reward_list_page_label_completed_empty": "沒有任何已完成的訂單",
  "reward_order_history_page_title": "我的訂單",
  "my_matches_page_scorecard_label_score": "Score",
  "my_matches_page_scorecard_label_over": "Over",
  "referral_fail_dialog_label_validation_error": "驗證錯誤",
  "home_page_invite_code_invalid": "無效的邀請碼",
  "home_page_invite_code_not_found": "找不到房間，請檢查邀請碼。",
  "referral_fail_dialog_title_time_up": "時間到！",
  "referral_fail_dialog_label_cannot_add_referral": "您無法輸入推薦碼",
  "contest_detail_page_label_won_rubies": "贏得 {number} 寶石",
  "wallet_history_page_detail_title": "交易細節",
  "download_app": "下載我們的 App 來享受所有功能！",
  "reward_order_history_page_label_order_date": "訂購日期",
  "payment_cancelled_toast_label": "交易已取消",
  "reward_order_country": "國家",
  "label_more_than": "超過 {number}",
  "label_edit_team": "編輯隊伍",
  "label_about_item": "關於這個商品",
  "return_to_app": "返回 App",
  "label_data_updating": "資料更新中",
  "scorecard_not_available": "此賽事不提供計分板",
  "login_page_label_agree_rule": "註冊即表示您同意我們的 {termsAndConditions} 和 {privacyAndPolicy}",
  "login_page_label_agree_rule_app": "註冊即表示您同意我們的 $termsAndConditions$ 和 $privacyAndPolicy$",
  "player_starting": "先發",
  "player_substitute": "替補",
  "fantasy_sport_label_max_prize_pool": "最大獎金池",
  "login_page_label_send_email_fail": "因為我們發現有不尋常的活動，所以封鎖了這個設備的所有請求，請稍後再試。",
  "label_description": "描述",
  "label_transaction": "交易紀錄",
  "label_add_again": "再試一次",
  "wallet_record_event_refund_reward_order": "來自商店退款",
  "user_profile_view_label_reach_steel_level": "驗證以達到鋼鐵等級",
  "label_ap_level_steel": "鋼鐵",
  "label_preview": "預覽",
  "no_player_selected_tip": "尚未選擇任何球員",
  "label_start_selecting": "開始選擇",
  "player_substitute_or_unannounced": "替補或是尚未公開的球員",
  "reward_purchase_fail": "購買商品時發生了錯誤，請再次購買。",
  "label_sorry": "對不起",
  "label_see_more_completed_matches": "查看更多已結束的賽事",
  "button_delete": "刪除",
  "coupon_description": "這是 {percentage}% 折扣的優惠券，加入個房間吧！",
  "contest_detail_page_leaderboard_label_your_teams": "您的隊伍",
  "contest_item_view_state_explanation_label_prize_hunter": "只會獎勵總分最高的隊伍",
  "coupon_used_up_toast_label": "無優惠券",
  "stop_join_contest_dialog_label": "停止加入房間",
  "started_time_out_dialog_label_1": "賽事正在進行中，在開賽後",
  "started_time_out_dialog_label_2": "{period} 分鐘內，您仍可加入房間。",
  "contest_item_view_label_prize_hunter": "只會獎勵總分最高的隊伍",
  "free_coupon_description": "這是免費的優惠券，加入一個房間吧！",
  "free_coupon_title": "免費優惠券",
  "player_role_basketball_point_guard": "控球後衛",
  "player_role_basketball_shooting_guard": "得分後衛",
  "player_role_basketball_small_forward": "小前鋒",
  "player_role_basketball_power_forward": "大前鋒",
  "player_role_basketball_center": "中鋒",
  "label_universal": "通用",
  "label_india": "印度",
  "label_thailand": "泰國",
  "label_philippines": "菲律賓",
  "label_malaysia": "馬來西亞",
  "label_bangladesh": "孟加拉",
  "label_pakistan": "巴基斯坦",
  "label_cambodia": "柬埔寨",
  "label_taiwan": "台灣",
  "label_nigeria": "奈及利亞",
  "redemption_panel_title": "優惠碼",
  "redemption_panel_error_limit_10_char": "限制 10 個字元",
  "redemption_panel_error_data_not_found": "輸入的優惠碼不正確或不存在",
  "redemption_panel_button": "使用",
  "redemption_panel_input_hint": "輸入優惠碼",
  "button_view_more": "查看更多",
  "button_cancel": "取消",
  "redemption_panel_error_title_oops": "哎呀！",
  "redemption_panel_error_code_redeemed": "您已經使用過該優惠碼",
  "redemption_panel_error_title_missing_by_step": "慢了一步",
  "redemption_panel_error_no_quota": "已達使用額度上限",
  "redemption_panel_error_title_insufficient_ap": "AP 不足",
  "redemption_panel_error_insufficient_ap": "您的 AP 等級{currentLevel}不足以參與此活動，請升級 AP 等級至{minLevel}再次參加。",
  "redemption_panel_error_insufficient_ap_upgrade_button": "提升等級",
  "redemption_panel_no_reward_result": "今天不是您的幸運日，下次再說吧。",
  "label_pending": "等待中",
  "redemption_panel_coupon_result": "恭喜！您收到了一張折價 {discountPercentage}% 的優惠券。",
  "wallet_record_event_manual_add_winning": "手動給予獎金",
  "result_empty_match": "不要灰心，下一個機會在等您！",
  "result_match_missed": "哎呀！錯過賽事了！",
  "label_result": "賽果",
  "nationality_title": "國籍",
  "born_title": "出生於",
  "role_title": "位置",
  "height_title": "身高",
  "weight_title": "體重",
  "preferred_foot_title": "慣用腳",
  "in_your_teams_title": "在您的隊伍",
  "player_info_no_add_player_in_any_team": "您還沒在隊伍中加入此球員",
  "performance_title": "表現",
  "game_log_title": "對戰紀錄",
  "glossary_title": "名詞解釋",
  "player_info_no_info_available": "這名球員尚無相關資訊",
  "dialog_button_remove": "移除",
  "dialog_button_add": "添加",
  "label_create": "創建",
  "batting_style_title": "擊球風格",
  "bowling_style_title": "投球風格",
  "label_non_starters": "{number} 名非首發球員",
  "sure_to_clear_team_title": "確定清空隊伍嗎？",
  "sure_to_clear_team_description": "是否清空所有已選擇的球員？",
  "sure_to_go_back": "確定返回嗎？",
  "change_not_saved": "您的變更將不會儲存",
  "set_reminders_title": "設定提醒",
  "set_reminders_description": "為您最愛的賽事設定提醒，再也不會錯過任何一場囉！",
  "notification_subscribed_successful": "提醒的偏好設定已成功變更。",
  "label_history": "歷史紀錄",
  "complete_reward_order_title": "商品已送達",
  "error_internet_disconnected": "網路已斷線",
  "internet_disconnected_message": "您目前是離線狀態",
  "try_again": "再試一次",
  "contest_full_dialog_content": "房間已滿，請加入其他房間。",
  "contest_full_dialog_button_text": "即將開始的房間",
  "label_referral_program": "推薦好友",
  "label_referrals": "推薦",
  "label_ruby": "寶石",
  "label_product": "商品",
  "label_full_ruby": "寶石全額折抵",
  "label_full_ruby_discount": "可用寶石作 {rubyExchangeRate}:1 的全額入場費折抵",
  "label_confirmation_note": "我同意守則。",
  "label_confirmation_note_highlight": "服務約定條款",
  "none": "無",
  "redemption_panel_ruby_result": "恭喜！您已經收到 {ruby} 寶石",
  "nickname_input_error_char_lengths": "您的使用者名稱必須在 6 到 30 個字元之間。",
  "nickname_input_error_duplicate": "此使用者名稱已被使用，請再次嘗試。",
  "wallet_record_event_manual_add_ruby": "手動給予寶石",
  "toast_message_successfully_changed": "已成功變更",
  "edit_profile_page_title_editing": "編輯個人資料",
  "contest_promotional_title": "挑戰賽",
  "contest_promotional_subtitle": "低入場費，高額獎金！",
  "contest_prize_hunter_title": "獎金獵人",
  "contest_prize_hunter_subtitle": "只給您最高分的隊伍獎勵",
  "contest_ruby_normal_title": "寶石賽",
  "contest_practice_title": "練習賽",
  "contest_practice_subtitle": "測試你選球員的能力",
  "label_earn_ruby": "賺取寶石",
  "label_contest_code": "進入房間",
  "ruby_contest_champion_title": "冠軍！恭喜您！！",
  "ruby_contest_champion_content": "{nickname}，在 {squadA} vs {squadB} 的賽事表現得真好！加入公開房間，讓大家看看您有多行！",
  "wallet_record_event_discount_contest": "使用折扣加入房間",
  "wallet_record_event_redemption": "使用優惠碼",
  "label_captain_point_rate": "2 倍積分",
  "label_vice_captain_point_rate": "1.5 倍積分",
  "button_remove_players": "移除 {players} 位球員",
  "label_verify": "驗證",
  "store_process_days": "天",
  "store_email": "請注意您的 Email，感謝您的耐心等待。",
  "daily_task_item_title_watch_ad": "觀看廣告",
  "daily_task_item_description_watch_ad": "觀看一則廣告，完成後即可獲得獎勵。",
  "daily_task_item_button_watch_ad": "觀看",
  "daily_task_item_button_claim_reward": "領獎",
  "daily_task_panel_title": "每日任務",
  "daily_task_page_title": "任務",
  "daily_task_panel_label_reminder": "提醒",
  "daily_task_panel_label_upgrade": "升級將解鎖更豐富的獎勵。",
  "daily_task_tutorial_step_1": "當前等級",
  "daily_task_tutorial_step_2": "點擊或向左右滑動以查看下一級任務的獎勵。",
  "daily_task_tutorial_step_3": "點擊加入任務",
  "daily_task_page_task_complete": "任務完成",
  "daily_task_page_no_ad": "廣告準備中，請稍候再試！",
  "sport_tab_label_cricket": "板球",
  "sport_tab_label_football": "足球",
  "sport_tab_label_basketball": "籃球",
  "reward_list_page_button_win_more": "贏得更多",
  "reward_list_page_button_buy_now": "立即購買",
  "label_all": "全部",
  "match_card_label_prize": "總獎金",
  "match_card_label_mega": "挑戰賽",
  "wallet_view_button_ap_level": "AP 等級",
  "wallet_view_register_label": "從 {date} 起開始玩",
  "label_soon": "即將到來",
  "download_app_dialog_context": "下載 App 以獲得額外的獎勵",
  "wallet_record_event_claim_task": "任務",
  "me_page_career_stats_title": "生涯統計",
  "me_page_career_stats_label_top_score": "最佳成績",
  "me_page_career_stats_label_description": "最佳成績 {topScore} 贏過了 {pr}% 的玩家",
  "label_follow": "追蹤",
  "label_unfollow": "取消追蹤",
  "me_page_career_stats_label_no_data": "目前還沒有分數喔！快來參加比賽，享受樂趣吧！",
  "me_page_career_stats_label_data_from": "統計區間：",
  "label_followers": "粉絲",
  "label_following": "追蹤中",
  "transfer_disabled_insufficient_ap_level": "已達當日轉帳額度，欲轉帳請升級 AP 等級。",
  "transfer_disabled_unfollow": "請先追蹤，才能啟用轉帳功能。",
  "daily_task_item_title_watch_tutorial": "觀看教學",
  "daily_task_item_description_watch_tutorial": "觀看教學以獲得獎勵。",
  "daily_task_item_button_play_back": "再看一次",
  "career_stats_you": "您",
  "career_stats_playing_since": "開始遊玩時間",
  "user_profile_page_friend_full_tip": "追蹤數量已達上限 {maxFollowing} 人。",
  "contest_card_button_free": "免費",
  "label_winning": "獎金",
  "label_coins": "金幣",
  "button_pay_with_winning": "使用 {winning} 塊獎金轉金幣支付",
  "label_return_on_investment": "投資報酬率",
  "label_roi": "ROI",
  "daily_task_page_task_incomplete": "任務未完成",
  "label_injury": "傷病",
  "label_scoring": "計分規則",
  "label_player_stat": "球員數據",
  "label_start": "開始",
  "contest_card_label_glory_awaits": "榮耀將至",
  "label_injury_playing_chance_probable": "極有機會上場",
  "label_injury_playing_chance_questionable": "有機會上場",
  "label_injury_playing_chance_doubtful": "可能不上場",
  "label_injury_playing_chance_out": "不會上場",
  "referral_program_page_label_description": "分享推薦碼，每個朋友最多獲得 {maxRuby} 寶石",
  "referral_program_page_label_invite_friend": "邀請朋友註冊",
  "referral_program_page_label_collect_ruby": "每次獲得 {returnToReferrerPercentage}% 寶石",
  "daily_task_page_label_remember_open": "通知已開啟",
  "daily_task_page_label_remember_cancel": "通知已關閉，記得領取明天領取獎勵",
  "welcome_page_button_earn_from_tutorial": "觀看教學以獲得 500 以上寶石",
  "label_email_not_found": "Email 不存在",
  "user_delete_page_title": "刪除您的帳號",
  "user_delete_page_description": "若您欲刪除帳號，請先驗證您的信箱。",
  "suspicious_email_dialog_label_1": "存在風險的電子信箱",
  "suspicious_email_dialog_label_2": "請使用其他可信賴的電子信箱",
  "user_delete_warn_dialog_title": "您確定要刪除帳號嗎？",
  "login_page_email_not_available": "Email 無法使用，請使用其他 Email。",
  "login_page_or_register_with": "或透過以下方式註冊",
  "login_page_or_login_with": "或透過以下方式登入",
  "user_account_been_deleted": "您的帳號已被刪除。",
  "auth_select_language_title": "選擇應用程式語言",
  "check_email_page_label_check_email_from_1": "您將會收到驗證信來自",
  "label_enter_referral_code": "輸入推薦碼",
  "enter_referral_code_label": "輸入推薦碼獲得 {rubies} 寶石 ",
  "label_submit": "送出",
  "referral_code_enter_later": "稍後再輸入",
  "contest_item_view_state_explanation_label_minimum_slip": "隊伍數未達將取消比賽，退還入場費",
  "contest_item_view_state_explanation_label_allow_discount_with_ruby": "允許使用寶石來折抵入場費",
  "entry_tutorial_step_title_1": "選擇賽事",
  "entry_tutorial_step_description_1": "參與一場即將到來的賽事",
  "entry_tutorial_step_title_2": "加入房間",
  "entry_tutorial_step_description_2": "與其他玩家同場競技，以獲得豐厚獎勵",
  "entry_tutorial_step_title_3": "創建隊伍",
  "entry_tutorial_step_description_3": "挑選合適的球員建立你的夢幻隊伍取得 Fantasy 分數",
  "entry_tutorial_step_title_4": "選擇隊長",
  "entry_tutorial_step_description_4": "為你的夢幻隊伍選擇正副隊長，加倍獲得更高的 Fantasy 分數",
  "login_page_title_login": "開始登入",
  "login_page_description": "在此輸入您的資訊",
  "login_page_title_sign_up": "開始註冊",
  "send_reset_password_dialog_title": "已送出重設密碼郵件",
  "send_reset_password_dialog_description": "請確認信箱以重設您的密碼",
  "send_reset_password_dialog_back_to_login": "回到登入",
  "forgot_password_page_description": "輸入信箱來重設您的密碼",
  "label_login_trouble": "登入遇到問題？",
  "label_contact_support": "聯繫客服",
  "contest_item_view_label_minimum_slip": "比賽將在參賽隊伍數量低於 {minimumSlip} 隊時取消",
  "referral_code_is_required": "推薦碼是必填",
  "language_name": "繁體中文（台灣）",
  "match_card_label_lineups_out": "先發已公布",
  "login_page_guest_login_button_label": "訪客登入",
  "anonymous_user_dialog_title": "立即綁定帳號",
  "anonymous_user_dialog_link_account_button": "綁定帳號",
  "anonymous_user_transfer_dialog_description": "需綁定帳號成為正式玩家才能使用轉帳",
  "anonymous_user_redemption_code_dialog_description": "需註冊成為正式玩家才能使用優惠碼",
  "anonymous_user_ad_dialog_description": "綁定帳號以解鎖更多功能！",
  "anonymous_user_store_dialog_description": "需綁定帳號成為正式玩家才能使用商店",
  "me_page_menu_label_link_account": "綁定帳號",
  "user_bind_page_title": "立即綁定帳號！",
  "user_bind_page_label_description": "綁定帳號以解鎖更多功能！",
  "contest_item_view_label_allow_discount_with_ruby": "允許寶石折抵",
  "user_bind_page_confirm_dialog_title": "確認 Email",
  "user_bind_page_confirm_dialog_description": "將使用 {email} 進行綁定，綁定後將無法更換 Email。",
  "user_bind_success_dialog_title": "綁定成功",
  "featured_contest_title": "精選賽事",
  "featured_contest_more_coming_soon": "敬請期待",
  "featured_contest_card_entry": "入場費",
  "label_id": "ID",
  "career_stats_contests": "房間",
  "career_stats_matches": "賽事",
  "label_are_you_sure": "您確定嗎？",
  "label_new": "New",
  "toggle_full_ruby_toast_label": "擇一：優惠券或寶石",
  "wallet_record_event_redeem_winning": "兌換成金幣",
  "wallet_record_event_redeem_deposit": "獎金兌換",
  "auth_select_language_submit_button": "將語言設定為繁體中文",
  "check_email_page_label_check_email_2_with_var_mins": "{email}，請在 {minutes} 分鐘內，點擊開啟信件中的連結。",
  "label_sure": "確定",
  "daily_task_item_title_watch_video": "觀看影片",
  "daily_task_item_description_watch_video": "觀看影片以獲得獎勵。",
  "daily_task_item_title_watch_video_tutorial_1": "遊戲中的運動類型",
  "daily_task_item_description_watch_video_tutorial_1": "完成第一個教學任務以獲得紅寶石獎勵",
  "daily_task_item_title_watch_video_tutorial_2": "貨幣介紹",
  "daily_task_item_description_watch_video_tutorial_2": "完成第二個教學任務以獲得紅寶石獎勵",
  "daily_task_item_title_watch_video_tutorial_3": "比賽介紹",
  "daily_task_item_description_watch_video_tutorial_3": "完成第三個教學任務以獲得紅寶石獎勵",
  "daily_task_item_title_watch_video_tutorial_4": "如何創建隊伍",
  "daily_task_item_description_watch_video_tutorial_4": "最終教學：創建夢幻隊伍並獲得紅寶石獎勵",
  "label_save": "儲存",
  "change_nickname_dialog_title": "修改使用者名稱",
  "change_nickname_dialog_content": "使用者名稱必須在 6 到 30 個字元之間，包含數字、英文、「 . 」、「 _ 」",
  "nickname_input_error_format_letters_and_numbers": "抱歉，只能用數字、英文、「 . 」、「 _ 」。",
  "user_bind_page_or_link_account_with": "或透過以下方式綁定",
  "user_bind_page_button_link_account": "綁定帳號",
  "user_bind_page_alert_email_duplicate": "此電子信箱已被註冊，請使用其他信箱。",
  "add_deposit_from_crypto_currency_hint": "我們的網站支援虛擬貨幣購買金幣。若您希望使用信用卡購買金幣，請下載我們的 App。",
  "login_page_alert_use_another_provider": "若登入遇到問題，請切換至另一種登入方式 - 如果您先前嘗試了信箱及密碼，請改用社交登入，反之亦然。您也可以使用「訪客遊玩」。",
  "add_deposit_page_alert_use_credit_card": "Our website supports the purchase of coins with virtual currency. If you wish to buy coins using a credit card, please download our app using the QR code at bottom right.",
  "label_overview": "總覽",
  "label_basketball_empty_news": "目前沒有關於這位球員的新聞",
  "login_page_button_apple_sign_up": "以 Apple 帳號註冊",
  "login_page_button_google_sign_up": "以 Google 帳號註冊",
  "login_page_button_apple_log_in": "以 Apple 帳號登入",
  "login_page_button_google_log_in": "以 Google 帳號登入",
  "share_contest_dialog_link_copied": "已複製邀請連結！",
  "anonymous_user_store_dialog_button_redeem_all": "將所有獎金兌換為金幣",
  "new_user_dialog_title": "恭喜您",
  "new_user_dialog_description": "獲得了一張八折的優惠券",
  "store_need_more_coins": "您需要更多的獎金才能購買。加入房間並贏得更多！",
  "order_fail_dialog_title": "購買失敗",
  "order_fail_dialog_content": "訂單已被取消。請再次購買，或是前往歷史交易紀錄。",
  "order_processing_dialog_toast_deposit_processing": "您的儲值正在處理中",
  "wallet_record_event_game_trier_daily_deposit": "試玩員每日贈幣",
  "wallet_record_event_revoke_game_trier_daily_deposit": "撤銷試玩員每日贈幣",
  "redemption_panel_description": "可獲得多種獎勵，像是金幣、寶石、優惠券和更多好康！現在開始就享有吧！",
  "redemption_panel_deposit_result": "恭喜！您已收到 {deposit} 的金幣。",
  "wallet_record_event_manual_add_deposit": "手動給予金幣",
  "transfer_page_label_negative_deposit": "餘額不足，禁止轉帳",
  "winning_tooltip_dialog_label": "金幣房間的獲勝獎金",
  "wallet_record_event_add_deposit_from_store": "由商店購買",
  "wallet_record_event_refund_deposit": "退款",
  "wallet_record_event_first_deposit": "首次購買金幣的獎勵",
  "activity_point_page_current_section_label_ap_add_deposit": "AP (購買金幣)",
  "transfer_success_dialog_label": "您已經成功轉了 {number} 獎金給 {username}",
  "add_deposit_page_label_first_deposit": "首次購買",
  "add_deposit_page_label_bonus": "獎勵",
  "label_add_deposit": "購買金幣",
  "referral_program_page_label_collect_ruby_description": "當被推薦的朋友使用金幣加入房間，您可以獲得入場費 {returnToReferrerPercentage}% 寶石 (最多{maxRuby})",
  "wallet_record_event_withdraw_deposit": "提領金幣",
  "add_deposit_page_detail_end": "寶石！",
  "deposit_tooltip_dialog_label": "金幣可作為加入房間的入場費。",
  "label_deposit": "金幣",
  "store_present_deposit": "當前金幣",
  "contest_detail_page_label_won_coins": "贏得 {number} 獎金",
  "user_delete_warn_dialog_description": "您的錢包將會被清空！ 您的錢包仍有金幣 {deposit}、獎金 {winning}和寶石 {ruby}。",
  "check_email_page_label_check_email": "信件將會寄送到 {email}，連結有效時間為 24 小時。沒有收到信件？確認您的垃圾郵件！",
  "check_email_page_label_check_email_1": "信件將會寄送到",
  "check_email_page_label_check_email_2": "{email}。 連結有效時間為 24 小時。",
  "check_email_page_label_check_email_3": "沒有收到信件？確認您的垃圾郵件！",
  "new_user_ruby_popup_title": "領取雙重好禮",
  "new_user_ruby_dialog_description": "驚不驚喜？你已經獲得 {ruby} 顆紅寶石，作為你開始遊戲的禮物！\n\n但等等，還有更多...",
  "new_user_ruby_dialog_confirm_btn": "還有更多！",
  "new_user_coupon_dialog_title": "更多驚喜！",
  "new_user_coupon_dialog_description": "現在就享受你的 {discountPercentage}% 折扣，並透過每日任務賺取更多的紅寶石\n",
  "new_user_coupon_dialog_confirm_btn": "探索每日任務",
  "new_user_coupon_dialog_cancel_btn": "稍後再說",
  "wallet_view_button_withdraw": "提領",
  "kyc_do_not_show_again": "今日不再顯示",
  "kyc_start_verification": "開始驗證",
  "kyc_verify_to_withdraw": "驗證您的帳戶以提領",
  "kyc_verify_to_withdraw_content_1": "為了提領，請先完成驗證，這可確保您的帳戶安全，並符合法規要求。",
  "kyc_verify_to_withdraw_content_2": "如果您之前的驗證未成功，請重新提交您的文件，再次進行驗證。",
  "kyc_unverify_hint": "需驗證以提領獎金",
  "kyc_do_it_later": "稍後再輸入",
  "label_verify_account": "驗證帳戶",
  "task_label_buy_coins": "購買",
  "task_label_buy_any_coins": "任意購買",
  "label_info": "Info",
  "label_player_stats": "Player Stats",
  "add_coin_page_first_purchase_title": "首次購買",
  "add_coin_page_first_purchase_hint": "獲得 {rubyPercentage}%  的紅寶石 + 鋼鐵升級",
  "add_coin_page_first_purchase_description": "在您首次購買 {minCoin} 枚以上的硬幣時，馬上可以享受 {rubyPercentage}%  的紅寶石加成！",
  "add_coin_page_extra_bonus_title": "額外的紅寶石獎勵",
  "add_coin_page_extra_bonus_hint": "獲得 {rubyPercentage}%  的紅寶石加成",
  "add_coin_page_extra_bonus_description": "首次購買 {minCoin} 枚以上的硬幣？購買馬上享受 {rubyPercentage}%  的紅寶石加成！",
  "add_coin_page_upgrade_to_steel_title": "等級提升",
  "add_coin_page_upgrade_to_steel_hint": "升級成鋼鐵等級",
  "add_coin_page_upgrade_to_steel_description": "任意購買可以升級成鋼鐵等級！",
  "wallet_view_label_negative_coin_balance": "餘額不足，無法使用特定功能。",
  "label_verification_successful": "驗證成功",
  "kyc_verification_successful_content_1": "恭喜！您的賬戶已完成驗證，所有功能包括提款均已解鎖。",
  "kyc_verification_successful_content_2": "享受增強安全性帶來的完整遊戲體驗。",
  "kyc_verification_successful_enjoy": "享受遊戲",
  "match_info_series": "聯賽",
  "match_info_start_time": "比賽時間",
  "match_info_venue": "場地",
  "match_info": "比賽資訊",
  "match_info_player_to_watch": "值得關注的球員",
  "result_tab_info": "資訊",
  "result_tab_player_stats": "球員數據",
  "result_tab_info_leaderboard": "排行榜",
  "kyc_completed_hint": "驗證已完成 - 即刻暢玩所有功能！",
  "label_verification_completed": "驗證已完成",
  "kyc_verification_welcome_title": "歡迎來到 Daily Fantasy 驗證流程！",
  "label_verification_in_progress": "驗證進行中",
  "verification_in_progress_content_1": "您的文件已成功提交，目前正在審核中。",
  "verification_in_progress_content_2": "驗證過程最多可能需要數個工作天的時間。一旦驗證完成，您將收到通知。感謝您的耐心等候。",
  "label_return_to_home": "返回首頁",
  "verfication_successful_content": "恭喜！您的驗證已成功完成。您現在可以完全存取所有平台功能，包括提領。\n\n感謝您抽出寶貴時間完成這個重要的步驟。現在就盡情享受遊戲吧！",
  "kyc_verification_process": "Daily Fantasy 驗證流程",
  "label_exit": "離開",
  "kyc_verification_process_welcome_content": "在您提領之前，我們需要確保確實是您本人。 這是一項快速安全檢查，有助於確保您和您的資金安全。 請提供以下資訊以開始提取獎金：",
  "kyc_verification_form_title_part_with_var": "基本資訊 - 第 {part} 部分",
  "kyc_verification_form_title_id_verification": "身份驗證",
  "kyc_verification_form_title_picture_with_id": "附身分證的正面照",
  "kyc_verification_form_description_id_verification": "請上傳身分證正反面的清晰圖片。 可接受的身分證件形式包括：護照、駕照或政府核發的身分證。 確保所有細節都可見且影像不模糊。",
  "kyc_verification_form_description_picture_with_id": "將您的身分證件放在臉部旁邊並拍一張正面照片。這有助於我們驗證您的身分，確保該身分證件屬於您。\n請確保您的臉部和身份證件上的詳細訊息清晰可見。",
  "label_first_name": "名字",
  "label_last_name": "姓氏",
  "label_date_of_birth": "出生年月日",
  "label_nationality": "國籍",
  "label_current_address": "現居地址",
  "label_permanent_address": "永久地址",
  "label_contact_information": "聯絡資訊",
  "label_nature_of_work": "職業",
  "label_enter_your_id_number": "輸入證件號碼",
  "label_change_image": "點擊或拖放以上傳不同的圖片",
  "placeholder_year": "年（西元）",
  "placeholder_month": "月份",
  "placeholder_date": "日期",
  "placeholder_document_type": "證件類型",
  "placeholder_id_number": "證件號碼",
  "placeholder_phone_number": "電話號碼",
  "placeholder_upload_front_side_id": "在此上傳或拖放證件正面照片",
  "placeholder_upload_back_side_id": "在此上傳或拖放證件反面照片",
  "placeholder_upload_picture_with_id": "在此上傳或拖放附身分證的正面照片",
  "placeholder_upload_file_max_size": "檔案大小限制為 {fileMaxSizeMB}MB 以下",
  "required_error_first_name": "請輸入您的名字",
  "required_error_last_name": "請輸入您的姓氏",
  "required_error_date_of_birth": "請輸入您有效的出生年月日",
  "required_error_nationality": "請選擇您的國家",
  "required_error_current_address": "請輸入您的現居地址",
  "required_error_permanent_address": "請輸入您的永久地址",
  "required_error_contact_information": "請輸入您的聯絡資訊",
  "required_error_nature_of_work": "請輸入您的職業",
  "required_error_id_number": "請輸入您的證件號碼",
  "required_error_upload_front_side_id": "請上傳您的證件正面照片",
  "required_error_upload_back_side_id": "請上傳您的證件反面照片",
  "required_error_upload_picture_with_id": "請上傳您的附身分證的正面照片",
  "invalid_type_error_upload_image": "請上傳小於 {fileMaxSizeMB}MB 的 JPG 或 PNG 格式圖片",
  "invalid_type_error_contact_information": "請輸入有效的電話號碼",
  "kyc_verification_incomplete_content": "如果您現在離開，您將退出驗證流程，您目前的進度將不會被保存。\n下次您需要重新填寫表單並完成驗證，此驗證為提領的必要步驟。\n\n您確定要放棄已填寫的表單，直接回到遊戲嗎?",
  "label_verification_incomplete": "驗證未完成",
  "label_exit_and_lose_progress": "離開並取消流程",
  "label_continue_verification": "繼續驗證流程",
  "main_page_bottom_bar_tab_my_games": "戰報",
  "wallet_view_label_ruby_usage": "{rubyExchangeRate} 寶石可以折抵 1 金幣的入場費",
  "contest_deposit_normal_subtitle": "贏得比賽，獲得獎金，兌換商品",
  "contest_ruby_normal_subtitle": "在每日任務中獲得免費的寶石",
  "label_my_games": "戰報",
  "upcoming_slates_label_coming_soon": "敬請期待",
  "kyc_verification_unsuccessful_resubmit": "重新提交驗證",
  "kyc_await_hint": "您的驗證作業目前正在進行中，請稍侯。",
  "kyc_await_to_withdraw": "提領驗證審核中",
  "kyc_await_to_withdraw_content": "您的驗證目前正在進行中，請給我們一些時間仔細審查您提交的資料。我們正努力確保您的帳戶安全，並遵守相關法規。驗證完成後，您將能夠提領。感謝您的耐心等待，我們將盡快處理。",
  "label_return_to_game": "返回遊戲",
  "cricket_quick_summary_snapshot_sync": "資料正在同步中，就快整理好了",
  "wallet_record_event_cancelled_user_withdraw_winning": "提領已撤銷",
  "activity_point_page_upgrade_section_label_level": "等級",
  "activity_point_page_upgrade_section_label_ap_needed": "需要AP",
  "label_support_link": "客服中心",
  "wallet_view_support_link": "需要協助嗎？ 現在就與我們的支援團隊聯繫吧",
  "ap_overview_view_label_beginner_next_level": "購買任意金幣以達到「鋼鐵」",
  "me_ap_hint_buy_any_coins": "購買任意金幣",
  "daily_task_item_title_watch_video_daily_tutorial_1": "如何加入比賽",
  "daily_task_item_title_watch_video_daily_tutorial_2": "每日任務與 AP 等級",
  "daily_task_item_title_watch_video_daily_tutorial_3": "如何使用寶石",
  "daily_task_item_title_watch_video_daily_tutorial_4": "增加您的勝率",
  "daily_task_item_description_watch_video_daily_tutorial_1": "快速遊玩正在進行的比賽",
  "daily_task_item_description_watch_video_daily_tutorial_2": "每天領取你的免費寶石",
  "daily_task_item_description_watch_video_daily_tutorial_3": "了解 RUBY 在遊戲中的用途",
  "daily_task_item_description_watch_video_daily_tutorial_4": "從新手到高手",
  "label_create_a_new_team_style_1": "建立一個新隊伍",
  "label_create_a_new_team_style_2": "建立一個新隊伍",
  "create_nine_cat_slate_team_description": "選擇一個數據或球員開始。再次點擊以將你的球員分配到選定的數據。",
  "label_clear_all": "全部清除",
  "label_points_scored": "得分",
  "label_field_goals_efficiency": "得分效率",
  "label_free_throws_efficiency": "罰球效率",
  "label_offensive_rebounds": "進攻籃板",
  "label_defensive_rebounds": "防守籃板",
  "label_assists": "助攻",
  "label_steals": "抄截",
  "label_blocks": "阻攻",
  "label_three_pointers_made": "三分球命中數",
  "label_search": "搜尋",
  "label_filter": "篩選",
  "label_selected_teams_for_contest": "選定的參賽隊伍",
  "label_view_my_team": "查看我的隊伍",
  "player_info_popup_label_last_5_games": "最近五場比賽",
  "player_info_popup_label_player_news": "球員新聞",
  "label_select": "選擇",
  "player_info_popup_label_no_game_data": "似乎沒有過去比賽資料",
  "nine_cat_popup_title": "什麼是 9-CAT?",
  "nine_cat_popup_description": "9-CAT 是遊戲中的一種特殊玩法，它允許用戶從所有每日比賽中挑選9位NBA球員，並將他們安排在你認為球員可以表現最佳的數據中。\n\n用戶有 9 個數據可以配置，分別是PTS、FGE、FTE、OR、DR、AST、ST、BLK 和 3PM。",
  "nine_cat_leaving_create_team_popup_title": "注意！",
  "nine_cat_leaving_create_team_popup_description": "如果你現在離開，你的組隊將不會被儲存。這意味著你到目前為止做出的所有出色的選擇和動作都將消失。下次你需要從頭開始。\n\n何不多停留一會兒，讓你的夢幻隊伍成真？",
  "nine_cat_leaving_create_team_popup_button_leave": "離開並丟失進度",
  "nine_cat_leaving_create_team_popup_button_continue": "繼續建立隊伍",
  "nine_cat_scoring_description_fte": "在 9-Cat 中，「FTE」代表「罰球效率」，是球員在罰球方面的技巧指標。就像場上投籃一樣，不僅僅是他們投中的罰球數（FTM）重要，他們的罰球命中率（FT%）也同樣重要。罰球效率透過將 FTM 與 FT% 相乘來計算，直接反映了球員在罰球時的精確性和效率。\n\n罰球看似簡單，但在關鍵時刻它們對勝負有著不可忽視的影響。每增加 1 個點數的 FTE，都能為你的 9-Cat 隊伍帶來 3 分。這獎勵了那些在罰球線上冷靜且精確的球員。重要的不是你站上罰球線多少次，而是每一次都要讓它有效。\n\n例如，James Harden 站上罰球線 50 次，成功 10 次，則他的罰球命中率（FT%）為 20% 。我們將他的罰球命中數 10（FTM）乘以他的罰球命中率 0.2（FT%，轉換為小數進行計算）—— 所以，10（FTM）x 0.2（FT%）= 2 FTE。\n\n因為在 9-Cat 中，每一點 FTE 的價值是三倍，所以 Harden 的 2 FTE 會轉換為你的隊伍的 6 分。這顯示了選擇在罰球線上可靠的球員可能成為改變比賽的原因。每一次成功的罰球都能讓你更接近勝利。",
  "nine_cat_scoring_description_pts": "在籃球領域，特別是 NBA 中，「PTS」代表「得分」。這是球員在比賽中積累的分數。得分可以通過幾種方式獲得：\n\n從三分線內投進的普通投籃得 2 分。\n從三分線外投進的投籃得 3 分。\n每個成功的罰球得 1 分。\n\n在 9-Cat 中，球員在實際 NBA 比賽中的得分會轉化為你隊伍的分數，NBA 中的 1 PTS = 1 分。\n\n這表示如果 LeBron James 今晚的 NBA 比賽得到 30 分，你的隊伍將獲得 30 分。每一分球場上的得分在 9-Cat 中都會一比一地轉化為你隊伍的分數，體現出選擇得分能力強的球員的戰略價值！",
  "nine_cat_scoring_description_fge": "在 9-Cat 中，「FGE」代表「投籃效率」。這項統計數據涉及到球員的投籃表現，可以將其看成是他們投中的球數（FGM）和射門準確率（FG%）的組合。球員的 FGE 是他們的 FGM 與 FG% 的乘積，顯示著高效得分的能力。\n\n在 9-Cat 中，每增加 1 個點數的 FGE，都能為你的隊伍帶來 3 分。這意味著準確射門的球員非常有價值。\n\n想象一下，如果 Steph Curry 在一場比賽裡， 20 次射門投中了 10 球，這代表他有 50% 的投籃命中率（因為 10 投中 / 20 次射門 = 0.5 或 50%）。在 FGE 公式中，我們將他的投中球數 10（FGM）乘以他的投籃命中率 0.5（FG%，轉換為小數進行計算） —— 所以，10（FGM）x 0.5（FG%）= 5 FGE。\n\n又由於在 9-Cat 中，每一點 FGE 的價值翻三倍，所以 Steph Curry 的 5 FGE 會轉換為你的隊伍的 15 分。因此，選擇射門準確的球員可以幫助你更接近勝利。",
  "nine_cat_scoring_description_or": "在籃球領域，尤其是 NBA 中，「OR」代表「進攻籃板」。這項統計數據突出了在球隊投失之後，搶先一步在對手之前抓到球的球員。這是球員奮鬥精神、決心以及在合適時間出現在正確位置的證明。\n\n在 9-Cat 中，每個進攻籃板能為你的隊伍帶來 6 分。這意味著能夠搶到這些籃板的球員可以改變比賽局面。得到進攻籃板的分數，不僅表示球員跳得高，更表示球員對於得分的渴望、判斷時機的能力以及預判球落點的技巧。\n\n如果 Russell Westbrook 在一場比賽中搶到 5 個進攻籃板，則在 9-Cat 中，這 5 個 進攻籃板將轉化為你的隊伍的 30 分。這展示了全力以赴的重要性以及它將顯著的影響比賽結果。選擇善於獲得進攻籃板分數的球員，可以大幅提升你的隊伍的分數，證明了努力和決心會有很大的回報。\n",
  "nine_cat_scoring_description_dr": "在籃球領域，特別是 NBA，「DR」代表了「防守籃板」。這項統計數據彰顯了那些在對手投籃失敗後接住球的球員。它展示了球員有效結束防守回合並啟動球隊進攻的能力。獲得防守籃板的分數，需要球員的努力、良好的位置感和快速的決策能力。\n\n在 9-Cat 中，每個防守籃板能為你的隊伍帶來 3 分。這意味著擅長搶到防守籃板的球員很重要。他們幫助隊伍阻止對手進攻，並啟動自己隊伍的攻勢。\n\n想像 Giannis Antetokounmpo 在一場比賽中搶到 10 個防守籃板，在 9-Cat 中，這 10 個防守籃板會為你帶來 30 分。選擇擅長搶防守籃板的球員可以提升你的分數，證明強大的防守能改變比賽的結果。",
  "nine_cat_scoring_description_ast": "在籃球領域，特別是 NBA，「AST」代表的是「助攻」。這項統計數據表示那些能夠巧妙傳球並協助隊友得分的球員。助攻不僅體現了球員的團隊合作精神，還顯示了他們出色的場上視野和對比賽情勢的深刻理解，這些都是推動團隊進攻流暢性的重要因素。\n\n在 9-Cat 中，每一次成功的助攻都會為你的隊伍增加 2.5 分，這彰顯了球員在策略安排和球隊進攻中的關鍵角色。擅長助攻的球員不僅能夠創造得分機會，也能透過精確的傳球控制比賽節奏。\n\n舉例來說，如果 Chris Paul 在一場比賽中為隊友送出了 10 次助攻，在 9-Cat 中，這 10 次助攻將為你的隊伍增加 25 分。這顯示了助攻高手如何在比賽中發揮控制和影響力。選擇擅長助攻的球員，以提升整體隊伍表現，並證明精確的傳球和團隊合作的是達成勝利的關鍵策略。",
  "nine_cat_scoring_description_st": "在籃球領域，特別是 NBA，「ST」代表的是「抄截」。這項統計突顯了那些能敏銳地從對手手中斷球的球員。抄截不僅是反應快速的表現，它也展示了球員的觀察力，以及對比賽流程的精準掌控。抄截是將防守轉為進攻的關鍵一環。\n\n在 9-Cat 中，抄截的價值非常顯著。每次成功的抄截都會為你的隊伍增加 7.5 分，這彰顯了球員在防守時機與執行力上的卓越能力。擅長抄截的球員不僅能夠預測和破壞對手的進攻，還能迅速將其轉換成自己的得分機會。\n\n假設 Jimmy Butler 在一場比賽中完成了 4 次抄截，在 9-Cat 中，這 4 次抄截將為你的隊伍增加 30 分。這凸顯了抄截高手如何在比賽中發揮決定性的作用。選擇這類球員可以大幅提升你的隊伍表現，證明了積極的防守轉換是獲得勝利的重要策略。",
  "nine_cat_scoring_description_blk": "在籃球的世界中，「BLK」代表的是「阻攻」，在口語中也常被稱作「火鍋」。這項統計數據特別表彰那些能夠成功阻擋對手投籃得分的球員。阻攻不只是防守的一部分，它還展示了球員的精準時機感、卓越運動天賦，以及守護籃筐的決心，是阻止進攻、改變比賽節奏的關鍵動作。\n\n在 9-Cat 中，阻攻的價值非常重要。每成功一次阻攻，就能為你的隊伍增加 7.5 分，突出了球員在抗衡對手進攻時的核心作用。技術高超的阻攻手不僅能守住陣地，還能透過粉碎對方的進攻來改變比賽走向。\n\n舉例來說，如果 Anthony Davis 在一場比賽中完成了 5 次阻攻，那麼在 9-Cat 中，這 5 次阻攻將為你的隊伍增加 37.5 分。這顯示了精通阻攻的球員能對比賽產生的決定性影響。選擇這樣的球員能顯著提高你的隊伍整體表現，並證明了堅實的防守是取得勝利的重要關鍵。",
  "nine_cat_scoring_description_3pm": "在籃球領域，尤其是 NBA 中，「3PM」 指的是三分球的命中次數 。這項統計數據為那些能在三分線之外得分的球員喝采。投進三分球不只是長距離射籃，它能拉開對手的防守，進而為遊戲開闢了新局面。\n\n在 9-Cat 中，每投進一個三分球，你的隊伍就能獲得 4 分，這彰顯了遠距離投射的技巧。精於三分球的球員能迅速翻轉比賽局勢，壓迫對手。\n\n想像如果 Stephen Curry 在一場比賽中投進 5 個三分球。在 9-Cat 中，這 5 個三分球將會為你的隊伍帶來 20 分。選擇以三分球見長的球員可以提升你的得分，這體現了精準的長距離射籃對勝利的作用",
  "invite_friend_page_copy_message_content_1": "贈送 {rubies} 寶石讓您開始遊玩 {appName}，您認為您贏得了我嗎？",
  "invite_friend_page_copy_message_content_2": "1. 前往下載 {appName} App： {url}",
  "invite_friend_page_copy_message_content_2_daily": "1. 前往 {appName} 網站：{url}",
  "invite_friend_page_copy_message_content_3": "2. 使用我的推薦碼： {code}",
  "invite_friend_page_copy_message_content_4": "3. 獲得 {rubies} 寶石開始遊玩 ",
  "invite_friend_page_copy_message_content_5": "讓遊戲開始吧！",
  "match_detail_page_bonus_banner_title": "獎勵時間！",
  "match_detail_page_bonus_banner_description": "我們在賽局中多加了一些獎金",
  "confirmation_dialog_label_rule": "數據不一致時，以系統即時數據為準。加入房間即表示您接受 {appName} 並確認您不是印度安得拉邦、阿薩姆邦、那加蘭邦、奧裡薩邦、錫金或特倫甘納邦的居民",
  "chat_room_page_label_system_announcement": "歡迎來到 {appName} 聊天室！請遵守聊天室禮儀並保護您的隱私。",
  "ruby_tooltip_dialog_label": "寶石是 {appName} 免費贈送給玩家的，可用來加入任何公開房間，有效期為 14 天，會依到期時間決定使用順序。",
  "add_deposit_page_detail_front": "註冊 {appName}，首次購買取得",
  "referral_program_page_label_invite_friend_description": "邀請朋友並分享在 {appName} 上獲得的樂趣",
  "me_page_label_share_icon": "準備好追蹤比賽了嗎？ 點擊連結立即開始關注朋友 {nickname} 在 {appName} 等你",
  "auth_select_language_description": "您可以之後在 {appName} 內再更改語言",
  "user_bind_page_confirm_dialog_description_2": "注意：請使用可信任的 Email 服務綁定帳號，例如 Gmail、Outlook、Yahoo 或 iCloud，若使用可疑或不可靠的第三方 Email 服務，您將可能無法繼續遊玩 {appName}。",
  "match_card_label_bonus": "額外獎金",
  "supplier_link_popup_label_1": "本內容由 {supplierName} 提供。如需更多詳細資訊，敬請參訪他們的官方網站(",
  "supplier_link_popup_label_2": ")。",
  "button_confirm_and_join": "確認加入",
  "label_link_copied": "連結已複製。",
  "all_spots_filled": "參加人數已滿",
  "spots_filled_content": "此房間已達參賽人數上限。\n不過別擔心，還有更多精彩賽事等著你參加！",
  "button_join_another": "參加其他房間",
  "button_upgrade_ap_level": "升級 AP 等級",
  "dialog_insufficient_ap_level_title": "AP 等級不足",
  "dialog_insufficient_ap_level_content": "您的 AP 等級目前為 {currentApLevel}，但您至少需要達到 {minApLevel} 才能入場。 請升級您的 AP 等級或探索其他房間。",
  "dialog_teams_already_joined_title": "隊伍已加入房間",
  "dialog_teams_already_joined_content_1": "您的以下隊伍已經加入房間",
  "dialog_teams_already_joined_content_2": "請更換你的隊伍",
  "button_change_team_selection": "更換隊伍",
  "join_contest_label_join_contests_with_selected_teams": "以選取的隊伍加入房間",
  "button_back_to_home": "回到首頁",
  "dialog_join_contest_successfully_content": "您已將隊伍 {teams} 加入 {slateName} {entryFee} {entryFeeType} 房間！",
  "dialog_join_contest_successfully_title": "加入成功",
  "label_no_discount": "不使用折抵",
  "label_unit_rubies": "寶石",
  "label_unit_coins": "金幣",
  "label_prizes_and_distribution": "獎金分配",
  "label_shared": "均分：{sharedPrize}",
  "label_coin": "金幣",
  "label_rubies": "寶石",
  "contest_card_label_participants": "參加者",
  "contest_card_label_first_prize": "頭獎",
  "contest_card_label_entry_limit": "隊伍上限",
  "contest_card_label_cancelled": "即取消",
  "contest_card_label_eligible": "可折抵",
  "contest_card_label_you_joined_with": "已加入隊伍：",
  "contest_card_label_full": "已滿",
  "confirmation_dialog_label_current_balance": "目前餘額",
  "confirmation_dialog_label_new_balance": "最終餘額",
  "confirmation_dialog_description_policy_1": "當您按下確認，代表您同意",
  "confirmation_dialog_description_policy_2": "。",
  "label_view_my_contests": "查看已加入的房間",
  "label_join_now": "立即加入",
  "my_games_page_description_empty_9_cat": "您還未加入任何 9-Cat 房間。\n立即前往加入！",
  "my_games_page_description_empty_matches": "您還未加入所選球類的任何房間。\n立即前往加入！",
  "my_contest_page_button_check_leaderboard": "查看排行榜及獎金",
  "contest_detail_page_leaderboard_label_winning_prize_details": "獲獎明細",
  "contest_card_label_entry_fee_free": "免費",
  "contest_card_title_practice": "練習",
  "contest_card_title_deposit_normal": "獎金",
  "contest_card_title_ruby_normal": "寶石",
  "contest_detail_page_ranking_label_prize_hint": "如果獎金無法整除，則可能會向下取整。獎金和得獎者人數可能會根據隊伍數量進行動態調整。",
  "share_contest_popup_label_share": "分享",
  "share_contest_popup_label_to_chat_room": "分享至聊天室",
  "share_contest_popup_textarea_placeholder_comment": "留下留言（選填）",
  "share_contest_popup_label_copy_link": "複製連結",
  "share_contest_popup_copy_success_message": "房間連結已複製！",
  "label_view": "觀看",
  "label_each": "每人獲得: {eachPrize}",
  "share_contest_popup_comment_includes_urls_error_message": "不允許使用連結。",
  "share_contest_popup_share_success_message": "成功分享！",
  "share_contest_popup_share_exceed_rate_limit_message": "分享太頻繁了！",
  "contest_leaderboard_view_message_joined": "已經加入了。",
  "contest_leaderboard_view_message_you_havent_joined": "別錯過了，立即加入！",
  "contest_leaderboard_view_message_you_have_joined": "祝您好運！",
  "contest_leaderboard_view_message_nobody_joined_1": "成為開路先鋒，",
  "contest_leaderboard_view_message_nobody_joined_2": "引領群雄，現在就加入！",
  "label_rank": "排名",
  "label_teams": "隊伍",
  "contest_leaderboard_view_label_end_of_prizes": "以上為所有獲獎者",
  "contest_leaderboard_view_label_participants": "參加者",
  "contest_leaderboard_view_team_card_label_won": "贏得",
  "contest_leaderboard_view_message_cancel": "很遺憾，這個房間已經被取消。與其他參加者們探索更多有趣的房間。",
  "label_guide": "遊玩指南",
  "how_to_play_step_1_title": "選擇一場賽事",
  "how_to_play_step_1_subtitle": "選擇你最喜愛的賽事。",
  "how_to_play_step_2_title": "選擇一場房間",
  "how_to_play_step_2_subtitle": "從各種獎金池的房間精挑細選。",
  "how_to_play_step_3_title": "挑選你的夥伴",
  "how_to_play_step_3_subtitle": "挑選你的夢幻團隊成員。",
  "how_to_play_step_4_title": "選擇隊長和副隊長",
  "how_to_play_step_4_subtitle": "隊長將可取得 2 倍點數，副隊長則是取得 1.5 倍的點數。",
  "how_to_play_step_5_title": "加入",
  "how_to_play_step_5_subtitle": "查看和確認。",
  "team_structure_title": "隊伍組成",
  "label_position": "球隊位置",
  "label_min": "最少",
  "label_max": "最多",
  "scoring_popup_title": "計分規則",
  "scoring_popup_label_type_of_points": "計分種類",
  "player_role_cricket_keeper_singular": "守門手",
  "player_role_cricket_batsman_singular": "擊球手",
  "player_role_cricket_bowler_singular": "投球手",
  "player_role_cricket_all_rounder_singular": "全能手",
  "player_role_football_defender_singular": "後衛",
  "player_role_football_midfielder_singular": "中場",
  "player_role_football_forward_singular": "前鋒",
  "scoring_popup_subtitle_fantasy_points_system": "夢幻分數系統",
  "scoring_popup_subtitle_bonus_points": "獎勵分數",
  "scoring_popup_subtitle_economy_rate": "經濟率",
  "scoring_popup_subtitle_strike_rate": "打擊率",
  "scoring_popup_subtitle_attack": "進攻",
  "scoring_popup_subtitle_defense": "防守",
  "scoring_popup_subtitle_cards_and_penalties": "犯規與其他罰則",
  "scoring_popup_subtitle_other_points": "其他分數",
  "scoring_popup_note_1": "如果一名球員最近轉到其他球隊，他/她仍可能被算作原球隊的球員。但在下次更新之前，你不會從他/她那裡獲得積分。",
  "scoring_popup_note_2": "如果一名球員被宣布為先發十一人之一但沒有上場，他/她將不會獲得任何積分。替補球員將會獲得積分。",
  "result_match_info_top_scorers_by_role": "各位置得分領先球員",
  "result_match_info_label_scores": "積分",
  "label_selected_by": "從中選擇",
  "daily_quest_page_title": "任務",
  "daily_check_in_popup_title": "連續 {days} 天",
  "daily_check_in_popup_title_first_day": "第一天，開啟冒險，一路向前！",
  "daily_check_in_popup_subtitle_coins_today": "今天獲得 {amount} 金幣",
  "daily_check_in_popup_subtitle_rubies_today": "今天獲得 {amount} 寶石",
  "label_today": "今天",
  "label_tomorrow": "明天",
  "daily_check_in_popup_button_more_rewards": "更多獎勵",
  "daily_check_in_upgrade_popup_title": "升級以獲得更多獎勵！",
  "daily_check_in_upgrade_popup_subtitle": "推薦的 AP 等級",
  "daily_check_in_upgrade_popup_button_compare": "比較不同 AP 等級的獎勵",
  "label_ap_level_beginner_upper": "新手",
  "label_ap_level_ordinary_upper": "青銅",
  "label_ap_level_silver_upper": "白銀",
  "label_ap_level_gold_upper": "黃金",
  "label_ap_level_platinum_upper": "白金",
  "label_ap_level_emerald_upper": "翡翠",
  "label_ap_level_diamond_upper": "鑽石",
  "label_ap_level_master_upper": "大師",
  "label_ap_level_ultimateMaster_upper": "終極大師",
  "label_check_in": "簽到",
  "label_per_video": "每部",
  "daily_check_in_upgrade_popup_label_max_reward": "最高獎勵！",
  "daily_check_in_upgrade_popup_label_max_reward_continue": "最高獎勵！",
  "daily_check_in_upgrade_popup_button_upgrade_now": "立即升級",
  "daily_check_in_upgrade_label_watch_ad": "觀看廣告",
  "daily_quest_item_title_daily_check_in": "每日簽到",
  "daily_quest_item_description_daily_check_in": "連續 {days} 天",
  "home_page_label_empty_upcoming_matches": "更多賽事即將到來！",
  "home_page_label_choose_a_tournament": "選場聯盟來看有什麼賽事吧！",
  "contest_deposit_normal_title": "獎金賽",
  "required_error_age_under_21": "抱歉，您必須年滿 21 歲。",
  "label_agree_terms_and_conditions": "我已閱讀並同意 {termsAndConditions} 和 {privacyAndPolicy} 。",
  "label_agree_terms_and_conditions_app": "我已閱讀並同意 $termsAndConditions$ 和 $privacyAndPolicy$ 。",
  "prize_distribution_calculator_description_1": "我們使用了特定公式，根據參與者數量計算獎金分配。",
  "prize_distribution_calculator_description_2": "調整下面輸入欄的隊伍數量，以查看獎金金額和獎金分配的變化。",
  "prize_distribution_calculator_teams_input_validation_error": "請輸入 {minSlips} 到 {spots} 之間的數字",
  "prize_distribution_calculator_min_spots": "最小值：",
  "prize_distribution_calculator_max_spots": "最大值：",
  "prize_distribution_calculator_title": "獎金分配",
  "kyc_verify_to_unlock": "驗證您的帳戶以解鎖所有功能！",
  "kyc_verify_to_unlock_content": "用 5 分鐘內完成 KYC 驗證，以確保您能完全使用所有功能，包括提領獎金。這有助於我們維持安全的遊戲環境。",
  "kyc_verify_to_unlock_note": "注意：必須在註冊後 7 天內完成 KYC 驗證，以繼續使用遊戲的關鍵功能。審核可能需要一些時間，我們會仔細審查您的提交內容以確保您的安全。",
  "label_verification_unsuccessful": "驗證失敗",
  "kyc_verification_unsuccessful_content_1": "您的驗證嘗試未成功。請重新提交您的驗證文件。",
  "kyc_verification_unsuccessful_content_2": "完成驗證是提領所必需的。",
  "kyc_verification_unsuccessful_content_note": "注意：必須在註冊後 7 天內完成 KYC 驗證，以繼續使用遊戲的關鍵功能。",
  "my_games_page_description_not_selected_tab": "讓我們選擇一項{data}來查看相關內容！",
  "confirmation_dialog_description_policy_3": "一旦隊伍確認參賽即無法取消。",
  "fantasy_sport_label_sport": "運動",
  "kyc_verification_complete_your_kyc": "完成您的 KYC 驗證",
  "kyc_verification_complete_your_kyc_content_1": "抱歉，由於您註冊已超過 7 天，您需要完成 KYC 驗證才能繼續遊玩。這是為了確保所有玩家的安全。",
  "kyc_verification_complete_your_kyc_content_2": "請完成驗證程序，以解鎖所有功能並享受完整的遊戲體驗。",
  "responsible_gaming_title": "遊戲責任須知",
  "responsible_gaming_age_confirmation": "我已年滿 21 歲。",
  "responsible_gaming_non_government_confirmation": "我不是政府官員，也不是與政府或其機構運作直接相關的員工，不是菲律賓武裝部隊成員，包括陸軍、海軍、空軍或菲律賓國家警察。",
  "responsible_gaming_non_gel_confirmation": "我不是博彩業就業許可證（GEL）持有人。",
  "responsible_gaming_confirmation_disclaimer": "勾選上述選項並點擊「確認」，即表示您確認您不屬於上述被禁止的人員類別。如發現有違此聲明，您同意您的帳戶將被關閉，且您的資金將按照相關法規被沒收給政府。",
  "withdraw_to_account_label_account_name": "帳戶名稱",
  "withdraw_to_account_label_account_bank": "銀行",
  "withdraw_to_account_label_account_number": "銀行帳號",
  "withdraw_to_account_name_validation_error": "請輸入您的帳戶名稱",
  "withdraw_to_account_bank_validation_error": "請選擇您的銀行",
  "withdraw_to_account_number_validation_error": "請輸入您的銀行帳號",
  "withdraw_to_account_transaction_fee_hint_label": "每次提款皆須支付 ₱{transactionFee} 手續費。",
  "withdraw_to_account_minimum_withdrawal_amount_hint_label": "最低提款金額為 ₱{minimumWithdrawAmount}。",
  "register_selected_site_label": "所有玩家都會參與到同一個獎金池中，不論他們來自哪個站點。",
  "buy_coin_special_label": "特別優惠",
  "buy_coin_standard_label": "標準商品",
  "buy_coin_purchase_title_label": "金幣正在發放中！",
  "buy_coin_purchase_content_label": "我們正在處理您的購買。稍待片刻。",
  "buy_coin_purchase_tip_label": "這可能需要幾分鐘的時間。在您的{data}中追蹤進度。",
  "label_play_now": "立即遊玩",
  "label_buy_more_coins": "購買更多金幣",
  "buy_coin_purchase_unsuccessful_title_label": "購買失敗",
  "buy_coin_purchase_unsuccessful_content_label": "您的購買未完成。請再試一次。",
  "buy_coin_purchase_unsuccessful_tip_label": "您的交易無法完成。請在您的{data}中追蹤狀態。",
  "label_available": "可用餘額：",
  "label_send_to": "轉帳至",
  "button_review": "確認詳情",
  "withdraw_to_account_amount_exceed_validation_error": "您輸入的金額超過您現有餘額，請輸入較小的金額。",
  "withdraw_to_account_minimum_amount_validation_error": "您至少需提領 ₱{minimumWithdrawAmount}。",
  "withdraw_to_account_amount_validation_error": "請輸入僅能使用數字的有效金額。",
  "selector_option_passport": "護照",
  "selector_option_drivers_license": "駕照",
  "selector_option_id": "身分證",
  "selector_option_other": "其他",
  "kyc_verification_title": "Daily Fantasy 驗證流程",
  "label_send_code": "發送簡訊",
  "label_edit_number": "更換電話",
  "label_status": "狀態",
  "label_success": "成功",
  "label_receive": "接收",
  "label_fee": "手續費",
  "label_to": "到",
  "label_account_no": "帳號",
  "button_try_again": "再試一次",
  "label_withdrawal": "提領金額",
  "label_transaction_fee": "手續費",
  "label_receive_amount": "實收金額",
  "withdraw_to_account_terms_and_condition_1": "當您滑動確認按鈕，代表您同意",
  "withdraw_to_account_terms_and_condition_2": "。",
  "withdraw_button_confirm": "滑動以確認",
  "label_withdraw_amount_insufficient": "餘額不足",
  "wallet_record_event_user_withdraw_winning": "提領",
  "wallet_record_event_user_withdraw_winning_cancelled": "提領已嘗試",
  "wallet_record_event_converted_to_winning": "轉至獎金（用於提領）",
  "wallet_record_event_converted_from_coin": "轉自金幣（用於提領）",
  "label_reversed": "已撤銷",
  "label_declined": "已拒絕",
  "otp_error_mobile_number_incorrect": "手機號碼不正確。請檢查並重試。",
  "otp_error_mobile_number_in_use": "此手機號碼已被使用。請提供一個不同的手機號碼以繼續您的驗證程序。",
  "otp_mobile_number_title": "手機號碼驗證",
  "otp_mobile_number_description": "請輸入您的手機號碼。我們將發送一個一次性密碼（OTP）到這個號碼進行驗證。",
  "label_mobile_number": "手機號碼",
  "otp_error_mobile_number_exceed_daily_limit_hint": "今日已達驗證碼發送上限。請明天再試。",
  "otp_error_mobile_number_exceed_interval_limit_hint": "您現在還不能發送新的驗證碼。請在 90 秒後再試。",
  "otp_error_verify_code_incorrect": "驗證碼不正確。請重試或重新發送驗證碼。",
  "otp_error_verify_code_expired": "驗證碼已過期。請重新發送驗證碼。",
  "otp_verify_code_title": "OTP 已發送至 {mobileNumber}",
  "otp_verify_code_description": "我們已經發送一個一次性密碼（OTP）到您的手機號碼。請在下方輸入該 OTP 以完成您的手機驗證。",
  "label_otp_code": "OTP 驗證碼",
  "otp_error_verify_code_expired_hint": "請重新發送新的驗證碼，因為當前的驗證碼已過期。",
  "otp_error_verify_code_exceed_attempt_limit_hint": "請重新發送驗證碼。您已達到驗證碼最大嘗試次數。",
  "otp_resend_successful": "新的一次性密碼（OTP）已發送至您的號碼。",
  "otp_resend_description": "還沒收到驗證碼嗎？",
  "otp_resend_limit_tip": "（每日最多發送 10 次驗證碼）",
  "otp_resend_now": "立即重新發送",
  "otp_resend_cooldown": "{seconds} 秒後可重新發送。",
  "label_withdrew_declined": "已嘗試的提領",
  "withdrew_result_progress_title": "提領進行中",
  "withdrew_result_progress_description": "提領可能需要數分鐘的時間進行處理。",
  "withdrew_result_declined_title": "提領被拒絕",
  "withdrew_result_declined_description": "很抱歉,您的提款無法處理。請檢查您的帳戶詳細資訊，或聯繫我們尋求協助。",
  "button_withdraw_another": "再次提領",
  "button_back_to_game": "回到遊戲",
  "label_collecting_data_to_chart": "我們正在收集更多數據以為您提供圖表",
  "login_page_label_tnc_not_agree": "請同意條款以繼續",
  "required_error_mobile_number": "請輸入您的手機號碼。",
  "label_start_play": "這就是我 - 來玩吧",
  "responsible_gaming_dialog_description": "<b>歡迎！</b>我們為您準備了一份<b>歡迎禮物</b>。完成最後這個步驟就可以打開它並開始遊戲了！",
  "required_error_place_of_birth": "請選擇您的出生地",
  "place_of_birth_title": "出生地",
  "label_same_as_above": "同上",
  "error_mobile_number_in_use": "此手機號碼已被使用",
  "label_source_of_income": "收入來源",
  "required_error_source_of_income": "請選擇您的收入來源",
  "responsible_gaming_dialog_prompt": "遊戲責任須知 - 請輸入您的<b>真實</b>個人資料。",
  "responsible_gaming_dialog_confirm_data_label": "這些資料<b>之後無法編輯，</b>我確認這是我的真實資料。",
  "required_error_is_info_confirmed": "請確認所填資料為真實資訊。",
  "withdraw_to_account_withdrawal_order_hint_label": "提領順序為獎金優先，其次是金幣。",
  "payment_app_download_title": "僅限 App 可使用信用卡儲值，下載 App 以使用信用卡購買金幣。",
  "payment_credit_card_method_title": "信用卡（僅限 App）",
  "payment_credit_card_method_button": "下載 App",
  "payment_method_title": "付款方式",
  "label_deactivate_account_message": "刪除帳號"
};