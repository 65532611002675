import { zodResolver } from "@hookform/resolvers/zod";
import { isOtpMobileNumber } from "helpers/RegexHelper";
import moment, { Moment } from "moment/moment";
import { z } from "zod";

const isAgeReached = (birthDayMoment: Moment, requiredAge: number) => {
    return moment().diff(birthDayMoment, "years") < requiredAge;
};

export const responsibleGamingRegistrationFormSchema = (formatMessage: (param: { id: string }) => string) => {
    return zodResolver(z.object({
            firstName: z.string().min(1, {
                message: formatMessage({ id: "required_error_first_name" })
            }),
            lastName: z.string().min(1, {
                message: formatMessage({ id: "required_error_last_name" })
            }),
            birth: z.object({
                yearOfBirth: z.coerce.number().min(1900).max(9999),
                monthOfBirth: z.coerce.number().min(1).max(12),
                dayOfBirth: z.coerce.number().min(1).max(31),
            }),
            countryCode: z.string(),
            mobileNumber: z.string()
                .min(1, {
                    message: formatMessage({ id: "required_error_mobile_number" })
                })
                .refine(isOtpMobileNumber, formatMessage({ id: "otp_error_mobile_number_incorrect" })),
            isInfoConfirmed: z.boolean().refine(value => value, formatMessage({ id: "required_error_is_info_confirmed" })),
        }).superRefine(({ birth }, ctx) => {
            const birthDayMoment = moment({
                year: birth.yearOfBirth,
                month: birth.monthOfBirth - 1,
                day: birth.dayOfBirth
            });

            if (!birthDayMoment.isValid()) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: formatMessage({ id: "required_error_date_of_birth" }),
                    path: ["birth"]
                });

                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: ["birth", "yearOfBirth"]
                });
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: ["birth", "monthOfBirth"]
                });
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: ["birth", "dayOfBirth"]
                });
            }

            if (isAgeReached(birthDayMoment, 21)) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: formatMessage({ id: "required_error_age_under_21" }),
                    path: ["birth"]
                });

                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: ["birth", "yearOfBirth"]
                });
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: ["birth", "monthOfBirth"]
                });
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: ["birth", "dayOfBirth"]
                });
            }
        })
    );
};
