import clsx from "clsx";
import { PlayerInfoVo } from "data/vo/player/PlayerInfoVo";
import {
    BasketballPlayerInfoProfileVo,
    INJURY_PLAYING_CHANCE
} from "data/vo/player/profile/BasketballPlayerInfoProfileVo";
import { PlayerLineup } from "domain/team/player/components/playerCard/PlayerCardInfo";
import { getAge } from "domain/team/playerInfoDialog/helper/PlayerInfoDialogHelper";
import { DotSeparator, Separator } from "domain/team/playerInfoDialog/PlayerInfoDialogStyle";
import { hasText } from "helpers/string/StringHelper";
import { formatToFullYearDate } from "helpers/TimeHelper";
import { isNull } from "lodash";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { LINEUP, SquadPlayer } from "types/player/Player";

interface PlayerProfileProps {
    player: SquadPlayer;
    playerInfo: PlayerInfoVo | null | undefined;
}

interface AgeProps {
    birthdate: string | null;
}

const Age: React.FC<AgeProps> = ({ birthdate }) => {
    if (isNull(birthdate)) return null;

    return <>
        <DotSeparator />
        <span className="fz-sf-regular-footnote text-text-secondary-1 flex-shrink-0" data-testid="age">
            {getAge(birthdate)} yrs
        </span>
    </>;
};

interface LineupProps {
    lineup: LINEUP;
}

const Lineup: React.FC<LineupProps> = ({ lineup }) => {
    if (lineup === LINEUP.TO_BE_DECIDED) return null;

    return <div className="flex-shrink-0" data-testid="lineup">
        <PlayerLineup lineup={lineup} as="span" />
    </div>;
};

interface NationalAndBirthdateProps {
    nationality: string | null;
    birthdate: string | null;
}

const NationalAndBirthdate: React.FC<NationalAndBirthdateProps> = ({ birthdate, nationality }) => {


    if (!hasText(nationality) && !hasText(birthdate)) return null;

    return <div className="d-flex gap-3 align-items-center">
        {
            hasText(nationality) &&
            <span className="fz-sf-regular-footnote text-text-primary-1" data-testid="nationality">
                <FormattedMessage id="nationality_title" />
                : {nationality}
            </span>
        }
        {
            (hasText(nationality) && hasText(birthdate)) &&
            <Separator />
        }
        {
            hasText(birthdate) &&
            <div className="fz-sf-regular-footnote text-text-primary-1" data-testid="birthdate">
                <FormattedMessage id="born_title" />
                <span className="fz-sf-regular-footnote text-text-primary-1"> {formatToFullYearDate(birthdate)}</span>
            </div>
        }
    </div>;
};

function getInjuryChanceColor(injuryPlayingChance: INJURY_PLAYING_CHANCE | null) {
    switch (injuryPlayingChance) {
        case INJURY_PLAYING_CHANCE.OUT:
            return "text-sys-danger-1";
        case INJURY_PLAYING_CHANCE.DOUBTFUL:
            return "text-sys-danger-2";
        case INJURY_PLAYING_CHANCE.QUESTIONABLE:
            return "text-secondary-2";
        case INJURY_PLAYING_CHANCE.PROBABLE:
            return "text-sys-warning-1";

        default:
            return "text-text-primary-1";
    }
}

export const PlayerProfile: React.FC<PlayerProfileProps> = ({ player, playerInfo }) => {
    const intl = useIntl();

    const description = playerInfo?.profile.getDescription((id: string) => intl.formatMessage({ id }));

    const injuryInfo = playerInfo?.profile.getInjuryInfo((id: string) => intl.formatMessage({ id }));

    const injuryPlayingChance = playerInfo?.profile instanceof BasketballPlayerInfoProfileVo ? (playerInfo).profile.injuryPlayingChance : null;

    return <div>
        <div className="d-flex flex-wrap gap-3 align-items-center mb-2">
            <span className="fz-sf-bold-body-1 text-text-primary-1">
                {player.fullName}
            </span>
            {
                playerInfo && <>
                    <Age birthdate={playerInfo.profile.birthdate} />
                    <Lineup lineup={playerInfo.profile.lineup} />
                </>
            }
        </div>
        {
            playerInfo && <NationalAndBirthdate birthdate={playerInfo.profile.birthdate}
                                                nationality={playerInfo.profile.nationality} />
        }

        {
            description &&
            <>
                <div className="fz-sf-regular-footnote text-text-primary-1" data-testid="profile">
                    {description}
                </div>
            </>
        }

        {
            injuryInfo &&
            <>
                <div
                    className={clsx("fz-sf-regular-footnote pt-2", getInjuryChanceColor(injuryPlayingChance))}
                    data-testid="injury-info">
                    {injuryInfo}
                </div>
            </>
        }
    </div>;
};
