import PageViewRecorder from "components/ga/PageViewRecorder";
import { MATCH_CARD_SKELETON_FOOTER_TYPE } from "components/skeleton/MatchCardSkeleton/type";
import { MatchesSkeleton } from "components/skeleton/MatchesSkeleton";
import ResultMatchesNavbar from "domain/result/components/ResultMatchesNavbar";
import ResultTournamentFilterDialog from "domain/result/components/ResultTournamentFilterDialog";
import useResultMatches from "domain/result/hooks/useResultMatches";
import { ResultMatchesFactory } from "domain/result/ResultMatchesFactory";
import { ResultMatchesContainer, ResultMatchesWrapper } from "domain/result/ResultMatchesStyle";
import { storageHelper } from "helpers/localStorage/LocalStorageHelper";
import { LOCAL_STORAGE_KEYS } from "helpers/localStorage/types";
import { getSkippedTournaments, matchesToTournaments } from "helpers/TournamentHelper";
import React, { useState } from "react";
import { useCurrentSport } from "domain/sport/useCurrentSport";


const ResultMatches = () => {
    const { currentSport } = useCurrentSport();
    const { resultMatches } = useResultMatches(currentSport);

    const skipCompletedTournamentsStorage = storageHelper(LOCAL_STORAGE_KEYS.SKIP_RESULT_TOURNAMENTS, currentSport);

    const [showFilterModal, setShowFilterModal] = useState(false);

    const tournaments = resultMatches ? matchesToTournaments(resultMatches, skipCompletedTournamentsStorage.get()) : [];

    const skippedTournamentIds = getSkippedTournaments(tournaments);

    const filteredMatches = resultMatches ? resultMatches.filter(match => !skippedTournamentIds.includes(match.tournamentId)) : [];

    const isTournamentSelectAll = filteredMatches.length === (resultMatches ?? []).length;

    const handleFilter = ({ skippedTournamentIds }) => {
        skipCompletedTournamentsStorage.put(getSkippedTournaments(skippedTournamentIds));
        setShowFilterModal(false);
    };

    return <>
        <PageViewRecorder title="Result" />
        <ResultTournamentFilterDialog
            tournaments={tournaments}
            show={showFilterModal}
            onHide={() => setShowFilterModal(false)}
            onFilter={handleFilter}
        />
        <ResultMatchesContainer>
            <ResultMatchesNavbar
                onFilterClick={() => setShowFilterModal(true)}
                isTournamentSelectAll={isTournamentSelectAll}
            />
            <ResultMatchesWrapper>
                {
                    resultMatches
                        ? <ResultMatchesFactory
                            resultMatches={filteredMatches}
                            currentSport={currentSport}
                            tournaments={tournaments} />
                        : <MatchesSkeleton footerType={MATCH_CARD_SKELETON_FOOTER_TYPE.NONE} />
                }
            </ResultMatchesWrapper>
        </ResultMatchesContainer>
    </>;
};

export default ResultMatches;
