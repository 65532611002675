import { BUTTON_PATTERN, NEW_BUTTON_VARIANTS } from "designToken/button/types";
import { Icon } from "helpers/IconHelper";
import { divideByOneHundred } from "helpers/number/NumberHelper";
import useCurrency from "hooks/useCurrency/useCurrency";
import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import Button from "../../../../../designToken/button/Button";
import { cn } from "../../../../../helpers/cn";
import useUserIdentity from "../../../../../hooks/useUserIdentity";
import { useSelector } from "../../../../../store";

export interface ConfirmationModalButtonFactoryProps {
    balanceDepositCent: number;
    onConfirmationButtonClick: () => void;
    onAddDepositButtonClick: () => void;
}


const AddDepositButton: React.FC<{
    onAddDepositButtonClick: () => void
}> = ({ onAddDepositButtonClick }) => {
    const {
        FantasyGameConfigState: { featureConfig }
    } = useSelector(state => state);

    if (!featureConfig.addCoin) return null;

    return <Button
        dataTestId="add-deposit-button"
        variant={NEW_BUTTON_VARIANTS.greyLight}
        prefixIcon={() => <Icon.Wallet size={"2rem"} />}
        className={cn(
            "tw-w-full tw-h-11 tw-rounded-lg",
            "tw-text-h6-medium",
        )}
        onClick={onAddDepositButtonClick}
    >
        <FormattedMessage id="label_add_deposit" />
    </Button>;
};
const ConfirmationModalButtonFactory: React.FC<ConfirmationModalButtonFactoryProps> = (
    {
        balanceDepositCent,
        onConfirmationButtonClick,
        onAddDepositButtonClick
    }) => {
    const {
        WalletState: { winningCent },
    } = useSelector(state => state);

    const { isB2B } = useUserIdentity();

    const { currentCurrencyObject: { toFormattedNumber } } = useCurrency();

    const isBalancePositive = useMemo(() => balanceDepositCent >= 0, [balanceDepositCent]);
    const isNotEnoughWinning = winningCent < Math.abs(balanceDepositCent);

    if (isBalancePositive) {
        return <Button className="mx-auto w-100 tw-text-h6-medium"
                       variant={NEW_BUTTON_VARIANTS.primaryDark}
                       pattern={BUTTON_PATTERN.rounded}
                       onClick={onConfirmationButtonClick}
                       dataTestId="join-contest-confirm-button">
            <FormattedMessage id="button_confirm_and_join" />
        </Button>;
    }

    if (isNotEnoughWinning) {
        return <AddDepositButton onAddDepositButtonClick={onAddDepositButtonClick} />;
    }

    if (isB2B) {
        return <div className="d-flex flex-column gap-3">
            <div
                data-testid="current-winning"
                className="fz-sf-regular-caption-1 text-text-tertiary-1">
                <FormattedMessage id="label_winning" />: {toFormattedNumber(divideByOneHundred(winningCent))}
            </div>
            <Button className="mx-auto w-100"
                    pattern={BUTTON_PATTERN.rounded}
                    onClick={onConfirmationButtonClick}
                    dataTestId="join-contest-confirm-button">
                <FormattedMessage id="button_confirm" />
            </Button>
        </div>;
    }

    return <div className="d-flex flex-column gap-3">
        <div
            data-testid="current-winning"
            className="fz-sf-regular-caption-1 text-text-tertiary-1">
            <FormattedMessage id="label_winning" />: {toFormattedNumber(divideByOneHundred(winningCent))}
        </div>
        <Button className="mx-auto w-100"
                pattern={BUTTON_PATTERN.rounded}
                prefixIcon={Icon.Swap}
                onClick={onConfirmationButtonClick}
                dataTestId="join-contest-confirm-button">
            <FormattedMessage
                id="button_pay_with_winning"
                values={{
                    winning: toFormattedNumber(divideByOneHundred(Math.abs(balanceDepositCent)))
                }} />
        </Button>
        <AddDepositButton onAddDepositButtonClick={onAddDepositButtonClick} />
    </div>;
};

export default ConfirmationModalButtonFactory;
