import Carousel from "components/carousel";
import Button from "designToken/button/Button";
import { Logo } from "domain/auth/components/Logo";
import { AuthImage, CarouselWrapper } from "domain/auth/entry/Style";
import { entryTutorialStepImg } from "helpers/IconHelper";
import useFantasyHistory from "hooks/useFantasyHistory";
import React from "react";
import { FormattedMessage } from "react-intl";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";
import { SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { BUTTON_PATTERN, BUTTON_SIZE, BUTTON_VARIANTS } from "designToken/button/types";
import { ContactInfoWithLink } from "domain/auth/components/ContactInfoWithLink";
import { analyticsEvent, logCustomEvent } from "ga";

const Entry: React.FC = () => {
    const history = useFantasyHistory();

    function handleToSignUp() {
        history.push(ROUTER_PATH_CONFIG.auth.signUp);
        logCustomEvent(analyticsEvent.webClickLetStart);
    }

    function handleToLogin() {
        history.push(ROUTER_PATH_CONFIG.auth.login);
        logCustomEvent(analyticsEvent.webClickLetStart);
    }

    return (
        <div className="px-4 py-5 d-flex flex-column justify-content-between h-100 gap-4">
            <Logo />
            <CarouselWrapper>
                <Carousel
                    autoplay={{ delay: 5000, disableOnInteraction: false, stopOnLastSlide: false }}
                    pagination={{ clickable: true }}
                    modules={[Autoplay]}
                >
                    {
                        Array.from({ length: 4 }).map((step, index) => {
                            return <SwiperSlide key={index} className="flex-column">
                                <AuthImage src={entryTutorialStepImg(index + 1)}
                                           alt={`tutorial-step-${index}`}
                                           className="mb-5"
                                />
                                <div className="fz-sf-bold-title-2 text-text-primary-1">
                                    <FormattedMessage id={`entry_tutorial_step_title_${index + 1}`} />
                                </div>
                                <div className="fz-sf-regular-body-2 text-text-tertiary-1">
                                    <FormattedMessage id={`entry_tutorial_step_description_${index + 1}`} />
                                </div>
                            </SwiperSlide>;
                        })
                    }
                </Carousel>
            </CarouselWrapper>
            <div className="text-center">
                <Button
                    variant={BUTTON_VARIANTS.primary}
                    size={BUTTON_SIZE.lg}
                    pattern={BUTTON_PATTERN.pill}
                    onClick={handleToSignUp}
                    className="w-100 mb-4"
                >
                    <FormattedMessage id="login_page_label_sign_up" />
                </Button>
                <Button
                    variant={BUTTON_VARIANTS.primary}
                    size={BUTTON_SIZE.lg}
                    pattern={BUTTON_PATTERN.pill}
                    onClick={handleToLogin}
                    className="w-100 mb-4"
                >
                    <FormattedMessage id="login_page_label_log_in" />
                </Button>
                <ContactInfoWithLink />
            </div>
        </div>
    );
};

export default Entry;
