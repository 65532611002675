import { ApiExecuteContext } from "apis/ApiContext";
import { getLoginInfo, LoginData } from "apis/login/LoginApi";
import redirectStorageHelper from "components/protect/RedirectStorageHelper";
import { auth } from "domain/auth/AuthConfig";
import { shouldSendCheckEmail } from "domain/auth/helper/AuthHelper";
import { logUserId } from "ga";
import { sessionStorageHelper } from "helpers/localStorage/SessionStorageHelper";
import { getQuery } from "helpers/queryString";
import useFantasyHistory from "hooks/useFantasyHistory";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDispatch } from "react-redux";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";
import { updateSiteId } from "store/actions/Actions";
import { updateUserIdentity, updateUserIsNewUser } from "store/actions/UserActions";
import useFantasyQuery from "hooks/useFantasyQuery";
import { QUERY_KEY } from "hooks/useFantasyQuery/type";
import { SessionRepository } from "data/repository/session/SessionRepository";
import { useSelector } from "store";
import useSignOut from "hooks/useSignOut";

export const REFETCH_VALIDATE_SESSION_INTERVAL = 30 * 1000;

const ProtectedRoute = ({ children }) => {
    const dispatch = useDispatch();
    const [user, loading] = useAuthState(auth);
    const { selectedSiteId, sessionId } = useSelector((store) => store.UserState);

    useFantasyQuery([QUERY_KEY.FETCH_VALIDATE_SESSION, sessionId], () => new SessionRepository().validateSession(sessionId), {
        options: {
            refetchInterval: REFETCH_VALIDATE_SESSION_INTERVAL,
            refetchIntervalInBackground: true,
            enabled: !!user && !!sessionId,
        }
    });

    const redirectStorage = useRef(
        redirectStorageHelper(sessionStorageHelper)
    );
    const history = useFantasyHistory();
    const { siteId } = getQuery();
    const [isAccessTokenValid, setIsAccessTokenValid] = useState(false);

    const apiExecutor = useContext(ApiExecuteContext);

    const loginBody = useMemo<LoginData | undefined>(() =>
            selectedSiteId ? { siteId: selectedSiteId } : undefined,
        [selectedSiteId]);

    const handleLogin = useCallback((user) => {
        if (user && shouldSendCheckEmail(user)) {
            history.replace(ROUTER_PATH_CONFIG.auth.login);
            return;
        }

        return new Promise((resolve) => {
            apiExecutor(
                Promise.all([
                    getLoginInfo(loginBody)
                ]),
                {
                    onSuccess: ([{ id, loginType, newUser }]) => {
                        dispatch(updateUserIsNewUser(newUser));
                        dispatch(updateUserIdentity(loginType));
                        setIsAccessTokenValid(true);
                        resolve(loginType);

                        logUserId(id);
                    },
                }
            );
        });
    }, [dispatch, apiExecutor, loginBody]);

    const handleRedirect = useCallback(() => {
        const redirectPath = redirectStorage.current.get();
        redirectStorage.current.remove();

        if (redirectPath) {
            history.push(redirectPath);
        }
    }, []);

    useEffect(() => {
        if (siteId) {
            if (Number.isInteger(Number(siteId))) {
                dispatch(updateSiteId(Number(siteId)));
            }
        }
    }, [siteId]);

    useEffect(() => {
        if (user) return;
        if (loading) return;
        redirectStorage.current.put(history.location.pathname);
    }, [loading]);

    const signOut = useSignOut();

    useEffect(() => {
        (async () => {
            if (loading) return;

            if (!user) {
                history.push(ROUTER_PATH_CONFIG.auth.index);
                return;
            }

            if (!sessionId) {
                signOut();
                return;
            }

            await handleLogin(user);
            handleRedirect();
        })();
    }, [handleLogin, loading, user, handleRedirect, sessionId]);

    return (
        isAccessTokenValid
            ? <>
                {children}
            </>
            : null
    );
};

export default ProtectedRoute;
