import { SupplierLogo } from "components/supplierLogo/SupplierLogo";
import { SUPPLIER_LOGO_TYPE } from "components/supplierLogo/type";
import CustomTab, { HeaderLine } from "components/tab/CustomTab";
import { PlayerInfoVo } from "data/vo/player/PlayerInfoVo";
import { Glossary } from "domain/team/playerInfoDialog/gameLog/glossary/Glossary";
import { MatchGameLog } from "domain/team/playerInfoDialog/gameLog/MatchGameLog";
import { BasketballPlayerNewsWithProvider } from "domain/team/playerInfoDialog/news/BasketballPlayerNews";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { SquadPlayer } from "types/player/Player";

interface MatchBasketballTabGameLogProps {
    player: SquadPlayer;
    playerInfo: PlayerInfoVo;
}

const TabBodyAndPanel = ({ tabs, activeTab }) => {
    const currentTab = tabs.find(({ tabKey }) => tabKey === activeTab);

    if (!currentTab) return null;

    const TabBodyLayout = currentTab.layout;
    const props = currentTab.props;

    return <>
        <TabBodyLayout {...props} />

    </>;
};

enum PLAYER_INFO_TAB_KEY {
    overview = "overview",
    gameLog = "game_log"
}

const generateTabs = (player: SquadPlayer, playerInfo: PlayerInfoVo) => {
    const baseTabs = [
        {
            tabKey: PLAYER_INFO_TAB_KEY.gameLog,
            text: <FormattedMessage id="game_log_title" />,
            layout: () => <>
                <SupplierLogo supplier={playerInfo.supplier} type={SUPPLIER_LOGO_TYPE.DIVIDER} offset />
                <MatchGameLog player={player} playerInfo={playerInfo} showTitle={false} />
                <Glossary sport={player.sport} />
            </>,
        }
    ];

    if (playerInfo.tournament === "NBA") {
        return [
            {
                tabKey: PLAYER_INFO_TAB_KEY.overview,
                text: <FormattedMessage id="label_overview" />,
                layout: BasketballPlayerNewsWithProvider,
                props: {
                    playerId: player.id,
                }
            },
            ...baseTabs
        ];
    }

    return baseTabs;
};

const MatchBasketballTabGameLog: React.FC<MatchBasketballTabGameLogProps> = ({ player, playerInfo }) => {
    const tabs = generateTabs(player, playerInfo);
    const [activeTab, setActiveTab] = useState<PLAYER_INFO_TAB_KEY>(tabs[0].tabKey);

    const onTabClick = (tabKey: string) => {
        setActiveTab(tabKey as PLAYER_INFO_TAB_KEY);
    };

    return <>
        <CustomTab tabs={tabs}
                   activeTab={activeTab}
                   onClick={onTabClick}
                   headerLine={HeaderLine.bottom}
        />
        <TabBodyAndPanel tabs={tabs} activeTab={activeTab} />
    </>;
};

export default MatchBasketballTabGameLog;
