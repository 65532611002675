import { getSportConfig } from "domain/SportConfig";
import { cn } from "helpers/cn";
import React, { ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { BASKETBALL_ROLE } from "types/sports/Basketball";
import { CRICKET_ROLE } from "types/sports/Cricket";
import { FOOTBALL_ROLE } from "types/sports/Football";
import { SPORT } from "types/sports/Sport";

interface TeamStructureTableProps {
    tableDataList: ReactNode[][];
}

export const TeamStructureTable: React.FC<TeamStructureTableProps> = ({
                                                                          tableDataList
                                                                      }) => {
    return <table className={
        cn("tw-w-full",
            "tw-text-center",
            "tw-text-body-1 tw-font-bold",
            "tw-rounded-md tw-overflow-hidden",
            "tw-border-[1px] tw-border-solid tw-border-grey-200",
            "tw-border-spacing-0 tw-border-separate"
        )
    }>
        <tr className={"tw-bg-b2b-primary-300 tw-text-white"}>
            <th className={"tw-py-1.5 tw-w-[13rem]"}>
                <FormattedMessage id={"label_position"} />
            </th>
            <th className={"tw-py-1.5"}>
                <FormattedMessage id={"label_min"} />
            </th>
            <th className={"tw-py-1.5"}>
                <FormattedMessage id={"label_max"} />
            </th>
        </tr>
        {tableDataList.map((teamStructureData, i) => (
            <tr key={i} className={"even:tw-bg-grey-50 odd:tw-bg-grey-100"}>
                {teamStructureData.map((cellData, i) => (
                    <td key={i} className={"tw-py-1.5"}>{cellData}</td>
                ))}
            </tr>
        ))}
    </table>;
};

const CRICKET_TEAM_CONFIG = getSportConfig(SPORT.CRICKET).team;
const CRICKET_TABLE_DATA_LIST = [
    [<FormattedMessage
        id={"player_role_cricket_keeper_singular"} />,
        CRICKET_TEAM_CONFIG.roleLimit[CRICKET_ROLE.WICKET_KEEPER.apiValue].LOWER,
        CRICKET_TEAM_CONFIG.roleLimit[CRICKET_ROLE.WICKET_KEEPER.apiValue].UPPER],
    [<FormattedMessage
        id={"player_role_cricket_batsman_singular"} />,
        CRICKET_TEAM_CONFIG.roleLimit[CRICKET_ROLE.BATSMAN.apiValue].LOWER,
        CRICKET_TEAM_CONFIG.roleLimit[CRICKET_ROLE.BATSMAN.apiValue].UPPER],
    [<FormattedMessage
        id={"player_role_cricket_all_rounder_singular"} />,
        CRICKET_TEAM_CONFIG.roleLimit[CRICKET_ROLE.ALL_ROUNDER.apiValue].LOWER,
        CRICKET_TEAM_CONFIG.roleLimit[CRICKET_ROLE.ALL_ROUNDER.apiValue].UPPER],
    [<FormattedMessage
        id={"player_role_cricket_bowler_singular"} />,
        CRICKET_TEAM_CONFIG.roleLimit[CRICKET_ROLE.BOWLER.apiValue].LOWER,
        CRICKET_TEAM_CONFIG.roleLimit[CRICKET_ROLE.BOWLER.apiValue].UPPER],
];

const FOOTBALL_TEAM_CONFIG = getSportConfig(SPORT.FOOTBALL).team;
const FOOTBALL_TABLE_DATA_LIST = [
    [<FormattedMessage id={"player_role_football_goalkeeper"} />,
        FOOTBALL_TEAM_CONFIG.roleLimit[FOOTBALL_ROLE.GOAL_KEEPER.apiValue].LOWER,
        FOOTBALL_TEAM_CONFIG.roleLimit[FOOTBALL_ROLE.GOAL_KEEPER.apiValue].UPPER
    ],
    [<FormattedMessage id={"player_role_football_defender_singular"} />,
        FOOTBALL_TEAM_CONFIG.roleLimit[FOOTBALL_ROLE.DEFENDER.apiValue].LOWER,
        FOOTBALL_TEAM_CONFIG.roleLimit[FOOTBALL_ROLE.DEFENDER.apiValue].UPPER
    ],
    [<FormattedMessage id={"player_role_football_midfielder_singular"} />,
        FOOTBALL_TEAM_CONFIG.roleLimit[FOOTBALL_ROLE.MID_FIELDER.apiValue].LOWER,
        FOOTBALL_TEAM_CONFIG.roleLimit[FOOTBALL_ROLE.MID_FIELDER.apiValue].UPPER
    ],
    [<FormattedMessage id={"player_role_football_forward_singular"} />,
        FOOTBALL_TEAM_CONFIG.roleLimit[FOOTBALL_ROLE.FORWARD.apiValue].LOWER,
        FOOTBALL_TEAM_CONFIG.roleLimit[FOOTBALL_ROLE.FORWARD.apiValue].UPPER
    ],
];

const BASKETBALL_TEAM_CONFIG = getSportConfig(SPORT.BASKETBALL).team;
const BASKETBALL_TABLE_DATA_LIST = [
    [<FormattedMessage id={"player_role_basketball_point_guard"} />,
        BASKETBALL_TEAM_CONFIG.roleLimit[BASKETBALL_ROLE.POINT_GUARD.apiValue].LOWER,
        BASKETBALL_TEAM_CONFIG.roleLimit[BASKETBALL_ROLE.POINT_GUARD.apiValue].UPPER
    ],
    [<FormattedMessage id={"player_role_basketball_shooting_guard"} />,
        BASKETBALL_TEAM_CONFIG.roleLimit[BASKETBALL_ROLE.SHOOTING_GUARD.apiValue].LOWER,
        BASKETBALL_TEAM_CONFIG.roleLimit[BASKETBALL_ROLE.SHOOTING_GUARD.apiValue].UPPER
    ],
    [<FormattedMessage id={"player_role_basketball_small_forward"} />,
        BASKETBALL_TEAM_CONFIG.roleLimit[BASKETBALL_ROLE.SMALL_FORWARD.apiValue].LOWER,
        BASKETBALL_TEAM_CONFIG.roleLimit[BASKETBALL_ROLE.SMALL_FORWARD.apiValue].UPPER
    ],
    [<FormattedMessage id={"player_role_basketball_power_forward"} />,
        BASKETBALL_TEAM_CONFIG.roleLimit[BASKETBALL_ROLE.POWER_FORWARD.apiValue].LOWER,
        BASKETBALL_TEAM_CONFIG.roleLimit[BASKETBALL_ROLE.POWER_FORWARD.apiValue].UPPER
    ],
    [<FormattedMessage id={"player_role_basketball_center"} />,
        BASKETBALL_TEAM_CONFIG.roleLimit[BASKETBALL_ROLE.CENTER.apiValue].LOWER,
        BASKETBALL_TEAM_CONFIG.roleLimit[BASKETBALL_ROLE.CENTER.apiValue].UPPER
    ],
];

const tableDataBySportMap: Record<SPORT, ReactNode[][]> = {
    [SPORT.CRICKET]: CRICKET_TABLE_DATA_LIST,
    [SPORT.FOOTBALL]: FOOTBALL_TABLE_DATA_LIST,
    [SPORT.BASKETBALL]: BASKETBALL_TABLE_DATA_LIST,
};

export function getTableDataListBySport(chosenSport: SPORT) {
    return tableDataBySportMap[chosenSport] || [];
}
