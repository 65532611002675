import { Callback } from "Common";
import { FantasyCheckbox } from "components/checkbox/FantasyCheckbox";
import { CHECKBOX_VARIANTS } from "components/checkbox/types";
import Countdown from "components/countdown/Countdown";
import Button from "designToken/button/Button";
import { BUTTON_PATTERN, BUTTON_SIZE, NEW_BUTTON_VARIANTS } from "designToken/button/types";
import { FONT } from "designToken/font";
import { cn } from "helpers/cn";
import { Icon, noCouponWithTwoCouponsImg } from "helpers/IconHelper";
import moment from "moment";
import React, { Fragment, useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { EnrichedCoupon } from "types/coupon/Coupon";

export interface SelectCouponModalProps {
    myUnusedCoupon: EnrichedCoupon[],
    selectedCoupons: EnrichedCoupon[],
    entryFeeCent: number,
    selectedCouponLimit: number,
    onHide: () => void,
    onSelectedCoupons: (coupons: EnrichedCoupon[]) => void,
}

function Title() {
    return <div className={"tw-text-center tw-text-h6 tw-font-bold"}>
        <FormattedMessage id="label_my_coupons" />
    </div>;
}

interface CouponButtonFactoryProps {
    unusedCouponsAmount: number;
    selectedCouponsAmount: number;
    onCancel: Callback;
    onConfirm: Callback;
}

const CouponButtonGroupFactory: React.FC<CouponButtonFactoryProps> = (props) => {
    if (props.unusedCouponsAmount === 0) {
        return <Button
            className="mx-auto w-100"
            dataTestId="select-coupon-cancel-button"
            size={BUTTON_SIZE.lg}
            variant={NEW_BUTTON_VARIANTS.primaryDark}
            pattern={BUTTON_PATTERN.rounded}
            onClick={props.onCancel}
        >
            <FormattedMessage id="dialog_button_back" />
        </Button>;
    }

    return (
        <div className={cn("tw-flex tw-gap-3",)
        }>
            <Button
                className="mx-auto w-100"
                dataTestId="select-coupon-cancel-button"
                size={BUTTON_SIZE.lg}
                variant={NEW_BUTTON_VARIANTS.grey}
                pattern={BUTTON_PATTERN.rounded}
                onClick={props.onCancel}
            >
                <FormattedMessage id="button_cancel" />
            </Button>
            <Button
                className="mx-auto w-100"
                dataTestId="select-coupon-confirm-button"
                size={BUTTON_SIZE.lg}
                disabled={props.selectedCouponsAmount === 0}
                variant={NEW_BUTTON_VARIANTS.primaryDark}
                pattern={BUTTON_PATTERN.rounded}
                onClick={props.onConfirm}
            >
                <FormattedMessage id="select_coupon_dialog_button_use" />
            </Button>
        </div>
    );
};

function NoCoupon() {
    return <div className={cn(
        "tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4",
        "tw-h-full"
    )}>
        <div className={"tw-max-w-[12rem]"}>
            <img src={noCouponWithTwoCouponsImg} alt="no coupon" />
        </div>
        <p className={"tw-text-subtitle-2 tw-font-extralight"}>
            <FormattedMessage id="select_coupon_dialog_label_no_coupon"></FormattedMessage>
        </p>
    </div>;
}

const SelectCouponModal: React.FC<SelectCouponModalProps> = ({
                                                                 entryFeeCent,
                                                                 myUnusedCoupon,
                                                                 selectedCoupons,
                                                                 selectedCouponLimit,
                                                                 onHide,
                                                                 onSelectedCoupons,
                                                             }) => {

    const [currentSelectedCoupons, setCurrentSelectedCoupons] = useState<EnrichedCoupon[]>(selectedCoupons);

    const onConfirm = () => {
        onSelectedCoupons(currentSelectedCoupons);

        onHide();
    };

    const isChecked = useCallback(
        (coupon: EnrichedCoupon) => currentSelectedCoupons.includes(coupon),
        [currentSelectedCoupons]
    );

    const canSelectMoreCoupon = currentSelectedCoupons.length < selectedCouponLimit;
    const onCouponClick = useCallback((coupon: EnrichedCoupon) => {
        if (isChecked(coupon)) {
            setCurrentSelectedCoupons(currentSelectedCoupons.filter((selectedCoupon) => selectedCoupon !== coupon));
        } else {
            if (canSelectMoreCoupon) {
                setCurrentSelectedCoupons([...currentSelectedCoupons, coupon]);
            }
        }
    }, [isChecked, currentSelectedCoupons, setCurrentSelectedCoupons, canSelectMoreCoupon]);


    const isExpiredInFiveDays = coupon => {
        const today = moment();

        const duration = Math.floor(moment.duration(moment(coupon.to).diff(today)).as("days"));

        return duration < 5;
    };

    const getTextColor = (disabled: boolean, checked: boolean, coupon: EnrichedCoupon): {
        discountColor: string,
        countdownColor: string
    } => {
        if (disabled) {
            return {
                discountColor: "text-text-quaternary-1",
                countdownColor: "text-text-quaternary-1"
            };
        }
        if (checked) {
            return {
                discountColor: "text-secondary-1",
                countdownColor: isExpiredInFiveDays(coupon) ? "tw-text-secondary-600" : "tw-text-primary-600"
            };
        }
        return {
            discountColor: "text-light-primary",
            countdownColor: isExpiredInFiveDays(coupon) ? "tw-text-secondary-600" : "tw-text-primary-600"
        };
    };

    return (
        <div className={
            cn("tw-p-4 tw-pb-8 tw-rounded-3xl tw-overflow-clip",
                "tw-flex tw-flex-col tw-justify-between",
                "tw-h-[56rem]",
                "tw-bg-grey-50"
            )
        }>
            <div className={"tw-flex tw-justify-end"}>
                <button onClick={onHide}>
                    <Icon.Close />
                </button>
            </div>
            <div className={"tw-flex tw-flex-col tw-gap-6 tw-h-[95%]"}>
                <Title />
                {
                    myUnusedCoupon.length === 0 ? (
                        <NoCoupon />
                    ) : (
                        <div className={
                            cn("tw-flex tw-flex-col tw-h-full tw-overflow-y-scroll",
                                "tw-h-full tw-px-6"
                            )
                        }>
                            {
                                myUnusedCoupon.map((coupon) => {
                                    const checked = isChecked(coupon);
                                    const disabled = !checked && !canSelectMoreCoupon;
                                    const { countdownColor } = getTextColor(disabled, checked, coupon);

                                    return <Fragment key={`select-coupon-${coupon.id}`}>
                                        <div
                                            data-testid={`select-coupon-${coupon.id}`}
                                            className={"tw-flex tw-justify-between tw-py-4"}
                                            onClick={() => onCouponClick(coupon)}
                                        >
                                            <FantasyCheckbox
                                                key={`coupon-${coupon.id}`}
                                                variant={CHECKBOX_VARIANTS.primary}
                                                checked={checked}
                                                disabled={disabled}
                                                text={`${coupon.discountPercentage}% OFF`}
                                                textFont={FONT.subtitle1}
                                            />
                                            {
                                                coupon.to !== null && (
                                                    <div className={cn("fz-sf-regular-body-2", countdownColor)}>
                                                        <Countdown finishAt={coupon.to} />
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <hr />
                                    </Fragment>;
                                })
                            }
                        </div>
                    )
                }
                <div className={"tw-pt-4"}>
                    <CouponButtonGroupFactory
                        selectedCouponsAmount={currentSelectedCoupons.length}
                        unusedCouponsAmount={myUnusedCoupon.length}
                        onConfirm={onConfirm}
                        onCancel={onHide}
                    />
                </div>
            </div>
        </div>
    );
};

export default SelectCouponModal;
