import Navbar from "components/container/navbar/Navbar";
import { TripleARepository } from "data/api/payment/TripleARepository";
import { PayMongoRepository } from "data/repository/payment/PayMongoRepository";
import { useAddCoinFlow } from "domain/coin/AddCoinFlow";
import { isOrderPending, isPaymentSuccessful, ORDER_STATUS } from "domain/coin/AddCoinHelper";
import CoinProducts from "domain/coin/component/product/CoinProducts";
import ResultView from "domain/coin/component/result/ResultView";
import { Icon } from "helpers/IconHelper";
import { getQuery } from "helpers/queryString";
import useCurrency from "hooks/useCurrency/useCurrency";
import useFantasyQuery from "hooks/useFantasyQuery";
import { QUERY_KEY } from "hooks/useFantasyQuery/type";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Redirect } from "react-router-dom";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";
import { useSelector } from "store";
import { ADD_COIN_METHOD } from "store/reducer/config/FantasyGameConfigState";
import { useSetting } from "hooks/useSetting";

const detectMillisecond = 1_000 * 60;


interface PaymentCheckOrderStatusProps {
    api: (reference: string) => Promise<ORDER_STATUS>,
    reference: string
}

const PaymentCheckOrderStatus: React.FC<PaymentCheckOrderStatusProps> = ({
                                                                             api,
                                                                             reference
                                                                         }) => {
    const { status: paymentStatus, onResultPageClose } = useAddCoinFlow();
    const { WalletState: { coin: coinFromWallet } } = useSelector(store => store);
    const { currentCurrencyObject: { toWalletNumber } } = useCurrency();

    const { data: orderStatus } = useFantasyQuery([QUERY_KEY.CHECK_ORDER_STATUS, reference], () => {
        return api(reference);
    }, {
        options: {
            placeholderData: ORDER_STATUS.PENDING,
            enabled: isPaymentSuccessful(paymentStatus),
            refetchInterval: (data) => {
                if (data && !isOrderPending(data)) {
                    return false;
                }
                return detectMillisecond;
            }
        }
    });

    return <>
        <Navbar
            navbarStart={false}
            navbarCenter={<div
                className="tw-flex tw-justify-center tw-items-center tw-h-full fz-sf-bold-title-3 text-white">
                <FormattedMessage id="label_add_deposit" />
            </div>}
            endIcon={<Icon.Close className="tw-cursor-pointer tw-text-white" onClick={onResultPageClose} />}
            navbarBottom={
                <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-pt-[3.3rem] tw-pb-[4rem]">
                    <div
                        className="tw-text-center fz-sf-bold-title-lg tw-text-grey-0">{toWalletNumber(coinFromWallet || 0)}</div>
                </div>
            }
            shouldCalculatePaddingTop={false}
            isTransparent={true}
            isHeaderLine={false}
            className="header-bg-linear-gradient-up !tw-sticky"
        />

        <CoinProducts />
        <ResultView orderStatus={orderStatus} />
    </>;
};

const PaymentResult = () => {
    const { settingState: { paymentMethod } } = useSetting();

    const { payment_reference } = getQuery();
    const paymentReference = payment_reference as string;

    const tripleARepository = new TripleARepository();
    const payMongoRepository = new PayMongoRepository();

    if (ADD_COIN_METHOD.TRIPLEA === paymentMethod) {
        return <PaymentCheckOrderStatus api={tripleARepository.getOrderStatus}
                                        reference={paymentReference} />;
    }

    if (ADD_COIN_METHOD.PAY_MONGO === paymentMethod) {
        return <PaymentCheckOrderStatus api={payMongoRepository.getOrderStatus}
                                        reference={paymentReference} />;
    }

    return <Redirect to={ROUTER_PATH_CONFIG.home} />;
};


export default PaymentResult;
