import PageViewRecorder from "components/ga/PageViewRecorder";
import MainLayout from "components/layout/MainLayout";
import ProtectedRoute from "components/protect/ProtectedRoute";
import ScrollToTop from "components/ScrollToTop";
import UserDeletePage from "components/userDelete/UserDeletePage";
import Alert from "designToken/alert/Alert";
import DesignToken from "designToken/demo/DesignToken";
import AuthPage from "domain/auth/AuthPage";
import ErrorPage from "domain/error/ErrorPage";
import FromMobileAppErrorPage from "domain/error/FromMobileAppErrorPage";
import Main from "domain/Main";
import B2BErrorPage from "domain/unauthorized/logout/B2BErrorPage";
import EmailCheckPage from "domain/unauthorized/notification/EmailCheckPage";
import React from "react";
import { Route, Switch } from "react-router-dom";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";
import useFantasyConfig from "./hooks/useFantasyConfig";

const UnAuthPage = () => {
    if (window.location.hostname.indexOf("power11") !== -1) {
        return <B2BErrorPage />;
    }

    return <AuthPage />;
};


const App = () => {
        const { devToggle } = useFantasyConfig();

        return (
            <>
                <PageViewRecorder />
                <Alert />
                <Switch>
                    {devToggle && (
                        <Route path="/design-token">
                            <DesignToken />
                        </Route>
                    )}
                    <Route path={ROUTER_PATH_CONFIG.auth.index}>
                        <UnAuthPage />
                    </Route>
                    <Route exact path={ROUTER_PATH_CONFIG.checkEmail}>
                        <EmailCheckPage />
                    </Route>
                    <Route exact path={ROUTER_PATH_CONFIG.error}>
                        <MainLayout>
                            <ErrorPage />
                        </MainLayout>
                    </Route>
                    <Route exact path={ROUTER_PATH_CONFIG.fromMobileAppError}>
                        <MainLayout>
                            <FromMobileAppErrorPage />
                        </MainLayout>
                    </Route>
                    <Route path={ROUTER_PATH_CONFIG.deleteUser.index}>
                        <UserDeletePage />
                    </Route>
                    <Route path={ROUTER_PATH_CONFIG.home}>
                        <ProtectedRoute>
                            <ScrollToTop>
                                <MainLayout>
                                    <Main />
                                </MainLayout>
                            </ScrollToTop>
                        </ProtectedRoute>
                    </Route>
                </Switch>
            </>
        );
    }
;

export default App;
