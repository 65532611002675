import MyNineCatDetail from "domain/slate/views/MySlateDetail/MyNineCatDetail";
import MyNineCatLeaderboard from "domain/slate/views/MySlateDetail/MyNineCatLeaderboard";
import { useCallback, useState } from "react";
import { MySlateProvider } from "../../providers/MySlateProvider";

enum MyNineCatSwitchPage {
    MY_SLATE_DETAIL = "mySlateDetail",
    SLATE_LEADERBOARD = "slateLeaderboard"
}

const {
    MY_SLATE_DETAIL,
    SLATE_LEADERBOARD
} = MyNineCatSwitchPage;

const MyNineCatSwitch = () => {
    const [currentPage, setCurrentPage] = useState<MyNineCatSwitchPage>(MY_SLATE_DETAIL);

    const handleTogglePage = useCallback(() => {
        setCurrentPage((prevState: MyNineCatSwitchPage) =>
            prevState === SLATE_LEADERBOARD ? MY_SLATE_DETAIL : SLATE_LEADERBOARD);
    }, []);

    return <MySlateProvider>
        {
            currentPage === SLATE_LEADERBOARD
                ? <MyNineCatLeaderboard onTogglePage={handleTogglePage} />
                : <MyNineCatDetail onTogglePage={handleTogglePage} />
        }
    </MySlateProvider>;
};

export default MyNineCatSwitch;
