import { Form } from "components/ui/form";
import { FantasyIntegerTextField } from "components/reactHookFormField/FantasyTextField";
import {
    PrizeDistributionList,
    PrizeDistributionListProps
} from "domain/contest/join/info/detail/PrizeDistributionList";
import {
    ENTRIES_FIELD_NAME,
    usePrizeDistributionCalculator
} from "domain/contest/join/info/detail/usePrizeDistributionCalculator";
import { cn } from "helpers/cn";
import { multiplyByOneHundred } from "helpers/number/NumberHelper";
import React from "react";
import { ProgressBar } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

export interface PrizeDistributionCalculatorProps extends Omit<PrizeDistributionListProps, "prizeList"> {
    contestId: number;
    minSlips: number;
    spots: number;
}

export function getEntriesPercentage(entries: number, minSlips: number, spots: number) {
    return Math.floor(multiplyByOneHundred((entries - minSlips) / (spots - minSlips)));
}

export const PrizeDistributionCalculator: React.FC<PrizeDistributionCalculatorProps> = ({
                                                                                            idealPrizeListLength,
                                                                                            contestId,
                                                                                            minSlips,
                                                                                            spots,
                                                                                            entryFeeType
                                                                                        }) => {
    const { form, entries, inputError, prizeList } = usePrizeDistributionCalculator(minSlips, spots, contestId);

    const entriesPercentage = getEntriesPercentage(entries, minSlips, spots);


    return (
        <div className={cn("tw-flex tw-flex-col tw-gap-3",
            "tw-max-w-[30.4rem] tw-px-4",
            "tw-flex-1 tw-overflow-scroll",
            "tw-max-h-[calc(100vh-29rem)]"
        )}>
            <p className={"tw-text-body-2 tw-text-left"}>
                <FormattedMessage id="prize_distribution_calculator_description_1" />
            </p>
            <p className={"tw-text-body-2 tw-text-left"}>
                <FormattedMessage id="prize_distribution_calculator_description_2" />
            </p>
            <div className={"tw-flex tw-flex-col tw-gap-3"}>
                <div className={"tw-flex tw-flex-col tw-items-center"}>
                    <p className="tw-p-3 tw-px-2.5 tw-pb-1">
                        <FormattedMessage id="label_teams" />
                    </p>
                    <Form {...form}>
                        <div className={"tw-relative"}>
                            <FantasyIntegerTextField
                                showErrorIcon={false}
                                name={ENTRIES_FIELD_NAME}
                                inputProps={{
                                    style: {
                                        width: Math.max(1, entries.toString().length) * 14 + 24,
                                    },
                                    "data-testid": "teams-input",
                                    className: "tw-text-h4 tw-font-bold tw-text-primary-800",
                                    value: form.watch("teams"),
                                    autoComplete: "off",
                                    placeholder: "0",
                                } as Omit<React.InputHTMLAttributes<HTMLInputElement>, "name">} />
                        </div>
                    </Form>
                </div>
                <div className="tw-px-1">
                    <ProgressBar
                        className="tw-min-h-3"
                        now={inputError ? 0 : entriesPercentage}
                        style={{
                            "--progress-bg": "#E6E6E6",
                        } as React.CSSProperties} />
                    <div className="tw-flex tw-justify-between tw-text-overline tw-mt-1">
                        <div>
                            <FormattedMessage id="prize_distribution_calculator_min_spots" />
                            <span>{minSlips}</span>
                        </div>
                        <div>
                            <FormattedMessage id="prize_distribution_calculator_max_spots" />
                            <span>{spots}</span>
                        </div>
                    </div>
                </div>
                <PrizeDistributionList
                    entryFeeType={entryFeeType}
                    prizeList={prizeList} idealPrizeListLength={idealPrizeListLength} />
            </div>
        </div>
    );
};
