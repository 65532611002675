import { sendOtp, verifyOtp } from "data/api/kyc/KycApi";

export class KycRepository {
    public async sendOtp() {
        await sendOtp();
    };

    public async verifyOtp(code: string) {
        await verifyOtp(code);
    };
}

