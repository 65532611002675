import { UPDATE_FEATURE_CONFIG } from "../../Constants";

export interface AppDownloadLink {
    googlePlay: string,
    appStore: string,
}


export interface Firebase {
    apiKey: string,
    authDomain: string,
    projectId: string,
    storageBucket: string,
    messagingSenderId: string,
    appId: string,
    measurementId: string,
}

export interface Gtm {
    id: string,
}

export interface Chat {
    key: string,
    channelType: string,
    channelId: string,
}

export enum ADD_COIN_METHOD {
    UPAY = "UPAY",
    TRIPLEA = "TRIPLEA",
    PAY_MONGO = "PAY_MONGO",
}

export enum CURRENCY {
    INDIAN = "indian",
    UNIVERSAL = "universal"
}

export enum CURRENCY_ICON_SUIT_NAME {
    DEFAULT = "DEFAULT",
    PESO = "PESO"
}

// TODO should use user setting instead
export interface FeatureConfig {
    hasApp: boolean,
    appleLogin: boolean,
    /**
     * @deprecated The method should not be used
     */
    referral: boolean,
    /**
     * @deprecated The method should not be used
     */
    addCoin: boolean,
    /**
     * @deprecated The method should not be used
     */
    paymentMethod: ADD_COIN_METHOD,
    /**
     * @deprecated The method should not be used
     */
    transfer: boolean,
    /**
     * @deprecated The method should not be used
     */
    withdrawWinning: boolean,
    /**
     * @deprecated The method should not be used
     */
    kyc: boolean,
    /**
     * @deprecated The method should not be used
     */
    liveChat: boolean,
    /**
     * @deprecated The method should not be used
     */
    chatRoom: boolean,
    /**
     * @deprecated The method should not be used
     */
    canUserSetCurrencyFormat: boolean,
    /**
     * @deprecated The method should not be used
     */
    defaultCurrencyFormat: CURRENCY,
    /**
     * @deprecated The method should not be used
     */
    currencyIconSuitName: CURRENCY_ICON_SUIT_NAME;
}

export interface FantasyGameConfig {
    appDownloadLink: AppDownloadLink;
    featureToggle: boolean;
    devToggle: boolean;
    resourceUrl: string;
    gameUrl: string;
    name: string;
    title: string;
    addDepositUrl: string;
    firebase: Firebase;
    gtm: Gtm;
    chat: Chat;
    featureConfig: FeatureConfig;
    liveChatLicense: string;
    tenantApiKey: string;
}

export const InitFantasyGameConfig: FantasyGameConfig = window.FANTASY_GAME_CONFIG();

const FantasyGameConfigState = (prevState: FantasyGameConfig = InitFantasyGameConfig, action) => {
    const { type, payload } = action;

    switch (type) {
        case UPDATE_FEATURE_CONFIG:
            return {
                ...prevState,
                featureConfig: {
                    ...prevState.featureConfig,
                    ...payload,
                },
            };
        default:
            return prevState;
    }
};

export default FantasyGameConfigState;
