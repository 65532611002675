import { ajax } from "apis/ApiUtils";
import { joinContest, joinRubyContest } from "apis/contest/ContestApi";
import { NINE_CAT_STAT, NineCatContestVo, NineCatPlayerDetailVO } from "domain/slate/data/ComponentTypes";
import {
    CreateTeamRequest,
    MyNineCatDetailDto,
    MyNineCatTeamDto,
    NineCatContestDto,
    UpcomingNineCatDto
} from "domain/slate/data/dto";
import { NineCatPlayersDto } from "domain/slate/data/types";
import { NineCatDetailVo, UpcomingNineCatVo } from "domain/slate/data/vo";
import { getSportConfig } from "domain/SportConfig";
import { divideByOneHundred } from "helpers/number/NumberHelper";
import { AP_LEVEL } from "types/activityPoint/ApLevel";
import { ROLE, SPORT } from "types/sports/Sport";

async function fetchSlatePlayers(nineCatId: number, sport: string) {
    return await ajax<NineCatPlayersDto[]>({
        url: `/${sport}/nine-cat/${nineCatId}/players`,
    });
}

export function toNineCatPlayerDetailVO(dto: NineCatPlayersDto): NineCatPlayerDetailVO {
    return {
        ...dto,
        id: dto.id,
        firstName: dto.firstName,
        middleName: dto.middleName,
        lastName: dto.lastName,
        avatar: dto.avatar,
        squadLogoUrl: dto.squadLogoUrl,
        squadName: dto.squadCode,
        injuryPlayingChance: dto.injuryPlayingChance,
        roleAbbr: getSportConfig(SPORT.BASKETBALL).team.getRoleAbbr(dto.role as ROLE),
        AST: dto.assistsCent,
        BLK: dto.blocksCent,
        DR: dto.defensiveReboundsCent,
        FGE: dto.fieldGoalsEfficiencyCent,
        FTE: dto.freeThrowsEfficiencyCent,
        OR: dto.offensiveReboundsCent,
        PTS: dto.pointsCent,
        ST: dto.stealsCent,
        "3PM": dto.threePointersMadeCent,
        matchId: dto.matchId,
    };
}

function toNineCatContestVO(dto: NineCatContestDto): NineCatContestVo {
    return ({
        ...dto,
        minApLevel: dto.minApLevel as AP_LEVEL,
        entryFee: divideByOneHundred(dto.entryFeeCent),
        type: dto.type,
        firstPrize: divideByOneHundred(dto.firstPrizeCent),
        entryFeeType: dto.entryFeeType,
        allowDiscountWithRuby: dto.allowDiscountWithRuby,
    });
}

export interface JoinContestRequest {
    contestId: number,
    selectedTeamIds: number[],
    useRuby: boolean,
    couponIds: number[]
}


export interface JoinRubyContestRequest extends Pick<JoinContestRequest, "contestId" | "selectedTeamIds"> {

}

class SlateRepository {
    async getSlatePlayers(slateId: number) {
        const { data } = await fetchSlatePlayers(slateId, SPORT.BASKETBALL);
        return data.data.map(toNineCatPlayerDetailVO);
    }

    async getUpcomingSlates(sport = SPORT.BASKETBALL): Promise<UpcomingNineCatVo[]> {
        const { data } = await ajax<UpcomingNineCatDto[]>({
            url: `/${sport}/nine-cat/upcoming`,
        });
        return data.data.map(dto => new UpcomingNineCatVo(dto));
    }

    async getDetail(nineCatId: number, sport = SPORT.BASKETBALL) {
        const { data } = await ajax<MyNineCatDetailDto>({
            url: `/${sport}/nine-cat/${nineCatId}/detail`,
        });
        return new NineCatDetailVo(data.data);
    }

    async getMyTeam(nineCatId: number, sport = SPORT.BASKETBALL) {
        const { data } = await ajax<MyNineCatTeamDto[]>({
            url: `/${sport}/nine-cat/${nineCatId}/team/my`,
        });
        return data.data;
    }

    async createMyTeam(request: CreateTeamRequest, sport = SPORT.BASKETBALL) {
        return ajax({
            method: "POST",
            url: `/${sport}/nine-cat/${request.slateId}/team`,
            data: request
        });
    }

    async getContests(nineCatId: number, sport = SPORT.BASKETBALL) {
        const { data } = await ajax<NineCatContestDto[]>({
            url: `/${sport}/nine-cat/${nineCatId}/contest/public`,
        });

        return data.data.map(toNineCatContestVO);
    }

    async joinContest({ contestId, couponIds, selectedTeamIds, useRuby }: JoinContestRequest) {
        await joinContest(contestId, selectedTeamIds, useRuby, couponIds);
    }

    async joinRubyContest({ contestId, selectedTeamIds, }: JoinRubyContestRequest) {
        await joinRubyContest(contestId, selectedTeamIds);
    }

    async getMyContests(sport = SPORT.BASKETBALL): Promise<NineCatContestVo[]> {
        const { data } = await ajax<NineCatContestDto[]>({
            url: `/${sport}/nine-cat/contest/my`,
        });
        return data.data.map(toNineCatContestVO);
    }
}

export default SlateRepository;

export function toCreateMyTeamRequest(slateId: string, statToPlayerIdMap: Record<string, string>): CreateTeamRequest {
    return ({
        slateId: slateId,
        points: statToPlayerIdMap[NINE_CAT_STAT.PTS],
        fieldGoalsEfficiency: statToPlayerIdMap[NINE_CAT_STAT.FGE],
        freeThrowsEfficiency: statToPlayerIdMap[NINE_CAT_STAT.FTE],
        offensiveRebounds: statToPlayerIdMap[NINE_CAT_STAT.OR],
        defensiveRebounds: statToPlayerIdMap[NINE_CAT_STAT.DR],
        assists: statToPlayerIdMap[NINE_CAT_STAT.AST],
        steals: statToPlayerIdMap[NINE_CAT_STAT.ST],
        blocks: statToPlayerIdMap[NINE_CAT_STAT.BLK],
        threePointersMade: statToPlayerIdMap[NINE_CAT_STAT["3PM"]],
    });
}
