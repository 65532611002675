import { Icon } from "helpers/IconHelper";
import { isNegative } from "helpers/number/NumberHelper";
import useFantasyHistory from "hooks/useFantasyHistory";
import React from "react";
import { MdOutlineChevronRight } from "react-icons/md";
import { FormattedMessage } from "react-intl";
import { useSelector } from "store";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";
import AmountDisplay from "containers/amount/AmountDisplay";
import { getCurrencyIconSuitByWalletType } from "containers/amount/CurrencyIconSuitHelper";
import { cn } from "helpers/cn";
import useCurrency from "hooks/useCurrency/useCurrency";
import {
    StyledWalletContentLabel,
    StyledWalletContentLabelWrapper,
    StyledWalletContentNote,
    StyledWalletContentValueWrapper,
    StyledWalletContentWrapper,
    StyledWalletIconWrapper,
} from "domain/wallet/components/button/WalletButtonStyle";
import { WALLET_TYPE } from "data/dto/wallet/walletHistoryDto";
import { useSetting } from "hooks/useSetting";

export const WalletDetailContent = () => {
    const history = useFantasyHistory();
    const { currentCurrencyObject: { toWalletNumber } } = useCurrency();
    const {
        WalletState: { coin, winning, ruby },
    } = useSelector((store) => store);
    const { settingState: { contest: { rubyExchangeRate } } } = useSetting();

    const handleToWalletHistoryDeposit = () => {
        history.push(ROUTER_PATH_CONFIG.wallet, {
            category: WALLET_TYPE.COIN,
            navbarBackGoTo: history.location.pathname,
        });
    };
    const handleToWalletHistoryWinning = () => {
        history.push(ROUTER_PATH_CONFIG.wallet, {
            category: WALLET_TYPE.WINNING,
            navbarBackGoTo: history.location.pathname,
        });
    };
    const handleToWalletHistoryCashBonus = () => {
        history.push(ROUTER_PATH_CONFIG.wallet, {
            category: WALLET_TYPE.RUBY,
            navbarBackGoTo: history.location.pathname,
        });
    };

    return (
        <>
            <StyledWalletContentWrapper
                $hasDivider
                onClick={handleToWalletHistoryDeposit}
            >
                <StyledWalletContentLabelWrapper>
                    <StyledWalletIconWrapper>
                        <Icon.WalletDetailCoin className="tw-text-primary-400" />
                    </StyledWalletIconWrapper>
                    <StyledWalletContentLabel>
                        <FormattedMessage id="label_deposit" />
                    </StyledWalletContentLabel>
                </StyledWalletContentLabelWrapper>
                <StyledWalletContentValueWrapper>
                    <AmountDisplay currencyIconSuit={getCurrencyIconSuitByWalletType(WALLET_TYPE.COIN)}
                                   className={cn("tw-gap-1 tw-text-h5 tw-font-bold", {
                                       "tw-text-system-danger500": isNegative(coin),
                                   })}>
                        {toWalletNumber(coin)}
                    </AmountDisplay>
                    <MdOutlineChevronRight
                        size="1.6rem"
                        className="text-light-tertiary"
                    />
                </StyledWalletContentValueWrapper>
            </StyledWalletContentWrapper>
            <StyledWalletContentWrapper
                $hasDivider
                onClick={handleToWalletHistoryWinning}
            >
                <StyledWalletContentLabelWrapper>
                    <StyledWalletIconWrapper>
                        <Icon.WalletDetailWinning className="tw-text-primary-400" />
                    </StyledWalletIconWrapper>
                    <StyledWalletContentLabel>
                        <FormattedMessage id="label_winning" />
                    </StyledWalletContentLabel>
                </StyledWalletContentLabelWrapper>
                <StyledWalletContentValueWrapper>
                    <AmountDisplay currencyIconSuit={getCurrencyIconSuitByWalletType(WALLET_TYPE.WINNING)}
                                   className="tw-gap-1 tw-text-h5 tw-font-bold">
                        {toWalletNumber(winning)}
                    </AmountDisplay>
                    <MdOutlineChevronRight
                        size="1.6rem"
                        className="text-light-tertiary"
                    />
                </StyledWalletContentValueWrapper>
            </StyledWalletContentWrapper>
            <StyledWalletContentWrapper onClick={handleToWalletHistoryCashBonus}>
                <StyledWalletContentLabelWrapper>
                    <StyledWalletIconWrapper>
                        <Icon.WalletDetailRuby className="tw-text-primary-400" />
                    </StyledWalletIconWrapper>
                    <div>
                        <StyledWalletContentLabel>
                            <FormattedMessage id="label_ruby" />
                        </StyledWalletContentLabel>
                        <StyledWalletContentNote>
                            <FormattedMessage
                                id="wallet_view_label_ruby_usage"
                                values={{ rubyExchangeRate }}
                            />
                        </StyledWalletContentNote>
                    </div>
                </StyledWalletContentLabelWrapper>
                <StyledWalletContentValueWrapper>
                    <AmountDisplay currencyIconSuit={getCurrencyIconSuitByWalletType(WALLET_TYPE.RUBY)}
                                   className="tw-gap-1 tw-text-h5 tw-font-bold">
                        {toWalletNumber(ruby)}
                    </AmountDisplay>

                    <MdOutlineChevronRight
                        size="1.6rem"
                        className="text-light-tertiary"
                    />
                </StyledWalletContentValueWrapper>
            </StyledWalletContentWrapper>
        </>
    );
};
