import React from "react";
import DepositConfirmationDialog, {
    DepositConfirmationDialogProps
} from "../../contest/components/ConfirmationDialog/DepositConfirmationDialog";
import { NineCatContestVo } from "../data/ComponentTypes";

interface NineCatDepositConfirmationDialogProps extends Omit<DepositConfirmationDialogProps, "contest"> {
    contest: NineCatContestVo;
}

export const NineCatDepositConfirmationDialog: React.FC<NineCatDepositConfirmationDialogProps> = (props) => {

    const { contest } = props;

    return <DepositConfirmationDialog
        {...props}
        pageTitle={"Confirm Join Nine Cat Contest"}
        contest={contest} />;
};
