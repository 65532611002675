import React from "react";
import IconButton, { IconButtonFor } from "components/buttons/IconButton";
import { Icon } from "helpers/IconHelper";
import { useSetting } from "hooks/useSetting";
import useFantasyConfig from "hooks/useFantasyConfig";

interface HowToPlayNavbarButtonProps {
    className?: string,
    for: IconButtonFor,
    background: boolean
}

export const HowToPlayNavbarButton: React.FC<HowToPlayNavbarButtonProps> = ({
                                                                                className,
                                                                                for: IconButtonFor,
                                                                                background
                                                                            }) => {
    const { settingState: settings } = useSetting();
    const fantasyGameConfig = useFantasyConfig();


    if (fantasyGameConfig.name === "daily") {
        return <IconButton for={IconButtonFor}
                           background={background}
                           icon={Icon.Game}
                           className={className}
                           onClick={() => {
                               window.open(settings.link.howToPlay, "_blank");
                           }}
        />;
    }

    return null;
};
