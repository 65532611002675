import { ChipFor } from "components/chip/type";
import { LightTopNavbar } from "components/container/navbar/TopNavbar";
import B2BHomeNavbar from "domain/home/components/FantasyHomeNavbar/B2BHomeNavbar/B2BHomeNavbar";
import WalletButton from "domain/wallet/components/button/TopNavbarWalletButton";
import useUserIdentity from "hooks/useUserIdentity";
import React from "react";
import { FormattedMessage } from "react-intl";
import { HowToPlayNavbarButton } from "domain/home/components/FantasyHomeNavbar/HowToPlayNavbarButton";
import { cn } from "helpers/cn";
import { IconButtonFor } from "components/buttons/IconButton";

const MyMatchNavbar = () => {
    const { isB2B } = useUserIdentity();

    if (isB2B) {
        return <B2BHomeNavbar />;
    }

    return (
        <LightTopNavbar>
            <div className="tw-w-full tw-text-grey-1000 tw-text-h6 tw-font-bold tw-text-center tw-relative">
                <FormattedMessage id="label_my_games" />
                {/*// TODO: only for verify & daily, and will be removed.*/}
                <div className={cn("tw-absolute tw-top-0 tw-right-0", "tw-flex tw-gap-1")}>
                    <HowToPlayNavbarButton for={IconButtonFor.ON_LIGHT} background={true} />
                    <WalletButton chipFor={ChipFor.ON_LIGHT} />
                </div>
            </div>
        </LightTopNavbar>
    );
};

export default MyMatchNavbar;
