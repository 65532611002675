import FantasyBasicPopup from "components/popup/FantasyBasicPopup";
import { NEW_BUTTON_VARIANTS } from "designToken/button/types";
import { analyticsEvent, logCustomEvent } from "ga";
import { presentCoupon } from "helpers/IconHelper";
import useFantasyHistory from "hooks/useFantasyHistory";
import React from "react";
import { useIntl } from "react-intl";
import { ROUTER_PATH_CONFIG } from "router/RouterPathConfig";
import { useDispatch } from "react-redux";
import { updateUserIsNewUser } from "../../../store/actions/UserActions";
import { useSetting } from "hooks/useSetting";

interface NewUserCouponPopupProps {
    isShow: boolean,
    onClose: () => void
}

const NewUserCouponPopup: React.FC<NewUserCouponPopupProps> = ({ isShow, onClose }) => {
    const intl = useIntl();
    const history = useFantasyHistory();
    const dispatch = useDispatch();
    const { settingState: { newUserGifts: { couponDiscountPercentage } } } = useSetting();

    return <FantasyBasicPopup
        show={isShow}
        imageSection={<img src={presentCoupon} alt="gift-coupon" className="tw-max-w-[85%]" />}
        title={intl.formatMessage({ id: "new_user_coupon_dialog_title" })}
        texts={[
            {
                text: intl.formatMessage({ id: "new_user_coupon_dialog_description" }, { discountPercentage: couponDiscountPercentage }),
            }
        ]}
        buttonSetting={{
            confirmButtonVariant: NEW_BUTTON_VARIANTS.tertiaryLight,
            cancelButtonVariant: NEW_BUTTON_VARIANTS.text,
            confirmButtonText: intl.formatMessage({ id: "new_user_coupon_dialog_confirm_btn" }),
            cancelButtonText: intl.formatMessage({ id: "new_user_coupon_dialog_cancel_btn" }),
            onConfirm: () => {
                logCustomEvent(analyticsEvent.webRegisterGiftTake);
                onClose();
                dispatch(updateUserIsNewUser(false));
                history.push(ROUTER_PATH_CONFIG.task.index);
            },
            onCancel: () => {
                logCustomEvent(analyticsEvent.webRegisterGiftCancel);
                onClose();
            }
        }}
        onHide={onClose}
    />;
};
export default NewUserCouponPopup;
