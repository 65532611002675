import React from "react";
import {
    FieldPath,
    FieldValues,
    PathValue,
    useController,
    UseControllerProps,
    useFormContext,
    useWatch
} from "react-hook-form";
import { FormFieldProvider } from "components/ui/form";
import { TEXT_FIELD_SIZE, TEXT_FIELD_VARIANT } from "components/input/FantasyText";
import { FantasySelect, FantasySelectAlignment } from "components/reactHookFormField/FantasySelect";
import { OnChangeValidator } from "components/reactHookFormField/OnChangeValidator";
import { FormErrorMessage } from "domain/kyc/pages/kycVerificationForm/components/FormErrorMessage";

interface FantasySelectFieldProps<T extends string | number> {
    size?: TEXT_FIELD_SIZE;
    variant?: TEXT_FIELD_VARIANT;
    inputProps?: Omit<React.InputHTMLAttributes<HTMLInputElement>, "name"> & {
        "data-testid"?: string
    };
    showErrorMessage?: boolean;
    showErrorIcon?: boolean;
    iconLeft?: React.ReactNode;
    iconRight?: React.ReactNode;
    maxLength?: number;
    onChangeValidator?: OnChangeValidator;
    disabled?: boolean;

    onSelect?: (value: T) => void;
    options: T[] | { label: string; value: T; }[],
    align?: FantasySelectAlignment,
}

export const FantasySelectField = <
    TOptions extends PathValue<TFieldValues, TName>,
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
      size = TEXT_FIELD_SIZE.Medium,
      variant = TEXT_FIELD_VARIANT.STANDARD,
      control,
      name,
      inputProps,
      showErrorMessage = true,
      showErrorIcon = true,
      iconLeft,
      maxLength,
      disabled,
      options,
  }: FantasySelectFieldProps<TOptions> & UseControllerProps<TFieldValues, TName>) => {
    const form = useFormContext<TFieldValues>();
    const { fieldState } = useController({ name });
    const fieldValue = useWatch({ name });

    const onSelect = (value: TOptions) => {
        form.setValue(name, value);
        form.trigger(name);
    };

    const getLabel = () => {
        const originValue = fieldValue;
        if (options.length === 0) {
            return undefined;
        }
        if (typeof options[0] === "string") {
            return originValue;
        }
        const found = (options as { label: string, value: string }[]).find(opt => opt.value === originValue);
        if (!found) {
            return originValue;
        }
        return found.label;
    };

    return <FormFieldProvider name={name}>
        <FantasySelect
            size={size}
            variant={variant}
            options={options}
            inputProps={{
                ...form.register(name),
                ...inputProps,
                value: getLabel(),
            }}
            onSelect={onSelect}
            errorMessage={<FormErrorMessage showIcon={showErrorIcon} />}
            isError={!!fieldState.error}
            showErrorMessage={showErrorMessage}
            iconLeft={iconLeft}
            maxLength={maxLength}
            disabled={disabled}
        />
    </FormFieldProvider>;
};
