import AmountDisplay from "containers/amount/AmountDisplay";
import { getCurrencyIconSuitByEntryFeeType } from "containers/amount/CurrencyIconSuitHelper";
import {
    CurrentBalanceDivider
} from "domain/contest/components/ConfirmationDialog/ConfirmationModal/CurrentBalanceDivider";
import { cn } from "helpers/cn";
import { divideByOneHundred } from "helpers/number/NumberHelper";
import useCurrency from "hooks/useCurrency/useCurrency";
import React from "react";
import { FormattedMessage } from "react-intl";
import { ENTRY_FEE_TYPE_FROM_API } from "types/contest/Contest";

export function BalanceSection(props: {
    balanceDepositCent: number,
    balanceRubyCent: number,
}) {
    const { currentCurrencyObject: { toWalletNumber } } = useCurrency();
    return <div data-testid={"newBalance"}
                className={cn(
                    "tw-flex tw-flex-col tw-gap-1",
                    "tw-w-full tw-m-auto",
                    "tw-py-3 tw-px-6",
                    "tw-rounded-lg",
                    "tw-bg-white"
                )}>
        <CurrentBalanceDivider>
            <FormattedMessage id="confirmation_dialog_label_new_balance" />
        </CurrentBalanceDivider>
        <div className={
            cn("tw-flex tw-justify-between",
                "tw-text-grey-800",
                "tw-text-subtitle-1 tw-font-normal",
                props.balanceDepositCent < 0 ? "tw-text-error-700" : "",
            )
        }>
            <FormattedMessage id="label_deposit" />
            <AmountDisplay currencyIconSuit={getCurrencyIconSuitByEntryFeeType(ENTRY_FEE_TYPE_FROM_API.DEPOSIT)}
                           className="tw-font-bold tw-text-inherit">
                {toWalletNumber(divideByOneHundred(props.balanceDepositCent))}
            </AmountDisplay>
        </div>
        <div className={
            cn("tw-flex tw-justify-between",
                "tw-text-grey-800",
                "tw-text-subtitle-1 tw-font-normal",
                props.balanceRubyCent < 0 ? "tw-text-error-700" : "",
            )
        }>
            <FormattedMessage id="label_ruby" />
            <AmountDisplay currencyIconSuit={getCurrencyIconSuitByEntryFeeType(ENTRY_FEE_TYPE_FROM_API.RUBY)}
                           className="tw-font-bold tw-text-inherit">
                {toWalletNumber(divideByOneHundred(props.balanceRubyCent))}
            </AmountDisplay>
        </div>
    </div>;
}
