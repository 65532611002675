import { useSelector } from "store";

const useUserIdentity = () => {

    const userState = useSelector(store => store.UserState);

    // TODO should not use isB2C
    return {
        isB2B: userState.isB2B,
        /** @deprecated */
        isB2C: userState.isB2C,
    };
};

export default useUserIdentity;
