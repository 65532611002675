import React from "react";
import IconButton, { IconButtonFor } from "components/buttons/IconButton";
import { Icon, responsibleGamingGiftImg } from "helpers/IconHelper";

interface HeaderProps {
    onClose: () => void;
}

export const Header: React.FC<HeaderProps> = ({ onClose }) => {
    return <div
        className="tw-pb-6 tw-pt-4 tw-px-4 tw-flex-shrink-0 tw-flex tw-flex-col tw-gap-2 tw-relative">
        <div className="tw-text-right">
            <IconButton
                for={IconButtonFor.ON_LIGHT}
                background={false}
                icon={Icon.Close}
                onClick={onClose}
            />
        </div>
        <img
            src={responsibleGamingGiftImg}
            alt="responsible-gaming-gift"
            className="tw-absolute tw-top-0 tw-left-1/2 -tw-translate-x-1/2 -tw-translate-y-[60%]"
        />
    </div>;
};
