export enum WALLET_TYPE {
    COIN = "COIN",
    WINNING = "WINNING",
    RUBY = "RUBY",
}

export enum WALLET_API_RESPONSE_EVENT {
    JOIN_CONTEST = "JOIN_CONTEST",
    WIN_CONTEST = "WIN_CONTEST",
    REFUND_CONTEST = "REFUND_CONTEST",
    REFUND_DEPOSIT_CONTEST = "REFUND_DEPOSIT_CONTEST",
    REFUND_RUBY_CONTEST = "REFUND_RUBY_CONTEST",
    USER_REGISTER = "USER_REGISTER",
    USER_DEPOSIT = "USER_DEPOSIT",
    USER_WITHDRAW_COIN = "USER_WITHDRAW_COIN",
    USER_WITHDRAW_WINNING = "USER_WITHDRAW_WINNING",
    CANCELLED_USER_WITHDRAW_WINNING = "CANCELLED_USER_WITHDRAW_WINNING",
    PURCHASE_REWARD_VOUCHER = "PURCHASE_REWARD_VOUCHER",
    PURCHASE_REWARD_REDEPOSIT = "PURCHASE_REWARD_REDEPOSIT",

    USER_TRANSFER_WINNING_OUT = "USER_TRANSFER_WINNING_OUT",
    USER_TRANSFER_WINNING_IN = "USER_TRANSFER_WINNING_IN",
    REFUND_DEPOSIT = "REFUND_DEPOSIT",
    JOIN_CONTEST_RETURN_TO_REFERRER = "JOIN_CONTEST_RETURN_TO_REFERRER",
    INVITED_BY_FRIEND = "INVITED_BY_FRIEND",
    REDEEM = "REDEEM",
    FIRST_DEPOSIT = "FIRST_DEPOSIT",
    REFUND_REWARD_ORDER = "REFUND_REWARD_ORDER",
    GAME_TRIER_DAILY_DEPOSIT = "GAME_TRIER_DAILY_DEPOSIT",
    REVOKE_GAME_TRIER_DAILY_DEPOSIT = "REVOKE_GAME_TRIER_DAILY_DEPOSIT",
    REDEMPTION_CODE_DEPOSIT = "REDEMPTION_CODE_DEPOSIT",
    REDEMPTION_CODE_RUBY = "REDEMPTION_CODE_RUBY",
    MANUAL_ADD_DEPOSIT = "MANUAL_ADD_DEPOSIT",
    MANUAL_ADD_RUBY = "MANUAL_ADD_RUBY",
    MANUAL_ADD_WINNING = "MANUAL_ADD_WINNING",
    JOIN_DEPOSIT_CONTEST = "JOIN_DEPOSIT_CONTEST",
    JOIN_RUBY_CONTEST = "JOIN_RUBY_CONTEST",
    ADD_BY_TENANT = "ADD_BY_TENANT",
    CLAIM_TASK = "CLAIM_TASK",
    CONVERTED_TO_WINNING = "CONVERTED_TO_WINNING",
    CONVERTED_FROM_COIN = "CONVERTED_FROM_COIN"
}

export enum OrderStatus {
    COMPLETED = "COMPLETED",
    PENDING = "PENDING",
    CANCELLED = "CANCELLED",
}

export interface RubyHistoryDto {
    historyId: number
    createTime: number
    event: WALLET_API_RESPONSE_EVENT
    amountCent: number
    refereeUserCode?: string
    referrerUserCode?: string
}

export interface RubyDto {
    expiringTime: number
    expiringRubyCent: number
    histories: RubyHistoryDto[]
}

export interface WalletHistoryDto {
    historyId: number
    createTime: number
    type: WALLET_TYPE
    event: WALLET_API_RESPONSE_EVENT
    amountCent: number
    totalCent: number

    transferredTo?: string
    transferredFrom?: string
    expectedAmountCent?: number
    orderStatus?: OrderStatus

}

export interface WalletHistoriesDto {
    coin: WalletHistoryDto[]
    winning: WalletHistoryDto[]
    ruby: RubyDto
}
