import { PlayerInfoDialogLoadingWrapper } from "domain/team/playerInfoDialog/PlayerInfoDialogStyle";
import React from "react";
import { Spinner } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

const PlayerInfoDialogLoading = () => {
    return <PlayerInfoDialogLoadingWrapper>
        <Spinner animation="border" role="status" />
        <span className="visually-hidden">
            <FormattedMessage id="loading_dialog_label" />
        </span>
    </PlayerInfoDialogLoadingWrapper>;
};

export default PlayerInfoDialogLoading;
