export enum SPORT {
    CRICKET = "cricket",
    FOOTBALL = "football",
    BASKETBALL = "basketball"
}

export const strToSport = (str: string): SPORT => {
    for (const key in SPORT) {
        if (SPORT[key] === str.toLowerCase()) {
            return SPORT[key];
        }
    }

    throw new Error(`${str} is not a valid SPORT`);
};

export type SportRoleVO<T extends string> = {
    [key in T]: {
        abbr: string,
        apiValue: ROLE
    }
}

export type ROLE =
    "point_guard"
    | "shooting_guard"
    | "small_forward"
    | "power_forward"
    | "forward"
    | "center"
    | "goalkeeper"
    | "defender"
    | "midfielder"
    | "keeper"
    | "batsman"
    | "all_rounder"
    | "bowler"


