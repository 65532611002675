import { cn } from "helpers/cn";
import React, { ReactNode } from "react";

export interface WithdrawLayoutProps {
    topBgColorClassName?: string;
    bottomBgColorClassName?: string;
    topSlot: ReactNode | ReactNode[];
    bottomSlot: ReactNode | ReactNode[];
}

const WithdrawLayout: React.FC<WithdrawLayoutProps> = ({
                                                           topBgColorClassName = "tw-bg-grey-50",
                                                           bottomBgColorClassName = "tw-bg-white",
                                                           bottomSlot,
                                                           topSlot
                                                       }) => {

    return (
        <div className={cn(bottomBgColorClassName)}>
            <div
                className={cn("tw-flex tw-flex-col tw-items-center tw-gap-5", "tw-pb-[5rem]", topBgColorClassName, "tw-rounded-bl-[8rem]")}>
                {topSlot}
            </div>
            <div
                className={cn("tw-relative", "tw-rounded-tr-[7rem]", "tw-relative tw-z-1")}>
                <div
                    className={cn("tw-w-[50%] tw-h-[7rem]", "tw-absolute tw-right-0 tw-z-[-1]", topBgColorClassName)} />
                <div
                    className={cn("tw-w-full tw-h-full", "tw-rounded-tr-[7rem]", "tw-absolute", bottomBgColorClassName)} />
                <div className={"tw-relative tw-z-2"}>
                    {bottomSlot}
                </div>
            </div>
        </div>
    );
};

export default WithdrawLayout;
