import React from "react";
import { FieldPath, FieldValues, useController, UseControllerProps, useFormContext, useWatch } from "react-hook-form";
import { FormErrorMessage } from "domain/kyc/pages/kycVerificationForm/components/FormErrorMessage";
import { OnChangeValidator, ZodOnChangeValidator } from "components/reactHookFormField/OnChangeValidator";
import { NON_ZERO_POSITIVE_OR_EMPTY_REGEX } from "helpers/RegexHelper";
import { z } from "zod";
import { FormFieldProvider } from "components/ui/form";
import { FantasyText, TEXT_FIELD_SIZE, TEXT_FIELD_VARIANT } from "components/input/FantasyText";

export interface FantasyTextFieldProps {
    size?: TEXT_FIELD_SIZE;
    variant?: TEXT_FIELD_VARIANT;
    inputProps?: Omit<React.InputHTMLAttributes<HTMLInputElement>, "name"> & {
        "data-testid"?: string
    };
    showErrorMessage?: boolean;
    showErrorIcon?: boolean;
    iconLeft?: React.ReactNode;
    iconRight?: React.ReactNode;
    maxLength?: number;
    onChangeValidator?: OnChangeValidator;
    disabled?: boolean;
}

export const FantasyTextField = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
      size = TEXT_FIELD_SIZE.Medium,
      variant = TEXT_FIELD_VARIANT.STANDARD,
      control,
      name,
      inputProps,
      showErrorMessage = true,
      showErrorIcon = true,
      iconLeft,
      iconRight,
      maxLength,
      onChangeValidator,
      disabled
  }: FantasyTextFieldProps & UseControllerProps<TFieldValues, TName>) => {

    const { register } = useFormContext();
    const { fieldState } = useController({ name });
    const fieldValue = useWatch({ name });

    const field = register(name);

    const onChange = inputProps?.onChange || field.onChange;
    const handleChange = onChangeValidator ? (e => {
        if (onChangeValidator.validate(e.target.value)) {
            onChange(e);
        }
    }) : onChange;

    return <FormFieldProvider name={name}>
        <FantasyText
            size={size}
            variant={variant}
            showErrorMessage={showErrorMessage}
            iconLeft={iconLeft}
            iconRight={iconRight}
            maxLength={maxLength}
            disabled={disabled}
            errorMessage={<FormErrorMessage showIcon={showErrorIcon} />}
            isError={!!fieldState.error}
            inputProps={{
                ...field,
                value: fieldValue ?? "",
                onChange: handleChange,
                ...inputProps
            }}
        />
    </FormFieldProvider>;
};


const schema = z.string().regex(NON_ZERO_POSITIVE_OR_EMPTY_REGEX);
const integerAmountValidator = new ZodOnChangeValidator(schema);
export const FantasyIntegerTextField = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(props: FantasyTextFieldProps & UseControllerProps<TFieldValues, TName>) => {
    return (
        <FantasyTextField
            {...props}
            onChangeValidator={integerAmountValidator}
        />
    );
};
