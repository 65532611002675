import { createSlice } from "@reduxjs/toolkit";
import { WalletVO } from "types/wallet/Wallet";

const walletReducer = createSlice({
    name: "wallet",
    initialState: {
        coinCent: 0,
        winningCent: 0,
        rubyCent: 0,
        totalCent: 0,

        coin: 0,
        winning: 0,
        ruby: 0,
        total: 0,
        lastCalledTimestamp: null
    },
    reducers: {
        updateWallet(wallet: WalletVO) {
            console.log("wallet", wallet);

        }
    },
});

export const { updateWallet } = walletReducer.actions;
export default walletReducer.reducer;
