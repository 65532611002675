import Carousel from "components/carousel";
import { ApLevelCarouselItem } from "domain/task/components/ApLevel/ApLevelCarouselItem";
import { ApLevelSwiperSlide, CarouselWrapper } from "domain/task/components/ApLevel/ApLevelStyle";
import { useApLevelCarousel } from "domain/task/components/ApLevel/hooks/useApLevelCarousel";
import { useApLevelCarouselConfig } from "domain/task/components/ApLevel/hooks/useApLevelCarouselConfig";
import { Progress } from "domain/task/components/ApLevel/Progress";
import { ProgressBar } from "domain/task/components/ApLevel/ProgressBar";
import { useTaskProvider } from "domain/task/TaskProvider";
import React from "react";
import { useSetting } from "hooks/useSetting";

const ApLevelCarousel: React.FC = () => {
    const {
        apOverview,
        myLevelIndex,
        currentLevelIndex,
        currentCarouselIndex,
        handleCarouselIndexChange,
    } = useTaskProvider();
    const { settingState: { apLevels } } = useSetting();

    const {
        slidesPerView,
    } = useApLevelCarouselConfig();

    const {
        handleSetTranslate,
        handleSwiperSlideClick,
        handleRealIndexChange,
        handelSwiperInit,
        getOffsetY,
        getProgress
    } = useApLevelCarousel(
        {
            currentCarouselIndex,
            handleCarouselIndexChange
        });


    if (!apOverview || apLevels.length === 0) {
        return <CarouselWrapper className="tw-mb-6" />;
    }

    return <CarouselWrapper id="ap-level-carousel" className="tw-mb-6">
        <Progress>
            <ProgressBar progress={getProgress(myLevelIndex)} />
        </Progress>

        <Carousel
            slidesPerView={slidesPerView}
            centeredSlides
            onRealIndexChange={handleRealIndexChange}
            onSetTranslate={handleSetTranslate}
            onInit={handelSwiperInit}
            initialSlide={currentLevelIndex}
        >
            {
                apLevels.map((apLevel, index) =>
                    <ApLevelSwiperSlide key={index}
                                        $offsetY={getOffsetY(index)}
                                        onClick={handleSwiperSlideClick(index)}
                    >
                        <ApLevelCarouselItem
                            apLevel={apLevel}
                            currentIndexDiff={Math.abs(index - currentCarouselIndex)}
                            apOverview={apOverview}
                        />
                    </ApLevelSwiperSlide>
                )
            }
        </Carousel>
    </CarouselWrapper>;
};

export default ApLevelCarousel;
