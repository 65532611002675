import { useSelector } from "store";
import useFantasyCommand from "hooks/useFantasyCommand";
import { WalletRepository } from "data/repository/wallet/walletRepository";
import React from "react";
import { WalletHistoryVo } from "data/vo/wallet/WalletHistoryVo";

const walletRepository = new WalletRepository();
const useWalletHistoryRecords = () => {
    const { WalletState } = useSelector(store => store);

    const getWalletDetail = useFantasyCommand(walletRepository.getWalletTransactionDeTail);

    const handleHistoryRecordRowClick = (walletHistoryVo: WalletHistoryVo) => {

        getWalletDetail.mutate(walletHistoryVo);
    };

    const handleHideTransaction = () => {
        getWalletDetail.reset();
    };

    return React.useMemo(() => ({
        WalletState,
        transactionDetailData: getWalletDetail.data,
        handleHistoryRecordRowClick,
        handleHideTransaction,
    }), [
        WalletState,
        getWalletDetail.data,
        handleHistoryRecordRowClick,
        handleHideTransaction
    ]);
};

export default useWalletHistoryRecords;
