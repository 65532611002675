import { ContestDetailVo } from "data/vo/contest/ContestDetailVo";
import { BUTTON_PATTERN, BUTTON_SIZE, BUTTON_VARIANTS } from "designToken/button/types";
import RubyConfirmationDialog from "domain/contest/components/ConfirmationDialog/RubyConfirmationDialog";
import useDevice from "hooks/useDevice/useDevice";
import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import Button from "../../designToken/button/Button";
import ConfirmationDialog from "domain/contest/components/ConfirmationDialog";
import { UseConfirmationModalProps } from "domain/contest/components/ConfirmationDialog/useConfirmationDialog";
import { getMaxUserEntries } from "helpers/ContestHelper";
import { sortByIdDesc } from "helpers/SortingHelper";
import { OnJoinRubyContestWithClose } from "store/reducer/selectTeamPanel/type";
import { FixedFooter } from "styles/component/GeneralStyle";
import { ContestDetailVO } from "types/contest/ContestDetail";
import { MATCH_STATUS_FROM_API } from "types/match/Match";
import { SPORT } from "types/sports/Sport";
import { MyTeamVO } from "types/team/MyTeam";
import PageViewRecorder from "components/ga/PageViewRecorder";
import { JoinedTeamNameBadges } from "components/panel/components/JoinedTeamNameBadges";
import { MaxEntriesInfo } from "components/panel/components/MaxEntriesInfo";
import { StatusTeamCardFactory } from "components/panel/components/StatusTeamCardFactory";
import Panel from "./Panel";
import {
    getSelectedTeamIdsOnTeamSelected,
    getSelectedTeamIdsOnTeamUnselected,
    isNextButtonDisabled
} from "./SelectTeamPanelHelper";
import {
    BoldText,
    CloseButton,
    EntryAllowedText,
    EntryAllowedTextWrapper,
    SelectTeamHeader,
    SelectTeamPanelBodyWrapper,
    SelectTeamPanelHeadWrapper,
    Title
} from "components/panel/SelectTeamPanelStyle";
import { useSetting } from "hooks/useSetting";

const closeButton = "/images/close_icon_primary.png";

export const teamStatus = {
    joined: "joined",
    selected: "selected",
    disabled: "disabled",
    unselected: "unselected"
};

interface SelectTeamPanelProps extends Pick<UseConfirmationModalProps, "onSubmit"> {
    sport: SPORT,
    contest: ContestDetailVO | ContestDetailVo | null,
    myTeams: MyTeamVO[],
    joinedTeamIds?: number[],
    selectedTeamIds: number[],
    setSelectedTeamIds: Function,
    closePanel: Function,
    goToCreateTeam: Function,
    autoOpenConfirmation?: boolean
    matchStatus: MATCH_STATUS_FROM_API
    onJoinRubyContest: OnJoinRubyContestWithClose
}

const SelectTeamPanelHead = ({ closePanel }) => {
    return <SelectTeamPanelHeadWrapper>
        <SelectTeamHeader>
            <Title>
                <FormattedMessage id="select_team_page_title" />
            </Title>
            <CloseButton $icon={closeButton} onClick={closePanel} />
        </SelectTeamHeader>
    </SelectTeamPanelHeadWrapper>;
};

const SelectTeamPanel: React.FC<SelectTeamPanelProps> = ({
                                                             sport,
                                                             contest,
                                                             myTeams,
                                                             joinedTeamIds = [],
                                                             selectedTeamIds,
                                                             setSelectedTeamIds,
                                                             onSubmit,
                                                             onJoinRubyContest,
                                                             closePanel,
                                                             goToCreateTeam,
                                                             autoOpenConfirmation = false,
                                                             matchStatus,
                                                         }) => {

    const { deviceWidth } = useDevice();
    const { settingState: { contest: { spotsToMaxEntries, defaultMaxEntries } } } = useSetting();

    const {
        props: joinContestConfirmationModalProps,
        openConfirmation,
        isDepositConfirmationModalOpened,
        isRubyConfirmationModalOpened
    } = ConfirmationDialog.useConfirmationDialog({
        sport,
        contest: contest!,
        autoOpenConfirmation,
        selectedTeamIds,
        myTeams,
        onSubmit
    });


    const maxUserEntries = getMaxUserEntries({
        allowMultiple: contest!.allowMultiple,
        spots: contest!.spots,
        defaultMaxEntries,
        spotsToMaxEntries
    });

    const onTeamSelected = selectedId => setSelectedTeamIds(getSelectedTeamIdsOnTeamSelected(selectedTeamIds, selectedId));

    const onTeamUnselected = selectedId => setSelectedTeamIds(getSelectedTeamIdsOnTeamUnselected(selectedTeamIds, selectedId));

    const handleCreateTeam = () => {
        closePanel();
        goToCreateTeam();
    };

    return <>
        <PageViewRecorder title="Select Team" />
        <Panel
            bsPrefix="select-team-panel offcanvas"
            show={true}
            onHide={() => {
                closePanel();
            }}
            Header={<SelectTeamPanelHead closePanel={closePanel} />}
            Body={<SelectTeamPanelBodyWrapper>
                <EntryAllowedTextWrapper>
                    <EntryAllowedText>
                        <FormattedMessage id="select_team_page_label_up_to_join_teams_1" />
                        &nbsp;
                        <BoldText data-testid="display-max-entries">
                            {maxUserEntries}
                        </BoldText>
                        &nbsp;
                        <FormattedMessage id="select_team_page_label_up_to_join_teams_2" />
                    </EntryAllowedText>
                    {
                        contest!.allowMultiple &&
                        <MaxEntriesInfo spotsToMaxEntries={spotsToMaxEntries}
                                        defaultMaxEntries={defaultMaxEntries} />
                    }
                </EntryAllowedTextWrapper>

                <JoinedTeamNameBadges myTeams={myTeams} joinedTeamIds={joinedTeamIds} />


                {
                    sortByIdDesc(myTeams).map(team => <Fragment key={team.id}>
                            <StatusTeamCardFactory team={team}
                                                   selectedTeamIds={selectedTeamIds}
                                                   joinedTeamIds={joinedTeamIds}
                                                   maxUserEntries={maxUserEntries}
                                                   onTeamSelected={onTeamSelected}
                                                   onTeamUnselected={onTeamUnselected}
                                                   matchStatus={matchStatus}
                            />
                        </Fragment>
                    )
                }

                <FixedFooter>
                    <Button disabled={joinedTeamIds.length >= maxUserEntries}
                            onClick={handleCreateTeam}
                            variant={BUTTON_VARIANTS.outlineDark}
                            size={BUTTON_SIZE.lg}
                            pattern={BUTTON_PATTERN.pill}
                            dataTestId="select-team-panel-create-team-button"
                            className={"w-100"}>
                        {
                            deviceWidth > 375
                                ? <FormattedMessage id="label_create_team" />
                                : <FormattedMessage id="label_create" />
                        }
                    </Button>
                    <Button disabled={isNextButtonDisabled(selectedTeamIds)}
                            onClick={openConfirmation}
                            size={BUTTON_SIZE.lg}
                            pattern={BUTTON_PATTERN.pill}
                            dataTestId="select-team-panel-next-button"
                            className={"w-100"}>
                        <FormattedMessage id="button_next" /> ({selectedTeamIds.length})
                    </Button>
                </FixedFooter>
            </SelectTeamPanelBodyWrapper>
            }

        />
        {
            isDepositConfirmationModalOpened && <ConfirmationDialog
                {...joinContestConfirmationModalProps}
            />
        }
        {
            isRubyConfirmationModalOpened && (
                <RubyConfirmationDialog {...joinContestConfirmationModalProps}
                                        onJoinRubyContest={onJoinRubyContest} />
            )}
    </>;
};

export default SelectTeamPanel;
