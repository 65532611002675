export const fil ={
  "@@locale": "fil",
  "main_page_bottom_bar_tab_home": "Home",
  "main_page_bottom_bar_tab_matches": "Mga tugma",
  "main_page_bottom_bar_tab_result": "Resulta",
  "main_page_bottom_bar_tab_chat": "Chat",
  "main_page_bottom_bar_tab_me": "Me",
  "label_upcoming_matches": "Upcoming",
  "label_live_matches": "ew",
  "label_completed_matches": "Nakumpleto",
  "label_completed": "Nakumpleto",
  "match_card_label_joined": "Sumali",
  "home_page_invite_code_input_label_hint": "Ipasok ang Code ng Paligsahan",
  "tournament_filter_dialog_title": "Filter ng Tournament",
  "wallet_view_title": "Balanse sa Wallet",
  "wallet_view_button_store": "Store",
  "match_detail_page_tab_contests": "Mga paligsahan",
  "match_detail_page_my_contests_section_label_empty": "Hindi ka pa sumasali sa isang contest!",
  "match_detail_page_my_teams_section_label_empty": "Hindi ka pa nakakagawa ng team!",
  "contest_card_button_entry": "Entry",
  "contest_card_label_upto": "Upto {entries}",
  "contest_card_label_otp": "OTP",
  "contest_card_label_single": "Single",
  "contest_card_label_prize_hunter": "PrizeHunter",
  "share_contest_dialog_title": "Ibahagi ang Paligsahan",
  "share_contest_dialog_label_invite": "Code ng Paligsahan",
  "share_contest_dialog_button_copy": "Kopya",
  "share_contest_dialog_label_code_copied": "Nakopya ang Code ng Paligsahan!",
  "create_team_success_dialog_title": "Matagumpay na Lumikha ng Koponan",
  "join_contest_success_dialog_title": "Matagumpay na Sumali sa Paligsahan",
  "edit_team_success_dialog_title": "Matagumpay na I-edit ang Koponan",
  "contest_detail_page_tab_winnings": "Mga panalo",
  "contest_detail_page_tab_leaderboard": "Leaderboard",
  "contest_detail_page_leaderboard_label_all_team": "Lahat ng Koponan",
  "contest_detail_page_ranking_label_winner_takes_all": "Ang unang premyo ay",
  "contest_detail_page_ranking_label_free_contest_ranking": "The winners win the attention and applause.",
  "contest_detail_page_ranking_label_ranking": "#Rank",
  "contest_detail_page_ranking_label_prize": "Premyo",
  "contest_detail_page_ranking_label_winner_hint": "Manalo ng buong premyo kapag sumali ang {teams} higit pang mga koponan.",
  "contest_detail_page_button_join_contest": "Sumali sa {contestName}",
  "select_team_page_title": "Piliin ang grupo",
  "select_team_page_label_up_to_join_teams_1": "Ang bawat user ay maaaring sumali hanggang sa",
  "select_team_page_label_up_to_join_teams_2": "(mga) koponan.",
  "select_team_page_label_have_joined_team": "Sumali na",
  "max_entries_dialog_label_max_entries": "Max na Mga Entry",
  "button_got_it": "Got It!",
  "confirmation_dialog_title": "Kumpirmasyon",
  "label_coupon": "Kupon",
  "select_coupon_dialog_button_use": "Gamitin",
  "select_coupon_dialog_label_no_coupon": "Walang Coupon",
  "label_contest_name": "Pangalan ng Paligsahan",
  "my_matches_page_label_empty_my_matches": "Walang mga contest na sinalihan mo.",
  "my_matches_page_label_empty_join_contest": "Sumali na tayo agad sa mga paligsahan!",
  "my_matches_page_started_match_tab_scorecard": "Scorecard",
  "my_matches_page_started_match_tab_player_stats": "Statistics ng Manlalaro",
  "my_matches_page_player_stats_label_stats_at_match": "Mga statistics ng manlalaro sa Match level",
  "my_match_card_label_team": "Koponan",
  "my_match_card_label_contest": "Paligsahan",
  "my_matches_page_scorecard_label_batting": "Batting",
  "my_matches_page_scorecard_label_batter": "Batter",
  "my_matches_page_scorecard_label_bowler": "Bowler",
  "my_matches_page_scorecard_label_fall_of_wicket": "Pagbagsak ng Wickets",
  "my_matches_page_scorecard_label_title_extras": "MGA EXTRA",
  "chat_room_page_label_system_announcement_more": "<Learn More>",
  "chat_room_page_label_chat_room_guideline": "Chat Room Guidelines",
  "chat_room_page_message_input_label_hint": "Message",
  "message_sent_toast_label": "Naipadala ang Mensahe",
  "message_sent_fail_toast_label_forbidden": "Bawal ang Mensahe",
  "id_copied_toast_label": "Nakopya ang ID!",
  "ap_overview_view_label_next_level": "Makakuha ng {ap} AP para maabot ang {apLevel}",
  "playing_record_view_participation_times_title": "Mga Oras ng Pakikilahok",
  "playing_record_view_participation_times_label_hint": "(Nakaraang 30 araw)",
  "playing_record_view_participation_times_label_joins": "Joins",
  "playing_record_view_participation_times_label_cricket_top_1": "Cricket Top 1",
  "playing_record_view_participation_times_label_football_top_1": "Football Top 1",
  "playing_record_view_roi_title": "ROI",
  "playing_record_view_roi_label_hint": "(Return on Investment)",
  "playing_record_view_roi_label_last_24_hrs": "Huling 24 na oras",
  "playing_record_view_roi_label_last_7_days": "Huling 7 araw",
  "playing_record_view_roi_label_last_30_days": "Huling 30 araw",
  "me_page_menu_label_how_to_play": "Paano laruin",
  "me_page_menu_label_contact_us": "Makipag-ugnayan sa amin",
  "me_page_menu_label_terms_and_conditions": "Mga tuntunin at Mga kundisyon",
  "me_page_menu_label_privacy_policy": "Patakaran sa Privacy",
  "me_page_menu_label_log_out": "Log Out",
  "edit_profile_page_title": "Profile",
  "edit_profile_page_label_username": "Username",
  "edit_profile_page_label_about_me": "Tungkol sa Akin",
  "edit_profile_page_about_me_input_label_hint": "(200 character max)",
  "change_password_dialog_title": "Palitan ANG password",
  "change_password_dialog_label": "Magpapadala kami ng liham sa email sa ibaba upang baguhin ang iyong password.",
  "change_password_dialog_button_send_email": "Magpadala ng Email",
  "change_password_success_toast_label": "Kakapadala lang namin sa iyo ng email para baguhin ang iyong password.",
  "friend_list_page_label_empty_friends": "Hindi ka pa nakakapagdagdag ng mga kaibigan!",
  "search_user_view_title": "Maghanap ng User ID",
  "search_user_view_error_invalid_format": "Mangyaring maglagay ng 6 na digit na user ID",
  "search_user_view_error_user_not_found": "Hindi nahanap ang user",
  "my_contest_detail_page_quick_summary_label_yet_to_bat": "Pa kay Bat",
  "match_status_label_upcoming": "Paparating",
  "wallet_history_page_label_ruby_expiry": "Mag-e-expire sa {date}",
  "wallet_history_page_button_join_contest": "Sumali sa Paligsahan",
  "wallet_history_page_label_empty_record": "Wala kang anumang mga transaksyon sa iyong account",
  "wallet_record_event_join_contest": "Sumali sa isang paligsahan",
  "wallet_record_event_user_register": "Magrehistro",
  "wallet_record_event_refund_contest": "Refund mula sa paligsahan",
  "wallet_record_event_user_withdraw": "Bawiin ang panalo",
  "wallet_record_event_purchase_reward": "Bumili ng produkto mula sa Tindahan",
  "wallet_record_event_win_contest": "Manalo sa isang paligsahan",
  "wallet_record_event_user_transfer": "Ilipat ({transferredTo})",
  "wallet_record_event_user_transfer_with_no_var": "Ilipat",
  "wallet_record_event_user_transfer_winning_in": "Nakatanggap ng paglipat",
  "wallet_record_event_user_transfer_winning_in_with_var": "Nakatanggap ng paglipat ({transferredFrom})",
  "wallet_record_event_invite_by_friend": "Inimbitahan ng kaibigan",
  "wallet_record_event_invite_by_friend_with_var": "Inimbitahan ng kaibigan ({referrerUserCode})",
  "deposit_page_label_select_amount": "Pumili ng Halaga",
  "deposit_page_label_google_play_error_1": "Marahil ito ay ilang pansamantalang isyu.",
  "deposit_page_label_google_play_error_2": "Subukang mag-log in muli sa Google Play o sa App Store.",
  "order_processing_dialog_title": "Sandali lang",
  "order_processing_dialog_label": "Pinoproseso ang iyong order at magiging available ito sa loob ng ilang minuto. Mangyaring pumunta sa kasaysayan ng transaksyon upang subaybayan ang katayuan ng order na ito.",
  "activity_point_page_title": "Programang Punto ng Aktibidad",
  "activity_point_page_tab_current": "Current",
  "activity_point_page_tab_upgrade": "Mag-upgrade",
  "activity_point_page_current_section_label_transfer_winning_limit": "Transfer Winning Limit",
  "activity_point_page_current_section_value_transfer_winning_limit": "{number} bawat araw",
  "activity_point_page_current_section_label_today_transferred": "Today Transferred",
  "activity_point_page_current_section_label_accumulation_period": "Accumulation Period",
  "activity_point_page_current_section_label_current_ap": "Kasalukuyang AP",
  "activity_point_page_current_section_label_ap_join_contest": "AP (Sumali sa Paligsahan)",
  "activity_point_page_current_section_label_remaining_ap_next_month": "Natitirang AP sa Susunod na Buwan",
  "activity_point_page_upgrade_section_label_transfer_limit": "Limitasyon sa paglipat",
  "activity_point_page_upgrade_section_label_required": "Kailangan",
  "activity_point_page_upgrade_section_label_transfer_limit_by_level": "{number} / araw",
  "label_jan": "Enero",
  "label_feb": "Pebrero",
  "label_mar": "Marso",
  "label_apr": "Abril",
  "label_may": "Mayo",
  "label_jun": "Hunyo",
  "label_jul": "Hulyo",
  "label_aug": "Agosto",
  "label_sep": "Setyembre",
  "label_oct": "Oktubre",
  "label_nov": "Nobyembre",
  "label_dec": "Disyembre",
  "label_ap_level_beginner": "Baguhan",
  "label_ap_level_ordinary": "Ordinaryo",
  "label_ap_level_silver": "Silver",
  "label_ap_level_gold": "Gold",
  "label_ap_level_platinum": "Platinum",
  "label_ap_level_emerald": "Emerald",
  "label_ap_level_diamond": "Diamond",
  "label_ap_level_master": "Master",
  "label_ap_level_ultimateMaster": "Ultimate Master",
  "invite_friend_page_label_received": "Natanggap",
  "invite_friend_page_label_how_it_work": "Paano Ito Gumagana?",
  "invite_friend_page_button_copy_link": "Kopyahin ang Link",
  "copy_referral_code_success_toast_label": "Kinopya sa Clipboard",
  "ruby_list_page_label_get_more_bonus": "Mas maraming contest na sinalihan nila, mas maraming bonus ang makukuha mo! Hanggang {maxRuby} bawat kaibigan",
  "ruby_list_page_ruby_item_label_received": "Natanggap: {number}",
  "ruby_list_page_button_invite_more_friends": "Mag-imbita ng Higit pang Kaibigan",
  "my_coupon_page_tab_unused": "Unused",
  "my_coupon_page_tab_used": "Used",
  "my_coupon_page_tab_expired": "Expired",
  "my_coupon_page_label_empty_coupons": "No available coupons at present.",
  "my_coupon_page_label_reserved_30_days": "Nai-save ang Record sa loob ng 30 Araw",
  "label_countdown_days": "{countdown} araw",
  "coupon_tooltip_dialog_label_1": "Ang mga kupon ng diskwento ay hindi maaaring gamitin kasabay ng iba pang mga alok",
  "coupon_tooltip_dialog_label_2": "Ang mga kupon ng diskwento ay magagamit lamang para sa isang Paligsahan at isang Koponan",
  "coupon_tooltip_dialog_label_3": "Ang bawat discount coupon ay may expiration date. Paalala: Gamitin ito bago ang petsa ng pag-expire. Ang mga kupon ay hindi na magiging wasto pagkatapos ng petsa ng pag-expire.",
  "sms_verification_page_title": "SMS Verification",
  "sms_verification_page_label_enter_mobile_number": "Pagkatapos ipasok ang iyong mobile number, makakatanggap ka ng isang beses na password.",
  "sms_verification_page_mobile_number_input_label": "Ilagay ang Mobile Number",
  "sms_verification_page_mobile_number_input_button_get_otp": "Kumuha ng OTP",
  "sms_verification_page_otp_input_label": "Ipasok ang OTP",
  "sms_verification_page_label_did_not_receive": "Hindi nakatanggap ng OTP?",
  "sms_verification_page_button_resend": "Muling ipadala ang OTP",
  "sms_verification_page_toast_countdown_again": "Countdown ulit",
  "sms_verification_page_label_send_sms_fail": "Nabigo ang pagpapadala ng SMS. Subukang muli mamaya.",
  "sms_verification_page_label_number_is_invalid": "Ang numerong ito ay hindi wasto. Pakisubukang muli.",
  "sms_verification_page_label_number_is_locked": "Ang numerong ito ay nailagay nang napakaraming beses, pakisubukang muli pagkatapos ng 24 na oras.",
  "sms_verification_page_label_please_correct_otp": "Di-wasto ang OTP. Pakipasok ang tamang OTP!",
  "sms_verification_page_label_otp_is_invalid": "Di-wasto ang OTP! Mayroon kang natitirang {number} (mga) pagsubok!",
  "sms_verification_page_label_otp_fail_too_many_times": "Ang di-wastong OTP ay umabot ng {number} beses. Paki-click muli ang \"Kumuha ng OTP\"!",
  "leave_sms_verification_dialog_label": "Sigurado ka bang iiwan ang SMS Verification?",
  "otp_verify_dialog_title": "Pag-verify ng OTP",
  "otp_verify_dialog_label": "Kailangan mong i-verify ang iyong mobile number.",
  "otp_verify_success_dialog_label": "Nagtagumpay ang Pag-verify ng Numero ng Mobile",
  "user_profile_page_button_add_friend": "kaibigan",
  "user_profile_page_toast_add_friend_success": "Matagumpay na Magdagdag ng Kaibigan",
  "user_profile_page_toast_remove_friend_success": "Matagumpay na Tanggalin ang Kaibigan",
  "login_page_label_log_in": "Mag log in",
  "login_page_label_sign_up": "Mag-sign Up",
  "login_page_label_register": "Magrehistro",
  "login_page_label_hint_forgot_password": "Nakalimutan ang password?",
  "login_page_label_forgot_password": "Nakalimutan ang password",
  "login_page_label_confirm_password": "Kumpirmahin ang password",
  "login_page_button_reset_password": "I-reset ang password",
  "login_page_label_do_not_have_account": "Walang account?",
  "login_page_label_already_have_account": "Mayroon nang account?",
  "login_page_label_account_or_password_invalid": "Di-wasto ang password o walang account",
  "login_page_label_password_format_invalid": "Pakitiyak na tumutugma ang iyong mga password.",
  "login_expired_toast_label": "Nag-expire na ang Login, mangyaring mag-login muli",
  "language_setting_page_title": "Wika",
  "language_setting_page_label": "Piliin ang iyong gustong wika",
  "currency_format_setting_page_title": "Format ng Pera",
  "currency_format_setting_label_choose_format": "Piliin ang iyong gustong format ng pera",
  "setting_button_save_changed": "I-save ang mga pagbabago",
  "check_email_page_title": "Suriin ang iyong Email",
  "add_referral_page_title": "INVITED NG ISANG KAIBIGAN",
  "add_referral_page_button_enter_code": "Ipasok ang Code",
  "add_referral_page_button_no_referral_code": "Walang Referral Code",
  "add_referral_page_label_rule_1": "Mangyaring ilagay ang referral code sa lalong madaling panahon. Awtomatikong mawawala ang referral code",
  "add_referral_page_label_rule_2": "PAGKATAPOS ng {number} HOURS.",
  "add_referral_page_referral_code_input_label": "Ilagay ang Referral Code",
  "add_referral_page_leaving_dialog_label_can_not_enter_again": "Kapag pinindot ang Sure, hindi mo na maipasok muli ang referral code.",
  "add_referral_page_warning_dialog_label_duplicated_referral": "Ikaw na ang referrer para sa {username}, mangyaring muling ipasok.",
  "add_referral_page_warning_dialog_label_use_myself_code": "Hindi mo magagamit ang iyong sariling referral code.",
  "dialog_button_understand": "Naiintindihan ko",
  "add_referral_page_toast_referred_success": "Matagumpay na na-refer",
  "add_referral_page_label_code_is_invalid": "Ang referral code ay hindi wasto! ",
  "select_player_page_player_section_label_player_limit_1": "Sel {number}",
  "select_player_page_player_section_label_player_limit_2": "Sel {lowerBound}-{upperBound}",
  "select_special_player_page_label_captain": "Captain",
  "select_special_player_page_label_vice_captain": "Vice Captain",
  "player_role_cricket_keeper": "Wicket-Keepers",
  "player_role_cricket_batsman": "Mga batter",
  "player_role_cricket_bowler": "Mga bowler",
  "player_role_cricket_all_rounder": "All-Rounders",
  "player_role_football_goalkeeper": "Goalkeeper",
  "player_role_football_defender": "Mga tagapagtanggol",
  "player_role_football_midfielder": "Mga midfielder",
  "player_role_football_forward": "Pasulong",
  "choose_recipient_page_title": "Piliin ang Tatanggap",
  "transfer_page_title": "Ipasok ang halaga",
  "transfer_page_label_exceed_quota": "Lampas ang halaga sa quota ngayong araw",
  "transfer_page_label_exceed_winning": "Ang halaga ay lumampas sa panalo:",
  "transfer_page_label_today_quota": "Ang quota ngayong araw: {number}",
  "transfer_page_button_next": "Mag-swipe sa susunod na hakbang",
  "confirm_transfer_page_title": "Kumpirmahin ang Paglipat",
  "confirm_transfer_page_button": "Ipadala",
  "label_amount": "Halaga",
  "label_note": "Tandaan",
  "transfer_success_dialog_title": "Matagumpay na Paglipat",
  "user_locked_dialog_label_1": "Naka-lock ka!",
  "user_locked_dialog_label_2": "Mangyaring makipag-ugnayan sa iyong serbisyo sa customer.",
  "button_confirm": "Kumpirmahin",
  "button_next": "Susunod",
  "fantasy_sport_label_spots": "mga spot",
  "fantasy_sport_label_entry_fee": "Bayad sa pagpasok",
  "loading_dialog_label": "Naglo-load...",
  "leaving_dialog_button_cancel": "Kanselahin",
  "leaving_dialog_button_sure": "Oo naman",
  "label_my_contests": "Aking Mga Paligsahan",
  "label_my_teams": "Aking Mga Koponan",
  "label_my_matches": "Aking Mga Tugma",
  "label_chat_room": "Silid pang-usap",
  "label_my_coupons": "Aking Kupon",
  "label_transfer": "Paglipat",
  "label_balance": "Balanse",
  "label_total": "Kabuuan",
  "label_create_team": "Lumikha ng Koponan",
  "label_credits": "Mga kredito",
  "label_players": "Mga manlalaro",
  "label_points": "Mga puntos",
  "label_score": "Puntos",
  "label_processing": "Pinoproseso",
  "label_cancelled": "Kinansela",
  "label_invite_friends": "Mag-imbita ng mga kaibigan",
  "label_transaction_history": "Kasaysayan ng Transaksyon",
  "label_email": "Email",
  "label_password": "Password",
  "label_cancel": "Kinansela",
  "dialog_button_close": "Isara",
  "dialog_button_back": "Bumalik",
  "dialog_button_apply": "Mag-apply",
  "label_date": "Petsa",
  "label_event": "Kaganapan",
  "label_sport": "Palakasan",
  "label_match": "tugma",
  "label_contest_cancelled": "Kinansela ang Paligsahan",
  "label_extra": "Dagdag",
  "label_regular": "Regular",
  "login_page_label_email_is_required": "Kinakailangan ang email",
  "login_page_label_password_is_required": "Kinakailangan ang password",
  "login_page_label_password_6_chars": "Dapat na hindi bababa sa 6 na character ang password",
  "login_page_label_provide_a_valid_email": "Magbigay ng wastong email",
  "insufficient_ap_level_dialog_label": "Hindi sapat ang antas ng AP para makasali",
  "button_done": "Done",
  "contest_item_view_label_legend_figure": "Walang mga premyong cash sa mga paligsahan sa pagsasanay",
  "contest_item_view_label_first_prize": "Unang premyo = ${prize}",
  "contest_item_view_label_win_rate": "Panalo ang mga koponan ng {joined} sa patimpalak na ito",
  "contest_item_view_label_entry": "Pinahihintulutan ang maximum na {entries} na mga entry sa paligsahan na ito",
  "contest_item_view_label_ap_level": "Ang antas ng {apLevel} o mas mataas ay kinakailangan upang sumali",
  "contest_item_view_label_otp": "Kinakailangan ang pag-verify ng OTP",
  "contest_item_view_state_explanation_label_first_prize": "Unang premyo",
  "contest_item_view_state_explanation_label_win_rate": "Possibility to win in this contest",
  "contest_item_view_state_explanation_label_single_entry": "Max 1 entry ang pinapayagan sa contest na ito",
  "contest_item_view_state_explanation_label_multiple_entry": "Max entries allowed in this contest",
  "contest_item_view_state_explanation_label_ap_level": "Min activity level",
  "contest_item_view_state_explanation_label_otp": "Kinakailangan ang pag-verify ng OTP",
  "match_detail_page_label_prize_is_ideal": "The prize pool is an ideal value.",
  "contest_item_view_state_explanation_title": "State Explanation",
  "label_you": "Ikaw",
  "label_and": "At",
  "match_preparing_toast_label": "Malapit nang magbukas ang mga paligsahan para sa laban na ito. Manatiling nakatutok!",
  "tournament_filter_dialog_label_preparing_switch": "Kasama ang paghahanda ng mga laban",
  "tournament_filter_dialog_label_select_all": "Piliin lahat",
  "select_payment_page_label_select_method": "Piliin ang paraan ng pagbabayad",
  "select_payment_page_select_method_label_cryptocurrency": "Cryptocurrency",
  "payment_notice_modal_title": "Magbayad gamit ang cryptocurrency",
  "payment_notice_modal_rule_1_1": "Sinusuportahan lang namin",
  "payment_notice_modal_rule_1_2": "binayaran sa pamamagitan ng Ethereum mainnet.",
  "payment_notice_modal_rule_2": "Mangyaring gamitin ang partikular na halaga ng crypto at natatanging address na ibinigay.",
  "payment_notice_modal_rule_3": "The address is time-limited and one time consuming for this time.",
  "payment_notice_modal_rule_4": "Standard network fees will be applied.",
  "payment_notice_modal_rule_5": "Ang oras ng pagproseso ay nag-iiba depende sa network.",
  "payment_notice_modal_rule_6": "Please pay the full crypto amount to avoid any error (under or overpayments) or delay process.",
  "payment_notice_modal_warning": "Warning: any payment through unsupported cryptocurrencies or network will result in lost funds.",
  "wallet_record_event_redeem": "Redeem",
  "store_insufficient_balance": "Insufficient Balance",
  "store_button_buy": "Buy it!",
  "store_transaction_successful": "Transaction Successful",
  "store_process": "It takes {days} to process this order.",
  "store_buy_dialog": "Gustong magbayad ng {winning} panalong para sa {rewardTitle}?",
  "store_item_sold_out": "Nabili na ang Item",
  "store_button_ok": "OK",
  "store_new_order": "Bagong Order",
  "reward_list_page_title": "Tindahan",
  "reward_list_page_label_empty_reward": "Kasalukuyang walang mga item.",
  "reward_list_page_label_remind_country": "Upang maihatid nang tama ang produkto, pakitiyak na pipiliin mo ang tamang bansa.",
  "reward_list_page_label_all_empty": "Mukhang hindi ka nag-order sa nakalipas na 3 buwan.",
  "reward_list_page_label_processing_empty": "Walang anumang processing order.",
  "reward_list_page_label_completed_empty": "Walang anumang nakumpletong order.",
  "reward_order_history_page_title": "Aking Mga Order",
  "my_matches_page_scorecard_label_score": "Puntos",
  "my_matches_page_scorecard_label_over": "Over",
  "referral_fail_dialog_label_validation_error": "Validation Error",
  "home_page_invite_code_invalid": "Invalid contest code",
  "home_page_invite_code_not_found": "Contest not found. Please check the contest code.",
  "referral_fail_dialog_title_time_up": "Time's up!",
  "referral_fail_dialog_label_cannot_add_referral": "Hindi ka maaaring maglagay ng referral code.",
  "contest_detail_page_label_won_rubies": "Nanalo ng {number} Rubies",
  "wallet_history_page_detail_title": "Detalye ng Transaksyon",
  "download_app": "I-download ang aming app at tamasahin ang lahat ng mga tampok!",
  "reward_order_history_page_label_order_date": "Petsa ng Order",
  "payment_cancelled_toast_label": "Kinansela ang transaksyon.",
  "reward_order_country": "Bansa",
  "label_more_than": "higit sa {number}",
  "label_edit_team": "I-edit ang Koponan",
  "label_about_item": "Tungkol sa Item na Ito",
  "return_to_app": "Bumalik sa APP",
  "label_data_updating": "Pag-update ng data",
  "scorecard_not_available": "Hindi available ang buong scorecard para sa laban na ito.",
  "login_page_label_agree_rule": "By signing up you agree to our {termsAndConditions} and {privacyAndPolicy}",
  "login_page_label_agree_rule_app": "By signing up you agree to our $termsAndConditions$ and $privacyAndPolicy$",
  "player_starting": "Starting",
  "player_substitute": "Substitute",
  "fantasy_sport_label_max_prize_pool": "MAX Prize Pool",
  "login_page_label_send_email_fail": "We have blocked all requests from this device due to unusual activity. Try again later.",
  "label_description": "Description",
  "label_transaction": "Transaksyon",
  "label_add_again": "Idagdag muli",
  "wallet_record_event_refund_reward_order": "Refund mula sa Store",
  "user_profile_view_label_reach_steel_level": "I-verify para maabot ang Steel AP Level",
  "label_ap_level_steel": "Steel",
  "label_preview": "Preview",
  "no_player_selected_tip": "Wala pang napiling manlalaro",
  "label_start_selecting": "Start selecting",
  "player_substitute_or_unannounced": "Substitute or Unannounced ",
  "reward_purchase_fail": "An error occurred while purchasing the item, please purchase again.",
  "label_sorry": "Sorry",
  "label_see_more_completed_matches": "See More Completed Matches",
  "button_delete": "Delete",
  "coupon_description": "This is a {percentage}% discount coupon. Let's join a contest!",
  "contest_detail_page_leaderboard_label_your_teams": "Your Teams ",
  "contest_item_view_state_explanation_label_prize_hunter": "Gantimpalaan lamang ang koponan na may pinakamataas na marka.",
  "coupon_used_up_toast_label": "Walang available na kupon.",
  "stop_join_contest_dialog_label": "Itigil ang pagsali sa mga paligsahan.",
  "started_time_out_dialog_label_1": "Live ang laban, at maaari ka pa ring sumali sa mga paligsahan sa susunod",
  "started_time_out_dialog_label_2": "{period} minuto.",
  "contest_item_view_label_prize_hunter": "Gantimpalaan lamang ang koponan na may pinakamataas na marka.",
  "free_coupon_description": "Ito ay isang libreng kupon. Sumali tayo sa isang paligsahan!",
  "free_coupon_title": "Free Coupon",
  "player_role_basketball_point_guard": "Point-Guard",
  "player_role_basketball_shooting_guard": "Shooting-Guard",
  "player_role_basketball_small_forward": "Small-Forward",
  "player_role_basketball_power_forward": "Power-Forward",
  "player_role_basketball_center": "Center",
  "label_universal": "Pangkalahatan",
  "label_india": "India",
  "label_thailand": "Thailand",
  "label_philippines": "Pilipinas",
  "label_malaysia": "Malaysia",
  "label_bangladesh": "Bangladesh",
  "label_pakistan": "Pakistan",
  "label_cambodia": "Cambodia",
  "label_taiwan": "Taiwan",
  "label_nigeria": "Nigeria",
  "redemption_panel_title": "Code na Pang-promosyon",
  "redemption_panel_error_limit_10_char": "Limit 10 characters",
  "redemption_panel_error_data_not_found": "Ang code na pang-promosyon na inilagay ay hindi tama o wala.",
  "redemption_panel_button": "Gamitin",
  "redemption_panel_input_hint": "Ilagay ang Promotional Code",
  "button_view_more": "View more",
  "button_cancel": "Cancel",
  "redemption_panel_error_title_oops": "OOPS!!!",
  "redemption_panel_error_code_redeemed": "Nagamit mo na.",
  "redemption_panel_error_title_missing_by_step": "Missed it by a step.",
  "redemption_panel_error_no_quota": "Ang quota ay umabot na sa limitasyon.",
  "redemption_panel_error_title_insufficient_ap": "Hindi sapat ang AP",
  "redemption_panel_error_insufficient_ap": "Your AP level {currentLevel} is insufficient for this activity. Please join again with your upgraded AP level {minLevel}.",
  "redemption_panel_error_insufficient_ap_upgrade_button": "Upgrade level",
  "redemption_panel_no_reward_result": "Today is not your lucky day. Maybe next time.",
  "label_pending": "Nakabinbin",
  "redemption_panel_coupon_result": "Congratulations, you have received a {discountPercentage}% off coupon.",
  "wallet_record_event_manual_add_winning": "Manual Winning",
  "result_empty_match": "Huwag mawalan ng pag-asa, ang susunod na pagkakataon ay naghihintay para sa iyo!",
  "result_match_missed": "OOPS!!! Match Missed!!!",
  "label_result": "Resulta",
  "nationality_title": "Nasyonalidad",
  "born_title": "Ipinanganak",
  "role_title": "Role",
  "height_title": "Height",
  "weight_title": "Weight",
  "preferred_foot_title": "Preferred foot",
  "in_your_teams_title": "Sa Iyong Mga Koponan",
  "player_info_no_add_player_in_any_team": "Hindi mo pa naidagdag ang manlalarong ito sa anumang koponan.",
  "performance_title": "Performance",
  "game_log_title": "Game Log",
  "glossary_title": "GLOSSARY",
  "player_info_no_info_available": "Wala pang available na impormasyon para sa player na ito!",
  "dialog_button_remove": "Alisin",
  "dialog_button_add": "Idagdag",
  "label_create": "Lumikha",
  "batting_style_title": "Estilo ng paghampas",
  "bowling_style_title": "Estilo ng bowling",
  "label_non_starters": "{number} Mga Hindi Nagsisimula",
  "sure_to_clear_team_title": "Sigurado ka bang aalisin ang koponan?",
  "sure_to_clear_team_description": "I-clear ang iyong mga piniling manlalaro?",
  "sure_to_go_back": "Are you sure to go back?",
  "change_not_saved": "Your changes will not be saved.",
  "set_reminders_title": "Set Reminders",
  "set_reminders_description": "Set reminders for your favorite matches and never miss a match again!",
  "notification_subscribed_successful": "Notification preferences successfully changed.",
  "label_history": "Kasaysayan",
  "complete_reward_order_title": "Ibinibigay ang pagkuha ng produkto",
  "error_internet_disconnected": "Nadiskonekta ang Internet",
  "internet_disconnected_message": "Kasalukuyan kang offline.",
  "try_again": "Subukan muli",
  "contest_full_dialog_content": "Puno na ang paligsahan, mangyaring sumali sa isa pang paligsahan.",
  "contest_full_dialog_button_text": "Tingnan ang Paparating na Paligsahan",
  "label_referral_program": "Referral Program",
  "label_referrals": "Mga referral",
  "label_ruby": "Ruby",
  "label_product": "Product",
  "label_full_ruby": "Buong Ruby",
  "label_full_ruby_discount": "Usable Ruby: {rubyExchangeRate} : 1 full discount of Entry Fee.",
  "label_confirmation_note": "Sumasang-ayon ako sa pamantayan",
  "label_confirmation_note_highlight": "Mga T&C",
  "none": "None",
  "redemption_panel_ruby_result": "Congratulations, you have received {ruby} Rubies.",
  "nickname_input_error_char_lengths": "Ang iyong username ay dapat nasa pagitan ng 6 at 30 characters ang haba.",
  "nickname_input_error_duplicate": "That username is taken. Try another.",
  "wallet_record_event_manual_add_ruby": "Manual Ruby",
  "toast_message_successfully_changed": "Matagumpay na nabago",
  "edit_profile_page_title_editing": "Ibahin ang profile",
  "contest_promotional_title": "Mega League",
  "contest_promotional_subtitle": "Mababang entry Mega Panalo!!",
  "contest_prize_hunter_title": "Prize Hunter",
  "contest_prize_hunter_subtitle": "Gantimpalaan lamang ang koponan na may pinakamataas na marka.",
  "contest_ruby_normal_title": "Ruby",
  "contest_practice_title": "Libre",
  "contest_practice_subtitle": "Subukan ang Iyong Kakayahan",
  "label_earn_ruby": "Kumita ng Ruby",
  "label_contest_code": "Code ng Paligsahan",
  "ruby_contest_champion_title": "Congrats, Champion!",
  "ruby_contest_champion_content": "{nickname}, Magandang trabaho sa {squadA} vs {squadB}! Sumali sa isang Pampublikong Paligsahan upang ipakita sa kanila kung ano ang nakuha mo.",
  "wallet_record_event_discount_contest": "May diskwento sa isang paligsahan",
  "wallet_record_event_redemption": "Gamitin ang Promotional Code",
  "label_captain_point_rate": "2x na Puntos",
  "label_vice_captain_point_rate": "1.5x na Puntos",
  "button_remove_players": "Alisin ang {players}",
  "label_verify": "I-verify",
  "store_process_days": "Mga araw",
  "store_email": "Mangyaring bigyang-pansin ang iyong email. Pinahahalagahan namin ang iyong pasensya.",
  "daily_task_item_title_watch_ad": "Manood ng Advertisement.",
  "daily_task_item_description_watch_ad": "Panoorin ang ad at makakuha ng reward kapag nakumpleto mo ito.",
  "daily_task_item_button_watch_ad": "Watch",
  "daily_task_item_button_claim_reward": "Mag-claim ng reward",
  "daily_task_panel_title": "Pang-araw-araw na gawain",
  "daily_task_page_title": "Mga gawain",
  "daily_task_panel_label_reminder": "Paalala",
  "daily_task_panel_label_upgrade": "Ang pag-upgrade ay nagbubukas ng mas malalaking reward.",
  "daily_task_tutorial_step_1": "Kasalukuyang lebel",
  "daily_task_tutorial_step_2": "Mag-click o mag-swipe pakaliwa at pakanan upang makita ang mga gantimpala ng susunod na antas ng gawain",
  "daily_task_tutorial_step_3": "I-click upang sumali sa gawain",
  "daily_task_page_task_complete": "Nakumpleto ang gawain",
  "daily_task_page_no_ad": "Advertisement: Isinasagawa ang muling pagdadagdag ng enerhiya. Subukang muli mamaya.",
  "sport_tab_label_cricket": "Cricket",
  "sport_tab_label_football": "Football",
  "sport_tab_label_basketball": "Basketball",
  "reward_list_page_button_win_more": "Manalo pa",
  "reward_list_page_button_buy_now": "Bumili ka na ngayon !",
  "label_all": "Lahat",
  "match_card_label_prize": "Premyo",
  "match_card_label_mega": "MEGA",
  "wallet_view_button_ap_level": "Antas ng AP",
  "wallet_view_register_label": "Naglalaro ka mula noong {date}",
  "label_soon": "malapit na",
  "download_app_dialog_context": "I-download ang app para sa mga karagdagang reward.",
  "wallet_record_event_claim_task": "Mga gawain",
  "me_page_career_stats_title": "Istatistika ng Karera",
  "me_page_career_stats_label_top_score": "Top Score",
  "me_page_career_stats_label_description": "Ang pinakamataas na marka na {topScore} ay tinatalo ang {pr}% ng mga user",
  "label_follow": "Follow",
  "label_unfollow": "Unfollow",
  "me_page_career_stats_label_no_data": "Wala pang score, sumali sa paligsahan at magsaya!",
  "me_page_career_stats_label_data_from": "Data mula sa: ",
  "label_followers": "Followers",
  "label_following": "Following",
  "transfer_disabled_insufficient_ap_level": "Naabot na ang quota ngayong araw. Mangyaring i-upgrade ang iyong antas ng AP upang ilipat ang panalo.",
  "transfer_disabled_unfollow": "Para ma-activate ang Winning transfer service, kailangan mo munang sumunod.",
  "daily_task_item_title_watch_tutorial": "Manood ng mga tutorial",
  "daily_task_item_description_watch_tutorial": "Makakuha ng gantimpala para sa panonood ng mga tutorial.",
  "daily_task_item_button_play_back": "Play back",
  "career_stats_you": "You",
  "career_stats_playing_since": "Naglalaro Since",
  "user_profile_page_friend_full_tip": "Naabot mo na ang sumusunod na limitasyon {maxFollowing}.",
  "contest_card_button_free": "FREE",
  "label_winning": "Winning",
  "label_coins": "mga barya",
  "button_pay_with_winning": "Magbayad gamit ang {winning} Panalo",
  "label_return_on_investment": "Return on Investment",
  "label_roi": "ROI",
  "daily_task_page_task_incomplete": "Hindi kumpleto ang gawain",
  "label_injury": "Pinsala",
  "label_scoring": "PAGMAmarka",
  "label_player_stat": "Istatistika ng manlalaro",
  "label_start": "Magsimula",
  "contest_card_label_glory_awaits": "Naghihintay ang kaluwalhatian",
  "label_injury_playing_chance_probable": "Malamang",
  "label_injury_playing_chance_questionable": "Kaduda-duda",
  "label_injury_playing_chance_doubtful": "Nagdududa",
  "label_injury_playing_chance_out": "Out",
  "referral_program_page_label_description": "Ibahagi ang Iyong Code, Mangolekta ng hanggang {maxRuby} Ruby bawat kaibigan",
  "referral_program_page_label_invite_friend": "Mag-imbita At Magparehistro",
  "referral_program_page_label_collect_ruby": "Mangolekta ng {returnToReferrerPercentage}% Ruby Bawat Oras",
  "daily_task_page_label_remember_open": "Tandaan bukas.",
  "daily_task_page_label_remember_cancel": "Kinansela ang paalala. Tandaan na i-claim ang grand prize bukas.",
  "welcome_page_button_earn_from_tutorial": "Kumita ng 500 Rubies mula sa tutorial",
  "label_email_not_found": "Hindi nakita ang email",
  "user_delete_page_title": "Tanggalin ang iyong account",
  "user_delete_page_description": "Kung gusto mong tanggalin ang iyong account. Paki-verify muna ang iyong email.",
  "suspicious_email_dialog_label_1": "Kahina-hinalang Email",
  "suspicious_email_dialog_label_2": "Mangyaring gumamit ng ibang pinagkakatiwalaang email.",
  "user_delete_warn_dialog_title": "Sigurado ka bang gusto mong tanggalin?",
  "login_page_email_not_available": "Hindi available ang email. Mangyaring gumamit ng isa pang email.",
  "login_page_or_register_with": "O magrehistro sa",
  "login_page_or_login_with": "O mag-log in gamit ang",
  "user_account_been_deleted": "Ang iyong account ay tinanggal.",
  "auth_select_language_title": "Piliin ang Wika ng App",
  "check_email_page_label_check_email_from_1": "Mag-click sa link na iyong natanggap mula sa",
  "label_enter_referral_code": "Ilagay ang Referral Code",
  "enter_referral_code_label": "Ilagay ang referral code para makakuha ng {rubies} Rubies",
  "label_submit": "Ipasa",
  "referral_code_enter_later": "gagawin ko ito mamaya",
  "contest_item_view_state_explanation_label_minimum_slip": "Sumali si Min sa mga team. Kakanselahin ang mga hindi nakumpletong paligsahan.",
  "contest_item_view_state_explanation_label_allow_discount_with_ruby": "Pahintulutan na gumamit ng mga rubi upang idiskwento ang bayad sa pagpasok.",
  "entry_tutorial_step_title_1": "Pumili ng Isang Tugma",
  "entry_tutorial_step_description_1": "Pumili ng paparating na laban na gusto mong laruin",
  "entry_tutorial_step_title_2": "Sumali sa mga Paligsahan",
  "entry_tutorial_step_description_2": "Makipagkumpitensya sa iba pang mga manlalaro para sa malalaking gantimpala",
  "entry_tutorial_step_title_3": "Lumikha ng Mga Koponan",
  "entry_tutorial_step_description_3": "Gamitin ang iyong mga kasanayan upang pumili ng mga tamang manlalaro at makakuha ng mga fantasy point.",
  "entry_tutorial_step_title_4": "Piliin ang Kapitan",
  "entry_tutorial_step_description_4": "Piliin ang captain at vice captain para i-double para makamit ang mas matataas na score",
  "login_page_title_login": "Hayaan kang mag-login!",
  "login_page_description": "Ilagay ang iyong impormasyon sa ibaba",
  "login_page_title_sign_up": "Mag-rehistro na ngayon!",
  "send_reset_password_dialog_title": "Matagumpay na Ipadala ang Email ng I-reset ang Password",
  "send_reset_password_dialog_description": "Pakisuri ang iyong email upang i-reset ang iyong password",
  "send_reset_password_dialog_back_to_login": "Bumalik sa pag-login",
  "forgot_password_page_description": "Piliin kung aling mga detalye ng contact ang dapat naming gamitin upang i-reset ang iyong password",
  "label_login_trouble": "Problema sa pag-login?",
  "label_contact_support": "Makipag-ugnayan sa suporta.",
  "contest_item_view_label_minimum_slip": "Mas mababa sa {minimumSlip} na mga koponan ang kakanselahin.",
  "referral_code_is_required": "Kinakailangan ang referral code",
  "language_name": "Tagalog",
  "match_card_label_lineups_out": "Nawala ang mga lineup",
  "login_page_guest_login_button_label": "Maglaro bilang Guest",
  "anonymous_user_dialog_title": "Mag-rehistro na ngayon",
  "anonymous_user_dialog_link_account_button": "Magrehistro",
  "anonymous_user_transfer_dialog_description": "To use transfer, you need to link email to this account.",
  "anonymous_user_redemption_code_dialog_description": "Upang magamit ang code na pang-promosyon, kailangan mong i-link ang email sa account na ito.",
  "anonymous_user_ad_dialog_description": "Irehistro ang account upang i-unlock ang higit pang mga features!",
  "anonymous_user_store_dialog_description": "To use store, you need to link email to this account.",
  "me_page_menu_label_link_account": "Magrehistro",
  "user_bind_page_title": "Mag-rehistro na ngayon!",
  "user_bind_page_label_description": "Irehistro ang account upang i-unlock ang higit pang mga features!",
  "contest_item_view_label_allow_discount_with_ruby": "Rubies allowed",
  "user_bind_page_confirm_dialog_title": "Kumpirmahin ang Email",
  "user_bind_page_confirm_dialog_description": "The {email} will be used for binding. After binding, the email cannot be changed.",
  "user_bind_success_dialog_title": "Register Successfully",
  "featured_contest_title": "Featured Contest",
  "featured_contest_more_coming_soon": "More Coming Soon",
  "featured_contest_card_entry": "Entry",
  "label_id": "ID",
  "career_stats_contests": "Contests",
  "career_stats_matches": "Matches",
  "label_are_you_sure": "Are you sure?",
  "label_new": "New",
  "toggle_full_ruby_toast_label": "Pumili ng isa: Kupon o Ruby",
  "wallet_record_event_redeem_winning": "Redeem to Coin",
  "wallet_record_event_redeem_deposit": "Redeem from Winning",
  "auth_select_language_submit_button": "Itakda ang Wika sa Tagalog",
  "check_email_page_label_check_email_2_with_var_mins": "{email} within {minutes} minutes creation.",
  "label_sure": "Sure",
  "daily_task_item_title_watch_video": "Manood ng video",
  "daily_task_item_description_watch_video": "Makakuha ng reward sa panonood ng video.",
  "daily_task_item_title_watch_video_tutorial_1": "Sports Type in the game",
  "daily_task_item_description_watch_video_tutorial_1": "Makakuha ng ruby ​​reward para sa iyong unang tutorial",
  "daily_task_item_title_watch_video_tutorial_2": "Currency Intro",
  "daily_task_item_description_watch_video_tutorial_2": "Makakuha ng ruby ​​reward para sa pangalawang tutorial",
  "daily_task_item_title_watch_video_tutorial_3": "Contest Intro",
  "daily_task_item_description_watch_video_tutorial_3": "Makakuha ng ruby ​​reward para sa ikatlong tutorial",
  "daily_task_item_title_watch_video_tutorial_4": "Paano Gumawa ng Team",
  "daily_task_item_description_watch_video_tutorial_4": "Final Tutorial to create dream team and get ruby reward",
  "label_save": "I-save",
  "change_nickname_dialog_title": "Baguhin ang User Name",
  "change_nickname_dialog_content": "Ang username ay dapat na 6 hanggang 30 character ang haba at maaaring magsama ng mga numero, titik, '.' at '_'",
  "nickname_input_error_format_letters_and_numbers": "Paumanhin, tanging mga titik, numero, simbolo ( . _ ) lamang ang pinapayagan.",
  "user_bind_page_or_link_account_with": "O i-link ang account sa",
  "user_bind_page_button_link_account": "Magrehistro",
  "user_bind_page_alert_email_duplicate": "Ginagamit na ang email sa ibang account. Mangyaring gumamit ng iba.",
  "add_deposit_from_crypto_currency_hint": "Sinusuportahan ng aming website ang pagbili ng mga barya gamit ang virtual na pera. Kung nais mong bumili ng mga barya gamit ang isang credit card, mangyaring i-download ang aming app",
  "login_page_alert_use_another_provider": "Mangyaring lumipat sa ibang paraan ng pag-login – gumamit ng social login kung sinubukan mo ang email at password, o kabaliktaran. Maaari ka ring 'Maglaro bilang Bisita'.",
  "add_deposit_page_alert_use_credit_card": "Sinusuportahan ng aming website ang pagbili ng mga barya gamit ang virtual na pera. Kung nais mong bumili ng mga barya gamit ang isang credit card, mangyaring i-download ang aming app gamit ang QR code sa kanang ibaba.",
  "label_overview": "Overview",
  "label_basketball_empty_news": "Mukhang walang balita sa player na ito ngayon.",
  "login_page_button_apple_sign_up": "Mag-sign Up sa Apple",
  "login_page_button_google_sign_up": "Mag-sign Up sa Google",
  "login_page_button_apple_log_in": "Mag-log In gamit ang Apple",
  "login_page_button_google_log_in": "Mag-log In gamit ang Google",
  "share_contest_dialog_link_copied": "Contest Link Copied!",
  "anonymous_user_store_dialog_button_redeem_all": "I-redeem ang lahat ng Panalo sa Coin",
  "new_user_dialog_title": "Congratulations",
  "new_user_dialog_description": "Nakatanggap ka ng 20% ​​na diskwento na kupon.",
  "store_need_more_coins": "Kakailanganin mo ng mas maraming Winnings para makabili. Sumali sa mga paligsahan at manalo ng higit pa!",
  "order_fail_dialog_title": "Purchasing failed",
  "order_fail_dialog_content": "Kinansela ang iyong order. Mangyaring bumili muli, o pumunta sa history ng transaksyon.",
  "order_processing_dialog_toast_deposit_processing": "Nasa proseso ang iyong pagbili",
  "wallet_record_event_game_trier_daily_deposit": "Game trier daily coin",
  "wallet_record_event_revoke_game_trier_daily_deposit": "Revoke game trier daily coin",
  "redemption_panel_description": "Enjoy multiple rewards such as coins, rubies, coupons, and more! Start enjoying them now!",
  "redemption_panel_deposit_result": "Congratulations, you have received {deposit} Coins.",
  "wallet_record_event_manual_add_deposit": "Manual Coin",
  "transfer_page_label_negative_deposit": "Hindi sapat na balanse ng Coin, hindi magamit ang mga function ng paglilipat.",
  "winning_tooltip_dialog_label": "\nAng panalong gantimpala ng Coin contest.",
  "wallet_record_event_add_deposit_from_store": "Buy coins from Store",
  "wallet_record_event_refund_deposit": "Refund",
  "wallet_record_event_first_deposit": "Reward para sa unang beses na pagbili ng mga Coins.",
  "activity_point_page_current_section_label_ap_add_deposit": "AP (Buy Coins)",
  "transfer_success_dialog_label": "You've successfully sent {number} winnings to {username}.",
  "add_deposit_page_label_first_deposit": "FIRST-TIME PURCHASE",
  "add_deposit_page_label_bonus": "BONUS",
  "label_add_deposit": "Buy Coins",
  "referral_program_page_label_collect_ruby_description": "Kapag ang iyong mga referral ay gumamit ng mga Coins upang sumali sa contest, maaari kang makatanggap ng {returnToReferrerPercentage}% ng entry fee sa ruby ​​​​(hanggang sa {maxRuby}).",
  "wallet_record_event_withdraw_deposit": "Withdraw Coin",
  "add_deposit_page_detail_end": "Rubies on the FIRST-TIME PURCHASE!",
  "deposit_tooltip_dialog_label": "Coins can be used as the entry fee to join the contest.",
  "label_deposit": "Coin",
  "store_present_deposit": "Coin Balance",
  "contest_detail_page_label_won_coins": "Won {number} Winnings",
  "user_delete_warn_dialog_description": "Your wallet Balance will ALL be cleared.\nYou still has some left in wallet: coin {deposit}, winning {winning} and ruby {ruby}.",
  "check_email_page_label_check_email": "Mag-click sa link na natanggap mo lang sa {email}. Ang link ay may bisa sa loob ng 24 na oras. Hindi makita ito? i-check ang iyong spam folder.",
  "check_email_page_label_check_email_1": "Mag-click sa link na kakatanggap mo lang sa",
  "check_email_page_label_check_email_2": "{email}. Ang link ay may bisa sa loob ng 24 na oras.",
  "check_email_page_label_check_email_3": "Hindi makita ito? i-check ang iyong spam folder.",
  "new_user_ruby_popup_title": "Dalawang Regalo Para sa Iyo!",
  "new_user_ruby_dialog_description": "Surprise! {ruby} Rubies are all yours as a gift to start your game journey!\n\nBut wait, there's more...",
  "new_user_ruby_dialog_confirm_btn": "Show Me More!",
  "new_user_coupon_dialog_title": "The Surprises Continue!",
  "new_user_coupon_dialog_description": "I-enjoy ang iyong {discountPercentage}% na diskwento ngayon at kumita ng higit pang Rubies sa mga Daily Tasks.",
  "new_user_coupon_dialog_confirm_btn": "Tuklasin ang Mga Daily Tasks",
  "new_user_coupon_dialog_cancel_btn": "Maybe Later",
  "wallet_view_button_withdraw": "Withdraw",
  "kyc_do_not_show_again": "Don't show this message again today.",
  "kyc_start_verification": "Simulan ang Pag-verify",
  "kyc_verify_to_withdraw": "I-verify ang Iyong Account para Ma-withdraw",
  "kyc_verify_to_withdraw_content_1": "To withdraw, please complete verification first. This ensures your account security and complies with regulations.",
  "kyc_verify_to_withdraw_content_2": "Kung ang iyong nakaraang pagsubok sa pag-verify ay hindi matagumpay, mangyaring muling isumite ang iyong mga dokumento upang magpatuloy.",
  "kyc_unverify_hint": "Verification Needed to Withdraw.",
  "kyc_do_it_later": "I'll Do It Later",
  "label_verify_account": "I-verify ang Account",
  "task_label_buy_coins": "Bumili ng mga Coins",
  "task_label_buy_any_coins": "Bumili ng Anumang Coins",
  "label_info": "Info",
  "label_player_stats": "Player Stats",
  "add_coin_page_first_purchase_title": "1ST BUY BONUS",
  "add_coin_page_first_purchase_hint": "GET {rubyPercentage}% + STEEL UPGRADE",
  "add_coin_page_first_purchase_description": "Mag-enjoy ng {rubyPercentage}% Rubies na bonus anytime sa iyong unang pagbili ng {minCoin}+ na mga Coins!",
  "add_coin_page_extra_bonus_title": "EXTRA RUBY BONUS",
  "add_coin_page_extra_bonus_hint": "GET {rubyPercentage}% RUBIES",
  "add_coin_page_extra_bonus_description": "First time na bumili ng {minCoin}+ coins? Mag-enjoy ng {rubyPercentage}% Rubies na bonus anytime!",
  "add_coin_page_upgrade_to_steel_title": "LEVEL UP",
  "add_coin_page_upgrade_to_steel_hint": "STEEL UPGRADE",
  "add_coin_page_upgrade_to_steel_description": "Upgrade to Steel with any purchase!",
  "wallet_view_label_negative_coin_balance": "Negative coin balance, unable to use certain features.",
  "label_verification_successful": "Verification Successful",
  "kyc_verification_successful_content_1": "Congratulations! Your account verification is completed, unlocking all features including withdrawals. ",
  "kyc_verification_successful_content_2": "Enjoy the full gaming experience with enhanced security.",
  "kyc_verification_successful_enjoy": "Enjoy the Game",
  "match_info_series": "Series",
  "match_info_start_time": "Date & Time",
  "match_info_venue": "Venue",
  "match_info": "Match Info",
  "match_info_player_to_watch": "Players to watch",
  "result_tab_info": "Info",
  "result_tab_player_stats": "Player Stats",
  "result_tab_info_leaderboard": "Leaderboard",
  "kyc_completed_hint": "Verification Completed - Enjoy All Features Now!",
  "label_verification_completed": "Verification Completed",
  "kyc_verification_welcome_title": "Welcome to the Daily Fantasy Verification Process!",
  "label_verification_in_progress": "Verification In Progress",
  "verification_in_progress_content_1": "Your documents have been submitted successfully and are now under review.",
  "verification_in_progress_content_2": "The verification process can take up to several working days.\nYou will receive an notification once the verification is completed.\nThank you for your patience.",
  "label_return_to_home": "Return to Home",
  "verfication_successful_content": "Congratulations! Your verification has been successfully completed. You now have full access to all platform features, including the ability to withdraw.\n\nThank you for taking the time to complete this important step. Enjoy the game now!",
  "kyc_verification_process": "Daily Fantasy Verification Process",
  "label_exit": "Exit",
  "kyc_verification_process_welcome_content": "Before you can withdraw, we need to make sure it’s really you. This is a quick security check that helps keep you and your money safe. \nPlease provide the following information to get started:",
  "kyc_verification_form_title_part_with_var": "Basic Information - Part {part}",
  "kyc_verification_form_title_id_verification": "Identity Verification",
  "kyc_verification_form_title_picture_with_id": "Picture with Your ID",
  "kyc_verification_form_description_id_verification": "Please upload clear images of the front and back of your ID. Acceptable forms of ID include: Passport, Driver's License, or Government-issued Identity Card. Ensure all details are visible and the images are not blurred.",
  "kyc_verification_form_description_picture_with_id": "Take a front-facing picture holding your ID next to your face. This helps us verify your identity and ensure that the ID belongs to you. \nPlease make sure your face and the details on the ID are clearly visible.",
  "label_first_name": "First Name",
  "label_last_name": "Last Name",
  "label_date_of_birth": "Date of Birth",
  "label_nationality": "Nationality",
  "label_current_address": "Current Address",
  "label_permanent_address": "Permanent Address",
  "label_contact_information": "Contact Information",
  "label_nature_of_work": "Nature of Work",
  "label_enter_your_id_number": "Enter Your ID Number",
  "label_change_image": "Click or drag & drop to upload a different image.",
  "placeholder_year": "Year",
  "placeholder_month": "Month",
  "placeholder_date": "Date",
  "placeholder_document_type": "Document Type",
  "placeholder_id_number": "ID Number",
  "placeholder_phone_number": "Phone Number",
  "placeholder_upload_front_side_id": "Upload or Drag and Drop Front Side of Your ID Here",
  "placeholder_upload_back_side_id": "Upload or Drag and Drop Back Side of Your ID Here",
  "placeholder_upload_picture_with_id": "Upload or Drag and Drop Picture with ID here",
  "placeholder_upload_file_max_size": "Maximum file size: {fileMaxSizeMB}MB",
  "required_error_first_name": "Please enter your first name.",
  "required_error_last_name": "Please enter your last name.",
  "required_error_date_of_birth": "Please enter a valid date of birth.",
  "required_error_nationality": "Please select your nationality.",
  "required_error_current_address": "Please enter your current address.",
  "required_error_permanent_address": "Please enter your permanent address.",
  "required_error_contact_information": "Please enter your contact Information.",
  "required_error_nature_of_work": "Please describe the nature of your work.",
  "required_error_id_number": "Please enter your ID number.",
  "required_error_upload_front_side_id": "Please upload the front side of your ID.",
  "required_error_upload_back_side_id": "Please upload the back side of your ID.",
  "required_error_upload_picture_with_id": "Please upload the picture with ID.",
  "invalid_type_error_upload_image": "Please upload an image smaller than {fileMaxSizeMB}MB and in JPG or PNG format.",
  "invalid_type_error_contact_information": "Please enter a valid contact number.",
  "kyc_verification_incomplete_content": "If you leave now, you'll exit the verification process and your progress will not be saved. \nYou'll need to start over next time to complete verification, which is required to withdraw.\n\nAre you sure you want to return to the game without saving?",
  "label_verification_incomplete": "Verification Incomplete",
  "label_exit_and_lose_progress": "Exit and Lose Progress",
  "label_continue_verification": "Continue Verification",
  "main_page_bottom_bar_tab_my_games": "My Games",
  "wallet_view_label_ruby_usage": "{rubyExchangeRate} Rubies Can Offset 1 Coin Entry Fee",
  "contest_deposit_normal_subtitle": "Win Contest, Get Winning, Redeem Vouchers",
  "contest_ruby_normal_subtitle": "Get Free Ruby in Daily Task",
  "label_my_games": "My Games",
  "upcoming_slates_label_coming_soon": "Coming Soon",
  "kyc_verification_unsuccessful_resubmit": "Resubmit Verification",
  "kyc_await_hint": "Verification In Progress - Please Wait.",
  "kyc_await_to_withdraw": "Awaiting Verification for Withdrawal",
  "kyc_await_to_withdraw_content": "Your verification is currently underway. Please allow some time for us to thoroughly review your submission. We're working hard to ensure your account security and comply with regulations. You will be able to withdraw once verification is completed. Thank you for your patience.",
  "label_return_to_game": "Return to Game",
  "cricket_quick_summary_snapshot_sync": "Data is synchronizing, will be ready soon.",
  "wallet_record_event_cancelled_user_withdraw_winning": "Withdraw Reversed",
  "activity_point_page_upgrade_section_label_level": "Level",
  "activity_point_page_upgrade_section_label_ap_needed": "AP Needed",
  "label_support_link": "Support",
  "wallet_view_support_link": "Need help? Talk to our support team now.",
  "ap_overview_view_label_beginner_next_level": "Buy any coins to get Steel",
  "me_ap_hint_buy_any_coins": "Buy Any Coins",
  "daily_task_item_title_watch_video_daily_tutorial_1": "How to Join Match",
  "daily_task_item_title_watch_video_daily_tutorial_2": "Daily Task & AP Level",
  "daily_task_item_title_watch_video_daily_tutorial_3": "How to use Ruby",
  "daily_task_item_title_watch_video_daily_tutorial_4": "Increase Your Win Rate",
  "daily_task_item_description_watch_video_daily_tutorial_1": "Quick Start to Play the live matches",
  "daily_task_item_description_watch_video_daily_tutorial_2": "Claim your FREE RUBY every day",
  "daily_task_item_description_watch_video_daily_tutorial_3": "Get to know RUBY Features in the game",
  "daily_task_item_description_watch_video_daily_tutorial_4": "From Beginner to Senior",
  "label_create_a_new_team_style_1": "Create a new team",
  "label_create_a_new_team_style_2": "Create a New Team",
  "create_nine_cat_slate_team_description": "Select a stat or a player to begin. Tap again to assign your player to a stat.",
  "label_clear_all": "Clear All",
  "label_points_scored": "Points Scored",
  "label_field_goals_efficiency": "FG Efficiency",
  "label_free_throws_efficiency": "FT Efficiency",
  "label_offensive_rebounds": "Offensive Rebounds",
  "label_defensive_rebounds": "Defensive Rebounds",
  "label_assists": "Assists",
  "label_steals": "Steals",
  "label_blocks": "Blocks",
  "label_three_pointers_made": "3-Pointers Made",
  "label_search": "Search",
  "label_filter": "Filter",
  "label_selected_teams_for_contest": "Selected Teams for Contest",
  "label_view_my_team": "View My Teams",
  "player_info_popup_label_last_5_games": "Last 5 Games",
  "player_info_popup_label_player_news": "Player News",
  "label_select": "Select",
  "player_info_popup_label_no_game_data": "Seems there's no game data right now.",
  "nine_cat_popup_title": "What is 9-CAT?",
  "nine_cat_popup_description": "9-CAT is a special gameplay in the game, it allows users to pick 9 NBA players from all the daily contests, and fit them into the stats whom you think the players could get the best performance.\n\nThere are 9 stats for users to fit in, PTS, FGE, FTE, OR, DR, AST, ST, BLK, and 3PM.",
  "nine_cat_leaving_create_team_popup_title": "Heads up!",
  "nine_cat_leaving_create_team_popup_description": "If you leave now, your team-making magic won't be saved. This means all the awesome choices and moves you've done so far will disappear. You'd need to start from scratch next time.\n\nWhy not stay a bit longer and see your dream team come alive?",
  "nine_cat_leaving_create_team_popup_button_leave": "Leave and Lose Progress",
  "nine_cat_leaving_create_team_popup_button_continue": "Continue Creating Team",
  "nine_cat_scoring_description_fte": "In 9-Cat, 'FTE' stands for Free Throw Efficiency. This is all about a player's skill in making free throws. Just like with field goals, it's not just how many free throws they make ('FTM' or Free Throws Made), but also how accurate they are ('FT%' or Free Throw Percentage). FTE is calculated by multiplying a player's FTM by their FT%, highlighting those who excel under pressure.\n\nFree throws might seem simple, but they're crucial. Each FTE point boosts your team by 3 points. This rewards players who are calm and precise from the free-throw line. It's not about how many times you get to the line; it's about making it count every time.\n\nFor example, if James Harden goes to the line 50 times and makes all 10, his Free Throw Percentage (FT%) is 20% or 0.2. To find his FTE, we multiply his Free Throws Made (FTM), which is 10, by his FT%, which is 0.2. So, 10 (FTM) x 0.2 (FT%) = 2 FTE for that game.\n\nBecause each point of FTE is worth triple in 9-Cat, Harden's 2 FTE becomes a massive 6 points for your team. This shows why picking players who are reliable from the free-throw line can be a game-changer. Every successful free throw can edge you closer to victory, proving that precision pays off.",
  "nine_cat_scoring_description_pts": "In the realm of basketball, particularly the NBA, 'PTS' is an abbreviation for 'Points'. This is the score a player accumulates during a game. Points can be scored in several ways:\n\n· 2 Points for a regular shot made from inside the three-point arc.\n· 3 Points for a shot made from beyond the three-point arc.\n· 1 Point for each successful free throw.\n\nIn 9-Cat, every point a player scores in the real-world NBA game directly translates to points in your team. 1 PTS in NBA = 1 Point.\n\nThis means if LeBron James scores 30 PTS in his NBA game tonight, you'll earn 30 points in your team. Simple and direct, your player's on-court success is your score's gain!",
  "nine_cat_scoring_description_fge": "In 9-Cat, 'FGE' stands for Field Goal Efficiency. It’s all about how well a player shoots. Think of it as a mix of how many shots they make ('FGM') and their shooting accuracy ('FG%'). A player's FGE is their FGM times their FG%, showing us who is really good at scoring efficiently.\n\nIn this game, FGE is important. Every point of FGE is worth 3 points for your team. This means players who shoot accurately are valuable. It's not just about shooting a lot; it's about shooting well.\n\nImagine a player, let’s say Steph Curry, has a great game where he makes 10 shots out of 20 attempts. This gives him a Field Goal Percentage (FG%) of 50% (because 10 made shots / 20 attempts = 0.5 or 50%).\nIn the FGE formula, we multiply his Field Goals Made (FGM), which is 10, by his FG% (converted into a decimal for the calculation), which is 0.5. So, 10 (FGM) x 0.5 (FG%) = 5 FGE for that game.\n\nIn 9-Cat, each point of FGE is tripled in value. So, Steph Curry’s FGE of 5 turns into 15 points for your team. So, picking players who are good at hitting their shots can really help you win. It shows that making each shot count can bring you closer to victory.",
  "nine_cat_scoring_description_or": "In the realm of basketball, especially in the NBA, 'OR' stands for Offensive Rebounds. This crucial stat highlights the players who grabs the ball after a missed shot by their team before the other team can get it. It's a testament to a player's hustle, determination, and knack for being in the right place at the right time.\n\nIn 9-Cat, OR is even more special. Each OR gives your team 6 points. This means players who can grab those rebounds are game-changers. It's about more than just jumping high; it's about desire, timing, and knowing where the ball will land.\n\nLet's say Russell Westbrook gets 5 ORs in a game. In 9-Cat, those 5 ORs turn into 30 points for you. This shows how hustle plays can make a huge difference. Picking players known for their rebounding can massively boost your score, proving that hard work and determination pay off big time.",
  "nine_cat_scoring_description_dr": "In basketball, especially in the NBA, 'DR' stands for Defensive Rebounds. This key stat highlights players who catch the ball after the other team misses a shot. It showcases a player's ability to effectively finish a defensive play and initiate their team's transition to offense. Success in this area isn't just about height; it requires effort, good positioning, and quick decision-making.\n\nIn 9-Cat, each DR brings your team 3 points. This means players good at getting those rebounds are important. They help stop the other team and start your team's attack.\n\nImagine Giannis Antetokounmpo grabs 10 DRs in a game. In 9-Cat, those 10 DRs give you 30 points. Selecting players who excel in gathering defensive rebounds can boost your score, demonstrating that a strong defense contributes to great outcomes.",
  "nine_cat_scoring_description_ast": "In the world of basketball, particularly in the NBA, 'AST' stands for Assists. This vital statistic highlights players who pass the ball to a teammate in such a way that it directly leads to a score. More than just a simple pass, an assist is a strategic move that culminates in points for the team.\n\nIn 9-Cat, each AST is worth 2.5 points for your team, emphasizing the value of teamwork and strategic play. Players who rack up assists are crucial because they're the ones setting the table for others to score. It's not just about personal glory; it's about lifting the entire team.\n\nImagine Chris Paul dishes out 10 assists in a game. In 9-Cat, those 10 ASTs translate into 25 points for you. Choosing players who are known for their ability to assist can elevate your team's performance, showcasing that sharing the spotlight is often the path to victory.",
  "nine_cat_scoring_description_st": "In the realm of basketball, particularly in the NBA, 'ST' stands for Steals. This exciting stat spotlights the players who can cunningly take the ball away from the opposing team. A steal is more than a mere moment of quick hands; it's a calculated move that showcases anticipation, agility, and the ability to read the opponent's next step. It turns defense into offense in the blink of an eye.\n\nIn 9-Cat, ST becomes even more crucial. Each ST earns your team 7.5 points, highlighting the immense value of defensive intelligence and quick action. Players adept at stealing are invaluable, as they not only prevent the opposing team from scoring but also create additional scoring opportunities for their own team.\n\nThink about if Jimmy Butler gets 4 steals in one game. In 9-Cat, those 4 STs would rocket your score by 30 points. \nThis illustrates the dramatic impact that defensive prowess can have. Opting for players known for their stealing prowess can greatly enhance your team's dynamics, proving that sometimes, a strong defense is the best offense.",
  "nine_cat_scoring_description_blk": "In the realm of basketball, particularly in the NBA, 'BLK' stands for Blocks. This thrilling stat puts a spotlight on players who can stop an opponent's shot by swatting it away. A block is much more than just a defensive play; it's a powerful statement that showcases timing, athleticism, and the instinct to protect the hoop. It's about denying the opponent and changing the game's momentum.\n\nIn 9-Cat, BLK gains greater significance. Each BLK is worth 7.5 points to your team, emphasizing the critical role of defensive mastery and the ability to challenge opponents' attempts. Players who excel in blocking shots are invaluable. They don't just defend; they demoralize their opponents by turning promising scoring attempts into powerful defensive victories.\n\nImagine Anthony Davis dominating the paint and recording 5 blocks in a single game. In 9-Cat, those 5 BLKs would elevate your score by an impressive 37.5 points. This underlines the game-changing impact that a skilled shot-blocker can have. Choosing players known for their blocking abilities can significantly improve your team's standing, proving that a formidable defense is key to securing wins.",
  "nine_cat_scoring_description_3pm": "In basketball, especially in the NBA, '3PM' stands for 3-Pointers Made. This stat celebrates the players who can score from beyond the three-point line. Hitting a three-pointer is more than shooting from far away, It stretches the defense and opens up the game.\n\nIn 9-Cat, 3PM takes on extra importance. Each 3-pointer made is worth 4 points for your team, highlighting the special skill of shooting from long distance. Players who are good at making three-pointers can change the game's flow and score quickly, adding pressure to the opponents.\n\nThink about if Stephen Curry hits 5 three-pointers in a game. In 9-Cat, those 5 3PMs boost your score by 20 points. Picking players known for their three-point shooting can greatly increase your score, showing that distance shots can lead to big wins.\n",
  "invite_friend_page_copy_message_content_1": "I have gifted you {rubies} Ruby to start playing on {appName}. Think you can beat me?",
  "invite_friend_page_copy_message_content_2": "1. Download the {appName} App from here: {url}",
  "invite_friend_page_copy_message_content_2_daily": "1. Go to {appName} website: {url}",
  "invite_friend_page_copy_message_content_3": "2. Use my referral code: {code}",
  "invite_friend_page_copy_message_content_4": "3. Get {rubies} Rubies to start.",
  "invite_friend_page_copy_message_content_5": "Let the games begin!",
  "match_detail_page_bonus_banner_title": "Bonus Time !",
  "match_detail_page_bonus_banner_description": "We add some extra bonus in this match",
  "confirmation_dialog_label_rule": "Ang real-time na data ng system ay mananaig sa kaso ng data inconsistency. Sa pagsali sa patimpalak na ito, tinatanggap mo ang {appName} at kinukumpirma na hindi ka residente ng India Andhra Pradesh, Assam, Nagaland, Odisha, Sikkim o Telangana.",
  "chat_room_page_label_system_announcement": "Maligayang pagdating sa {appName} Chat Room! Mangyaring Sundin ang Etiquette sa Chat Room at Protektahan ang Iyong Privacy.\n",
  "ruby_tooltip_dialog_label": "Ruby is given as a freebie to {appName} players. You can use it to join any public cash contests. It is valid for 14 days from the date it has been credited on. The Ruby is used in order of expiry date.",
  "add_deposit_page_detail_front": "Register with {appName} and received",
  "referral_program_page_label_invite_friend_description": "Mag-imbita ng mga kaibigan na may ibinahaging pagmamahal sa sports na pumunta at samahan ka sa {appName}",
  "me_page_label_share_icon": "Handa nang subaybayan ang kumpetisyon? I-tap ang link para simulang sundan si {nickname} sa {appName} ngayon!",
  "auth_select_language_description": "Maaari mong baguhin ang wika sa ibang pagkakataon mula sa {appName} App Settings",
  "user_bind_page_confirm_dialog_description_2": "Notice: Please register with a reputable email service such as Gmail, Outlook, Yahoo or iCloud. If you use a suspicious or unreliable third-party email service, you will be unable to continue playing {appName}.",
  "match_card_label_bonus": "Bonus",
  "supplier_link_popup_label_1": "This content is provided by {supplierName}. Visit their website (",
  "supplier_link_popup_label_2": ") for more information.",
  "button_confirm_and_join": "Confirm and Join",
  "label_link_copied": "Link Copied.",
  "all_spots_filled": "All Spots Filled",
  "spots_filled_content": "This contest has reached its participant limit. But don't worry, there are more exciting events for you to join!",
  "button_join_another": "Join Another",
  "button_upgrade_ap_level": "Upgrade AP Level",
  "dialog_insufficient_ap_level_title": "Insufficient AP Level",
  "dialog_insufficient_ap_level_content": "Your AP level is currently ‘{currentApLevel}’, but you need to be at least ‘{minApLevel}’ to join this activity. Please upgrade your AP level or explore other contests.",
  "dialog_teams_already_joined_title": "Teams Already Joined",
  "dialog_teams_already_joined_content_1": "You have already joined with",
  "dialog_teams_already_joined_content_2": "Please change your team selection.",
  "button_change_team_selection": "Change Selection",
  "join_contest_label_join_contests_with_selected_teams": "Join Contests with Selected Teams",
  "button_back_to_home": "Back to Home",
  "dialog_join_contest_successfully_content": "You’ve joined {slateName} {entryFee} {entryFeeType} contest with {teams}!",
  "dialog_join_contest_successfully_title": "Join Successfully",
  "label_no_discount": "No Discount",
  "label_unit_rubies": "rubies",
  "label_unit_coins": "coins",
  "label_prizes_and_distribution": "Prizes & Distribution",
  "label_shared": "Shared: {sharedPrize}",
  "label_coin": "Coin",
  "label_rubies": "Rubies",
  "contest_card_label_participants": "Participants",
  "contest_card_label_first_prize": "1st Prize",
  "contest_card_label_entry_limit": "Entry Limit",
  "contest_card_label_cancelled": "Cancelled",
  "contest_card_label_eligible": "Eligible",
  "contest_card_label_you_joined_with": "You Joined with:",
  "contest_card_label_full": "Full",
  "confirmation_dialog_label_current_balance": "Current Balance",
  "confirmation_dialog_label_new_balance": "New Balance",
  "confirmation_dialog_description_policy_1": "I agree to the",
  "confirmation_dialog_description_policy_2": "by completing this payment.",
  "label_view_my_contests": "View My Contests",
  "label_join_now": "Join Now",
  "my_games_page_description_empty_9_cat": "You haven't joined any 9-Cat games yet.\nLet's go join one now!",
  "my_games_page_description_empty_matches": "You haven't joined any games in the sports you chose above yet. Let's go join one now!",
  "my_contest_page_button_check_leaderboard": "Check Leaderboard & Winning Prize",
  "contest_detail_page_leaderboard_label_winning_prize_details": "Winning Prize Details",
  "contest_card_label_entry_fee_free": "Free",
  "contest_card_title_practice": "Practice",
  "contest_card_title_deposit_normal": "Coin",
  "contest_card_title_ruby_normal": "Ruby",
  "contest_detail_page_ranking_label_prize_hint": "The prizes could be rounded down if they cannot be divided into whole numbers. The prizes and number of winners may be adjusted dynamically based on the number of teams.",
  "share_contest_popup_label_share": "Share",
  "share_contest_popup_label_to_chat_room": "To Chat Room",
  "share_contest_popup_textarea_placeholder_comment": "Leave a comment (optional)",
  "share_contest_popup_label_copy_link": "Copy Link",
  "share_contest_popup_copy_success_message": "Contest link copied!",
  "label_view": "View",
  "label_each": "Each: {eachPrize}",
  "share_contest_popup_comment_includes_urls_error_message": "Links are not allowed.",
  "share_contest_popup_share_success_message": "Shared successfully!",
  "share_contest_popup_share_exceed_rate_limit_message": "Sharing too frequently.",
  "contest_leaderboard_view_message_joined": "have already joined.",
  "contest_leaderboard_view_message_you_havent_joined": "Don’t miss out, join now!",
  "contest_leaderboard_view_message_you_have_joined": "Good luck!",
  "contest_leaderboard_view_message_nobody_joined_1": "Be the First to Join!",
  "contest_leaderboard_view_message_nobody_joined_2": "Set the pace, join now!",
  "label_rank": "Rank",
  "label_teams": "Teams",
  "contest_leaderboard_view_label_end_of_prizes": "End of Prizes",
  "contest_leaderboard_view_label_participants": "Participants",
  "contest_leaderboard_view_team_card_label_won": "Won",
  "contest_leaderboard_view_message_cancel": "Unfortunately, this contest has been cancelled. Explore more exciting contests with other participants.",
  "label_guide": "Guide",
  "how_to_play_step_1_title": "Choose a Match",
  "how_to_play_step_1_subtitle": "Choose your favorite match.",
  "how_to_play_step_2_title": "Choose a Contest",
  "how_to_play_step_2_subtitle": "Choose from a variety of prize pools.",
  "how_to_play_step_3_title": "Pick Your Members",
  "how_to_play_step_3_subtitle": "Pick your fantasy team members.",
  "how_to_play_step_4_title": "Choose the Captain and Vice-Captain",
  "how_to_play_step_4_subtitle": "Captain will earn 2x points, Vice-Captain will earn 1.5x points.",
  "how_to_play_step_5_title": "Join",
  "how_to_play_step_5_subtitle": "Review and confirm.",
  "team_structure_title": "Team Structure",
  "label_position": "Position",
  "label_min": "Min",
  "label_max": "Max",
  "scoring_popup_title": "PAGMAmarka",
  "scoring_popup_label_type_of_points": "Type of Points",
  "player_role_cricket_keeper_singular": "Wicket-Keeper",
  "player_role_cricket_batsman_singular": "Mga batter",
  "player_role_cricket_bowler_singular": "Mga bowler",
  "player_role_cricket_all_rounder_singular": "All-Rounder",
  "player_role_football_defender_singular": "Mga tagapagtanggol",
  "player_role_football_midfielder_singular": "Mga midfielder",
  "player_role_football_forward_singular": "Pasulong",
  "scoring_popup_subtitle_fantasy_points_system": "FANTASY POINTS SYSTEM",
  "scoring_popup_subtitle_bonus_points": "BONUS POINTS",
  "scoring_popup_subtitle_economy_rate": "ECONOMY RATE",
  "scoring_popup_subtitle_strike_rate": "STRIKE RATE (EXCEPT BOWLERS)",
  "scoring_popup_subtitle_attack": "ATTACK",
  "scoring_popup_subtitle_defense": "DEFENSE",
  "scoring_popup_subtitle_cards_and_penalties": "CARDS & OTHER PENALTIES",
  "scoring_popup_subtitle_other_points": "OTHER POINTS",
  "scoring_popup_note_1": "If a player has recently switched teams, it's possible he/she will still count for the team he/she left. But, you won't get points from him/her until the next update.",
  "scoring_popup_note_2": "If a player is announced in the starting eleven but doesn't play, they won't score any points. The substitute player will score points instead.",
  "result_match_info_top_scorers_by_role": "Top Scorers by Role",
  "result_match_info_label_scores": "Scores",
  "label_selected_by": "Selection Rate",
  "daily_quest_page_title": "Quests",
  "daily_check_in_popup_title": "{days} Days in a Row",
  "daily_check_in_popup_title_first_day": "First Day, Keep Going",
  "daily_check_in_popup_subtitle_coins_today": "+{amount} Coins Today",
  "daily_check_in_popup_subtitle_rubies_today": "+{amount} Rubies Today",
  "label_today": "Today",
  "label_tomorrow": "Tomorrow",
  "daily_check_in_popup_button_more_rewards": "More Rewards",
  "daily_check_in_upgrade_popup_title": "Upgrade for better rewards!",
  "daily_check_in_upgrade_popup_subtitle": "Recommendation Level:",
  "daily_check_in_upgrade_popup_button_compare": "Compare Level Rewards",
  "label_ap_level_beginner_upper": "BEGINNER",
  "label_ap_level_ordinary_upper": "ORDINARY",
  "label_ap_level_silver_upper": "SILVER",
  "label_ap_level_gold_upper": "GOLD",
  "label_ap_level_platinum_upper": "PLATINUM",
  "label_ap_level_emerald_upper": "EMERALD",
  "label_ap_level_diamond_upper": "DIAMOND",
  "label_ap_level_master_upper": "MASTER",
  "label_ap_level_ultimateMaster_upper": "ULTIMATE MASTER",
  "label_check_in": "Check-In",
  "label_per_video": "per video",
  "daily_check_in_upgrade_popup_label_max_reward": "Max Rewards!",
  "daily_check_in_upgrade_popup_label_max_reward_continue": "Max Rewards Continue!",
  "daily_check_in_upgrade_popup_button_upgrade_now": "Upgrade Now",
  "daily_check_in_upgrade_label_watch_ad": "Watch Ad",
  "daily_quest_item_title_daily_check_in": "Daily Check-In",
  "daily_quest_item_description_daily_check_in": "{days} Days in a row.",
  "home_page_label_empty_upcoming_matches": "More matches coming soon!",
  "home_page_label_choose_a_tournament": "Let's choose a tournament to view the content!",
  "contest_deposit_normal_title": "Coach Battle",
  "required_error_age_under_21": "Sorry, you must be at least 21 years old.",
  "label_agree_terms_and_conditions": "I have read and agreed to the {termsAndConditions} and the {privacyAndPolicy}",
  "label_agree_terms_and_conditions_app": "I have read and agreed to the $termsAndConditions$ and the $privacyAndPolicy$",
  "prize_distribution_calculator_description_1": "We calculate prize distributions based on the number of teams using specific formulas.",
  "prize_distribution_calculator_description_2": "Adjust the number of teams below to see changes in prize amounts and prize tiers.",
  "prize_distribution_calculator_teams_input_validation_error": "Please enter a number between {minSlips} and {spots}.",
  "prize_distribution_calculator_min_spots": "Min: ",
  "prize_distribution_calculator_max_spots": "Max: ",
  "prize_distribution_calculator_title": "Prize Distribution",
  "kyc_verify_to_unlock": "I-verify ang Iyong Account para I-unlock ang Lahat ng Features!",
  "kyc_verify_to_unlock_content": "Complete the KYC verification within 5 minutes to ensure full access to all features, including withdrawals. It helps us to maintain a secure gaming environment.",
  "kyc_verify_to_unlock_note": "Note: KYC verification must be completed within 7 days of registration to continue accessing key game features. Approval may take some time as we review your submission for your security.",
  "label_verification_unsuccessful": "Verification Unsuccessful",
  "kyc_verification_unsuccessful_content_1": "Your verification attempt was not successful. Please resubmit your verification documents.",
  "kyc_verification_unsuccessful_content_2": "Completing the verification is necessary to withdraw.",
  "kyc_verification_unsuccessful_content_note": "Note: KYC verification must be completed within 7 days of registration to continue accessing key game features.",
  "my_games_page_description_not_selected_tab": "Let's choose a {data} to view the content!",
  "confirmation_dialog_description_policy_3": "Entries cannot be cancelled once confirmed.",
  "fantasy_sport_label_sport": "sport",
  "kyc_verification_complete_your_kyc": "Complete Your KYC",
  "kyc_verification_complete_your_kyc_content_1": "Sorry, you need to complete the KYC verification to continue playing, as it has been more than 7 days since you registered. This is to ensure the safety and security of all players.",
  "kyc_verification_complete_your_kyc_content_2": "Please complete the verification process to unlock all features and enjoy the full gaming experience.",
  "responsible_gaming_title": "Responsible Gaming",
  "responsible_gaming_age_confirmation": "I am at least 21 years old.",
  "responsible_gaming_non_government_confirmation": "I am not a government official, or employee connected directly with the operation of the Government or an of its agencies, member of the Armed Forces of the Philippines, including the Army, Navy, Air Force, or the Philippine National Police.",
  "responsible_gaming_non_gel_confirmation": "I am not a Gaming Employment License (GEL) holder.",
  "responsible_gaming_confirmation_disclaimer": "By checking the boxes above and clicking \"Confirm\" you affirm that you are not one of the above-described banned persons. If found otherwise, you consent to the closure of your account and forfeiture of your funds in favor of the Government, as provided under the relevant regulatory framework.",
  "withdraw_to_account_label_account_name": "Account Name",
  "withdraw_to_account_label_account_bank": "Bank",
  "withdraw_to_account_label_account_number": "Account Number",
  "withdraw_to_account_name_validation_error": "Please enter your account name.",
  "withdraw_to_account_bank_validation_error": "Please select your bank.",
  "withdraw_to_account_number_validation_error": "Please enter your account number.",
  "withdraw_to_account_transaction_fee_hint_label": "₱{transactionFee} fee applies to each withdrawal.",
  "withdraw_to_account_minimum_withdrawal_amount_hint_label": "The minimum withdrawal amount is ₱{minimumWithdrawAmount}.",
  "register_selected_site_label": "All players share the same prize pools regardless of their sites.",
  "buy_coin_special_label": "Special Offer",
  "buy_coin_standard_label": "Standard Offer",
  "buy_coin_purchase_title_label": "Coins On Their Way!",
  "buy_coin_purchase_content_label": "We're processing your purchase. This usually takes just a moment.",
  "buy_coin_purchase_tip_label": "Sometimes, it may take a few minutes. Track the progress in your {data}.",
  "label_play_now": "Play Now",
  "label_buy_more_coins": "Buy More Coins",
  "buy_coin_purchase_unsuccessful_title_label": "Purchase Unsuccessful",
  "buy_coin_purchase_unsuccessful_content_label": "Your purchase was not completed. Please try again.",
  "buy_coin_purchase_unsuccessful_tip_label": "Your transaction could not be completed. Track the status in your {data}.",
  "label_available": "Available: ",
  "label_send_to": "Send to",
  "button_review": "Review",
  "withdraw_to_account_amount_exceed_validation_error": "The amount entered are more than your balance. Please enter a smaller amount.",
  "withdraw_to_account_minimum_amount_validation_error": "You need to withdraw at least ₱{minimumWithdrawAmount}.",
  "withdraw_to_account_amount_validation_error": "Please enter a valid amount using only numbers.",
  "selector_option_passport": "Passport",
  "selector_option_drivers_license": "Driver's License",
  "selector_option_id": "Government-issued Identity Card",
  "selector_option_other": "Other",
  "kyc_verification_title": "Daily Fantasy Verification Process",
  "label_send_code": "Send Code",
  "label_edit_number": "Edit Number",
  "label_status": "Status",
  "label_success": "Success",
  "label_receive": "Receive",
  "label_fee": "Fee",
  "label_to": "To",
  "label_account_no": "Account No.",
  "button_try_again": "Try Again",
  "label_withdrawal": "Withdrawal",
  "label_transaction_fee": "Fee",
  "label_receive_amount": "Receive",
  "withdraw_to_account_terms_and_condition_1": "I agree to the",
  "withdraw_to_account_terms_and_condition_2": "by swiping to confirm.",
  "withdraw_button_confirm": "Swipe to Confirm",
  "label_withdraw_amount_insufficient": "Insufficient",
  "wallet_record_event_user_withdraw_winning": "Withdraw",
  "wallet_record_event_user_withdraw_winning_cancelled": "Withdraw Attempted",
  "wallet_record_event_converted_to_winning": "Converted to Winning (Withdrawal)",
  "wallet_record_event_converted_from_coin": "Converted from Coin (Withdrawal)",
  "label_reversed": "Reversed",
  "label_declined": "Declined",
  "otp_error_mobile_number_incorrect": "Incorrect number. Please check and retry.",
  "otp_error_mobile_number_in_use": "This number is already in use. Please provide a different number to continue your verification process.",
  "otp_mobile_number_title": "Phone Number Verification",
  "otp_mobile_number_description": "Please enter your mobile number. We will send a One-Time Password (OTP) to this number for verification.",
  "label_mobile_number": "Mobile Number",
  "otp_error_mobile_number_exceed_daily_limit_hint": "Max codes reached today. Please try again tomorrow.",
  "otp_error_mobile_number_exceed_interval_limit_hint": "You cannot request a new code yet. Please try again after 90 seconds.",
  "otp_error_verify_code_incorrect": "Incorrect code. Please try again or resend the code.",
  "otp_error_verify_code_expired": "The code has expired. Please request a new one.",
  "otp_verify_code_title": "OTP Sent to {mobileNumber}",
  "otp_verify_code_description": "We have sent a One-Time Password (OTP) to your mobile number. Please enter the OTP below to complete your phone verification.",
  "label_otp_code": "OTP Code",
  "otp_error_verify_code_expired_hint": "Please resend a new code, as the current code has expired.",
  "otp_error_verify_code_exceed_attempt_limit_hint": "Please request a new code. You have reached max tries.",
  "otp_resend_successful": "New OTP has been sent to your number.",
  "otp_resend_description": "Haven't received your code?",
  "otp_resend_limit_tip": "(Max 10 codes per day)",
  "otp_resend_now": "Resend now.",
  "otp_resend_cooldown": "Resend it in {seconds} seconds.",
  "label_withdrew_declined": "Attempted Withdraw",
  "withdrew_result_progress_title": "Withdrawal in Progress",
  "withdrew_result_progress_description": "The transaction may take a few minutes to process.",
  "withdrew_result_declined_title": "Withdrawal Declined",
  "withdrew_result_declined_description": "Unfortunately, your withdrawal could not be processed. Please check your account details or contact support for assistance.",
  "button_withdraw_another": "Withdraw Another",
  "button_back_to_game": "Back to Game",
  "label_collecting_data_to_chart": "We're collecting more data to bring you the chart.",
  "login_page_label_tnc_not_agree": "Please agree to the terms to continue.",
  "required_error_mobile_number": "Please enter your mobile number",
  "label_start_play": "That's Me - Let's Play",
  "responsible_gaming_dialog_description": "<b>Greetings!</b> We’ve got a <b>welcome gift</b> for you. Finish this last step to open it and start playing!",
  "required_error_place_of_birth": "Please select your place of birth.",
  "place_of_birth_title": "Place of Birth",
  "label_same_as_above": "Same as above.",
  "error_mobile_number_in_use": "This number is already in use.",
  "label_source_of_income": "Source of Income",
  "required_error_source_of_income": "Please select your source of income.",
  "responsible_gaming_dialog_prompt": "Responsible Gaming - Please enter your <b>real</b> personal information.",
  "responsible_gaming_dialog_confirm_data_label": "The information <b>cannot edit later,</b> I am sure this is my real information.",
  "required_error_is_info_confirmed": "Please confirm the information is real.",
  "withdraw_to_account_withdrawal_order_hint_label": "Withdrawals start with Winning, then Coin.",
  "payment_app_download_title": "Credit card top-ups are app-only. Download our app to buy coins via credit card.",
  "payment_credit_card_method_title": "Credit Card (App Only)",
  "payment_credit_card_method_button": "Download App",
  "payment_method_title": "Payment Method",
  "label_deactivate_account_message": "Tanggalin ang aking account."
};