export enum QUEST_TYPE {
    WATCH_AD = "WATCH_AD",
    WATCH_TUTORIAL = "WATCH_TUTORIAL",
    WATCH_VIDEO = "WATCH_VIDEO",
}

export const isKnownTaskType = (value: string): boolean => Object.values(QUEST_TYPE).includes(value as QUEST_TYPE);

export enum QUEST_RESET_TYPE {
    NEVER = "NEVER",
    DAILY = "DAILY",
}

export enum QUEST_PROGRESS {
    AVAILABLE = "AVAILABLE",
    COMPLETED = "COMPLETED"
}
