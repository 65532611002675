import { fetchSettingUrl } from "apis/ApiUrlConfig";
import { ajax } from "apis/ApiUtils";
import { AP_LEVEL } from "types/activityPoint/ApLevel";
import { ADD_COIN_METHOD, CURRENCY, CURRENCY_ICON_SUIT_NAME } from "../../store/reducer/config/FantasyGameConfigState";

export interface ContestDto {
    spotsToMaxEntries: { [key: string]: number };
    defaultMaxEntries: number;
    rubyExchangeRateCent: number;
}

export interface MatchDto {
    kickoffDurationMilliseconds: number;
}

export interface ReferrerDto {
    refereeRubyCent: number;
    maxRubyCentPerReferee: number;
    returnToReferrerPercentage: number;
}

export interface ApLevelDto {
    level: AP_LEVEL,
    weight: number,
    minAp: number
}

interface NewUserGiftsDto {
    rubyCent: number,
    couponDiscountPercentage: number
}

interface LinkDto {
    howToPlay: string,
    termsAndConditions: string,
    privacyPolicy: string
}

interface WithdrawDto {
    provider: string;
    minimumAmountCent: number;
    handleFeeCent: number;
}

interface SportsDto {
    sport: string;
    message: string;
    order: number;
    enabled: boolean;
}

export interface SettingDto {
    sports: SportsDto[],
    contest: ContestDto,
    referrer: ReferrerDto,
    match: MatchDto,
    apLevels: ApLevelDto[],
    following: FollowingDto,
    newUserGifts: NewUserGiftsDto,
    link: LinkDto
    withdraw: WithdrawDto
    mpblEnabled: boolean,
    questEnabled: boolean,
    referral: boolean,
    addCoin: boolean,
    paymentMethod: ADD_COIN_METHOD,
    transfer: boolean,
    withdrawWinning: boolean,
    kyc: boolean,
    liveChat: boolean,
    chatRoom: boolean,
    canUserSetCurrencyFormat: boolean,
    defaultCurrencyFormat: CURRENCY,
    currencyIconSuitName: CURRENCY_ICON_SUIT_NAME;
}

export interface FollowingDto {
    maxFollowing: number,
}

export const fetchSetting = async (): Promise<SettingDto> => {
    const { data } = await ajax<SettingDto>({ url: fetchSettingUrl });

    return data.data;
};
