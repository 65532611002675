import React from "react";
import { CirclePlaceHolder, TextPlaceHolder } from "../SkeletonStyle";

interface NineCatCardSkeletonProps {
    type?: "primary" | "secondary";
}

const NineCatCardSkeleton: React.FC<NineCatCardSkeletonProps> = ({
                                                                     type = "primary"
                                                                 }) => {
    const bg = type === "primary" ? "tw-bg-primary-800" : "tw-bg-secondary-700";

    const renderTextPlaceHolder = () => {
        return (
            <div className="tw-space-y-2">
                <TextPlaceHolder $width={4.5} $height={1.2} $radius={0.8} $bg="tw-bg-grey-200" />
                <br />
                <TextPlaceHolder $width={4.5} $height={1.2} $radius={0.8} $bg="tw-bg-grey-200" />
            </div>
        );
    };

    return (
        <div
            className="tw-w-full tw-pt-2 tw-pb-3 tw-px-4 tw-rounded-xl"
            style={{ background: "linear-gradient(96.7deg, rgba(216, 215, 233, 0.8) 0.54%, rgba(238, 235, 221, 0.87) 100%)" }}
        >
            <div className="tw-flex tw-py-1 tw-gap-2 tw-items-center">
                <CirclePlaceHolder $size={4} $bg={bg} />
                <div className="tw-flex-1 tw-space-y-2">
                    <TextPlaceHolder $width={4} $height={1.2} $radius={0.8} $bg="tw-bg-grey-200" />
                    <br />
                    <TextPlaceHolder $width={10} $height={1.2} $radius={0.8} $bg="tw-bg-grey-200" />
                </div>
                <CirclePlaceHolder $size={2.8} $bg="tw-bg-grey-200" />
            </div>
            <div></div>

            <div className="tw-flex tw-justify-center tw-items-center tw-gap-2">
                {renderTextPlaceHolder()}
                <div className="tw-w-[0.1rem] tw-h-8 tw-bg-grey-200" />
                {renderTextPlaceHolder()}
                <div className="tw-w-[0.1rem] tw-h-8 tw-bg-grey-200" />
                {renderTextPlaceHolder()}
                <div className="tw-w-[0.1rem] tw-h-8 tw-bg-grey-200" />
                {renderTextPlaceHolder()}
            </div>
        </div>
    );
};

export default NineCatCardSkeleton;

