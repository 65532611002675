import UserAvatarWithFrame from "components/avatar/UserAvatarWithFrame";
import { cn } from "helpers/cn";
import { leaderboardBg, leaderboardPlayer } from "helpers/IconHelper";
import { toUniversalFormattedNumber } from "helpers/number/NumberHelper";
import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";

interface LeaderboardNotRankProps {
    isCancelled?: boolean;
    myJoinedTeams: number;
    joinedTeamCount: number;
    userName?: string;
    avatar?: string;
}

const defaultPlayerBgColors = [
    ["tw-bg-secondary-50", "tw-bg-primary-50", "tw-bg-warning-50", "tw-bg-tertiary-50"],
    ["tw-bg-secondary-100", "tw-bg-primary-100", "tw-bg-warning-100", "tw-bg-tertiary-100"],
    ["tw-bg-secondary-150", "tw-bg-primary-150", "tw-bg-warning-200", "tw-bg-tertiary-150"],
    ["tw-bg-secondary-200", "tw-bg-primary-200", "tw-bg-warning-300", "tw-bg-tertiary-200"],
    ["tw-bg-secondary-300", "tw-bg-primary-300", "tw-bg-warning-400", "tw-bg-tertiary-300"],
    ["tw-bg-secondary-400", "tw-bg-primary-400", "tw-bg-warning-500", "tw-bg-tertiary-400"],
    ["tw-bg-secondary-500", "tw-bg-primary-500", "tw-bg-warning-600", "tw-bg-tertiary-500"],
    ["tw-bg-secondary-600", "tw-bg-primary-600", "tw-bg-warning-700", "tw-bg-tertiary-600"],
];

const NotRankedYetLeaderboard: React.FC<LeaderboardNotRankProps> = ({
                                                                        isCancelled,
                                                                        joinedTeamCount,
                                                                        myJoinedTeams,
                                                                        userName = "",
                                                                        avatar
                                                                    }) => {

    const hasJoined = myJoinedTeams > 0;

    const players = useMemo(() => {
        const defaultPlayers: (number | undefined)[][] = [
            [1, 2, 3, 4],
            [5, 6, 7, 8],
            [1, 2, 3, 4],
            [5, 6, 7, 8],
            [1, 2, 3, 4],
            [5, 6, 7, 8],
            [1, 2, 3, 4],
            [5, 6, 7, 8],
        ];

        if (hasJoined) {
            defaultPlayers[0][2] = undefined;
        }

        return defaultPlayers;
    }, [hasJoined]);

    const renderPlayer = (playerItem: number | undefined, outerIndex: number, innerIndex: number) => {
        if (!playerItem) {
            return <UserAvatarWithFrame
                name={userName}
                size="6.6rem"
                hasFrame={false}
                font="tw-text-h2 tw-font-black"
                url={avatar}
            />;
        }

        return <div
            className={cn("tw-w-[6.6rem] tw-h-[6.6rem] tw-rounded-full", defaultPlayerBgColors[outerIndex][innerIndex])}
        >
            <img src={leaderboardPlayer(playerItem)} alt="player" />
        </div>;
    };

    const renderText = () => {
        if (isCancelled) {
            return (
                <p className="tw-text-subtitle-2 tw-text-grey-1000 tw-px-10 tw-text-center">
                    <FormattedMessage id="contest_leaderboard_view_message_cancel" />
                </p>
            );
        }

        if (joinedTeamCount === 0) {
            return (
                <div className="tw-space-y-0.5 tw-text-center tw-text-h6 tw-text-grey-1000">
                    <p className="tw-text-h4 tw-text-secondary-500 tw-font-bold">
                        <FormattedMessage id="contest_leaderboard_view_message_nobody_joined_1" />
                    </p>
                    <p>
                        <FormattedMessage id="contest_leaderboard_view_message_nobody_joined_2" />
                    </p>
                </div>
            );
        }

        if (hasJoined) {
            if (joinedTeamCount === myJoinedTeams) {
                return (
                    <div className="tw-space-y-0.5 tw-text-center tw-text-h6 tw-text-grey-1000">
                        <p className="tw-text-h4 tw-text-primary-700 tw-font-bold">
                            <FormattedMessage id="label_you" />
                        </p>
                        <p>
                            <FormattedMessage id="contest_leaderboard_view_message_joined" />
                        </p>
                        <p>
                            <FormattedMessage id="contest_leaderboard_view_message_you_have_joined" />
                        </p>
                    </div>
                );
            }

            return (
                <div className="tw-space-y-0.5 tw-text-center tw-text-h6 tw-text-grey-1000">
                    <p className="tw-space-x-1">
                        <span className="tw-text-h4 tw-text-primary-700 tw-font-bold">
                            <FormattedMessage id="label_you" />
                        </span>
                        <span>
                            <FormattedMessage id="label_and" />
                        </span>
                        <span className="tw-text-h4 tw-text-secondary-500 tw-font-bold">
                            {toUniversalFormattedNumber(joinedTeamCount - myJoinedTeams)}
                        </span>
                    </p>
                    <p>
                        <FormattedMessage id="contest_leaderboard_view_message_joined" />
                    </p>
                    <p>
                        <FormattedMessage id="contest_leaderboard_view_message_you_have_joined" />
                    </p>
                </div>
            );
        }


        if (joinedTeamCount >= 1) {
            return (
                <div className="tw-space-y-0.5 tw-text-center tw-text-h6 tw-text-grey-1000">
                    <p className="tw-text-h4 tw-text-secondary-500 tw-font-bold">{joinedTeamCount}</p>
                    <p>
                        <FormattedMessage id="contest_leaderboard_view_message_joined" />
                    </p>
                    <p>
                        <FormattedMessage id="contest_leaderboard_view_message_you_havent_joined" />
                    </p>
                </div>
            );
        }

        return null;
    };

    return (
        <>
            <div className="tw-space-y-6 tw-w-full tw-max-w-[390px] tw-mx-auto tw-py-2">
                {renderText()}

                <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-3 w-full">
                    {
                        players.map((player, outerIndex) => (
                            <div key={outerIndex} className="tw-flex tw-gap-3">
                                {
                                    player.map((item, innerIndex) => (
                                            <React.Fragment key={innerIndex}>
                                                {renderPlayer(item, outerIndex, innerIndex)}
                                            </React.Fragment>
                                        )
                                    )
                                }
                            </div>
                        ))
                    }

                </div>
            </div>
            {
                !isCancelled && (
                    <div className="tw-fixed tw-left-0 tw-bottom-0 tw-w-full tw-max-w-app-max tw-h-full">
                        <img src={leaderboardBg} className="tw-object-cover tw-w-full tw-h-full" alt="leaderboard" />
                    </div>
                )
            }
        </>
    );
};

export default NotRankedYetLeaderboard;
